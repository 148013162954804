<div class="custom-container">
  <span class="mat-error-2">1</span>
  <div class="col-12 text-center" *ngIf="closedDatesError">
    <h1>{{closedDatesError}}</h1>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="month" *ngFor="let month of months , let i = index"
           [ngClass]="{'active': displayedMonth == i }">
        <div class="header">
          <div class="monthHeader">
            <div class="monthName"><span>{{month.longName}}</span></div>
            <div class="monthYear"><span>{{month.year}}</span></div>
            <div *ngIf="displayedMonth > 0" class="monthArrow previousMonthArrow" (click)="previousMonth()">
              <img alt="" src="/assets/images/left-arrow.svg">
            </div>
            <div *ngIf="displayedMonth < 10" class="monthArrow nextMonthArrow" (click)="nextMonth()">
              <img alt="" src="/assets/images/right-arrow.svg">
            </div>
          </div>
          <div class="weekDays">
            <span *ngFor="let day of days">{{day.shortName}}</span>
          </div>
        </div>
        <ng-container *ngIf="closedDates">
          <div class="monthDays">
            <div class="monthDayContainer disabledDate" *ngFor="let day of toArray(month.firstDayWeekPosition);"></div>
            <ng-container *ngFor="let day of month.numberOfDays; let i = index">
              <div class="monthDayContainer">
                <ng-container *ngIf="isClosed(month ,i)">
                  <div class="monthDay closedDate {{'date-'+month.year+'-'+month.number+'-'+(i+1)}}">
                    <span class="day">{{(i + 1)}}</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="!isClosed(month ,i)">
                  <ng-container *ngIf="notAllowedDate(month ,i)">
                    <div class="monthDay closedDate {{'date-'+month.year+'-'+month.number+'-'+(i+1)}}">
                      <span class="day">{{(i + 1)}}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!notAllowedDate(month ,i)">
                    <div class="monthDay {{'date-'+month.year+'-'+month.number+'-'+(i+1)}}"
                         (click)="selectDay(month , i)">
                      <span class="day">{{(i + 1)}}</span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!closedDates">
          <div class="col-12">
            <div class="row justify-content-center" style="padding-top: 25%">
              <i style="font-size: 2rem" class="fa fa-spinner fa-spin"></i>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-12 text-center selectDayError" *ngIf="selectDayError">
    <span>عفوا اليوم الذى قمت باختياره مغلق برجاء اخيار يوم اخر</span>
  </div>
</div>
