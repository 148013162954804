import {Component, Inject, OnInit} from '@angular/core';
import {DialogData} from "../pages/booking/booking.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-certificates',
  templateUrl: './dialog-certificates.component.html',
  styleUrls: ['./dialog-certificates.component.scss']
})
export class DialogCertificatesComponent implements OnInit {
  certificateId!:any;
  activityID!: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.certificateId = this.data['certificateId'];
    console.log(this.data,'this.datathis.data');
    this.activityID = this.data['certificateId'];
  }

}
