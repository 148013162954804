import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ApisService } from "src/app/services/apis.service";
import { CookieService } from "ngx-cookie-service";
import { LocalStorageService } from "../../services/webApis/local-storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Clipboard } from "@angular/cdk/clipboard";
import { AppComponent } from '../../app.component';
import { WindowService } from 'src/app/services/webApis/window.service';

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss']
})
export class PolicyDetailsComponent implements OnInit {
  loadings = [

    { width: '300px' },
    { width: '300px' },
    { width: '300px' },    { width: '300px' },

  ];
  loadingsShare = [
    { width: '40px' },
    { width: '40px' },
    { width: '40px' },
    { width: '50px' },
  ];


  constructor(
    private localStorage: LocalStorageService,
    private _ActivatedRoute: ActivatedRoute,
    private apis: ApisService,
    private cookie: CookieService,
    private _Router: Router,
    private modalService: NgbModal,
    private router: Router,
    private clipboard: Clipboard,
    private _Window: WindowService
  ) { }
  ngOnInit(): void {
  }

}
