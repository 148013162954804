import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApisService} from '../../services/apis.service';

@Component({
  selector: 'app-become-volunteer-form',
  templateUrl: './become-volunteer-form.component.html',
  styleUrls: ['./become-volunteer-form.component.scss']
})
export class BecomeVolunteerFormComponent implements OnInit {
  form: FormGroup;
  countries: any;
  fileToUpload: any;
  fileToUploadFront: any;
  fileToUploadBack: any;
  fileToUploadClearance: any;
  image: any;
  imageFront: any;
  imageBack: any;
  imageClearance: any;
  ImagePath: any;
  message: any;
  selected: any = 'A week';
  date: any;
  governorates: any;
  clubs: any;
  email: any;
  spinner: any = false;
  right: any = false;
  right2 = false;
  spinner2 = false;
  rightSsn = false;
  spinnerSsn = false;
  phone: any;
  ssn: any;
  constructor(private apis: ApisService,) { }

  ngOnInit(): void {
    this.getCountries();
    this.form = this.initForm();
  }
  initForm(): FormGroup {
    return new FormGroup({
      fullName_ar: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z\\s]*$')]),
      fullName_en: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z\\s]*$')]),
      email: new FormControl('', [Validators.minLength(3), Validators.required]),
      address: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]),
      // ssn: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      join_date: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      governorate: new FormControl('', [Validators.required]),
      clubs: new FormControl('', [Validators.required]),
      education: new FormControl('', [Validators.required]),
      work: new FormControl('', [Validators.required]),
      voluntaryWork: new FormControl('', [Validators.required]),
      // schedule: new FormControl('', [Validators.required]),
    });
  }

  getPosts(e): any {
    const obj = this.getCountriesObject(e);
    this.getGovernorates(obj.id);
  }

  appendMainClubs(e): any {
    const obj = this.getGovernoratesObject(e);
    this.getClubs(obj.id);
  }

  getCountries(): any {
    this.apis.getCountries().subscribe((data: any) => {
        this.countries = data.data;
      },
      err => {
        console.log(err);
      });
  }

  getGovernorates(id): any {
    this.apis.getGovernorates(id).subscribe((data: any) => {
        this.governorates = data.data;
      },
      err => {
        console.log(err);
      });
  }

  getClubs(id): any {
    this.apis.getClubs(id).subscribe((data: any) => {
        this.clubs = data.data;
      },
      err => {
        console.log(err);
      });
  }

  getCountriesObject(purpose): any {
    const index = this.countries.findIndex(obj => obj.name_en === purpose);
    if (index > -1) {
      return this.countries[index];
    }
  }

  getGovernoratesObject(purpose): any {
    const index = this.governorates.findIndex(obj => obj.name_en === purpose);
    if (index > -1) {
      return this.governorates[index];
    }
  }

  getClubsObject(club): any {
    const index = this.clubs.findIndex(obj => obj.name_en === club);
    if (index > -1) {
      return this.clubs[index];
    }
  }
  close(): void {
    // this.dialogRef.close(this.addMemberForm());
  }
  dateFormat(date): any {
    date = new Date(date);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = +'0' + dt;
    }
    if (month < 10) {
      month = +'0' + month;
    }
    return (year + '-' + month + '-' + dt);
  }

  checkMail(): void {
    this.message = '';
    const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (this.form.value.email?.match(mailFormat)) {
      this.message = '';
      this.right = false;
      this.spinner = true;
      this.apis.checkMail(this.email).subscribe((data: any) => {
          if (data.success === true) {
            this.spinner = false;
            this.right = true;
          } else {
            this.spinner = false;
            this.message = 'The email has already been taken.';
            this.form.controls['email'].setErrors({'incorrect': true, someProp: 'The email has already been taken.'});
          }
        },
        err => {


        });

    } else {
      this.message = 'You have entered an invalid email address!';
      this.form.controls['email'].setErrors({'incorrect': true, someProp: 'You have entered an invalid email address'});
    }
  }

  checkPhone(): void {
    this.message = '';
    if (this.form.get('phone').errors == null) {
      this.message = '';
      this.right2 = false;
      this.spinner2 = true;
      this.apis.checkPhone(this.phone).subscribe((data: any) => {
          if (data.success === true) {
            this.spinner2 = false;
            this.right2 = true;
          } else {
            this.spinner2 = false;
            this.message = 'The email has already been taken.';
            this.form.controls['phone'].setErrors({'incorrect': true, someProp: 'The email has already been taken.'});
          }
        },
        err => {


        });

    } else if (this.form.get('phone').errors) {
      this.message = 'You have entered an invalid phone number !';
      this.form.controls['phone'].setErrors({'incorrect': true, someProp: 'You have entered an invalid Phone number'});
    }
  }

  checkSsn(): void {
    this.message = '';
    this.rightSsn = false;
    if (this.form.get('ssn').errors == null) {
      this.message = '';
      this.rightSsn = false;
      this.spinnerSsn = true;
      this.apis.checkSsn(this.ssn).subscribe((data: any) => {
          if (data.success === true) {
            this.spinnerSsn = false;
            this.rightSsn = true;
          } else {
            this.spinnerSsn = false;
            this.message = 'The SSN has already been taken.';
            this.form.controls['ssn'].setErrors({'incorrect': true, someProp: 'The SSN has already been taken.'});
          }
        },
        err => {


        });

    } else if (this.form.get('ssn').errors) {
      this.message = 'You have entered an invalid ssn !';
      this.form.controls['ssn'].setErrors({'incorrect': true, someProp: 'You have entered an invalid Ssn'});
    }
  }

  checkDate(e): void {
    this.date = new Date().getTime();
    var due = new Date(this.dateFormat(e.value)).getTime();
    if (this.date >= due) {
      this.form.controls['due_date'].setErrors({'incorrect': true, someProp: 'You must choose a day after today'});
    }
  }
}
