import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApisService} from '../../services/apis.service';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-video-description',
  templateUrl: './video-description.component.html',
  styleUrls: ['./video-description.component.scss']
})
export class VideoDescriptionComponent implements OnInit {
  videos: any;
  id: any;
  video: any;
  title: any;
  description: any;
  date: any;
  latestNews: any;

  constructor(private route: ActivatedRoute, private apis: ApisService, private router: Router, private seoService: SeoService) {
  }

  ngOnInit(): void {
    // this.route.params.subscribe(params => {
    //   this.id = +params['id'];
    // });
    // this.apis.videosApi().subscribe((response: any) => {
    //   this.videos = response.data;
    //   console.log(this.videos);
    //   for (const video of this.videos) {
    //     if (this.id === video['id']) {
    //       this.title = video['title_ar'];
    //       this.seoService.updateTitle(this.title);
    //       this.description = video['description_ar'];
    //       this.seoService.updateDescription(this.description);
    //       this.date = video['date'];
    //       this.video = video['video'];
    //     }
    //   }
    // }, err => {
    //   console.log(err);
    // });
    // this.getNews();
  }

  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateUrl();
  }

  newsDetails(id): void {
    this.router.navigate(['/news-details/' + id]);
  }

  getNews(): void {
    this.apis.newsApi().subscribe((data: any) => {
      this.latestNews = data.data;
      console.log(this.latestNews);

    }, (err: any) => {
    });
  }

  transform(n: string): string {
    var date = new Date(n);
    var months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    var days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }

  prevent(e): void {
    e.preventDefault();
  }
}
