import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-members-slider',
  templateUrl: './members-slider.component.html',
  styleUrls: ['./members-slider.component.scss']
})
export class MembersSliderComponent implements OnInit {
  @Input() boardMembers: any;
  carouselOptions2: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 1},
      992: {items: 3}
    },
    items: 3,
    center: true,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };
  constructor(private router: Router,) { }

  ngOnInit(): void {
  }
  getMemberInfo(id:any)
  {
    this.boardMembers.board_id ? this.router.navigate(['/memberDetails/',this.boardMembers.board_id ,id]) :this.router.navigate(['/memberDetails/', id ,id])
    console.log(this.boardMembers.board_id)
  }
}
