import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {SeoService} from '../../services/seo.service';
import {ApisService} from '../../services/apis.service';
import {PopUpComponent} from '../../pop-up/pop-up.component';
import {MatDialog} from '@angular/material/dialog';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Clipboard} from "@angular/cdk/clipboard";
import {ToastrService} from "ngx-toastr";
import { DialogToastComponent } from 'src/app/dialog-toast/dialog-toast.component';
import { DonationFormComponent } from '../../donation-form/donation-form.component';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormControl, FormGroup, FormBuilder , Validators} from '@angular/forms';

// @ts-ignore
@Component({
    selector: 'app-event-details',
    templateUrl: './event-details.component.html',
    styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
    eventObj: any;
    user: any;
    token: any;
    events: any;
    allUpcoming: any;
    activities: any;
    latestActivity: any;
    activitiey: any;
    tasksbelongactivity: any;
    tasksId: number
    read: any;
    slug = this.activatedRoute.snapshot.params.slug;
    showAll: boolean = true;
    videos: any = [];
    // dialog: any;
    bgProgress: string = '';
    copied = false;
    test: any;
    lang: string;
    long: string;
    metaTitle: string;
    metaDescription: string;
    metaImage: string;
    currentUser: any = [];
    role: any;
    modalReference: any;
    formD: FormGroup;
    eventId: any;
    EnentId: string | Blob;
    formSubmitted: boolean;
   
    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private route: ActivatedRoute,
                public dialog: MatDialog,
                private seoService: SeoService,
                private activatedRoute: ActivatedRoute,
                private apis: ApisService,
                private router: Router,
                private clipboard: Clipboard,
                private toastr: ToastrService,
                private modalService: NgbModal,
                private formBuilder: FormBuilder,
    ) {
        this.getActivitiesApi();
        if (isPlatformBrowser(this.platformId)) {
            this.token = localStorage.getItem('access_token');
        }
        this.long = localStorage.getItem('lang');
                                                  
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
        this.role = this.currentUser?.role;
        console.log(this.currentUser ,' this.currentUser ')
        console.log(this.role ,' this.role ')
        this.formD = this.formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.email]],
            phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
          });
    }


    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.user = JSON.parse(localStorage.getItem('currentUser'))
            console.log(this.user)
        }
        this.slug = this.activatedRoute.snapshot.params.slug;
        this.getEventsApi();

        // this.seoUpdate(this.activities.activities.meta_title, this.activities.activities.meta_description, this.activities.activities.images[0].url);
        this.getEvents();
        this.getUpcoming();
        this.getLatestActivity();
        this.getSupportEvent();
    }

    seoUpdate(test) {
        this.metaTitle = test.activities.name;
        this.metaDescription = test.activities.meta_description;            
        this.metaImage = test.activities.images[0].url;
        this.seoService.updateUrl();
        if (this.metaTitle != null) {
            this.seoService.updateTitle(this.metaTitle);
        }
        if (this.metaDescription != null) {
            this.seoService.updateDescription(this.metaDescription);
        }
        if (this.metaImage != null) {
            this.seoService.updateImage(this.metaImage);
        }
    }

    showMap(fragment) {
        const element = document.getElementById(fragment);
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
    getUpcoming(): void {
        this.apis.getUpcomingEvents().subscribe((data: any) => {
            this.allUpcoming = data.data;
            // console.log('mmmmmmmmmmmmmmm',this.allUpcoming);
        }, (err: any) => {
        });
    }
    getSupportEvent(): void {
        this.apis.getSupportEvent().subscribe((data: any) => {
            this.activitiey = data.data;
            // console.log('mam', this.events)

        }, (err: any) => {
        });
    }
    getLatestActivity(): void {
        this.apis.latestActivity().subscribe((data: any) => {
            this.latestActivity = data.data;
            //  console.log('mamamam', this.latestNew)

        }, (err: any) => {
        });
    }

    getEventsApi(): void {
        this.apis.getEvents()?.subscribe((data: any) => {
            this.events = data?.data;
        }, (err: any) => {
        });
    }

    getTasksBelongActivityAPI(ID): any {
        this.apis.getTasksBelongActivity(ID).subscribe((data: any) => {
                this.tasksbelongactivity = data.data;
                console.log(this.tasksbelongactivity)
            },
            err => {
                console.log(err);
            });
    }

    getActivitiesApi(): void {
        this.apis.getActivities(this.slug).subscribe((data: any) => {
            console.log(data);
            this.activities = data.data;
            console.log(this.activities.activities);
            this.tasksId = this.activities.activities.id;
            console.log(this.tasksId)
            this.getTasksBelongActivityAPI(this.tasksId);
            this.activatedRoute.queryParams.subscribe(query=>{
                console.log('query' , query)
                this.lang = query.lang;
                // if(this.lang=='ar'){
                    this.apis.getActivitiesLang(this.slug,this.lang).subscribe((data: any) => {

                        this.test = data.data;
                        this.seoUpdate(this.test);
                    });
                // }
                // if(this.lang=='en'){
                //     this.apis.getActivities(this.slug).subscribe((data: any) => {
                //         this.test = data.data;
                //         this.seoUpdate(this.test);
                //         console.log('afkjafkjafk',this.test)

                //     });
                // }
            })
            if ((this.activities.activities.budget / this.activities.activities.target) * 100 < 25) {
                this.bgProgress = 'bg-danger';
            }
            if ((this.activities.activities.budget / this.activities.activities.target) * 100 >= 25 && (this.activities.activities.budget / this.activities.activities.target) * 100 <= 50) {
                this.bgProgress = 'bg-warning';
            }
            if ((this.activities.activities.budget / this.activities.activities.target) * 100 >= 50 && (this.activities.activities.budget / this.activities.activities.target) * 100 < 100) {
                this.bgProgress = '';
            }
            if ((this.activities.activities.budget / this.activities.activities.target) * 100 >= 100) {
                this.bgProgress = 'bg-success';
            }
            this.videos = this.activities.activities.videos;
            console.log(this.videos)

        }, (err: any) => {
        });
    }

    getEvents(): void {
        this.apis.getEventsApi().subscribe((data: any) => {
            this.read = data.data;
            console.log(this.read);
        }, (err: any) => {
        });
    }

    timeFormat(time: any) {
        var ts = time;
        var H = +ts.substr(0, 2);
        var h: any = (H % 12) || 12;
        h = (h < 10) ? (' 0' + h) : h;  // leading 0 at the left for 1 digit hours
        var ampm = H < 12 ? ' AM' : ' PM';
        ts = h + ts.substr(2, 3) + ampm;
        return ts;
    }

    eventDetails(slug): void{
        this.slug = slug
        this.router.navigate(['/eventDetails/' + slug],{queryParams:{lang:localStorage.getItem('lang')}});
        console.log('language',localStorage.getItem('lang'));
        this.getActivitiesApi()
      }

    refresh(slug) {
        // @ts-ignore
        if (typeof document !== 'undefined') {
            window.scrollTo(0, 0);
        }
        this.slug = slug;
        // this.getEventsApi();
        this.getActivitiesApi();
    }

    changeFlag() {
        this.showAll = !this.showAll;
    }

    openDialog(startDate) {
        this.dialog.open(PopUpComponent, {
            data: startDate
        });
    }

    getVolunteerActivities(id: number) {
        if(this.user == null){
            this.router.navigate(['/personal-form/' ],{ queryParams: {lang:this.lang,activityId:id}});
            return
        }
        this.apis.VolunteerActivitiesApi(id).subscribe({
            next: (res: any) => {
                console.log(res);
                if(res.message == 'Sent Your Request To Review'){
                    this.openToast();
                } else{
                this.toastr.success(res.message, 'Success');
                }
            }, error: (err: any) => {
                console.log(err)
                this.toastr.error(err.error.message, 'Failed');
            }
        })
    }

    cop() {
        this.clipboard.copy('https://newegyptgroup.com/eventDetails/' + this.slug +'?lang='+ this.lang)
        this.copied = !this.copied;
        setTimeout(() => {
            this.copied = false;
        }, 1500)

    }

    fbc(e) {
        let url = "www.google.com";
        e.preventDefault();
        var facebookWindow = window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" +
            'https://newegyptgroup.com/eventDetails/' + this.slug +'?lang='+ this.lang,
            "facebook-popup",
            "height=720px,width=555px"
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }

        return false;
    }

    tw() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://twitter.com/intent/tweet?url=' +'https://newegyptgroup.com/eventDetails/' + this.slug +'?lang='+ this.lang,
            'twitter-popUp',
            'width= 555px, height= 450px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' +  'https://newegyptgroup.com//eventDetails/' + this.slug +'?lang='+ this.lang,
            'linkedin-popUp',
            'width= 555px, height= 603px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    openToast() {
        // console.log(event.activity.id)
        // this.eachEvent = event
        const dialogRef = this.dialog.open(DialogToastComponent,{
            panelClass: 'my-great-class',
            
          data: {
            // certificateId: event.activity.id
          }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
          });
    }
    openDonationForm() {
        const dialogRef = this.dialog.open(DonationFormComponent , {
          height: '90vh',
          width: '25vw',
          data :{id: this.tasksId}
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
      }

      
      openTransfer(contactBox , id) {
        this.eventId = id;
        console.log(this.eventId , 'this.eventId');
        this.modalReference= this.modalService.open(contactBox, {
          centered: true,
          size: 'md'
        });}

        closeModal() {
            this.modalReference.close();
          }


          contactUsDown(){
            const formDA = new FormData();
            formDA.append('name', this.formD.value.name );
            formDA.append('email', this.formD.value.email );
            formDA.append('phone', this.formD.value.phone );
            formDA.append('activity_id', this.eventId);
            this.apis.contactUsDown(formDA).subscribe((data:any) => {
                this.formD.reset();
                alert("Sended Successfuly")
                },
                err => {
                  alert("Something Error")

                  this.formSubmitted = true;
                });

                // this.formD.reset();
                this.closeModal();
          }
}
