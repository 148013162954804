import {Component, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SeoService} from "../../services/seo.service";
import {Router} from "@angular/router";
import {Clipboard} from "@angular/cdk/clipboard";
import {ThemePalette} from '@angular/material/core';

interface dates {
    from: string;
    to: string;
}

@Component({
    selector: 'app-boards',
    templateUrl: './boards.component.html',
    styleUrls: ['./boards.component.scss']
})
export class BoardsComponent implements OnInit {
    loading:boolean = false ;
    form = new FormGroup({
    board_id: new FormControl(''),
    club_id: new FormControl(''),
  });
    clubs_id:any;
    members: any;
    boards: any = null;
    president: any;
    secretary: any;
    treasurer: any;
    clubs: any;
    first: any;
    board_id:any;
    members_id:any;
    boardMember: any = null;
    getBoardClubsMember: any[] = [];
    carouselOptions2: OwlOptions = {
        navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
        responsive: {
            0: {items: 1},
            768: {items: 1},
            992: {items: 3}
        },
        items: 1,
        loop: true,
        autoplay: true,
        dots: false,
        nav: true,
    };
    copied= false;
    metaTitle:string='New Egypt International Board of Directors';
    metaDescription:string='The New Egypt Group is a non-profit organization that builds bridges between the Egyptian community in America and their beloved country, Egypt. Our mission is to aid rural poverty areas around the world through donations from kind hearted people. We aim to improve education systems, water supplies, increase employment and food distribution and';
    metaImage:any='https://newegyptgroup.com/assets/newIcon/home page share.jpg';

    constructor(private apis: ApisService, private seoService: SeoService,private router: Router,private clipboard: Clipboard,) {

    }

    ngOnInit(): void {
        // this.getAllEvents();
        this.getClubs();
        this.seoUpdate(this.metaTitle,this.metaDescription,this.metaImage);
    }

    // getAllEvents(): any {
    //     this.apis.getBoards().subscribe((res: any) => {
    //         this.members = res.data.members;
    //         this.president = res.data.presidnet;
    //         this.secretary = res.data.secretary;
    //         this.treasurer = res.data.treasurer;
    //       console.log(this.members , 'result Data');
    //     }, err => {
    //         console.log(err);
    //     });
    // }

    getClubs(): any {
        this.apis.GetClubs().subscribe((data: any) => {
            this.clubs = data.data;
            this.clubs_id = this.clubs[0].club_id;
            this.getBoard(this.clubs_id);
            this.getBoardClubs(this.clubs_id)
            // this.getBoard(this.clubs[0].club_id);
            // this.getBoardClubs(this.clubs[0].club_id);
            // console.log(data , 'this clubs data')
        },
            err => {
            console.log(err);
          });
    }

    getBoard(clubId:any) {
        this.apis.getBoardByClubs(clubId).subscribe((data: any) => {
            this.boards = data.data;
            this.board_id = this.boards[0]?.id;
            this.getBoardMember(this.board_id,clubId);
            // this.getBoardClubs(clubId)
        },
          err => {
            console.log(err);
          });
    }
  getBoardClubs(club_id: any) {
    this.apis.getBoardClubs(club_id).subscribe((data: any) => {
      this.getBoardClubsMember = data.data;
    },
      err => {
        console.log(err);
      });
  }

  getBoardMember(board_id:number , club_id:number ){
      this.loading = true;
      this.form.patchValue({
        board_id: board_id,
        club_id: club_id,
      })
    this.apis.getBoardMembers(board_id , club_id).subscribe((data: any) => {
        console.log(board_id,club_id)
      this.boardMember = data.data;
        console.log(this.boardMember);
        this.loading = false;
      // this.first = this.boardMember[0].boardMember;
      // this.getMembersPeriod(this.boardMember[0].id)
    },
      err => {
        console.log(err);
        this.loading = false;
      });
  }
    // getMembersPeriod(id: any) {
    //     this.boardMember = null;
    //     this.members_id = id ;
    //     this.apis.getBoardMembers(id ,this.clubs_id ).subscribe((data: any) => {
    //         this.boardMember = data.data;
    //       console.log(this.boardMember)
    //       this.first = this.boardMember[0].boardMember;
    //       this.getMembersPeriod(this.boardMember.id)
    //     },
    //       err => {
    //         console.log(err);
    //       });
    //   this.getBoardMember(id);
    // }

    seoUpdate(
        title = null,
        description = null,
        image = null,
    ) {
        if (title != null) {
            this.seoService.updateTitle(title)
        }
        if (description != null) {
            this.seoService.updateDescription(description)
        }
        if (image != null) {
            this.seoService.updateImage(image)
        }
        this.seoService.updateUrl()

    }

    cop() {
        this.clipboard.copy('https://newegyptgroup.com' + this.router.url);
        this.copied = !this.copied;
        setTimeout(() => {
            this.copied = false;
        }, 1500)

    }

    fbc(e) {
        let url = "www.google.com";
        e.preventDefault();
        var facebookWindow = window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" +
            "newegyptgroup.com" +
            this.router.url,
            "facebook-popup",
            "height=700px,width=555px"
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }

        return false;
    }

    tw() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://twitter.com/intent/tweet?url=' + 'newegyptgroup.com' + this.router.url,
            '-popUp',
            'width= 555px, height= 450px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' + 'newegyptgroup.com' + this.router.url,
            '-popUp',
            'width= 555px, height= 603px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

}
