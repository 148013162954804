<!-- <div class="container-fluid"> -->
<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">

<div class="handelMargin"></div>
<mat-tab-group>
  <mat-tab  label="{{ 'home.happening' | translate }}" ng-model="myModel"><h1 class="title"></h1>
    <section id="offers" class="section-with-bg ">
      <div class="container-fluid">
        <div class="row mx-16 ">
          <div class="col-4 col-md-4 col-lg-4 col-sm-4 my-3"*ngFor="let val of allHappening"  >
            <div class="post-item position-relative h-100" (click)="EventDetails(val.slug);">
              <div class="post-img position-relative overflow-hidden" >
                <img *ngIf="val.image"
                     src="{{val.image}}" class="img-fluid" alt="{{val.title}}" style="height: 250px !important; width: 100%; object-fit: cover;">
                <span class="post-date" dir="ltr"> {{val.start_date | date :'d  MMM  yyy'}}
<!--                  <img src="assets/icons/clock-circular-outline.png" class="handelClock">-->
<!--                  {{timeFormat(val.start_at) }} - {{timeFormat(val.end_at)}}-->
                  </span>
              </div>

              <div class="post-content d-flex flex-column">
                <div class="handelTitle">
                  <h3 class="post-title">{{val.name.slice(0,100)}}</h3>
                </div>
                <div class="location">
                  <i class="fa fa-map-marker"></i>
                  {{val.address}}
                </div>
                <div class="desHandel">
                  <p>
                    {{val.excerpt.slice(0,50)+'...'}}
                  </p>
                </div>
                
                <hr>
                <a  class="readmore stretched-link"><span translate>home.details</span>
<!--                  <i class="bi bi-arrow-right"></i>-->
                </a>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <nav aria-label="..." class="d-flex justify-content-center my-5 py-5 paginator">
      <ul class="pagination">
        <li class="page-item " [style]="pageHappening == 1 ? 'pointer-events: none':''">
          <a class="page-link" href="javascript:void(0);" tabindex="-1"
             (click)="getHappening(pageHappening-1)">Previous</a>
        </li>
        <li [class]="pageHappening == i+1 ? 'active': '' " class="page-item " *ngFor="let _ of [].constructor(collectionSizeHappening); let i = index ">
          <a class="page-link" href="javascript:void(0);" (click)="getHappening(i+1)">{{i + 1}}
          </a>
        </li>
        <li class="page-item" [style]="pageHappening == collectionSizeHappening ? 'pointer-events: none':''">
          <a class="page-link" href="javascript:void(0);" (click)="getHappening(pageHappening+1)">Next</a>
        </li>
      </ul>
    </nav>
  </mat-tab>
  <mat-tab label="{{ 'home.upcoming' | translate }}" ng-model="myModel"><h1 class="title"></h1>
    <section id="offers" class="section-with-bg">
      <div class="container-fluid" >

        <div class="row mx-16 ">
          <div class="col-4 col-md-4 col-lg-4 col-sm-4 my-3"*ngFor="let val of allUpcoming"  >
            <div class="post-item position-relative h-100" (click)="EventDetails(val.slug);">
              <div class="post-img position-relative overflow-hidden" >
                <img *ngIf="val.image"
                     src="{{val.image}}" class="img-fluid" alt="{{val.title}}" style="height: 250px !important;width: 100%; object-fit: cover;">
                <span class="post-date" dir="ltr">
<!--                  <img src="assets/icons/clock-circular-outline.png" class="handelClock">-->
<!--                  {{timeFormat(val.start_at) }} - {{timeFormat(val.end_at)}}-->
                  {{val.start_date | date :'d  MMM  yyy'}}
                </span>
              </div>

              <div class="post-content d-flex flex-column">
                <div class="handelTitle">
                  <h3 class="post-title">{{val.name.slice(0,100)}}</h3>
                </div>
                <div class="location">
                  <i class="fa fa-map-marker"></i>
                  {{val.address}}
                </div>
                <div class="desHandel">
                  <p>
                    {{val.excerpt.slice(0,50)+'...'}}
                  </p>
                </div>
                
                <hr>
                <a  class="readmore stretched-link"><span translate>home.details</span>
<!--                  <i class="bi bi-arrow-right"></i>-->
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <nav aria-label="..." class="d-flex justify-content-center my-5 py-5 paginator">
      <ul class="pagination">
        <li class="page-item " [style]="pageUpcoming == 1 ? 'pointer-events: none':''">
          <a class="page-link" href="javascript:void(0);" tabindex="-1"
             (click)="getUpcoming(pageUpcoming-1)">Previous</a>
        </li>
        <li [class]="pageUpcoming == i+1 ? 'active': '' " class="page-item " *ngFor="let _ of [].constructor(collectionSizeUpcoming); let i = index ">
          <a class="page-link" href="javascript:void(0);" (click)="getUpcoming(i+1)">{{i + 1}}
          </a>
        </li>
        <li class="page-item" [style]="pageUpcoming == collectionSizeUpcoming ? 'pointer-events: none':''">
          <a class="page-link" href="javascript:void(0);" (click)="getUpcoming(pageUpcoming+1)">Next</a>
        </li>
      </ul>
    </nav>
  </mat-tab>
  <mat-tab label="{{ 'home.expired' | translate }}" ng-model="myModel"> <h1 class="title"></h1>
    <section id="offers" class="section-with-bg">
      <div class="container-fluid" >
        <div class="row mx-16 ">
          <div class="col-4 col-md-4 col-lg-4 col-sm-4 my-3"*ngFor="let val of allExpired"  >
            <div class="post-item position-relative h-100" (click)="EventDetails(val.slug);">
              <div class="post-img position-relative overflow-hidden" >
                <img *ngIf="val.image"
                     src="{{val.image}}" class="img-fluid" alt="{{val.title}}" style="height: 250px !important; width: 100%; object-fit: cover;">
                <span class="post-date" dir="ltr">
<!--                  <img src="assets/icons/clock-circular-outline.png" class="handelClock">-->
<!--                  {{timeFormat(val.start_at) }} - {{timeFormat(val.end_at)}}-->
                  {{val.start_date | date :'d  MMM  yyy'}}
<!--                  -  {{val.end_date | date :'d  MMM  yyy'}}-->
                </span>
              </div>

              <div class="post-content d-flex flex-column">
                <div class="handelTitle">
                  <h3 class="post-title">{{val.name.slice(0,100)}}</h3>
                </div>
                <div class="location">
                  <i class="fa fa-map-marker"></i>
                  {{val.address}}
                </div>
                <div class="desHandel">
                  <p>
                    {{val.excerpt.slice(0,50)+'...'}}
                  </p>
                </div>
                
                <hr>
                <a  class="readmore stretched-link"><span translate>home.details</span>
<!--                  <i class="bi bi-arrow-right"></i>-->
                </a>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <nav aria-label="..." class="d-flex justify-content-center my-5 py-5 paginator">
      <ul class="pagination">
        <li class="page-item " [style]="pageExpired == 1 ? 'pointer-events: none':''">
          <a class="page-link" href="javascript:void(0);" tabindex="-1"
             (click)="getExpired(pageExpired-1)">Previous</a>
        </li>
        <li [class]="pageExpired == i+1 ? 'active': '' " class="page-item " *ngFor="let _ of [].constructor(collectionSizeExpired); let i = index ">
          <a class="page-link" href="javascript:void(0);" (click)="getExpired(i+1)">{{i + 1}}
          </a>
        </li>
        <li class="page-item" [style]="pageExpired == collectionSizeExpired ? 'pointer-events: none':''">
          <a class="page-link" href="javascript:void(0);" (click)="getExpired(pageExpired+1)">Next</a>
        </li>
      </ul>
    </nav>
  </mat-tab>
</mat-tab-group>
<!-- </div> -->

