import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';
import {ActivatedRoute, Router} from '@angular/router';
import {ApisService} from '../../services/apis.service';
import {image} from 'html2canvas/dist/types/css/types/image';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';
import {isPlatformBrowser} from '@angular/common';
@Component({
  selector: 'app-haya-news-details',
  templateUrl: './haya-news-details.component.html',
  styleUrls: ['./haya-news-details.component.scss']
})
export class HayaNewsDetailsComponent implements OnInit {
  newsId: any;
  news: any;
  latestNews;
  videoUrl = 'https://player.vimeo.com/external/567641203.hd.mp4?s=c04076dd9a63e7d8dd2c7312cf1178db4066063b&profile_id=175';
  items: GalleryItem[];
  images: any = [];
  zero: number = 0;
  id: any;
  title: any;
  description: any;
  date: any;
  type: any;
  image: any;
  hostUrl;
  routerUrl;
  relatedNews: any;
  totalDonation: any;
  length;
  donorsNames;
  amount;
  constructor(public gallery: Gallery,
              public lightbox: Lightbox,
              private seoService: SeoService,
              private activatedRoute: ActivatedRoute,
              private apis: ApisService,
              private router: Router,
              @Inject(PLATFORM_ID) private platform: Object
  ) {
  }

  ngOnInit(): void {
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.newsId = this.activatedRoute.snapshot.params.id;
    this.videoHandler();
    this.getNews();
    this.activatedRoute.params.subscribe(params => {
      this.id = +params['id'];
    });
    this.getDonation();
    this.seoUpdate();
    if (isPlatformBrowser(this.platform)) {
      window.scrollTo(0, 0);
    }

  }
  getDonation(): any{
    this.apis.getDonation().subscribe((res: any) => {
      this.amount = res.data.credit_dontaion;
      this.donorsNames = res.data.donatives;
    }, err => {
      console.log(err);
    });
  }

  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/haya/bg-home.jpg');
    location.replace ('https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl);
    window.location.href = 'https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl;
  }

  basicLightboxExample(): void {
    this.gallery.ref().load(this.items);
  }

  /**
   * Use custom gallery config with the lightbox
   */
  withCustomGalleryConfig(): void {
    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }

  videoHandler(): void {
    if (window.screen.availWidth < 600) {
      this.videoUrl = 'https://player.vimeo.com/external/567151192.hd.mp4?s=db1921b9a7500a7a0cf1846bdaf69cec317782c5&profile_id=175';
    }
  }

  getNews(): void {
    this.apis.hayaNewsApi().subscribe((data: any) => {
      this.news = data.data;
      this.latestNews = data.data;
      this.length = data.data.length;
      for (let post of this.latestNews) {
        if (this.id == post['id']) {
          this.title = post['title'];
          this.description = post['article'];
          this.seoService.updateTitle(this.title);
          this.seoService.updateDescription(this.description);
          this.date = post['date'];
          this.image = post.images[0].image;
        }
      }

    }, (err: any) => {
    });
  }


  setPostId(e): any {
    this.router.navigateByUrl(this.router.url.replace(this.newsId, e));
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  transform(n: string): string {
    var date = new Date(n);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }

  numberWithCommas(x): any {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
