import {Component, Input, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';


@Component({
  selector: 'app-news-slide',
  templateUrl: './news-slide.component.html',
  styleUrls: ['./news-slide.component.scss']
})
export class NewsSlideComponent implements OnInit {

  @Input() donorsNames: any;
  constructor() { }

  ngOnInit(): void {
  }

}
