<div class="home-video-section mx-0 px-0 container-fluid">
<app-home-main-slider></app-home-main-slider>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 mt-5">
      <div class="text-center mb-5">
        <button class="book " routerLink="/haya" translate>home.haya-karima</button>
      </div>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header class="headers">
            <mat-panel-title>
              <button class="btn-doc" translate>home.sisi
              </button>
            </mat-panel-title>

          </mat-expansion-panel-header>
          <app-president-document></app-president-document>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-5">
      <div class="text-center">
        <button class="book" (click)="routerLink()" translate>home.case-report-form</button>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex">
    <div class="col-md-6 col-sm-12">
    <div class="row next-event">
    <p translate>home.next-event</p>
    </div>
    <div class="row next-event" *ngIf="nextevent">
    <h4>{{nextevent.name}}</h4>
      <h5>{{nextevent.excerpt}}</h5>
      <div class="read-more mt-4">
        <button [routerLink]="['/eventDetails/', nextevent.slug]" translate>home.read-more</button>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-12 ">
      <mat-accordion>
          <!--  <mat-expansion-panel [expanded]="true">
         <mat-expansion-panel-header>-->
            <!-- <mat-panel-description>
            </mat-panel-description> -->
         <!--  </mat-expansion-panel-header>

          </mat-expansion-panel>-->
         <app-calendar></app-calendar>
      </mat-accordion>
    </div>
  </div>
    <div class="col-12">
      <app-our-mission></app-our-mission>
    </div>
</div>
<app-recent-activities></app-recent-activities>
<app-recent-news></app-recent-news>
<div class="container">
  <app-home-videos-section></app-home-videos-section>
</div>


