<section class=" height-800">
  <mat-dialog-actions align="end">
    <button class="close"  mat-button mat-dialog-close>X</button>
    <!--      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>-->
  </mat-dialog-actions>
  <div  class="border-1" *ngFor="let item of Certificates ">
    <div class="border-2">
      <div class="border-3" id="print-section">
        <div class="parent w-100">
          <div class="container">
            <div class="row width-full">
              <div class="col-4">
                <div class="logo mt-2">
                  <img  src="assets/images/certificate/New%20Egypt%20Inter%20clubs.png">
<!--                  <img class="image-logo" src="assets/images/logo.png">-->
                </div>
              </div>
              <div class="col-8 mt-5 mb-2" style="text-align: left">
                <h1  style="text-align: left">NEW EGYPT GRUOP</h1>
              </div>
              <div class="col-12">
                <h6>INTERNATIONAL CLUB</h6>
              </div>
              <div class="col-12 mt-4">
                <h3>CERTIFICATE</h3>
                <h4>OF ACHIEVEMENT</h4>
              </div>
              <div class="col-12 mt-4">
                <h6>Presented To</h6>
              </div>
              <div class="col-12 mt-4">
                <span class="name" >{{item.user.fullname}}</span>
              </div>
              <div class="col-12">
                <p class="mt-4">
                  for her important contributions as a volunteer during
                  {{item.activity.name}}
                </p>
              </div>
              <div class="col-12" >
                <span class="date mb-3">
            Presented this {{item.date}}
                </span>
              </div>
              <div class="col-3 chairman">
                <hr class="hr">
                <h2>MAGED AMIN</h2>
                <span>Group Founder_Chairman of the Board of Directors</span>
              </div>
              <div class="col-3 chairman">
                <hr class="hr">
                <h2>ABED ELKESHK</h2>
                <span>Chairman Of the Board of Directors</span>
              </div>
              <div class="col-3 chairman">
                <hr class="hr">
                <h2>Aida Elmanzalawi</h2>
                <span>Executive Board Member At Large</span>
              </div>
              <div class="col-3 chairman">
                <hr class="hr">
                <h2>DR.MONA TANTAWI</h2>
                <span>Executive Board Member At Large</span>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="bg-image"><img src="./assets/images/logo.png"></div>-->
      </div>
    </div>
  </div>
  <div class="print text-center">
    <button
      class="btn "
      style="width: 150px; text-align: center;"
      styleSheetFile="src/app/components/certificate/certificate.component.css"
      printSectionId="print-section"
      [useExistingCss]="true"
      ngxPrint>  Print
    </button>
    <a class="btn btn-outline-secondary hide-print btn-block mx-2 text-center" style="width: 200px;" (click)="CertificateDown1()"
    rippleEffect>
    <i class="fa fa-download mx-1" *ngIf="!loadingDownload1"></i>
    <div *ngIf="loadingDownload1" class="spinner-border " role="status">
    </div>
    Download
</a>
  </div>
 
</section>
