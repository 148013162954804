import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {SeoService} from '../../services/seo.service';
import {AppComponent} from '../../app.component';
import {FormControl, FormGroup} from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  form: FormGroup=new FormGroup({
    fullname:new FormControl(),
    phone:new FormControl(),
    email:new FormControl(),
    subject:new FormControl(),
    message:new FormControl(),
  });
  constructor(private seoService: SeoService,private apis: ApisService,) { }

  ngOnInit(): void {
    this.seoUpdate();
  }
  seoUpdate():any {
    this.seoService.updateConstMetaTags();

    this.seoService.updateTitle('New Egypt Group | contact us');
    this.seoService.updateDescription('New Egypt Group is a non-profit organization that builds bridges between the Egyptian community in America and their beloved country, Egypt. It carries out many activities including education initiatives for children as well empowerment of women so they can build futures generations upon generation sweepingly');
    this.seoService.updateUrl();
    this.seoService.updateImage( 'https://newegyptgroup.com/assets/images/menu-photos/contact-us.png');
  }

  addContact():any {
    this.apis.contactUs(this.form.value).subscribe((data:any) => {
      this.form.reset();
      alert("Sended Successfuly")
      },
      err => {
        alert("Something Error")
      });
  }

}
