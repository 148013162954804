<img style="width: 100%; margin-bottom: 1px" src="assets/images/gray-plane-wing-62623.png">

<div class="container">
<!--  <mat-tab-group>-->
<!--    <mat-tab label="Clubs">-->
<!--      <div class="row mt-3">-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class="post" tabindex="0">-->
<!--            <div class="photo"-->
<!--                 style="background-image: url('https://api.flyallover.com/storage/bQ5YR5zwDVyNZomXQWG0NZDFi6ULZKwLy8hKda6J.jpg')">-->
<!--              <div class="overlay text-left"><a class="label">Allison Walker</a></div>-->
<!--            </div>-->
<!--            <div class="description">-->
<!--              <h3 class="text-center mb-3">Allison Walker</h3>-->
<!--              <h6 class="text-center">Member</h6>-->
<!--              <p class="post_description d-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit et-->
<!--                cupiditate-->
<!--                deleniti Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate-->
<!--                deleniti.</p></div>-->
<!--            <footer class="footer-card">-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--      </div>-->
  <div class="row">
      <div class="col-3">
      </div>
    <div class="col-6 justify-content-center">
      <mat-form-field appearance="fill" class=" mt-5 w-100">
        <mat-label class="mb-1 clubs" translate>home.clubs</mat-label>
        <mat-select   ng-model="myModel" class="select">
          <mat-option *ngFor="let option of clubs" (click)="allVolunteersAtClub(option.id)" [value]="option.id" >
            {{option.name}}
          </mat-option>
        </mat-select>
        <!--          <mat-error *ngIf="club_id.hasError('required')" translate>home.board-members</mat-error>-->
      </mat-form-field>
    </div>
    <div class="col-3">
    </div>
  </div>

  <div class="tpath-team-grid-wrapper team-type-grid" *ngIf="!loading">
        <div id="tpath-team-grid1" class="row tpath-grid-inner team-columns-4">
          <div class="team-item col-md-3 col-xs-12" *ngFor="let item of allVolunteers?.volunteers">
            <div class="team-item-wrapper">
              <div class="team-image-wrapper">
                <div class="team-item-img">
                  <img *ngIf="!item.image"  alt="{{item.fullname_en}}"  height="250px" width="450px" class="img-responsive" src="assets/images/avatar/user-anonymous.png">
                  <img *ngIf="item.image" src="{{item.image}}" alt="{{item.fullname_en}}" width="450" height="250px"  class="img-responsive">
                </div>
                <div class="team-label"></div>
              </div>
              <div class="team-content-wrapper">
                <h5 class="team-member-name">
                  <a href="" title="Jonathan Doe">{{item.fullname_en}}</a>
                </h5>
<!--                <div class="team-member_phone">-->
<!--                  <i class="bi bi-phone-fill"></i>-->
<!--                  <span>Mobile</span> :{{item.phone}}</div>-->
<!--                <div class="team-member_email">-->
<!--                  <i class="bi bi-envelope-fill"></i>-->
<!--                  <span>Email</span> :-->
<!--                  <a href="">{{item.email}}</a>-->
<!--                </div>-->
                <div class="member-social"
                ><ul class="tpath-member-social-icons list-inline">
                  <li class="facebook">
                    <a *ngIf="item.facebook" target="_blank" href="{{item.facebook}}">
                      <img src="assets/icons/287739_facebook_icon.svg">
                    </a>
                      <a *ngIf="!item.facebook"target="_blank" href="https://www.facebook.com/">
<!--                      <i class="fa fa-facebook"></i>-->
                      <img src="assets/icons/287739_facebook_icon.svg">
                    </a>
                  </li>
                  <li class="twitter">
                    <a *ngIf="item.twitter" target="_blank" href="{{item.twitter}}">
<!--                      <i class="fa fa-twitter"></i>-->
                      <img src="assets/icons/294709_circle_twitter_icon.svg">
                    </a>
                    <a *ngIf="!item.twitter" target="_blank" href="https://twitter.com/">
                      <img src="assets/icons/294709_circle_twitter_icon.svg">
                    </a>
                  </li>
                  <li class="instagram">
                    <a *ngIf="item.instagram" target="_blank" href="{{item.instagram}}">
<!--                      <i class="fa fa-instagram"></i>-->
                      <img src="assets/icons/3225191_app_instagram_logo_media_popular_icon.svg">
                    </a>
                    <a *ngIf="!item.instagram "target="_blank" href="https://www.instagram.com/">
                      <img src="assets/icons/3225191_app_instagram_logo_media_popular_icon.svg">
                    </a>
                  </li>
                  <li class="linkedin">
                    <a *ngIf="item.linkedin" target="_blank" href="{{item.linkedin}}">
<!--                    <i class="fa fa-google-plus"></i>-->
                    <img src="assets/icons/317750_linkedin_icon.svg">
                  </a>
                    <a *ngIf="!item.linkedin" target="_blank" href="https://www.linkedin.com/">
                      <img src="assets/icons/317750_linkedin_icon.svg">
                    </a>
                  </li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  <div *ngIf="loading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="w-100 mb-5 notfound " *ngIf="allVolunteers?.volunteers.length == 0">
    <div class="face">
      <div class="band">
        <div class="red"></div>
        <div class="white"></div>
        <div class="blue"></div>
      </div>
      <div class="eyes"></div>
      <div class="dimples"></div>
      <div class="mouth"></div>
    </div>

    <h1>Oops!</h1>
    <h3 style="margin: 0 auto ;max-width: max-content">Not Found Results!</h3>

  </div>

  <nav aria-label="..." class="d-flex justify-content-center my-5 py-5 paginator">
    <ul class="pagination">
      <li class="page-item " [style]="page == 1 ? 'pointer-events: none':''">
        <a class="page-link" href="javascript:void(0);" tabindex="-1"
           (click)="getAllVolunteers(page-1)">Previous</a>
      </li>
      <li [class]="page == i+1 ? 'active': '' " class="page-item " *ngFor="let _ of [].constructor(collectionSize); let i = index ">
        <a class="page-link" href="javascript:void(0);" (click)="getAllVolunteers(i+1)">{{i + 1}}
        </a>
      </li>
      <li class="page-item" [style]="page == collectionSize ? 'pointer-events: none':''">
        <a class="page-link" href="javascript:void(0);" (click)="getAllVolunteers(page+1)">Next</a>
      </li>
    </ul>
  </nav>

</div>
