
  <div class="row">
    <div class="w-100 mb-3">
      <h1>
        <div translate>home.Your-journey</div>
      </h1>
    </div>
  </div>

<div class="row">
  <div class="col-8 mt-4">
    <div class="picsum-img-wrapper">
      <img src="./assets/images/add-member/Haya-Karima.jpg"
           alt="Random second slide"
           width="823px"
           height="548px"
           style="border-radius: 0px;"
      />
    </div>
  </div>
   <div class="col-4 mt-4">
    <section>
      <div class="sticky-content">
        <div class="social-icons social-icons-colored social-icons-vertical">
          <div class="icon-share">
            <span class="social-label " translate>home.share</span>
            <a class="social-icon image-item" (click)="cop()"
               target="_blank">
              <img *ngIf="!copied" title="copy" style="width: 22px"
                   alt="copy"
                   src="./assets/newIcon/copy-2.png"/>
              <img *ngIf="copied" title="copied" style="width: 28px"
                   alt="copied"

                   src="./assets/newIcon/copied.png"/>
            </a>
            <a (click)="fbc($event)" class="social-icon social-facebook"
               title="Facebook"><i
                    class="icon-facebook-f"></i></a>
            <a (click)="tw()" class="social-icon social-twitter"
               title="Twitter"><i
                    class="icon-twitter"></i></a>
            <a (click)="in()" class="social-icon social-linkedin"
               title="Linkedin"><i
                    class="icon-linkedin"></i></a>
          </div>
        </div>
      </div>
  </section>
   </div>
</div>
<div class="row">
  <div class="col-12 copy mt-3">
    <div>
      <p translate>home.egypt-group-family</p>

      <p translate>home.egypt-group-welcome</p>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 mb-5 submitBtn justify-content-center">
    <button mat-button class="btn btn-success submitBtn mt-5 mb-5" (click)="openDialog()" routerLink="/add-member" ><span translate>home.next</span></button>
  </div>
</div>
<!-- <form [formGroup]="form">
  <div class="details-traveler">
    <div class="formTitle">
      <h3 translate>
        home.become-a-member
      </h3>
    </div>
    <div class="">
      <h4 class="tell-us" translate>home.about-yourself</h4>
    </div>
    <div class=" d-flex overflow-auto" id="style-15"> -->
<!--      <div class="row">-->
<!--        <div class="col-12">-->
      <!-- <div class="row w-100">
        <div class="col-6">
          <div >
            <mat-form-field appearance="fill">
              <mat-label class="" translate>home.full-name</mat-label>
              <input matInput placeholder="Join" type="text" formControlName="fullName_en">
            </mat-form-field>
            <div class="mat-error error-age"
                 *ngIf="form.get('fullName_en').errors?.required && form.get('fullName_en').touched">This field is required
            </div>
          </div>
          <div class="mt-6">
            <h4 translate>home.contact-you</h4>
          </div>
          <div >
            <mat-form-field appearance="fill">
              <mat-label class="star" translate>home.your-email</mat-label>
              <input matInput placeholder="examle@gmail.com" [(ngModel)]="email" type="text" formControlName="email"
                     (keyup)="checkMail()">
            </mat-form-field> -->
            <!-- <div class="marks position-absolute">
              <i *ngIf="spinner" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
              <i *ngIf="right" class="fa fa-check ml-2"></i>
            </div> -->
            <!-- <div class="mat-error error-age "
                 *ngIf="form.get('email').errors?.someProp">{{form.get('email').errors?.someProp}}</div>
          </div>
          <div > -->
            <!-- <mat-form-field appearance="fill">
              <mat-label class="star" translate>home.your-mobile</mat-label>
              <input matInput placeholder="Phone Number" type="text" [(ngModel)]="phone" formControlName="phone"
                     (keyup)="checkPhone()">
              <div class="mat-error error-age" *ngIf="form.get('phone').errors?.pattern">Please Type Numbers Only.
              </div>
              <div class="mat-error error-age"
                   *ngIf="form.get('phone').errors?.required && form.get('phone').touched">phone is Required
              </div>

            </mat-form-field> -->
            <!-- <div class="marks position-absolute">
              <i *ngIf="spinner2" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
              <i *ngIf="right2" class="fa fa-check ml-2"></i>
            </div> -->
            <!-- <div class="mat-error error-age "
                 *ngIf="form.get('phone').errors?.someProp">{{form.get('phone').errors?.someProp}}</div>
          </div>
          <div  *ngIf="egyptCase">
            <mat-form-field appearance="fill">
              <mat-label class="star" translate>home.national-number</mat-label>
              <input matInput placeholder="SSN" type="text" formControlName="ssn" [(ngModel)]="ssn" (focusout)="checkSsn()"  >
            </mat-form-field> -->
            <!-- <div class="marks position-absolute">
              <i *ngIf="spinnerSsn" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
              <i *ngIf="rightSsn" class="fa fa-check ml-2"></i>
            </div> -->
            <!-- <div class="mat-error error-age" *ngIf="form.get('ssn').errors?.pattern">Please Type Numbers Only.
            </div>
          </div>
          <div >
            <h4 translate>home.Serve</h4>
          </div>
          <div > -->
            <!-- <mat-form-field appearance="fill">
              <mat-label class="star" translate>home.Current-address</mat-label>
              <input matInput placeholder="Join" type="text" formControlName="address"> -->
              <!--            <div class="mat-error error-age"-->
              <!--                 *ngIf="form.get('address').errors?.required && form.get('address').touched">Address is-->
              <!--              Required-->
              <!--            </div>-->
            <!-- </mat-form-field>
          </div>
          <div > -->
            <!-- <mat-form-field appearance="fill">
              <mat-label class="star" translate>home.country</mat-label>
              <input style="cursor: pointer" matInput placeholder="Pick country" [matAutocomplete]="country" type="text"
                     formControlName="country">
              <mat-autocomplete autoActiveFirstOption #country="matAutocomplete"
                                (optionSelected)='getPosts($event.option.value)'>
                <mat-option *ngFor="let option of countries" [value]="option.name">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div > -->
            <!-- <mat-form-field appearance="fill">
              <mat-label class="star" translate>home.club</mat-label>
              <input style="cursor: pointer" matInput placeholder="Pick up your favourite club" [matAutocomplete]="main_club" type="text"
                     formControlName="clubs">
              <mat-autocomplete autoActiveFirstOption #main_club="matAutocomplete">
                <mat-option *ngFor="let option of clubs" [value]="option.name">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div > -->
            <!-- <mat-form-field appearance="fill" (click)="join_date.open()">
              <mat-label class="star" translate>home.date-of-join</mat-label>
              <input style="cursor: pointer" matInput placeholder="Due Date" (dateChange)="checkDate($event)" [matDatepicker]="join_date"
                     formControlName="join_date">
              <mat-datepicker-toggle matSuffix [for]="join_date"></mat-datepicker-toggle>
              <mat-datepicker #join_date></mat-datepicker>
              <div class="mat-error error-age"
                   *ngIf="form.get('join_date').errors?.someProp">{{form.get('join_date').errors?.someProp}}</div>
              <div class="mat-error error-age"
                   *ngIf="form.get('join_date').errors?.required && form.get('join_date').errors?.touched">Please
                enter a Joined Date
              </div>
            </mat-form-field>
          </div>
          <div > -->
            <!-- <div class="col-12 " *ngIf="egyptCase">
              <div class="item d-flex border-dashed">
                <button translate>home.profile-picture
                  <i class="fas fa-image"></i>
                </button>
                <input id="pic" type="file" #selectFile formControlName="image"
                       (change)="onFileSelected($event.target.files)" accept="image/*">
                <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah"></div>
              </div>
            </div> -->
            <!-- <div class="col-12 " *ngIf="egyptCase">
              <div class="item d-flex border-dashed">
                <button translate>
                  <i class="fas fa-image"></i>
                  home.front-side
                </button>
                <input id="pic" type="file" #selectFile formControlName="image_front"
                       (change)="onFileSelectedFront($event.target.files)" accept="image/*">
                <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah1"></div>
              </div>
            </div> -->
            <!-- <div class="col-12 " *ngIf="egyptCase">
              <div class="item d-flex border-dashed">
                <button translate>
                  <i class="fas fa-image"></i>
                  home.back-side
                </button>
                <input id="pic" type="file" #selectFile formControlName="image_back"
                       (change)="onFileSelectedBack($event.target.files)" accept="image/*">
                <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah2"></div>
              </div>
            </div> -->
            <!-- <div class="col-12 " *ngIf="egyptCase">
              <div class="item d-flex border-dashed">
                <button translate>
                  <i class="fas fa-image"></i>
                  home.criminal-chip
                </button>
                <input id="pic" type="file" #selectFile formControlName="image_clearance"
                       (change)="onFileSelectedClearance($event.target.files)" accept="image/*">
                <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah3"></div>
              </div>
            </div>
          </div> -->

          <!-- <div class="row pl-2 justify-content-center">
            <button class="btn submit-btn" [disabled]="form.status == 'INVALID'" (click)="close()" translate>home.submit</button>
          </div>
        </div>
        <div class="col-6">
          <img class="img"
               src="./assets/images/add-member/join us final.jpg">
        </div>
      </div>
    </div>
  </div>
</form> -->


