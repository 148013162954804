<div class="videos-section mt-3 mb-3">
<owl-carousel-o [options]="carouselOptions">
  <ng-container *ngIf="videos">
    <ng-container *ngFor="let video of videos">
      <ng-template carouselSlide>
        <div class="post-container">
          <div class="post">
            <div class="post-photo">
              <video class="img-fluid home-video"
                     title="EGYPT"
                     muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
                     (contextmenu)="prevent($event)"
                     src="{{video['video']}}" controls>
              </video>
            </div>
            <div class="post-description">
              <div class="offer_name">
                <h1 style="font-size: medium; font-weight: bold; color:#148181">{{video['title_ar'] | slice :0 : 40}}</h1>
              </div>
              <div class="offer_description">
                <p>{{video['description_ar'] | slice :0 : 200}}
                </p>
                <a (click)="videDetails(video['id'])" *ngIf="video['description_ar'].length > 200 && readMore"
                   class="readMore"
                >..قرأة المزيد</a>
              </div>
<!--              <label>{{transform(video['created_at'] | date: 'yyyy-MM-dd')}}</label>-->
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</owl-carousel-o>
</div>
