<div class="custom-container m-auto w-50">
  <div class="row">
    <div class="col-12">
      <div class="header">
        <img style="width: 70%" src="/assets/images/Mask%20Group%208.png">
        <!--          <img src="/assets/images/eagle-flag-egypt.png">-->
      </div>
      <div class=" message-failed" *ngIf="bookingNumber['message']">
        <div class="content">
          <img src="/assets/images/covers/cancel-button.svg">
        </div>
        <div class="message-content">
          <span>{{bookingNumber['message']}}</span>
        </div>
      </div>
      <div class="form-group" *ngIf="bookingNumber['book_number']">
        <div class="content">
          <p class="text-center"> عزيزي المواطن </p>
          <p>({{personalData['name']}})</p>
          <p class="text-center"> تم حجز موعدك برجاء زيارة بريدك الالكترونى لتاكيد الحجز </p>
        </div>
        <div class="content-reserve">
          <span>رقم الحجز</span>
          <span>{{bookingNumber['book_number']}}</span>
        </div>
        <div class="date text-center mt-3">
          <span class="ml-2">بتاريخ</span>
          <span>{{date| date: 'yyyy-dd-MM'}}</span>
        </div>
        <div class="content mb-4">
          <div class="time">
            <span class="ml-2"> وتوقيت </span>
            <span class="" *ngFor="let time of times">{{trnsformTime(time['time_name'])}}</span>
          </div>
          <hr class="hr">
          <span class="header-service"> الخدمات المطلوبه</span>
          <div class="services">
            <div *ngFor="let service of services">
              {{service['service_name']}}
            </div>
          </div>
          <hr class="hr">
        </div>
      </div>
      <div class="buttons-group-message d-flex flex-column-reverse justify-content-between">
        <div class="btn-close">
          <span (click)="closeEvent.emit(); redirectHome();">إغلاق</span>
        </div>
          <div class="example-button-row">
            <button routerLink="/instruction-services" mat-raised-button color="warn">إرشادات إتمام الخدمات القنصلية</button>
            <button routerLink="/instruction-services" mat-raised-button color="warn">إتمام الخدمات بالبريد</button>
            <button routerLink="/prices-list" class="bg-007bff" mat-raised-button color="accent">أسعار الخدمات
            </button>
        </div>
      </div>
    </div>
  </div>
</div>
