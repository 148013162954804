import { isPlatformBrowser } from '@angular/common';
import {Component, ComponentRef, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import  AOS  from "aos";
import { Router } from '@angular/router';
import { CasesBelongVolunteerComponent } from 'src/app/cases-belong-volunteer/cases-belong-volunteer.component';
import { DialogCertificatesComponent } from 'src/app/dialog-certificates/dialog-certificates.component';
import { DialogDonationComponent } from 'src/app/dialog-donation/dialog-donation.component';
import { CaseSearchComponent } from 'src/app/field-social-research/case-search/case-search.component';
import { PrintDonationDetailsComponent } from 'src/app/print-donation-details/print-donation-details.component';
import { ApisService } from 'src/app/services/apis.service';
import {ToastrService} from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import {EMPTY} from "rxjs";



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  // @ViewChild(NgxTypedJsComponent) typed: NgxTypedJsComponent;
  members: any;
  eachEvent: any;
  eachDonation: any;
  profile: any;
  Board: any;
  clubMembers: any;
  user: any;
  fileToUpload: any = '';
  token: any;
  errorMessage!: string;
  settingForm: FormGroup;
  dateFrom1: any = '';
  date: any = '';
  image: any;
  slug: any;
  id: any;
  lang: any;
  clubMember: any;
  memberId: any;
  Certificates: any;
  mydonations: any;
  casesBelongVolunteer: any;
  caseSearchesDelivered: any;
  spinnerFilter: boolean = false;
  currentPage:number = 1;
  collectionSize: any;
  pageSize: any;
  selectedOption: any;
  govermnets:any[]=[];
  loadingDownload: boolean = false;
  filterForm: FormGroup = this.fb.group({
    fullname: [null],
    goverment_id: [null],
    completed: [null],
    accepted: [null],
    delivered: [null],
  })
  compeletedList: any[] = [
    { name: 'Un Compeleted', id: 0 },
    { name: 'Compeleted', id: 1 }
  ]
  acceptedList: any[] = [
    { name: 'Under Review', id: 0 },
    { name: 'Accepted', id: 3 },
    { name: 'Accepted with Budget', id: 1 },
  ]
  deliveredList: any[] = [
    { name: 'shipped', id: 0 },
    { name: 'Done', id: 1 }
  ]
  loading:boolean = false;
  isBrowser:boolean = false;

  @ViewChild('table') table!: ElementRef;

  _componentRef: ComponentRef<any>;
  activity_Id: any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private activatedRoute: ActivatedRoute,
              private apis: ApisService,
              public fb: FormBuilder,
              private router: Router,
              public dialog: MatDialog,
              private toastr: ToastrService,
              public datepipe: DatePipe,
              private _elementRef: ElementRef
    ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('access_token') ;
      console.log(this.token);
    }
    this.settingForm = this.fb.group({
      // fullname_ar: ['', [Validators.required]],
      fullname_en: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      national_id: ['', [Validators.required]],
      address: ['', [Validators.required]],
      job: ['', [Validators.required,]],
      age: ['', [Validators.required,]],
      bio: ['', [Validators.required]],
      facebook: ['', [Validators.required]],
      twitter: ['', [Validators.required]],
      instagram: ['', [Validators.required]],
      reddit: ['', [Validators.required]],
      linkedin: ['', [Validators.required]],
      birthdate: ['', [Validators.required]],
      image: ['', [Validators.required]],

    });
  }
  ngOnInit(): void {
    AOS.init({
      duration: 750,
      delay: 150,
    })

    if (isPlatformBrowser(this.platformId)) {
      this.user = JSON.parse(localStorage.getItem('currentUser'))
    }
    this.memberId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(this.memberId)
    this.getProfileApi();
    this.getCertificatesApi();
    this.getMyDonationsApi();
    this.getGovermnets();
    this.getCasesBelongVolunteer(this.currentPage, this.filterForm.value);
    // this.BoardMembersApi(this.id);
    this.clubMembersApi(this.id);
    // this.getMember()

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      AOS.refresh()
    }, 500)
  }

  // ngOnDestroy() { // #44
  //     this._componentRef.destroy();
  // }
  // getMember() {
  //   this.apis.getMemberInfo(this.id).subscribe((data: any) => {
  //       this.clubMember = data.data;
  //       console.log(this.clubMember);
  //     },
  //     err => {
  //       console.log(err);
  //     });
  // }


  // getProfileApi(): void {
  //   this.apis.profileApi(this.token).subscribe((data: any) => {
  //     this.profile = data.data;
  //     console.log(data);
  //   }, (err: any) => {
  //   });
  // }
  getCertificatesApi(): any {
    this.apis.getCertificates().subscribe((data: any) => {
        this.Certificates = data.data;
        console.log(this.Certificates);
      },
      err => {
        console.log(err);
      });
  }
  getMyDonationsApi(): any {
    this.apis.getMyDonations().subscribe((data: any) => {
        this.mydonations = data.data;
        console.log(this.mydonations);
      },
      err => {
        console.log(err);
      });
  }

  getProfileApi() {
    this.apis.profileApi().subscribe((data: any) => {
        this.profile = data.data;
        console.log(this.profile);
        console.log("karimdebug : ",data.data.birthdate);
        this.settingForm.patchValue({
          address: data.data.address,
          fullname_en: data.data.fullname_en,
          email: data.data.email,
          job: data.data.job,
          age: data.data.age,
          phone: data.data.phone,
          bio: data.data.bio,
          facebook: data.data.facebook,
          twitter: data.data.twitter,
          instagram: data.data.instagram,
          linkedin: data.data.linkedin,
          birthdate: new Date(data.data.birthdate),

        })
        this.image = data.data?.image
        // this.settingForm.controls.fullname_en.patchValue(data.data.fullname_en);

      },
      err => {
        console.log(err);
      });
  }

  clubMembersApi(id) {
    this.apis.clubMembers(id).subscribe((data: any) => {
        this.clubMembers = data.data.members;
        console.log(data);
      },
      err => {
        console.log(err);
      });
  }
  filter(event:any) {
    this.currentPage = event
    this.spinnerFilter = true;
    this.table.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
  });
this.getCasesBelongVolunteer(event, this.filterForm.value);
  }
  getCasesBelongVolunteer(currentPage:number, filterForm: any): any {
    this.loading = true;
    this.apis.casesBelongVolunteer(currentPage, filterForm).subscribe((data: any) => {
      this.casesBelongVolunteer = data.data.cases;
      const paginator = data.data.paginator
      this.collectionSize = paginator.number_of_pages*10;
      this.pageSize = Object.entries(this.casesBelongVolunteer).length;
      this.loading = false;
    },
      err => {
        console.log(err);
      this.loading = false;

      });
  }
  getGovermnets() {
    this.apis.getGovermnets().subscribe({
      next: (res: any) => {
        this.govermnets = res.data
      }
    })
  }
  CaseSearchesDelivered(id: number): any {
    this.apis.CaseSearchesDelivered(id).subscribe((data: any) => {
      this.caseSearchesDelivered = data
      this.toastr.success(data.message, 'Success');
      this.getCasesBelongVolunteer(this.currentPage, this.filterForm.value);
    },
      err => {
        console.log(err);
      });
  }

  // BoardMembersApi(id: any) {
  //   this.apis.getBoardMembers(id).subscribe((data: any) => {
  //       this.Board = data.data;
  //       console.log(this.Board);
  //     },
  //     err => {
  //       console.log(err);
  //     });
  // }
  refresh(slug) {
    // @ts-ignore
    if (typeof document !== 'undefined') {
      window.scrollTo(0, 0);
    }
    this.slug = slug;
    this.clubMembersApi(this.id);
    this.getProfileApi();
    this.editProfile();
  }


  dateFormat(date): any {
    date = new Date(date);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = +'0' + dt;
    }
    if (month < 10) {
      month = +'0' + month;
    }
    return (year + '-' + month + '-' + dt);
  }

  checkDate(e): void {
    this.date = new Date(e).getTime();
    let due = new Date(this.dateFormat(e.value)).getTime();
    if (this.date >= due) {
      // this.form.controls['due_date'].setErrors({'incorrect': true, someProp: 'You must choose a day after today'});
    }
  }

  editProfile(): any {
    const formData: FormData = new FormData();
    this.fileToUpload ? formData.append('image', this.fileToUpload) : EMPTY;
    // let birthdate : string = this.datepipe.transform(this.settingForm.get('birthdate').value, 'yyyy-MM-dd');
    console.log(' ayman ',this.settingForm.value.birthdate)
    console.log(this.settingForm.value.fullName_en)
    formData.append('fullname_en', this.settingForm.value.fullname_en);
    formData.append('email', this.settingForm.value.email);
    formData.append('phone', this.settingForm.value.phone);
    formData.append('address', this.settingForm.value.address);
    formData.append('job', this.settingForm.value.job);
    formData.append('age', this.settingForm.value.age);
    formData.append('bio', this.settingForm.value.bio);
    formData.append('facebook', this.settingForm.value.facebook);
    formData.append('twitter', this.settingForm.value.twitter);
    formData.append('instagram', this.settingForm.value.instagram);
    formData.append('linkedin', this.settingForm.value.linkedin);
    formData.append('birthdate',this.datepipe.transform(this.settingForm.value.birthdate, 'yyyy-MM-dd'));
    // @ts-ignore
    return formData;
  }

  submit() {
    this.apis.getProfile(this.token, this.editProfile()).subscribe((data: any) => {
        console.log(data);
        this.getProfileApi();
        this.toastr.success(data.message, 'Success');
      },
      err => {
        console.log(err);
        this.errorMessage = err.error.errors
        for (const key in err.error.errors) {
          this.toastr.error( err.error.errors[key] , 'faild')
        }
      });
  }

  onFileSelected(files: any): void {
    this.fileToUpload = files.files.item(0);
    if (files.files && files.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = event.target.result;
      };
      reader.readAsDataURL(files.files[0]);
    }
  }

  // memberDetails(id): void {
  //   this.router.navigate(['/memberDetails/', this.members.board_id, id]);
  // }
  memberDetails(id): void{
    console.log(id);
    console.log(this.clubMembers.id);
    this.router.navigate(['/memberDetails/',id ,id]);

  }
  tabIndex = 0;
  onTabClick(index){
    this.tabIndex = index;
  }
  openDialog(event) {
    console.log(event.activity.id)
    this.eachEvent = event
    this.activity_Id =event.activity.id;
    const dialogRef = this.dialog.open(DialogCertificatesComponent,{
      data: {
        certificateId: event.activity.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openPopUp(event) {
    console.log(event)
    // console.log(eventdonation.activities_incomes[0].activity_income_id)
    // console.log(generalpurpose.general_purpose[0].general_purpose_transaction_id)
    const dialogRef = this.dialog.open(DialogDonationComponent,{
      data: {
        donationId: event.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDonation(event) {
    console.log(event)
    const dialogRef = this.dialog.open(PrintDonationDetailsComponent,{
      data: {
        donationId: event.id,
        donationdetails: event,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openCases(event) {
    console.log(event)
    const dialogRef = this.dialog.open(CasesBelongVolunteerComponent,{
      data: {
        Cases: event,
        page: this.currentPage
        // donationdetails: event,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openCaseSearch(event:any) {
    const dialogRef = this.dialog.open(CaseSearchComponent , {
      height: '90vh',
      width: '25vw',
      minWidth: '390px',
      data :{ completed: 1,case:event}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'deliverd'){
        this.CaseSearchesDelivered(result.deliverd);
      }
      this.getCasesBelongVolunteer(this.currentPage, this.filterForm.value);
    });
  }

  CertificateDown1() {
    this.loadingDownload = true;

    this.apis.CertificateDown1(541).subscribe(
        (data: any) => {
            this.loadingDownload = false;
            var link = document.createElement('a');
            link.href = data.data;
            link.target = '_blank';
            link.download = `Donation Report.pdf`;
            link.click();
        },
        error => {
            this.loadingDownload = false;

            console.log(error);
        }
    );
  }
}

