// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mainDomain: 'https://api.newegyptgroup.com/api',
  // mainDomain: 'https://apistage.newegyptgroup.com/api',
  hayaDomain: 'https://staging.backend.hayakarimausa.com/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// domainDev: 'https://newegyptgroup-web.hostallover.net/public/api',
// domain: 'https://api.flyallover.com',
// domainDev: 'https://api.flyallover.com',
