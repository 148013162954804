import {Component, Output, EventEmitter, OnInit, Inject} from '@angular/core';
import {ApisService} from '../../../services/apis.service';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @Output() setServicesEvent:EventEmitter<any> = new EventEmitter();
  myModel: any;
  popup = false;
  closedStatus = true;
  bookTimePopUp: any;
  bookPrimaryTimePopUp: any;
  requests = Array(1);
  selectedServices: any = {};
  selectedServices2: any = [];
  selectedTimes: any = [];
  selectedExcellentServices: any = {};
  checked = false;
  checkedServices: any = false;
  checkedExcellentServices: any = false;
  primaryTime: any;
  servicesTypes: any;
  services: any;
  data: any;
  timeChosen: any;
  specialServices: any;
  specialServicesType: any;
  selectedTimes2: any = [];
  errorMessage: any = '';

  constructor(
    private apis: ApisService,
    @Inject(MAT_DIALOG_DATA) public result: any,
    private dialogRef: MatDialogRef<ServicesComponent>,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    // this.getServices();
    // this.getTime();
    // this.getSpecialServices();
  }

  // getServices(): any {
  //   this.apis.services().subscribe((response: any) => {
  //     this.servicesTypes = Object.keys(response);
  //     this.services = response;
  //   }, err => {
  //     console.log(err);
  //   });
  // }
  // getSpecialServices(): any {
  //   this.apis.specialServices().subscribe((response: any) => {
  //     this.specialServicesType = Object.keys(response);
  //     this.specialServices = response;
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  // getTime(): any {
  //   this.apis.time().subscribe((response: any) => {
  //     this.timeChosen = response.data;
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  servicesHandler(service, e): any {
    if (e.checked) {
      const s = {};
      s['service_id'] = service.id;
      s['service_name'] = service.name;
      this.selectedServices2.push(s);
    } else {
      for (var i = 0; i < this.selectedServices2.length; i++) {
        if (this.selectedServices2[i].service_id == service.id) {
          this.selectedServices2.splice(i, 1);
        }
      }
    }
  }

  // timesHandler(time, e): any {
  //   console.log(time);
  //   if (e.checked) {
  //     const s = {};
  //     s['time_id'] = time.id;
  //     s['time_name'] = time.time;
  //     this.selectedTimes.push(s);
  //   } else {
  //     for (var i = 0; i < this.selectedTimes.length; i++) {
  //       if (this.selectedTimes[i].time_id == time.id) {
  //         this.selectedTimes.splice(i, 1);
  //       }
  //     }
  //   }
  //   console.log(this.selectedTimes);
  // }
  checkIfSelectedService(service): any {
    for (var i = 0; i < this.selectedServices2.length; i++) {
      if (this.selectedServices2[i].service_id == service.id) {
        return this.selectedServices2[i];
      }
    }
    return false;
  }

  // checkIfSelectedTime(time): any {
  //   for (var i = 0; i < this.selectedTimes.length; i++) {
  //     if (this.selectedTimes[i].service_id == time.id) {
  //       return this.selectedTimes[i];
  //     }
  //   }
  //   return false;
  // }

  setTime(e): any {
    const s: any = {};
    s.time_id = e.value.id;
    s.time_name = e.value.time;
    this.selectedTimes.length = 0;
    this.selectedTimes.push(s);
  }

  confirm(): any {
    if (this.selectedServices2.length == 0) {
      this.errorMessage = 'من فضلك قم بإختيار الخدمات المطلوبه';
    } else if (this.selectedTimes2.length == 0) {
      this.errorMessage = 'من فضلك قم بإختيار موعد الخدمه';
    } else if (this.selectedServices2 && this.selectedTimes2) {
      const s = {};
      s['time_id'] = this.selectedTimes2.id;
      s['time_name'] = this.selectedTimes2.time;
      this.selectedTimes.push(s);
      const data: any = {};
      data.services = this.selectedServices2;
      data.times = this.selectedTimes;
      data.dataServicesSelected = data;
      this.dialogRef.close(data);
    }
  }

  translate(word: string): string {
    if (word == 'acknowledgments') {
      return 'الإقرارت والمستندات';
    }
    if (word == 'agencies') {
      return 'التوكيلات';
    } else {
      return 'الترجمة';
    }

  }

  translateSpecial(word: string): string {
    if (word == 'agencies') {
      return 'خدمات الرقم القومى';
    }

  }

  trnsformTime(time): any {
    if (time == '11:00 am') {
      return '11:00 صباحا';
    }
    if (time == '11:30 am') {
      return '11:30 صباحا';
    }
    if (time == '12:00 pm') {
      return '12:00 ظهرا';
    }
    if (time == '12:30 pm') {
      return '12:30 ظهرا';
    }
    if (time == '1:00 pm') {
      return '01:00 ظهرا';
    }
    if (time == '9:00 am') {
      return '09:00 صباحا';
    }
    if (time == '9:30 am') {
      return '09:30 صباحا';
    }
    if (time == '10:00 am') {
      return '10:00 صباحا';
    }
    if (time == '10:30 am') {
      return '10:30 صباحا';
    }
    if (time == '1:30 pm') {
      return '01:30 مساء';
    }
    if (time == '2:00 pm') {
      return '02:00 مساء';
    }
    if (time == '2:30 pm') {
      return '02:30 مساء';
    }
    if (time == '3:00 pm') {
      return '03:00 مساء';
    }
  }

}
