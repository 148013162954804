<h1 >the remaining</h1>

<div class="time" >
  <div><span>{{label[0]}}   : {{label[1]}}  :  {{label[2]}}  :  {{label[3]}}</span></div>
  <div class="count d-flex">
    <span class="days">{{counter[0]}}</span>
    <span class="hours">{{counter[1]}}</span>
    <span class="minutes">{{counter[2]}}</span>
    <span class="seconds">{{counter[3]}}</span>
  </div>
</div>
