<div class="container print" >
  <div class="row">
    <div class="col-12">
      <mat-dialog-actions align="end">
        <button class="close"  mat-button mat-dialog-close>X</button>
      </mat-dialog-actions>
      <h1>Donation Report Details</h1>
      <h3>{{myDate |date: 'medium'}}</h3>
      <h3 *ngIf="mydonations?.date">{{mydonations.date | date}}</h3>

      <mat-tab-group >
          <mat-tab label="General Purpose" *ngIf="generalpurpose != 0">
            <table class="table table-hover table-bordered">
              <thead>
              <tr>
                <th scope="col">GENERAL PURPOSE NAME</th>
                <th scope="col">AMOUNT</th>
                <th scope="col">RESIDUAL</th>
                <th scope="col">DATE</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of generalpurpose">
                <td>{{item.general_purpose}}</td>
                <td>{{item.amount}} $</td>
                <td>{{item.Residual}} $</td>
                <td>{{item.created_at | date}}</td>
              </tr>
              </tbody>
            </table>

          </mat-tab>
          <mat-tab label="Activities Incomes" *ngIf="activitiesincomes != 0">
            <table class="table table-hover table-bordered">
              <thead>
              <tr>
                <th scope="col">ACTIVITY NAME</th>
                <th scope="col">AMOUNT</th>
                <th scope="col">PERCENTAGE</th>
                <th scope="col">DATE</th>
                <th scope="col">ACTIONS</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of activitiesincomes">
                <td>{{item.activity.name}}</td>
                <td>{{item.amount}} $</td>
                <td>{{item.percentage}} %</td>
                <td>{{item.date | date}}</td>
                <td>
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="border: none;background:none;height: 35px;width: 35px;">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                </td>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item  (click)="openexpenses(item)">
                    <mat-icon>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit ml-3 mb-3">
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                    </mat-icon>
                    <span >details</span>
                  </button>
                </mat-menu>
              </tr>
              </tbody>
            </table>
          </mat-tab>
          <mat-tab label="Activities From General Purpose" *ngIf="activitiesfromgeneral !=0">
            <table class="table table-hover table-bordered">
              <thead>
              <tr>
                <th scope="col">ACTIVITY NAME</th>
                <th scope="col">AMOUNT</th>
                <th scope="col">PERCENTAGE</th>
                <th scope="col">DATE</th>
                <th scope="col">ACTIONS</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of activitiesfromgeneral">
                <td>{{item.activity.name}}</td>
                <td>{{item.amount}} $</td>
                <td>{{item.percentage}} %</td>
                <td>{{item.date | date}}</td>
                <td>
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="border: none;background:none;height: 35px;width: 35px;">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                </td>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item  (click)="openexpenses(item)">
                    <mat-icon>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit ml-3 mb-3">
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                    </mat-icon>
                    <span >details</span>
                  </button>
                </mat-menu>
              </tr>
              </tbody>
            </table>
          </mat-tab>
        </mat-tab-group>
      <div class="px-3 d-flex justify-content-center mt-2">
        <a class="btn btn-outline-secondary hide-print btn-block"
           href="javascript:window.print();"
           rippleEffect
        >
          Print
        </a>
      </div>
    </div>
  </div>
</div>

