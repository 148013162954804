<div [dir]="lang == 'ar' ? 'rtl':'ltr'">
    <div class="container">
      <div class="mt-1">
        <div class="col-12 mb-5">
          <mat-dialog-actions align="end">
            <button class="close"  mat-button mat-dialog-close>X</button>
          </mat-dialog-actions>
        </div>
      </div>
    </div>
    <div class="mb-4 ">
      <div class="progress">
          <!-- ID of prBar (By default width is 25%) -->
          <div id="prgBar" class="progress-bar" [style.width.%]="step"></div>
      </div>
  </div>
  <mat-horizontal-stepper class="justify-content-center" #stepper>
    <mat-step [stepControl]="0" *ngIf="!user">
        <form [formGroup]="myForm">
  
          <div class="col-12 p-0 my-2" >
            <label for="fullName" class="form-label" translate>home.full-name</label>
            <input type="text" class="form-control" formControlName="fullname"
            [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
            >
            <div class="invalid-feedback">
              Valid full name is required.
            </div>
          </div>
          <div class="col-12 p-0 my-2 mt-5">
            <label for="phone" class="form-label" translate>home.phone-number</label>
            <input type="text" class="form-control" formControlName="phone"
            >
            <div class="invalid-feedback">
              Valid phone  is required.
            </div>
          </div>
          <div class="col-12 my-2 p-0 mt-5" >
            <label for="email" class="form-label" translate>home.email</label>
            <input
              [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
              type="text" class="form-control" formControlName="email" placeholder="">
             <div class="invalid-feedback" *ngIf="myForm.get('email')?.dirty && myForm.get('email')?.errors?.required">
              Email is required.
            </div>
            <div class="invalid-feedback"
              *ngIf="myForm.get('email')?.dirty && myForm.get('email')?.errors?.email">
              Email is invalid.
            </div>
          </div>
          <div class="submitBtn mt-5">
            <button mat-button matStepperNext class="btn btn-success submitBtn"
                 (click)="step=45"><span translate>home.next</span></button>
  <!--                  [disabled]="firstFormGroup.invalid"-->
        </div>
          </form>
          </mat-step>
          <mat-step [stepControl]="1" *ngIf="!data?.id">
            <form [formGroup]="myForm">
              <div class="col-12 my-2 p-0 mt-5">
                <label for="purpose" class="form-label" translate>home.purpose</label>
                <!-- <div class="position-relative">
                   <input
                    [style.backgroundPosition]=" lang == 'ar'?'left calc(1.375em + 0.1875rem) center':'right calc(1.375em + 0.1875rem) center'"
                    style="cursor: pointer" class="form-control" ng-model="myModel"
                    type="text"  />
  
                  <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
                 </div>
                 <mat-label class="form-control">Select</mat-label> -->
  
                <mat-form-field appearance="fill" class="w-100 activities example-form-field">
                  <mat-select formControlName="general_purpose_id" (selectionChange)="checkselect($event)" (ngModelChange)="checkselect(true)">
                    <!-- <mat-option [value]=0>Clear</mat-option> -->
                    <mat-option value="">Select a Purpose...</mat-option>
                    <mat-option  *ngFor="let option of generalpurposes; let i=index;" (click)="getIdPurpose(option.id)" [value]="option.id">
                      {{option.name}}
                    </mat-option>
  
                  </mat-select>
                  <!-- <button *ngIf="generalpurposes[i]?.id" matSuffix mat-icon-button aria-label="Clear" (click)="general_purpose_id='null'">
                    <mat-icon>close</mat-icon>
                  </button> -->
  
                  <!-- <button mat-button *ngIf="option" matSuffix mat-icon-button
                  aria-label="Clear" (click)="option=undefined; $event.stopPropagation()">
                <mat-icon>x</mat-icon>
                </button> -->
  
                <!-- <button *ngIf="myForm.controls.option.value"
                   matSuffix
                   mat-icon-button
                   type="button"
                   aria-label="Clear"
                   (click)="myForm.controls.option(null); $event.stopPropagation()">
             <mat-icon>close</mat-icon>
             </button> -->
  
                </mat-form-field>
  
              </div>
  
              <div class="col-12 my-2 p-0 mt-5 text-center">
  
                <h1 class="form-label"> OR </h1>
  
                </div>
  
  
  
              <div class="col-12 my-2 p-0 mt-5">
                <label for="activity_id" class="form-label" translate>home.following-activities</label>
                <!-- <div class="position-relative">
                  <input
                    [style.backgroundPosition]=" lang == 'ar'?'left calc(1.375em + 0.1875rem) center':'right calc(1.375em + 0.1875rem) center'"
                    style="cursor: pointer" class="form-control" ng-model="myModel"
                    type="text"  />
  
                  <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
                </div> -->
  
                <mat-form-field appearance="fill" class="activities">
  
                <mat-select formControlName="activity_id" (ngModelChange)="checkselect(false)">
                  <mat-option *ngFor="let item of donateactivities" (click)="getIdActivitis(item.id)" [value]="item.id">
                    {{item.name}}
                  </mat-option>
                </mat-select>
                </mat-form-field>
              </div>
              <div class="submitBtn my-3 mt-5">
                <button mat-button matStepperNext class="btn btn-success submitBtn"
                     (click)="step=70"><span translate>home.next</span></button>
  <!--                  [disabled]="secondFormGroup.invalid"-->
            </div>
            <div class="submitBtn mt-3" *ngIf="!user">
              <button mat-button matStepperPrevious (click)="step = 20" class="btn btn-primary submitBtn"><span
                      translate>home.back</span></button>
          </div>
  
              </form>
              </mat-step>
              <mat-step [stepControl]="2">
                <form [formGroup]="myForm">
  
                  <div class="col-12 p-0 form-label mt-3" style="line-height: 30px;" translate>
                    home.DONATE
                  </div>
                  <div class="col-12 p-0 form-label mt-5" translate>
                        home.custom-amount
                  </div>
                  <div class="col-12 my-2 p-0 mt-3">
                    <div class="input-group">
                      <span class="input-group-text" style="z-index: 66">$</span>
                      <input id="custom_amount" class="position-absolute" type="number"
                        formControlName="amount"
                        style="width: 100%; min-height: 100%; padding-left: 50px;">
                    </div>
                  </div>
                  <!-- <div class="d-flex"> -->
                    <div class="col-12 p-0 form-label mt-5" style="line-height: 30px;" translate>
                      home.credit-card-fees
                    </div>
                    <div class="col-12 my-2  p-0 mt-3">
                    <label class="checkbox ">
                      <input type="checkbox" checked name="vehicle1" value="Bike" formControlName="checked_fee" (click)="checkedFee"   style="cursor: pointer; min-width: 58px; min-height: 40px ;">
                      </label>
                 </div>
                  <!-- </div> -->
                  <div class="row">
                    <div class="col-4 mt-3 justify-content-between" *ngFor="let donate of donateSelect">
                      <button class="pill" [class]="select == donate ? 'pill-select':''" (click)="selectDonate(donate)" type="button" >${{donate}}</button>
                    </div>
                  </div>
                  <div class="submitBtn mt-5">
                    <button mat-button matStepperNext class="btn btn-success submitBtn"
                         (click)="step = 100;payment()"><span
                            translate>home.next</span></button>
  <!--                  [disabled]="thirdFormGroup.invalid"-->
                </div>
                <div class="submitBtn my-3 mt-3" >
                  <button mat-button matStepperPrevious (click)="step = 45" class="btn btn-primary submitBtn"><span
                          translate>home.back</span></button>
              </div>
                  </form>
                  </mat-step>
  
                  <mat-step [stepControl]="3">
                    <form [formGroup]="myForm">
                      <div class="row">
                        <div class="container d-flex justify-content-center mt-5">
                          <div class="row g-3 p-0 w-100">
                            <!-- <div class="mt-5">
                              <h4 class="tell-us" translate>home.card-information</h4>
                            </div> -->
                            <div class="col-12 my-2 mt-3 p-0">
                              <div class="card">
                                <div class="accordion">
                                  <div class="card">
                                    <div class="card-header p-0">
                                      <h2 class="mb-0">
                                        <button class="btn btn-light btn-block text-left p-3 rounded-0" data-toggle="collapse"
                                          data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                          <div class="d-flex align-items-center justify-content-between">
                                            <span translate>home.credit-card</span>
                                            <div class="icons">
                                              <img src="https://i.imgur.com/2ISgYja.png" width="30">
                                              <img src="https://i.imgur.com/W1vtnOV.png" width="30">
                                              <!-- <img src="https://i.imgur.com/35tC99g.png" width="30"> -->
                                              <img src="../../assets/images/cards/icons8-american-express-48.png" width="30">
                                              <img _ngcontent-serverapp-c358="" alt="discover" src="https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg" class="p-CardBrandIcon p-CardBrandIcon--visible" width="30">
  
                                            </div>
  
                                          </div>
                                        </button>
                                      </h2>
                                    </div>
  
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
                                      <div class="card-body payment-card-body">
  
                                        <div class="row">
                                          <div class="col-12 my-2 mt-3 p-0">
                                            <span class="font-weight-normal card-text" translate>home.card-number</span>
                                          <div class="input position-relative mt-2">
                                            <div id="floatingNumber1" class="form-control stripe-form-control"
                                            placeholder="ex. 1111 1111 1111 1111"></div>
                                            <i [ngStyle]="lang == 'ar' ? {'left':'10px' }: {'right': '10px'}" class="position-absolute fa fa-credit-card"></i>
                                          </div>
                                          </div>
                                        </div>
  
                                        <div class="row mt-3 mb-3">
                                          <div class="col-6 my-2 p-0 mt-3">
                                            <span class="font-weight-normal card-text" translate>home.expiry-date</span>
                                            <div class="input position-relative mt-2">
                                              <i [ngStyle]="lang == 'ar' ? {'left':'10px' }: {'right': '10px'}" class="position-absolute fa fa-lock"></i>
                                              <div id="floatingExpiry1" class="form-control stripe-form-control" placeholder="ex. 12/24"></div>
                                            </div>
                                          </div>
                                          <div class="col-6 my-2 pr-1 mt-3 ">
                                            <span class="font-weight-normal card-text" translate>home.CVC-CVV</span>
                                            <div class="input position-relative mt-2">
                                              <i [ngStyle]="lang == 'ar' ? {'left':'10px' }: {'right': '10px'}" class="position-absolute fa fa-calendar"></i>
                                              <div id="floatingCvc1" class="form-control stripe-form-control" placeholder="ex. 123"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <span class="text-muted certificate-text position-relative" translate><i class="fa fa-lock"></i>
                                          home.Your-transaction-is-secured</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                          </div>
                        </div>
                      </div>
  
                      <!-- <div class="text-center">
                        <button class="w-25 bg-card book" type="submit" translate>home.donate</button>
                      </div> -->
                      <div class="submitBtn mt-3">
                        <button mat-button class="btn btn-success submitBtn" (click)="createToken()">
                          <span translate>home.submit</span>
                          <div *ngIf="loading" class="spinner-border text-light" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>
                      </div>
  
                      <div class="submitBtn mt-3">
                        <button mat-button matStepperPrevious (click)="step = 70" class="submitBtn btn btn-primary "><span
                          translate>home.back</span></button>
                      </div>
                      </form>
                      </mat-step>
  
          </mat-horizontal-stepper>
    <!-- <mat-steper> -->
      <!-- <form [formGroup]="myForm" (ngSubmit)="createToken()"> -->
  
        <!-- <div class="submitBtn my-3 mt-5">
          <button mat-button cdkStepperNext class="btn btn-success submitBtn" [disabled]="myForm.invalid">
            <span translate>home.next</span>
          </button>
        </div> -->
      <!-- </form> -->
    <!-- </mat-steper>
  
    <mat-steper> -->
      <!-- <form [formGroup]="myForm"> -->
  
  
  
      <!-- </form>
    </mat-steper>
  
    <mat-steper> -->
      <!-- <form [formGroup]="myForm"> -->
  
  
  
  
      <!-- </form> -->
    <!-- </mat-steper> -->
  </div>
  