import {Component, OnInit, PLATFORM_ID, Inject,} from '@angular/core';
import {Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';
import {ActivatedRoute, Router} from '@angular/router';
import {ApisService} from '../../services/apis.service';
// import {image} from 'html2canvas/dist/types/css/types/image';
import {SeoService} from '../../services/seo.service';
import {AppComponent} from '../../app.component';
import {isPlatformBrowser} from '@angular/common';
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
    selector: 'app-news-details',
    templateUrl: './news-details.component.html',
    styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
    newsId: any;
    news: any;
    slug: any = this.activatedRoute.snapshot.params.slug;
    element: any = [];
    latestNews: any;
    article: any;
    allUpcoming: any;
    events: any;
    showAll: boolean = true;
    copied = false;
    latestNew: any;
    relatedNews: any;
    videoUrl = 'https://player.vimeo.com/external/567641203.hd.mp4?s=c04076dd9a63e7d8dd2c7312cf1178db4066063b&profile_id=175';
    items: GalleryItem[];
    images: any = [];
    zero = 0;
    isBrowser: any;
    lang:string=localStorage.getItem('lang')
    QLang:any;
    langNews: any;
  metaTitle: any;
  metaDeescription: any;
  metaImage: any;
    constructor(public gallery: Gallery,
                public lightbox: Lightbox,
                private activatedRoute: ActivatedRoute,
                private apis: ApisService,
                private router: Router,
                private clipboard: Clipboard,
                private seoService: SeoService,
                @Inject(PLATFORM_ID) platformId: Object,
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      activatedRoute.params.subscribe(params => {
        this.newsId = params.slug;
        // console.log('msnd,ms',this.newsId);
        
      })
      this.activatedRoute.queryParams.subscribe(query=> {
        // console.log('query', query);
        this.QLang = query.lang;
        // console.log('jjj',this.QLang)

          this.apis.getnewsApi(this.slug).subscribe((data: any) => {
            this.langNews = data.data;
            console.log('dddd',this.langNews)
            this.seoUpdate(this.langNews);
          })

      },error => {
        // console.log('hhhhhh')
      })
      
        // this.seoUpdate(this.langNews);
    }

    ngOnInit(): void {
        // this.slug = this.activatedRoute.snapshot.params.slug;
        // this.getNewsDetails(this.newsId);

        this.getNews();
        this.videoHandler();
        this.getNewsApi();
        this.getUpcoming();
        this.getEventsInPageNews();
        this.getLatestNews();
        this.getRelatedNews();
    }

    // seoUpdate(title = null, description = null, image = null, url = '') {
    //     console.log(title, description, image);
    //     this.seoService.updateUrl();
    //     if (title != null) {
    //         this.seoService.updateTitle(title);


    //     }
    //     if (description != null) {
    //         this.seoService.updateDescription(description);
    //     }
    //     if (image != null) {
    //         this.seoService.updateImage(image);
    //     }
    // }
    seoUpdate(metaData) {
    //   console.log(metaData);
       this.metaTitle=metaData.meta_title
       this.metaDeescription=metaData.meta_description;
        this.metaImage=metaData.meta_image;
       if (this.metaTitle != null) {
        this.seoService.updateTitle(this.metaTitle);
        // console.log(this.metaTitle);

      }
      if (this.metaDeescription != null) {
        this.seoService.updateDescription(this.metaDeescription);
      }
      if (this.metaImage != null) {
        this.seoService.updateImage(this.metaImage);
      }
    }

    basicLightboxExample(): void {
        this.gallery.ref().load(this.items);
    }

    /**
     * Use custom gallery config with the lightbox
     */
    withCustomGalleryConfig(): void {

        // 2. Get a lightbox gallery ref
        const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
            imageSize: ImageSize.Cover,
            thumbPosition: ThumbnailsPosition.Top
        });

        // 3. Load the items into the lightbox
        lightboxGalleryRef.load(this.items);
    }

    videoHandler(): void {
        if (this.isBrowser) {
            if (window.screen.availWidth < 600) {
                this.videoUrl = 'https://player.vimeo.com/external/567151192.hd.mp4?s=db1921b9a7500a7a0cf1846bdaf69cec317782c5&profile_id=175';
            }
        }
    }

    getNewsDetails(id): void {
        // console.log(id, "id")
        this.apis.getNews(id).subscribe((data: any) => {
            // this.news = data.data.news;


            this.items = this.images.map(item =>
                new ImageItem({src: item.srcUrl, thumb: item.previewUrl})
            );
            this.basicLightboxExample();
            this.withCustomGalleryConfig();
        }, err => {
        });
    }

    getUpcoming(): void {
        this.apis.getUpcomingEvents().subscribe((data: any) => {
            this.allUpcoming = data.data;
            // console.log(this.allUpcoming);
        }, (err: any) => {
        });
    }

    getEventsInPageNews(): void {
        this.apis.getEventsInPageNews().subscribe((data: any) => {
            this.events = data.data;
            // console.log('mam', this.events)

        }, (err: any) => {
        });
    }

    getLatestNews(): void {
        this.apis.latestNews().subscribe((data: any) => {
            this.latestNew = data.data;
            //  console.log('mamamam', this.latestNew)

        }, (err: any) => {
        });
    }
    getRelatedNews(): void {
        this.apis.getRelatedNews(this.slug).subscribe((data: any) => {
            this.relatedNews = data.data;
            this
            // console.log('mammm', this.relatedNews)

        }, (err: any) => {
        });
    }

    getNewsApi(): void {
        this.apis.newsApi().subscribe((data: any) => {
            this.news = data.data.news;
            // console.log( "new",this.news)
        }, (err: any) => {
        });
    }

    getNews(): void {
        // console.log('dcdscvwcscsac',this.slug);
        this.apis.getnewsApi(this.slug).subscribe((data: any) => {
            this.latestNews = data.data;
            console.log('nsdbfv',this.latestNews);
        }, (err: any) => {
        });
    }
    

    

    // extractTextBetweenDots() {
    //     console.log('dfsg');

    //     // Split the input string using the dot as the separator
        
    //     console.log('sfdsf',this.article);

    //     // Filter out any empty strings and remove leading/trailing white spaces
    //     const textBetweenDots = parts
    //       .filter(part => part.trim() !== '')
    //       .map(part => part.trim());
    
    //     // Now, textBetweenDots will contain the text between the dots
    //     // console.log(textBetweenDots);
    //   }
    
    newsDetails(slug): void{
        this.slug = slug
        this.router.navigate(['/news-details/' + slug],{queryParams:{lang:localStorage.getItem('lang')}});
        console.log('language',localStorage.getItem('lang'));
        this.getNews()
      }


    setPostId(e): any {
        this.router.navigateByUrl(this.router.url.replace(this.newsId, e));
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    transform(n: string): string {
        const date = new Date(n);
        const months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
            'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
        ];
        const days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
        const delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
        return delDateString;
    }

    changeFlag() {
        this.showAll = !this.showAll;
    }

    // cop() {
    //     this.clipboard.copy('https://newegyptgroup.com' + this.router.url)
    //     this.copied = !this.copied;
    //     setTimeout(() => {
    //         this.copied = false;
    //     }, 1500)

    // }

    // fbc(e) {
    //     let url = "www.google.com";
    //     e.preventDefault();
    //     var facebookWindow = window.open(
    //         "https://www.facebook.com/sharer/sharer.php?u=" +
    //         "newegyptgroup.com" +
    //         this.router.url,
    //         "facebook-popup",
    //         "height=700px,width=555px"
    //     );
    //     if (facebookWindow.focus) {
    //         facebookWindow.focus();
    //     }

    //     return false;
    // }

    // tw() {
    //     let url = 'www.google.com';
    //     var Window = window.open(
    //         'https://twitter.com/intent/tweet?url=' + 'newegyptgroup.com' + this.router.url,
    //         '-popUp',
    //         'width= 555px, height= 450px'
    //     );
    //     if (Window.focus) {
    //         Window.focus();
    //     }
    // }

    // in() {
    //     let url = 'www.google.com';
    //     var Window = window.open(
    //         'https://linkedin.com/shareArticle?mini=true&url=' + 'newegyptgroup.com' + this.router.url,
    //         '-popUp',
    //         'width= 555px, height= 603px'
    //     );
    //     if (Window.focus) {
    //         Window.focus();
    //     }
    // }
    cop() {
      this.clipboard.copy('https://newegyptgroup.com/news-details/' + this.slug +'?lang='+ this.lang)
      this.copied = !this.copied;
      setTimeout(() => {
          this.copied = false;
      }, 1500)

  }

  fbc(e) {
      let url = "www.google.com";
      e.preventDefault();
      var facebookWindow = window.open(
          "https://www.facebook.com/sharer/sharer.php?u=" +
          'https://newegyptgroup.com/news-details/' + this.slug +'?lang='+ this.lang,
          "facebook-popup",
          "height=720px,width=555px"
      );
      if (facebookWindow.focus) {
          facebookWindow.focus();
      }

      return false;
  }

  tw() {
      let url = 'www.google.com';
      var Window = window.open(
          'https://twitter.com/intent/tweet?url=' +'https://newegyptgroup.com/news-details/' + this.slug +'?lang='+ this.lang,
          'twitter-popUp',
          'width= 555px, height= 450px'
      );
      if (Window.focus) {
          Window.focus();
      }
  }

  in() {
      let url = 'www.google.com';
      var Window = window.open(
          'https://linkedin.com/shareArticle?mini=true&url=' +  'https://newegyptgroup.com/news-details/' + this.slug +'?lang='+ this.lang,
          'linkedin-popUp',
          'width= 555px, height= 603px'
      );
      if (Window.focus) {
          Window.focus();
      }
  }
}
