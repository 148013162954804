<div class="container mb-5 mt-5">
  <div class="row mb-4 ">
    <div class="col-md-6 " style="overflow: hidden">
      <div class="btn-group">
        <div
          class="btn btn-special btn-primary"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        translate>
          home.previous
        </div>
        <div
          class="btn btn-special btn-outline-secondary"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        translate>
          home.today
        </div>
        <div
          class="btn btn-special btn-primary"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        translate>
          home.next
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-end" style="overflow: hidden">
      <div class="btn-group">
        <div
          class="btn btn-primary btn-special"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        translate>
          home.month
        </div>
        <div
          class="btn btn-primary btn-special"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        translate>
          home.week
        </div>
        <div
          class="btn btn-primary btn-special"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        translate>
          home.day
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <p class="font-weight-bolder" style="font-size:22px">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</p>
  </div>
  <br/>
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-day-view>
  </div>
  <!-- Everything you see below is just for the demo, you don't need to include it in your app -->
 <ng-template #modalContent let-close="close" class="modal-content">
    <div class="modal-header">
      <div class="text-center">
        <h3>{{ eventObj[0].title }}</h3>
      </div>
      <div class="row">
      <button _ngcontent-serverapp-c206="" class="close mat-button" (click)="close()">X</button>
      </div>
    </div>
   <mat-tab-group>
     <mat-tab  label="{{ 'home.content' | translate }}" ng-model="myModel">
       <!-- <div class="logo">
       <img *ngIf="eventObj[0]"  [src]="eventObj[0].logo">
     </div>
       <div class="text-justify desc">
         <p>{{ eventObj[0].description.slice(0,150) }}.....</p>
       </div> -->
       <div class="row">
         <div class="col-6">
           <div class=" desc">
             <p>{{ eventObj[0].description.slice(0,150) }}.....</p>
           </div>
         </div>
         <div class="col-6">
        <div class="logo">
            <img *ngIf="eventObj[0]"  [src]="eventObj[0].logo">
         </div>
        </div>
       </div>
     </mat-tab>
     <mat-tab label="{{ 'home.date' | translate }}" ng-model="myModel">
         <div class="icon-box date">
           <div class="icon"><img class="calendar-icon m-3" src=""></div>
           <h4></h4>
           <div class="icon"><img class="calendar-icon m-3" src="assets/icons/calendar.png"></div>
           <p> <span translate>home.event-start</span>{{eventObj[0].start | date : 'fullDate'}}  </p>
           <p class="my-2"> <span translate> home.event-end </span> {{eventObj[0].end | date: 'fullDate' }} </p>
         </div>
     </mat-tab>
   </mat-tab-group>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" [routerLink]="['/eventDetails/', eventObj[0].slug]"  (click)="close()" translate>
        home.go-to-event-page
      </button>
    </div>
  </ng-template>
</div>
