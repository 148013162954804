import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ApisService} from "../services/apis.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogData} from "../pages/booking/booking.component";
import {id} from "date-fns/locale";

@Component({
  selector: 'app-dialogexpenses',
  templateUrl: './dialogexpenses.component.html',
  styleUrls: ['./dialogexpenses.component.scss']
})
export class DialogexpensesComponent implements OnInit {
  myDate = new Date();
  expenses: any;
  expensesdetailsreport: any;
  expensesDetailsReportIsExist: boolean = false;
  expensesId!: any;
  expensesDetailsReportsId!: any;

  constructor(private apis: ApisService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  ngOnInit(): void {
    this.expensesId = this.data['expensesId']
    this.expensesDetailsReportsId = this.data['expensesDetailsReportsId']
    this.getExpensesApi(this.expensesId);
    // this.getActivityExpensesDetailsReportApi(this.expensesDetailsReportsId);

  }

  getExpensesApi(id): any {
    console.log(id, "id")
    this.apis.getExpenses(id).subscribe((data: any) => {
        this.expenses = data.data;
        console.log(this.expenses);
      },
      err => {
        console.log(err);
      });
  }

  getActivityExpensesDetailsReportApi(id): any {
    console.log(id, "id")
    this.apis.getActivityExpensesDetailsReport(id).subscribe((data: any) => {
        this.expensesdetailsreport = data.data;
        console.log(this.expensesdetailsreport);
      },
      err => {
        console.log(err);
      });
  }

  openeExpensesDetailsReports(id) {
    this.getActivityExpensesDetailsReportApi(id)
    this.expensesDetailsReportIsExist = true;

  }
}
