<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">
<div class="container" *ngIf="latestNews">
  <div class="row body">
    <div class="row w-100">
      <div class="col-8">
        <div class="pt-5">
          <div class="flexDiv" style="justify-content: space-between">
            <div class="flexDiv" *ngIf="latestNews">
              <h1 class="popular-city">{{latestNews.title}}</h1>
            </div>
          </div>
        </div>
        <!-- <div class="col-8 p-0"> -->
        <div class="row">
          <div class="col-7">
            <span class="format-date">{{latestNews.date | date}}</span>
          </div>
          <div class="col-5">
            <section>
              <div class=" w-100 order-lg-first ">
                <div class="sticky-content">
                  <div class="social-icons social-icons-colored social-icons-vertical">
                    <div class="icon-share">
                      <span class="social-label" translate>home.share</span>
                      <a class="social-icon image-item" (click)="cop()" target="_blank">
                        <img *ngIf="!copied" title="copy" style="width: 22px" alt="copy"
                          src="./assets/newIcon/copy-2.png" />
                        <img *ngIf="copied" title="copied" style="width: 28px" alt="copied"
                          src="./assets/newIcon/copied.png" />
                      </a>
                      <a (click)="fbc($event)" class="social-icon social-facebook" title="Facebook">
                        <i class="icon-facebook-f"></i>
                      </a>
                      <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                        <i class="icon-twitter"></i>
                      </a>
                      <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                        <i class="icon-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="">
          <div class="w-100">
            <div class="flexDiv" style="justify-content: space-between">
              <div class="flexDiv">
                <h1 class="sub-title">{{latestNews.excerpt}} </h1>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>

      <div class="col-4 ">
        <div class="pt-5">
          <div class="flexDiv" style="justify-content: space-between">
            <div class="flexDiv">
              <h1 class="activity w-100" translate>home.activities</h1>
            </div>
          </div>
        </div>
        <div class="flexDiv" *ngFor="let item of events">
          <h2 class="activity-title w-100" [routerLink]="['/eventDetails/', item.slug]" >
            {{item.name.slice(0,35)}}...</h2>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-8 mt-4">
        <div class="">
          <div class="row">
            <div class="col-12" *ngFor="let iem of latestNews.articles">
              <h1 *ngIf="iem.sort == 1" class="description-1 my-1">{{iem.article}}</h1>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-5 mb-5">
            <ngb-carousel>
              <ng-container *ngFor="let item of latestNews.images">
                <ng-template ngbSlide>
                  <div class="picsum-img-wrapper">
                    <img [src]=item.image_url alt="Random second slide" width="100%" height="540">
                    <!-- style="border-radius: 12px" -->

                    <!-- <h4 class="sub-title"></h4> -->
                  </div>
                  <div class="carousel-caption"></div>

                </ng-template>
              </ng-container>
            </ngb-carousel>

          </div>
          <!-- <div class="row"> -->
          <div class="col-4 col-md-4 col-lg-4 col-sm-4  mt-1" *ngFor="let item of relatedNews">
            <div class="post-item position-relative h-50 justify-content-between" (click)="newsDetails(item.slug)">
              <div class="post-img position-relative overflow-hidden">
                <img *ngIf="item.image" src="{{item.image}}" class="img-fluid" alt="{{item.title}}"
                  style="height: 250px !important;width: 100%">
                <span class="post-date">{{item.date | date }}</span>
                <!-- :'d  MMM  yyy' -->
              </div>

              <div class="post-content d-flex flex-column">
                <div class="handelTitle">
                  <h3 class="post-title">{{item.title}}</h3>
                  <!-- .slice(0,40) -->
                </div>
                <!-- <p>
                      {{item.article.slice(0,90)}}...
                    </p>
                    <hr>
                    <a routerLink="/news" class="readmore stretched-link"><span translate>home.details</span>
                                 <i class="bi bi-arrow-right"></i>
                    </a> -->

              </div>

            </div>
          </div>
          <!-- <div class="row"> -->
          <div class="col-12 mt-4">
            <div class="row">
              <div class="col-12" *ngFor="let iem of latestNews.articles">
                <h1 *ngIf="iem.sort == 2" class="description-1 my-1">{{iem.article}}</h1>
              </div>
            </div>
          </div>
          <!-- </div> -->

          <!--        <ng-container *ngFor="let item of news; let i = index">-->
          <!--            <div class=" col-xxl-4 col-lg-4 col-md-4 col-sm-6" *ngIf="i<15 && i>8">-->
          <!--                <div class="news" [routerLink]="['/news-details/', item.slug]" (click)="refresh(item.slug)">-->
          <!--                    <img src="{{item.image}}"/>-->
          <!--                    <div class="info" [routerLink]="['/news-details/', item.slug]" (click)="refresh(item.slug)">-->
          <!--                        <h2>{{item.title}}</h2>-->
          <!--                        <p></p>-->
          <!--                    </div>-->
          <!--                </div>-->
          <!--            </div>-->
          <!--        </ng-container>-->
          <!-- </div> -->
        </div>
      </div>

      <div class=" col-4 mt-3 w-100">
        <!-- col-md-3 col-lg-3 col-sm-3 -->
        <div class="flexDiv" style="justify-content: space-between">
          <div class="flexDiv">
            <h1 class="activity w-100 mt-2" translate>home.features</h1>
          </div>
        </div>
        <div class="" *ngFor="let item of latestNew">
          <div class="post-item position-relative h-50" (click)="newsDetails(item.slug)">
            <div class="post-img position-relative overflow-hidden">
              <img *ngIf="item.image" class="img-fluid" src="{{item.image}}" alt="{{item.title}}"
                style="height: 250px !important;width: 100%">
              <span class="post-date">{{item.date | date }}</span>
              <!-- :'d  MMM  yyy' -->
            </div>

            <div class="post-content d-flex flex-column">
              <div class="handelTitle">
                <h3 class="post-title">{{item.title}}</h3>
                <!-- .slice(0,40) -->
              </div>
              <p>{{item.excerpt.slice(0,90)}}...</p>
              <!-- <hr>
                    <a routerLink="/news" class="readmore stretched-link"><span translate>home.details</span>
                                 <i class="bi bi-arrow-right"></i>
                    </a> -->

            </div>

          </div>
        </div>

        <!--                        <div class="col-6  btn-donate-sec">-->
        <!--                            <a routerLink="/donation" class="button3d" translate>home.donate</a>-->
        <!--                        </div>-->
      </div>
    </div>

    <!-- </div> -->

    <div class="row w-100">
      <div class="col-8 mt-5">
        <!-- <ngb-carousel> -->
        <ng-container *ngIf="latestNews.image">
          <!-- <ng-template ngbSlide > -->
          <div class="picsum-img-wrapper">
            <img src="{{latestNews.image}}" alt="{{latestNews.title}}" width="100%" height="540">
            <!-- style="border-radius: 12px" -->

            <!-- <h4 class="sub-title"></h4> -->
          </div>
          <div class="carousel-caption"></div>

          <!-- </ng-template> -->
        </ng-container>
        <!-- </ngb-carousel> -->

      </div>
      <div class="col-4 mt-4">
        <div class="flexDiv" style="justify-content: space-between">
          <div class="flexDiv">
            <h1 class="activity mb-2 w-100" translate>home.Up-Coming-Event</h1>
          </div>
        </div>
        <div class="">
          <ngb-carousel>
            <ng-container *ngFor="let item of allUpcoming">
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper" style="cursor: pointer;">
                  <img [routerLink]="['/eventDetails/', item.slug]"  [src]=item.image
                    alt="Random second slide" width="100%" height="510">
                  <!-- style="border-radius: 12px" -->

                  <!-- <h4 class="sub-title"></h4> -->
                </div>
                <div class="carousel-caption"></div>
              </ng-template>
            </ng-container>
          </ngb-carousel>
        </div>
      </div>
    </div>



    <div class="portfolio-description   readMore">
      <div class="">
        <!--                            <div class="col-4 tit-description">-->
        <!--                                <h4 class="title" translate> home.more-on <br translate> home.news </h4>-->
        <!--                                <div class="title-tit">-->
        <!--                                    <ng-container *ngFor="let item of news; let i = index">-->
        <!--                                        <div class="column" *ngIf="i<7 && i>0 ">-->
        <!--                                            <div class="card card-border" [routerLink]="['/news-detalis/', item.slug]"-->
        <!--                                                 (click)="refresh(item.slug)">-->
        <!--                                                <h5 class="tit">{{item.title}}</h5>-->
        <!--                                            </div>-->
        <!--                                        </div>-->
        <!--                                    </ng-container>-->
        <!--                                </div>-->
        <!--                            </div>-->


        <!-- <div class="col-8 p-0" >
                              <ul class="list-unstyled p-0">
                                <li class="mb-3 portfolio-description aboutText" *ngFor="let item of article">{{item}}.</li>
                              </ul> -->


        <!-- <div class="row portfolio-description  p-4 aboutText">
                                    <span class="aboutText" *ngIf="showAll"> {{latestNews.article| slice:0:1300}}....<p
                                            *ngIf="showAll&&latestNews.article.length>200" (click)="changeFlag()"
                                            class="readMore" translate>home.read-more</p> </span>

                                    <span class="aboutText" *ngIf="!showAll">
                                      {{latestNews.article}}<p *ngIf="!showAll" (click)="changeFlag()"
                                     class="readMore" translate>home.read-less</p> </span>
                                </div> -->
        <!--                                <ngb-carousel class="slider-bottom">-->
        <!--                                    <ng-container>-->
        <!--                                        <ng-template ngbSlide *ngFor="let item of latestNews.images | reverse">-->
        <!--                                            <div class="picsum-img-wrapper">-->
        <!--                                                <img-->
        <!--                                                        [src]=item.image_url-->
        <!--                                                        width="100%"-->
        <!--                                                        height="auto"-->
        <!--                                                        style="border-radius: 12px"-->
        <!--                                                >-->
        <!--                                            </div>-->
        <!--                                            <div class="carousel-caption"></div>-->
        <!--                                        </ng-template>-->
        <!--                                    </ng-container>-->
        <!--                                </ngb-carousel>-->
        <!-- </div> -->
      </div>
    </div>

  </div>
  <div class="col-4">
    <!--                  <section>-->
    <!--                    <div class=" order-lg-first mt-3 ">-->
    <!--                      <div class="sticky-content">-->
    <!--                        <div class="social-icons social-icons-colored social-icons-vertical">-->
    <!--                          <div class="icon-share">-->
    <!--                            <span class="social-label" translate>home.share</span>-->
    <!--                            <a class="social-icon image-item" (click)="cop()"-->
    <!--                               target="_blank">-->
    <!--                              <img *ngIf="!copied" title="copy" style="width: 22px"-->
    <!--                                   alt="copy"-->
    <!--                                   src="./assets/newIcon/copy-2.png"/>-->
    <!--                              <img *ngIf="copied" title="copied" style="width: 28px"-->
    <!--                                   alt="copied"-->

    <!--                                   src="./assets/newIcon/copied.png"/>-->
    <!--                            </a>-->
    <!--                            <a (click)="fbc($event)" class="social-icon social-facebook" title="Facebook">-->
    <!--                              <i class="icon-facebook-f"></i>-->
    <!--                            </a>-->
    <!--                            <a (click)="tw()" class="social-icon social-twitter" title="Twitter">-->
    <!--                              <i class="icon-twitter"></i>-->
    <!--                            </a>-->
    <!--                            <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">-->
    <!--                              <i class="icon-linkedin"></i>-->
    <!--                            </a>-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </section>-->
  </div>

</div>

<div class="col-4">
  <div class="row justify-content-center dst-img">
    <!--                <ng-container *ngFor="let item of news; let i = index">-->
    <!--                    <div class="column" *ngIf="i<7 && i>0 ">-->
    <!--                        <div class="card bord" [routerLink]="['/news-details/', item.slug]"-->
    <!--                             (click)="refresh(item.slug)">-->
    <!--                            <img src="{{item.image}}">-->
    <!--                            <h5 class="tit">{{item.title}}</h5>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </ng-container>-->

  </div>
</div>


<!--    <div class="container">-->
<!--        <h1 translate>home.activities</h1>-->
<!--        <div _ngcontent-dmi-c79="" style="background-color: #9D2121; height: 3px; width: 75px; margin: auto;"></div>-->
<!--        <div class="row" *ngIf="read?.activities">-->
<!--            <ng-container *ngFor="let item of read?.activities; let i = index">-->
<!--                <div class=" col-xxl-4 col-lg-4 col-md-4 col-sm-6" *ngIf="i<4 && i>0">-->
<!--                    <div class="news" [routerLink]="['/eventDetails/', item.slug]" (click)="refresh(item.slug)">-->
<!--                        <img src="{{item.image}}"/>-->
<!--                        <div class="info" [routerLink]="['/eventDetails/', item.slug]" (click)="refresh(item.slug)">-->
<!--                            <h2>{{item.name}}</h2>-->
<!--                            <p></p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-container>-->
<!--        </div>-->
<!--    </div>-->

<!-- ======= Activities Section ======= -->
<!-- <section id="recent-blog-posts" class="recent-blog-posts">
  <div class="container" data-aos="fade-up">
    <header class="section-header mb-4">
      <h1 translate>home.activities</h1>
      <div _ngcontent-dmi-c79="" style="background-color: #9D2121; height: 3px; width: 75px; margin: auto;"></div>
    </header>
    <div class="row" *ngIf="read?.activities">
      <ng-container *ngFor="let item of read?.activities; let i = index">
      <div class="col-lg-4 mt-5" *ngIf="i<7 && i>0">
        <div class="post-box" [routerLink]="['/eventDetails/', item.slug]" (click)="refresh(item.slug)">
          <div class="post-img"><img src="{{item.image}}"  height="280px" alt=""></div>
          <span class="post-date"> {{item.start_date | date : 'fullDate'}}</span>
          <h3 class="post-title">{{item.name}}</h3>
          <a [routerLink]="['/eventDetails/', item.slug]" class="readmore stretched-link mt-auto">
            <span translate>home.read-more</span>
            <i class="bi bi-arrow-right"></i>
          </a>
        </div>
      </div>
      </ng-container>
    </div>

  </div>

</section> -->
<!-- End Activities Section  -->


<!-- ======= NEWS Section ======= -->
<!-- <div class="container" >
  <div >
    <h1 class="topNews" translate>home.other-news</h1>
    <div _ngcontent-dmi-c79="" style="background-color: #9D2121; height: 3px; width: 75px; margin: auto;"></div>
  </div>

    <div class="row">
      <div class="col-4 col-md-4 col-lg-4 col-sm-4 mb-3 mt-2" *ngFor="let item of news;let i = index " >
        <div class="post-item position-relative h-100" *ngIf="i<15 && i>8" [routerLink]="['/news-details/', item.slug]" (click)="refresh(item.slug)">
          <div class="post-img position-relative overflow-hidden" >
            <img *ngIf="item.image" src="{{item.image}}" class="img-fluid" alt="{{item.title}}" style="height: 250px !important;width: 100%">
            <span class="post-date">{{item.date | date :'d  MMM  yyy'}}</span>
          </div>

          <div class="post-content d-flex flex-column">
            <div class="handelTitle">
              <h3 class="post-title">{{item.title.slice(0,40)}}</h3>
            </div>
            <p>
              {{item.article.slice(0,90)}}...
            </p>
            <hr>
            <a routerLink="/news" class="readmore stretched-link"><span translate>home.details</span>
                         <i class="bi bi-arrow-right"></i>
            </a>

          </div>

        </div>
      </div>
    </div>
</div> -->


<!--        <ng-container *ngFor="let item of news; let i = index">-->
<!--            <div class=" col-xxl-4 col-lg-4 col-md-4 col-sm-6" *ngIf="i<15 && i>8">-->
<!--                <div class="news" [routerLink]="['/news-details/', item.slug]" (click)="refresh(item.slug)">-->
<!--                    <img src="{{item.image}}"/>-->
<!--                    <div class="info" [routerLink]="['/news-details/', item.slug]" (click)="refresh(item.slug)">-->
<!--                        <h2>{{item.title}}</h2>-->
<!--                        <p></p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </ng-container>-->