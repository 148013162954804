<div class="custom-container">
  <span class="mat-error-2">3</span>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label>الاسم بالكامل باللغة الانجليزية</label>
        <input [(ngModel)]="name" (focusout)="setPersonalData()" type="text" class="form-control" placeholder="">
      </div>
    </div>
    <div class="col-12">
      <div class="form-group" *ngIf="!checked">
        <label> الرقم القومى ققط للتسجيل</label>
        <input  [(ngModel)]="nationalId" id="national_id"   (keyup)="validForm()" (focusout)="setPersonalData();" type="text" class="form-control" placeholder="">
        <span class="mat-error d-block text-left mt-2 font-size-20-600">{{errorMessage}}</span>
      </div>
      <div class="">
        <section class="example-section direction-rtl">
          <mat-checkbox #event [(ngModel)]="checked"
                         (change)="check($event)"
            class="example-margin">
            لا أمتلك رقم قومى
          </mat-checkbox>
        </section>
      </div>
    </div>
    <div class="col-12" *ngIf="checked">
      <mat-form-field appearance="fill" class="w-100 ">
        <mat-label>اختر</mat-label>
        <mat-select [(ngModel)]="selectedId"  (selectionChange)="getType($event)"  name="food">
          <mat-option *ngFor="let item of idTypes" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="selectedValue && checked">
      <div class="form-group">
        <label>{{selectedValue}}</label>
        <input [(ngModel)]="nationalId" (keyup)="validSsn($event)"  (focusout)="setPersonalData()" type="text" class="form-control" placeholder="">
        <span class="mat-error d-block text-left mt-2 font-size-20-600">{{errorMessage}}</span>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>رقم الهاتف المحمول</label>
        <input [(ngModel)]="mobile"  (focusout)="setPersonalData()"  ng2TelInput type="text" class="form-control" required="required" placeholder="">
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label>ملاحظات</label>
        <textarea [(ngModel)]="notes"
                  (focusout)="setPersonalData()"
                  class="form-control text-right notes" rows="6"></textarea>
      </div>
    </div>
  </div>
</div>
