<div class="container">
  <div class="videos-section mt-3 mb-5">
    <h3 class="header">Videos</h3>
    <div class="post-container">
      <div class=" row">
        <div class="content col-4">
          <p>A donation campaign from Egyptians in the United States to support a "dignified life" for our
            families
          </p>
          <a class="share-button mt-3 d-block">
            <button class="share btn m-auto">
              Share
              <i class="fab fa-facebook"></i>
            </button>
          </a>
          <a class="share-button mt-3 d-block " (click)="navigate()"> <!--(click)="openDonate()"-->
            <button class="share btn m-auto">
              Donate
            </button>
          </a>
        </div>
        <owl-carousel-o [options]="carouselOptionsVideos" class="video-width">
          <ng-container>
            <ng-container *ngFor="let item of video">
              <ng-template carouselSlide>
                <iframe class="video-width"
                        [src]="item?.video_url| safe"
                        [title]="item.title"
                ></iframe>
              </ng-template>
            </ng-container>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
