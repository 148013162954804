import { Inject, Injectable, PLATFORM_ID, Query } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from "../../environments/environment"
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LocalStorageService } from './webApis/local-storage.service';


@Injectable({
  providedIn: 'root'
})

export class ApisService {
  token: any
  currentLang$ = new BehaviorSubject<string>('en');
  Lang$ = this.currentLang$.asObservable();
  private newDomain = environment.mainDomain;
  private hayaDomain = environment.hayaDomain;
  constructor(private localStorage: LocalStorageService,
    private http: HttpClient, public translate: TranslateService) {

    this.token = this.localStorage.getItem('access_token');

  }
  getLanguage(lang: string) {
    this.currentLang$.next(lang);

    localStorage.setItem('lang', lang)
    this.translate.use(lang)
    console.log(this.currentLang$.value)
    console.log(window.location.search);
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set('lang', lang);
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
    console.log(window.location.href);
  }

  // videosApi(): any {
  //   return this.http.post(`${this.newDomain}/videos`, null);
  // }

  donation(): any {
    return this.http.get(`${this.hayaDomain}/donation`);
  }
  // profileApi(token): any {
  //   const headers = new HttpHeaders().set('Authorization', 'Bearer' + token).set('Accept', 'application/json').set('lang', 'en');
  //   return this.http.get(`${this.newDomain}/profile`, {headers});
  // }
  profileApi(): any {
    console.log(this.token)
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + this.token).set('Accept', 'application/json');
    return this.http.get(`${this.newDomain}/profile`, { headers });
  }

  clubMembers(pageNumber): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-my-club-members?page=${pageNumber}`, { headers });
  }
  getclubs(): Observable<any> {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/clubs`, { headers });
  }
  newsApi(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/news`, { headers });
  }

  newsPaginatorApi(pageNumber): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/news?page=${pageNumber}`, { headers });
  }

  getnewsApi(slug): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/news/${slug}`, { headers });
  }
  getnewsApiArLang(slug): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', 'ar');
    return this.http.get(`${this.newDomain}/news/${slug}`, { headers });

  }

  projectsApi(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/projects`, { headers });
  }
  getGeneralPurposes(): Observable<any> {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-general-purposes-for-select`, { headers });
  }
  getActivitiesApi(): Observable<any> {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-activities-for-donation`, { headers });
  }
  getDonate(token, data): Observable<any> {
    console.log(this.token)
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + this.token).set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/donate-v1.0`, data, { headers });
  }
  getDonateForActivity(id, body): Observable<any> {
    console.log(this.token)
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + this.token).set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/donate-v1.0/` + id, body, { headers });
  }
  getProject(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/projects/` + id, { headers });
  }
  getProfile(token, obj): any {
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token).set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/profile`, obj, { headers });
  }

  getNews(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/public-news/` + id, { headers });
  }

  getDonation(): any {
    return this.http.get(`${this.hayaDomain}/aplos-donations`);
  }

  getBoards(): any {
    return this.http.get(`${this.newDomain}/main-board`);
  }

  getOrphans(): any {
    return this.http.get(`${this.newDomain}/orphans`);
  }

  eventDateApi(date): any {
    return this.http.post(`${this.hayaDomain}/events-date`, { date });
  }

  // time(): any {
  //   return this.http.get(`${this.newegyptDomain}/times`);
  // }

  // closedDates(data: any = null): any {
  //   return this.http.post(`${this.newegyptDomain}/closedDates`, data);
  // }

  // specialServices(data: any = null): any {
  //   return this.http.post(`${this.newegyptDomain}/special_services`, data);
  // }

  // services(data: any = null): any {
  //   return this.http.post(`${this.newegyptDomain}/public-services`, data);
  // }

  addMemberApi(data): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/join-us`, data, { headers });
  }
  addVolunteerApi(data): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/volunteer-join`, data, { headers });
  }
  getAllVolunteers(pageNumber): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-all-volunteers?page=${pageNumber}`, { headers });
  }
  allVolunteersAtClub(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-all-volunteers/${id}`, { headers });
  }
  VolunteerActivitiesApi(data): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/volunteer-activities`, { activity_id: data }, { headers });
  }
  casesBelongVolunteer(currentPage: number, filterForm: any): any {
    filterForm["page"] = currentPage;
    let httpParams = new HttpParams();
    Object.keys(filterForm).forEach((key) => {
      if (filterForm[key] != null) {
        httpParams = httpParams.append(key, filterForm[key]);
      }
    });
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/cases-belong-volunteer?page=${currentPage}`, { headers, params: httpParams });
  }
  CaseSearchesDelivered(id: number): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/case-searches/delivered/${id}`, { headers });
  }
  getCertificates(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/certificates`, { headers });
  }
  getMyDonations(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-my-donations`, { headers });
  }
  getActivitiesIncomes(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/activities-incomes/${id}`, { headers });
  }
  getGeneralPurpose(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/general-purpose/${id}`, { headers });
  }
  getActivitiesFromGeneral(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/activities-from-general/${id}`, { headers });
  }
  getExpenses(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/expenses/${id}`, { headers });
  }
  getActivityExpensesDetailsReport(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/activity-expenses-details-report/${id}`, { headers });
  }
  getGovernorates(id): any {
    return this.http.post(`${this.newDomain}/gevernorates`, { country_id: id });
  }

  getClubs(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-clubs-on-country/${id}`, { headers });
  }
  getAllClubs(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-all-clubs`, { headers });
  }

  validate(key, value): any {
    return this.http.post(`${this.newDomain}/validate-unique-inputs`, { key: key, value: value });
  }

  checkMail(email): any {
    return this.http.post(`${this.newDomain}/members/check-email`, { email });
  }

  checkPhone(phone): any {
    return this.http.post(`${this.newDomain}/members/check-phone`, { phone });
  }

  checkSsn(ssn): any {
    return this.http.post(`${this.newDomain}/members/check-ssn`, { ssn });
  }

  // getEvents(): any {
  //   const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
  //   return this.http.get(`${this.newDomain}/calender-activities`, {headers});
  // }

  getEvents(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/calender-activities`, { headers });
  }
  getEventsApi(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/activities`, { headers });
  }

  getEventsInPageNews(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-events`, { headers });
  }
  getSupportEvent(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/support-activities`, { headers });
  }

  latestNews(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/latest-news`, { headers });
  }
  latestActivity(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/latest-activities`, { headers });
  }

  getRelatedNews(slug): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/related-news/${slug}`, { headers });
  }

  SliderApi(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/sliders`, { headers });
  }

  getHappeningApi(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/happening-activities?page=${id}`, { headers });
  }

  getUpcomingApi(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/upcoming-activities?page=${id}`, { headers });
  }

  UpcomingActivitiesApi(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/upcoming-activities`, { headers });
  }

  getUpcomingEvents(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-upcoming-events`, { headers });
  }

  getExpiredApi(pageNumber): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/expired-activities?page=${pageNumber}`, { headers });
  }

  getNextEvent(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/next-activity`, { headers });
  }

  getActivities(slug): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/activities/${slug}`, { headers });
  }
  getTasksBelongActivity(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/tasks-belong-activity/${id}`, { headers });
  }

  aboutUsApi(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/about-us`, { headers });
  }
  countries(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get(`${this.newDomain}/countries`, { headers });
  }
  getCasePurposes(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get(`${this.newDomain}/get-case-purposes`, { headers });
  }
  getGovermnets(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/ge-govermnets`, { headers });
  }
  getCities(id: number): any {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get(`${this.newDomain}/get-cities/${id}`, { headers });
  }
  getVillages(id: number): any {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get(`${this.newDomain}/get-villages/${id}`, { headers });
  }
  addCaseSearches(obj): any {
    const headers = new HttpHeaders().set('Accept', 'multipart/form-data').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/case-searches`, obj);
  }
  updateCaseSearches(obj, id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/case-searches/${id}?_method=put`, obj);
  }
  states(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get(`${this.newDomain}/get-states/${id}`, { headers });
  }
  cities(id): any {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get(`${this.newDomain}/get-cities/${id}`, { headers });
  }

  getCountries(): any {
    return this.http.get(`${this.newDomain}/get-countries`);
  }

  hayaNewsApi(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.hayaDomain}/news`, { headers });
  }

  membersApi(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/members`, { headers });
  }

  contactUs(data: any): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/create-support-ticket`, data);
  }
  contactUsDown(data: any): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/contact-for-download`, {data});
  }
  addClubApi(obj): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.post(`${this.newDomain}/club-request`, obj, { headers });
  }
  // contactUs(id:any): any {
  //   const headers = new HttpHeaders().set('Accept', 'application/json');
  //   return this.http.get(`${this.newDomain}/board-members/${id}`);
  // }
  GetClubs(): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-clubs`, { headers });
  }

  getBoardByClubs(clubId: any): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/boards/${clubId}`, { headers });
  }

  getBoardMembers(id: any, club_id: any): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/board-members/${id}/${club_id}`, { headers });
  }
  getBoardClubs(id: any): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-clubs/${id}`, { headers });
  }

  getMemberInfo(id: any): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/members/${id}`, { headers });
  }
  getBoardMembersExcept(bId: any, id: any): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/board-members-except/${bId}/${id}`, { headers });
  }
  getMyClubMembers(): any {
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + this.token).set('Accept', 'application/json').set('lang', this.currentLang$.value);
    return this.http.get(`${this.newDomain}/get-my-club-members`, { headers });
  }
  videosApi(): any {
    return this.http.get(`${this.hayaDomain}/videos`);
  }
  getDonateBount() {
    return this.http.get(`${this.newDomain}/activities/a-donation-campaign-from-egyptians-in-the-united-states-to-support-a-haya-karima-for-our-families`);
  }
  getActivitiesLang(slug, lang): any {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', lang);
    return this.http.get(`${this.newDomain}/activities/${slug}`, { headers });
  }
  CertificateDown1(id){
    const headers = new HttpHeaders().set('Accept', 'application/json').set('lang', this.currentLang$.value);
        return this.http.get(`${this.newDomain}/download-certificate/${id}`);
}

}
