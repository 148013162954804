<section>
    <div class="col-lg-1 order-lg-first ">
        <div class="sticky-content">
            <div class="social-icons social-icons-colored social-icons-vertical">
                <div class="icon-share">
                    <span class="social-label " translate>home.share</span>
                    <a class="social-icon image-item" (click)="cop()"
                       target="_blank">
                        <img *ngIf="!copied" title="copy" style="width: 22px"
                             alt="copy"
                             src="./assets/newIcon/copy-2.png"/>
                        <img *ngIf="copied" title="copied" style="width: 28px"
                             alt="copied"

                             src="./assets/newIcon/copied.png"/>
                    </a>
                    <a (click)="fbc($event)" class="social-icon social-facebook"
                       title="Facebook"><i
                            class="icon-facebook-f"></i></a>
                    <a (click)="tw()" class="social-icon social-twitter"
                       title="Twitter"><i
                            class="icon-twitter"></i></a>
                    <a (click)="in()" class="social-icon social-linkedin"
                       title="Linkedin"><i
                            class="icon-linkedin"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>