import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-haya-message',
  templateUrl: './our-haya-message.component.html',
  styleUrls: ['./our-haya-message.component.scss']
})
export class OurHayaMessageComponent implements OnInit {
  videoUrl3 = 'https://player.vimeo.com/external/595429216.hd.mp4?s=3cc7bb1e1fa2dc94246a74c12815467d8361f51b&profile_id=174';

  constructor() { }

  ngOnInit(): void {
  }
  prevent(e): void{
    e.preventDefault();
  }
}
