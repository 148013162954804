import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {NotifierService} from 'angular-notifier';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {of, Subscription, timer} from 'rxjs';
import {catchError, filter, switchMap} from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-hayah',
  templateUrl: './hayah.component.html',
  styleUrls: ['./hayah.component.scss']
})

export class HayahComponent implements OnInit, OnDestroy {
  videoUrl = 'https://player.vimeo.com/external/603293831.hd.mp4?s=16649f0d5817cc1eb39fd9e3e9dd54f3f3e52d75&profile_id=175';
  id: any = null;
  messageText: any = ['# your pennies saves many', '# you have the power to empower'];
  message: any = '';
  events: any;
  private readonly notifier: NotifierService;
  hostUrl;
  routerUrl;
  eventTitle: any;
  key: any;
  error: any;
  buttonText: any = '';
  membersNames: any;
  accessToken: any;
  contributions: any;
  amount: any;
  donorsNames: any = [];
  show1: any = false;
  show2: any = false;
  show3: any = false;
  show4: any = false;
  donation: any;
  panelOpenState = false;
  panelOpenState2 = false;
  minutes: number;
  subscription: Subscription;
  intervalPeriod: number = 1;
  data: any;
  checkAmount: any;
  totalAmount: any;
  allEvents: any;
  allEventsOBj: any = [];
  donate: any[]=[];
  budget: number;
  expenses: number;
  donateBount: number;
  constructor(private apis: ApisService,
              private notifierService: NotifierService,
              private seoService: SeoService,
              private router: Router,
              @Inject(PLATFORM_ID) private platform: Object
  ) {
    this.notifier = notifierService;
    if (isPlatformBrowser(this.platform)) {
      window.scrollTo(0, 0);
    }
  }

  ngOnInit(): void {
    this.seoUpdate();
    this.getDonate();
    this.getAllEvents();
    this.getDonation();
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.routerUrl = this.router.url;
    for (let i = 0; i < 2; i++) {
      this.message = this.messageText[i];
      this.notification(i);
    }
    // this.getEvent('2021-10-2');
    this.donationText(1);

  }

  getDonate(){
    this.apis.getDonateBount().subscribe((data:any)=>{
      this.donate=data.data.activities;
      this.budget=data.data.activities.budget;
      this.expenses=data.data.activities.expenses;
      this.donateBount= this.budget+this.expenses;
      console.log(this.donateBount,'sjdjdfj');
    })
  }
  ngOnDestroy(): void {
  }

  notification(i): any {
    this.message = this.messageText[i];
    const x = document.getElementById('snackbar');
    // x.className = 'show';
    x?.classList.add('show');
    setTimeout(function() {
      if (x) {
        x.className = x.className.replace('show', '');
      }
    }, 10000);
  }

  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('حملة تبرعات من المصريين بالولايات المتحدة لدعم "حياة كريمة " لأهالينا');
    this.seoService.updateDescription('كانت نقطة الانطلاق من أحد ايقونات "مصر تستطيع بالتاء المربوطة" والمتصدرة قائمة «فوربس» كأحد أفضل مستشاري\n' + '            إدارة الثروات بالولايات المتحدة الأمريكية، السيدة "ليلي بنس "و زوجها رجل الأعمال الأمريكي درايدن بنس، والذين\n' +
      '            استجابوا لدعوة وزيرة الهجرة وشئون المصريين بالخارج السفيرة نبيلة مكرم لدعم أنشطة المشروع الرئاسي القومي الذي\n' +
      '            تشرف عليه مؤسسة «حياة كريمة» عبر الاتفاق علي تنظيم فاعلية لجمع تبرعات المصريين بالولايات المتحدة للمساهمة في\n' +
      '            دعم المبادرة ،كما تم دعوة مؤسسة "نيوايحيبت " للاشتراك في تنظيم وادارة الفاعلية ومرة جديدة يؤكدون انهم نموذج\n' +
      '            للمصريين بالخارج الذين لا يدخروا جهدًا في مساندة وطنهم ودعم أشقائهم فيه، ومستعدون لبذل كل الجهود الممكنة\n' +
      '            للمساهمة في دعم المناطق الأكثر احتياجًا في مصر وإشراك المصريين بالولايات المتحدة وحثهم على ذلك ');
    this.seoService.updateImage('https://newegyptgroup.com/assets/images/haya/hayah2-08.png');
    this.seoService.updateUrl();
  }

  prevent(e): void {
    e.preventDefault();
  }

  getAllEvents(): any {
    this.apis.getEvents().subscribe((res: any) => {
      this.allEvents = res.data;
      let i = 0;
      const colors = ['red', 'blue'];
      for (const item of this.allEvents) {
        if (i >= colors.length) {
          i = 0;
        }
        const obj: any = {};
        obj.start = new Date(item.start_date);
        obj.title = item.name_en;
        obj.color = {primary: colors[i], secondary: '#A885D8'};
        obj.description = item.details_en;
        this.allEventsOBj.push(obj);
        console.log(obj);
        i++;
      }
    }, err => {
      console.log(err);
    });
  }

  dateFormat(dateIn): any {
    let date = new Date(dateIn);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = +'0' + dt;
    }
    if (month < 10) {
      month = +'0' + month;
    }
    const dateSelected = year + '-' + month + '-' + dt;
    this.getEvent(dateSelected);
  }


  donationText(num): any {
    if (num == 0) {
      document.getElementById('ar')?.classList.add('active');
      document.getElementById('ar')?.classList.add('active');
      document.querySelector('.donate-content')?.classList.add('direction-ltr');
      document.querySelector('.donate-content')?.classList.remove('direction-rtl');
      this.buttonText = 'ادعم المشروع القومى حياة كريمة \n' +
        '        الان من داخل الولايات المتحدة الامريكية\n' +
        '        تحت رعاية الدكتور مصطفى مدبولى رئيس مجلس الوزراء';
    } else if (num === 1) {
      document.getElementById('en')?.classList.add('active');
      document.querySelector('.donate-content')?.classList.add('direction-rtl');
      document.querySelector('.donate-content')?.classList.remove('direction-ltr');
      document.getElementById('ar')?.classList.remove('active');
      this.buttonText = `support Haya Karima now from United States of America
Under the patronage of Dr. Mostafa Madbouly, Prime minister of Egypt “Haya Karima” or Decent Life is an initiative launched and sponsored by H.E President Abdel Fattah Al-Sisi, with the main objective of improving the quality of life in the poorest rural communities within the framework of the Sustainable Development Strategy: Egypt Vision 2030, through decreasing multidimensional poverty and unemployment rates.

For more information on how you can support “Haya Karima” please visit

https://hayakarimausa.com/

You can also donate directly from your cell phone by texting

+18334861528

All donations made by those living in the United States are 100% tax deductible, and 100% of donations go directly to the project with no administrative costs.`;

    }
  }

  getEvent(date): any {
    this.apis.eventDateApi(date).subscribe((res: any) => {
      if (res.data.events) {
        this.events = res.data.events;
        this.error = '';
      } else if (res.data.msg) {
        this.error = res.data.msg;
      }
    }, err => {
      console.log(err);
    });
  }

  getDonation(): any {
    this.apis.getDonation().subscribe(data => {
      this.data = data;
      console.log(this.data);
      this.amount = this.data?.data.credit_dontaion;
      this.donation = this.data?.data;
      this.donorsNames = this.data?.data.donatives;
      this.checkAmount = this.data.data.check_donation;
      this.totalAmount = this.data.data.total_amount;
      this.donorsNames = this.data.data.donatives;
    });
  }

  numberWithCommas(x): any {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  navigate(): void{
    this.router.navigate(['/']).then(result => (window.location.href = 'https://newegyptgroup.com/donation-for-activity/a-donation-campaign-from-egyptians-in-the-united-states-to-support-a-haya-karima-for-our-families'));
  }
}
