import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {first} from "rxjs/operators";
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public submitted = false;
  signinForm: FormGroup;
  public error = '';
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              public fb: FormBuilder,
              private _router: Router,
              public authService: AuthService,
              public router: Router,
              private localStorage:LocalStorageService
  ) {
    this.signinForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }
  ngOnInit() {}
  loginUser() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signinForm.invalid) {
      return;
    }
    this.authService.signIn(this.signinForm.value).pipe(first())
      .subscribe(
        data => {
            // this.localStorage.setItem('currentUser', JSON.stringify(data.success));
          this.router.navigate(['/']);
        },
        error => {
          console.log(error)
          this.error = error.error.message;
        }
      );
  }
  get f() {
    return this.signinForm.controls;
  }
  refresh(): void {
    window.location.reload();
  }
}
