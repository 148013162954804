import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {ApisService} from "../services/apis.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {error} from "protractor";
import {ActivatedRoute, Router} from "@angular/router";
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {DialogCertificatesComponent} from "../dialog-certificates/dialog-certificates.component";
import {DialogDonationComponent} from "../dialog-donation/dialog-donation.component";
import {PrintDonationDetailsComponent} from "../print-donation-details/print-donation-details.component";
import {CasesBelongVolunteerComponent} from "../cases-belong-volunteer/cases-belong-volunteer.component";
import { CaseSearchComponent } from '../field-social-research/case-search/case-search.component';


@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  members: any;
  eachEvent: any;
  eachDonation: any;
  profile: any;
  Board: any;
  clubMembers: any;
  user: any;
  fileToUpload: any = '';
  token: any;
  settingForm: FormGroup;
  dateFrom1: any = '';
  date: any = '';
  image: any;
  slug: any;
  id: any;
  clubMember: any;
  memberId: any;
  Certificates: any;
  mydonations: any;
  casesBelongVolunteer: any[]=[];
  caseSearchesDelivered: any;
  spinnerFilter: boolean = false;
  currentPage: any;
  collectionSize: any;
  pageSize: any;
  selectedOption: any;
  govermnets:any[]=[];
  filterForm: FormGroup = this.fb.group({
    fullname: [null],
    goverment_id: [null],
    completed: [null],
    accepted: [null],
    delivered: [null],
  })
  compeletedList: any[] = [
    { name: 'Un Compeleted', id: 0 },
    { name: 'Compeleted', id: 1 }
  ]
  acceptedList: any[] = [
    { name: 'Under Review', id: 0 },
    { name: 'Accepted', id: 3 },
    { name: 'Accepted with Budget', id: 1 },
  ]
  deliveredList: any[] = [
    { name: 'shipped', id: 0 },
    { name: 'Done', id: 1 }
  ]
  @ViewChild('table') table!: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private activatedRoute: ActivatedRoute,
              private apis: ApisService,
              public fb: FormBuilder,
              private router: Router,
              public dialog: MatDialog
    ) {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('access_token') ;
      console.log(this.token);
    }
    this.settingForm = this.fb.group({
      // fullname_ar: ['', [Validators.required]],
      fullname_en: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      national_id: ['', [Validators.required]],
      address: ['', [Validators.required]],
      job: ['', [Validators.required,]],
      age: ['', [Validators.required,]],
      bio: ['', [Validators.required]],
      facebook: ['', [Validators.required]],
      twitter: ['', [Validators.required]],
      instagram: ['', [Validators.required]],
      reddit: ['', [Validators.required]],
      linkedin: ['', [Validators.required]],
      birth_date: ['', [Validators.required]],
      image: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.user = JSON.parse(localStorage.getItem('currentUser'))
    }
    this.memberId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(this.memberId)
    this.getProfileApi();
    this.getCertificatesApi();
    this.getMyDonationsApi();
    this.getGovermnets();
    this.getCasesBelongVolunteer(this.currentPage, this.filterForm.value);    // this.BoardMembersApi(this.id);
    this.clubMembersApi(this.id);
    this.getMember()
  }
  getMember() {
    this.apis.getMemberInfo(this.id).subscribe((data: any) => {
        this.clubMember = data.data;
        console.log(this.clubMember);
      },
      err => {
        console.log(err);
      });
  }


  // getProfileApi(): void {
  //   this.apis.profileApi(this.token).subscribe((data: any) => {
  //     this.profile = data.data;
  //     console.log(data);
  //   }, (err: any) => {
  //   });
  // }
  getCertificatesApi(): any {
    this.apis.getCertificates().subscribe((data: any) => {
        this.Certificates = data.data;
        console.log(this.Certificates);
      },
      err => {
        console.log(err);
      });
  }
  getMyDonationsApi(): any {
    this.apis.getMyDonations().subscribe((data: any) => {
        this.mydonations = data.data;
        console.log(this.mydonations);
      },
      err => {
        console.log(err);
      });
  }

  getProfileApi() {
    this.apis.profileApi().subscribe((data: any) => {
        this.profile = data.data;
        console.log(this.profile);
        this.settingForm = this.fb.group({
          fullname_en: [data.data.fullname_en, [Validators.required]],
          email: [data.data.email, [Validators.required, Validators.email]],
          job: [data.data.job, [Validators.required,]],
          birth_date: [data.data.birth_date, [Validators.required,]],
          image: [data.data.image, [Validators.required,]],
          phone: [data.data.phone, [Validators.required]],
          address: [data.data.address, [Validators.required]],
          bio: [data.data.bio, [Validators.required]],
          facebook: [data.data.facebook, [Validators.required]],
          twitter: [data.data.twitter, [Validators.required]],
          instagram: [data.data.instagram, [Validators.required]],
          national_id: [data.data.national_id, [Validators.required]],
          linkedin: [data.data.linkedin, [Validators.required]],
        });
        console.log()
        // this.settingForm.controls.fullname_en.patchValue(data.data.fullname_en);

      },
      err => {
        console.log(err);
      });
  }

  clubMembersApi(id) {
    this.apis.clubMembers(id).subscribe((data: any) => {
        this.clubMembers = data.data.members;
        console.log(data);
      },
      err => {
        console.log(err);
      });
  }
  filter(event:any) {
    this.spinnerFilter = true;
    this.table.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
  });
    this.getCasesBelongVolunteer(event, this.filterForm.value);
  }
  getCasesBelongVolunteer(currentPage: any, filterForm: any): any {
    console.log(currentPage);
    this.apis.casesBelongVolunteer(currentPage, filterForm).subscribe((data: any) => {
      // window.scrollTo({ top: this.table.nativeElement, behavior: 'smooth'});
      this.casesBelongVolunteer = data.data.data;
      this.currentPage = data["data"].current_page;
      this.collectionSize = data["data"].total;
      this.pageSize = data["data"].per_page;
    },
      err => {
        console.log(err);
      });
  }
  getGovermnets() {
    this.apis.getGovermnets().subscribe({
      next: (res: any) => {
        this.govermnets = res.data
      }
    })
  }
  CaseSearchesDelivered(id: number): any {
    this.apis.CaseSearchesDelivered(id).subscribe((data: any) => {
      this.caseSearchesDelivered = data
      console.log(this.caseSearchesDelivered)
      this.getCasesBelongVolunteer(this.currentPage, this.filterForm.value);
    },
      err => {
        console.log(err);
      });
  }

  // BoardMembersApi(id: any) {
  //   this.apis.getBoardMembers(id).subscribe((data: any) => {
  //       this.Board = data.data;
  //       console.log(this.Board);
  //     },
  //     err => {
  //       console.log(err);
  //     });
  // }
  refresh(slug) {
    // @ts-ignore
    if (typeof document !== 'undefined') {
      window.scrollTo(0, 0);
    }
    this.slug = slug;
    this.clubMembersApi(this.id);
    this.getProfileApi();
    this.editProfile();
  }


  dateFormat(date): any {
    date = new Date(date);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = +'0' + dt;
    }
    if (month < 10) {
      month = +'0' + month;
    }
    return (year + '-' + month + '-' + dt);
  }

  checkDate(e): void {
    this.date = new Date().getTime();
    let due = new Date(this.dateFormat(e.value)).getTime();
    if (this.date >= due) {
      // this.form.controls['due_date'].setErrors({'incorrect': true, someProp: 'You must choose a day after today'});
    }
  }

  editProfile(): any {
    const formData: FormData = new FormData();
    formData.append('image', this.fileToUpload);
    console.log(this.settingForm.value.fullName_en)
    formData.append('fullname_en', this.settingForm.value.fullname_en);
    formData.append('email', this.settingForm.value.email);
    formData.append('phone', this.settingForm.value.phone);
    formData.append('address', this.settingForm.value.address);
    formData.append('job', this.settingForm.value.job);
    formData.append('bio', this.settingForm.value.bio);
    formData.append('facebook', this.settingForm.value.facebook);
    formData.append('twitter', this.settingForm.value.twitter);
    formData.append('instagram', this.settingForm.value.instagram);
    formData.append('national_id', this.settingForm.value.national_id);
    formData.append('linkedin', this.settingForm.value.linkedin);
    formData.append('birth_date', this.dateFormat(this.settingForm.value.birth_date));
    // @ts-ignore
    return formData;
  }

  submit() {
    this.apis.getProfile(this.token, this.editProfile()).subscribe((data: any) => {
        console.log(data);
        this.getProfileApi();
      },
      err => {
        console.log(err);
      });
  }

  onFileSelected(files: FileList): void {
    this.fileToUpload = files.item(0);
    (document.getElementById('blah') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUpload);
    (document.getElementById('blah') as HTMLInputElement).style.height = '70px';
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = event.target.result;
      };
      reader.readAsDataURL(files[0]);
    }
  }

  // memberDetails(id): void {
  //   this.router.navigate(['/memberDetails/', this.members.board_id, id]);
  // }
  memberDetails(id): void{
    console.log(id);
    console.log(this.clubMembers.id);
    this.router.navigate(['/memberDetails/',id ,id]);

  }
  tabIndex = 0;
  onTabClick(index){
    this.tabIndex = index;
  }
  openDialog(event) {
    console.log(event.activity.id)
    this.eachEvent = event
    const dialogRef = this.dialog.open(DialogCertificatesComponent,{
      data: {
        certificateId: event.activity.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openPopUp(event) {
    console.log(event)
    // console.log(eventdonation.activities_incomes[0].activity_income_id)
    // console.log(generalpurpose.general_purpose[0].general_purpose_transaction_id)
    const dialogRef = this.dialog.open(DialogDonationComponent,{
      data: {
        donationId: event.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDonation(event) {
    console.log(event)
    const dialogRef = this.dialog.open(PrintDonationDetailsComponent,{
      data: {
        donationId: event.id,
        donationdetails: event,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openCases(event) {
    console.log(event)
    const dialogRef = this.dialog.open(CasesBelongVolunteerComponent,{
      data: {
        Cases: event,
        page: this.currentPage
        // donationdetails: event,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openCaseSearch(event:any) {
    const dialogRef = this.dialog.open(CaseSearchComponent , {
      height: '90vh',
      width: '25vw',
      minWidth:'390px',
      data :{ completed: 1,case:event}
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
}


