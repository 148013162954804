<!-- <img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png"> -->

<div class="about-page">
  <!-- start about-header -->
  <div class="about-header">
    <div class="overlay">
      <div class="container">
        <h1 class="text-center" translate>home.about-us</h1>
      </div>
    </div>
  </div>
  <!-- end about-header -->

  <!-- start about-body -->
  <div class="about-body">

    <div class="mission text-center">
      <div class="container" *ngIf="aboutus">
        <!--        <h1><u>{{aboutus.title}}</u></h1>-->
        <!--        <p>{{aboutus.body}}</p>-->
        <!--        <div class="picsum-img-wrapper " *ngIf="aboutus">-->
        <!--          <video  autoplay  style="width: 100%">-->
        <!--            <source src="{{aboutus.video_link}}">-->
        <!--          </video>-->
        <!--          <h4 class="sub-title"></h4>-->
        <!--        </div>-->
        <h2>{{aboutus.mission_title}}</h2>
        <p>{{aboutus.mission_description}}</p>
        <img title="" alt="" src="./assets/images/about-us/Who-are-we.jpg" />
      </div>
    </div>
    <div class="container" *ngIf="aboutus">
      <h2 class="text-center">{{aboutus.message_title}}</h2>
      <p class="m-auto about-team col-8 pt-5">{{aboutus.message_description}}</p>
    </div>

    <!-- end mission -->
    <!-- start team -->
    <div class="team text-center">
      <div>
        <div class="overlay">
          <div class="container" *ngIf="aboutus">
            <h2 class="text-center">{{aboutus.our_team_title}}</h2>
          </div>
        </div>
      </div>
      <div class="team-body">
        <p></p>
      </div>
    </div>
    <div class="container pb-65">
      <div class="row text-center" *ngIf="aboutus">
        <p class="m-auto about-team col-6">{{aboutus.our_team_description}}</p>
      </div>
      <div class="team text-center m-0">
        <div>
          <div class="overlay">
            <div class="container">
              <h2 class="text-center" translate>home.Voting-Policy</h2>
              <p class="voting-title mt-5" translate>home.Board-Structure</p>
              <ul class="voting-dsription">
                <li translate>home.The-board-of-New-Egypt-Group-Inc</li>
              </ul>
              <p class="voting-title" translate>home.Majority-Rules</p>
              <ul class="voting-dsription">
                <li translate>home.All-decisions-will-be-made</li>
              </ul>
              <p class="voting-title" translate>home.Power-Delegation</p>
              <ul class="voting-dsription">
                <li translate>home.The-board-has-agreed-to-delegate</li>
                <li translate>home.The-small-power-board</li>
              </ul>
              <p class="voting-title" translate>home.Decision-Making-Process</p>
                <ul class="voting-dsription">
                  <li translate>home.All-decisions-will-be-made-through-formal-meetings</li>
                  <li translate>home.A-quorum-defined</li>
                  <li translate>home.If-a-quorum-is-not-achieved</li>
                </ul>
                <p  class="voting-title" translate>home.Voting-Procedures</p>
                <ul class="voting-dsription">
                  <li translate>home.All-board-members</li>
                  <li translate>home.Voting-may-be-conducted</li>
                  <li translate>home.Each-board-member</li>
                  <li translate>home.In-the-event-of-a-tie-vote</li>
                </ul>
                <p class="voting-title" translate>home.Amendments-to-the-Voting-Policy</p>
              <ul class="voting-dsription">
                <li translate>home.This-voting-policy</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-5 text-center">
            <!-- <div class="col-6 mt-2 text-center"> -->
              <a href="../../../assets/pdf/NewEgyptGroupstracture2.pdf" target="_blank" download="NEW EGYPT GROUP BOARDS">
                <button class="bg-card book" type="button" translate>home.NEW-EGYPT-GROUP-BOARDS</button>
              </a>
            <!-- </div> -->
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <section>
            <div class="col-lg-1 order-lg-first mt-4">
              <div class="sticky-content">
                <div class="social-icons social-icons-colored social-icons-vertical">
                  <div class="icon-share">
                    <span class="social-label" translate>home.share</span>
                    <a class="social-icon image-item" (click)="cop()" target="_blank">
                      <img *ngIf="!copied" title="copy" style="width: 22px" alt="copy"
                        src="./assets/newIcon/copy-2.png" />
                      <img *ngIf="copied" title="copied" style="width: 28px" alt="copied"
                        src="./assets/newIcon/copied.png" />
                    </a>
                    <a (click)="fbc($event)" class="social-icon social-facebook" title="Facebook">
                      <i class="icon-facebook-f"></i>
                    </a>
                    <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                      <i class="icon-twitter"></i>
                    </a>
                    <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                      <i class="icon-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        

      </div>

      <div class="contact-us">
        <form>
          <div class="inside">
            <h2 translate>home.reach-you</h2>
            <!-- Full name -->
            <div class="field">
              <label for="name" translate>home.full-name</label>
              <input id="name" type="text" required />
            </div>
            <!-- Email -->
            <div class="field">
              <label for="email" translate>home.email-address</label>
              <input id="email" type="email" required />
            </div>
            <!-- mobile number -->
            <div class="field">
              <label for="mobile" translate>home.mobile-number</label>
              <input id="mobile" type="text" required />
            </div>
            <!-- subject -->
            <div class="field">
              <label for="subject" translate>home.subject</label>
              <input id="subject" type="text" required />
            </div>
            <!-- message -->
            <div class="field">
              <label for="message" translate>home.your-message</label>
              <textarea id="message" placeholder="{{ 'home.tell-us' | translate }}" ng-model="myModel"
                required></textarea>
            </div>
            <div class="submit">
              <button class="button active" translate>home.send</button>
              <!-- <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i> -->
            </div>
          </div>
        </form>
      </div>

    </div>
    <!-- end team -->
  </div>
  <!-- end about-body -->

</div>