<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">

<!-- <div style="background-color: #F8F7F5 "> -->
  <div class="container">
  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
      <li class="breadcrumb-item"><a [routerLink]="['/children']">Donate Children</a></li>
      <li class="breadcrumb-item active">Ahmed Mostafa</li>
    </ol>
  </nav>
  </div>
<div class="container project">
  <div class="container">
  </div>
  <div class="head">
    <div>
      <h1 class="project-name">Ahmed Mostafa</h1>
      <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
    </div>
    <div>
      <button class="projects-btn">DONATE</button>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-between">
    <div class="col-6">
      <video class="img-fluid home-video"
             title="EGYPT"
             controls muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
             src="{{videoUrl}}">
      </video>
    </div>
    <div class="col-6">
      <div class="photos">
        <img style="height: 120px; margin-bottom: 27px; border-radius: 8px; max-width: 200px" *ngFor="let item of items; index as i"
             [lightbox]="i"
             [src]="item.data.thumb">
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between align-items-baseline mt-3">
    <h2 class="project-name">Case details</h2>
  </div>
  <div class="article">Health system support project Health system support project Health system support projectHealth system support project Health system support project Health system support project Health system support projectHealth system support project Health system support project Health system support project Health system support projectHealth system support project Health system support project Health system support project Health system support projectHealth system support project Health system support project Health system support project Health system support projectHealth system support project</div>
  <a class="see-more">See More</a>
  <div class="duration">
    <div class="duration-item">Age : 10 Years </div>
    <div class="duration-item">Health Status: Healthy</div>
    <div class="duration-item"> Family Income: 400 EG</div>
  </div>
</div>
<!-- </div> -->

