import {Component, OnInit} from '@angular/core';
import {SeoService} from "../services/seo.service";
import {Router} from "@angular/router";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
    selector: 'app-join-us',
    templateUrl: './join-us.component.html',
    styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit {

    constructor(private seoService: SeoService, private router: Router, private clipboard: Clipboard) {
        this.seoUpdate('Join New Egypt Group and Help Make a Difference in Your Community', 'Join the New Egypt Group family and become part of something bigger than yourself. We\'re looking for people like you: kind-hearted, passionate about their community. New Egypt Group welcomes everybody with open arms. One heart many hands.', 'https://newegyptgroup.com/assets/newIcon/volunteer-first.png');}

    metaTitle: string = 'Join New Egypt Group and Help Make a Difference in Your Community';
    metaDeescription: string = 'Join the New Egypt Group family and become part of something bigger than yourself. We\'re looking for people like you: kind-hearted, passionate about their community. New Egypt Group welcomes everybody with open arms. One heart many hands.';
    metaImage: any = 'https://newegyptgroup.com/assets/newIcon/Volunteer share.jpg';
    copied = false;
    lang:string=localStorage.getItem('lang')

    ngOnInit(): void {
        this.seoUpdate(this.metaTitle, this.metaDeescription, 'https://newegyptgroup.com/assets/newIcon/join us.webp');

    }

    seoUpdate(title = null, description = null, image = null) {
        if (title != null) {
            this.seoService.updateTitle(title);
        }
        if (description != null) {
            this.seoService.updateDescription(description);
        }
        if (image != null) {
            this.seoService.updateImage(image)
        }
        this.seoService.updateUrl();
    }

    cop() {
        this.clipboard.copy('https://newegyptgroup.com' + this.router.url)
        this.copied = !this.copied;
        setTimeout(() => {
            this.copied = false;
        }, 1500)

    }

    fbc(e) {
        let url = "www.google.com";
        e.preventDefault();
        var facebookWindow = window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" +
            "newegyptgroup.com" +
            this.router.url,
            "facebook-popup",
            "height=700px,width=555px"
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }

        return false;
    }

    tw() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://twitter.com/intent/tweet?url=' + 'newegyptgroup.com' + this.router.url,
            '-popUp',
            'width= 555px, height= 450px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' + 'newegyptgroup.com' + this.router.url,
            '-popUp',
            'width= 555px, height= 603px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }
    routerLink(): void {
        this.router.navigate(['/personal-form/' ],{ queryParams: {lang:this.lang}});
    }
    routToAddMember():void{
      this.router.navigate(['/add-member/'],{queryParams:{lang:this.lang}})
    }
}
