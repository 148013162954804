import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-dialog-toast',
  templateUrl: './dialog-toast.component.html',
  styleUrls: ['./dialog-toast.component.scss']
})
export class DialogToastComponent implements OnInit {
  token: any;
  long: string;


  constructor(@Inject(PLATFORM_ID) private platformId: Object,) {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('access_token')
  }
  this.long = localStorage.getItem('lang')
   }

  ngOnInit(): void {

  }

}
