<!-- <img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png"> -->
<!-- <section>
  <div class="container">
    <div class="row">
      <div class="col-12">
      <div class="profile-header">
        <div class="profile-img">
          <img src="https://randomuser.me/api/portraits/women/21.jpg" width="200" alt="Profile Image">
        </div>
        <div class="profile-nav-info">
          <h3 class="user-name">Bright Code</h3>
          <div class="address">
            <p id="state" class="state">New York,</p>
            <span id="country" class="country">USA.</span>
          </div>

        </div>
      </div>
      </div>
      <div class="main-bd">
        <div class="right-side w-100 direction-ltr">
          <div class="nav" class="w-100">
            <mat-tab-group class="w-100 vertical">
             <mat-tab label="EDIT PROFILE"><app-personal-form></app-personal-form></mat-tab>-->
              <!-- <mat-tab label="Orphans"><app-orphans-profile></app-orphans-profile></mat-tab>
              <mat-tab label="Certificate"><app-certificate></app-certificate></mat-tab>
              <mat-tab label="Activities"> Content 3 </mat-tab>
            </mat-tab-group>
          </div>
          <div class="profile-body">
            <div class="profile-posts tab">
              <h1>Your Post</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa quia sunt itaque ut libero cupiditate ullam qui velit laborum placeat doloribus, non tempore nisi ratione error rem minima ducimus. Accusamus adipisci quasi at itaque repellat sed
                magni eius magnam repellendus. Quidem inventore repudiandae sunt odit. Aliquid facilis fugiat earum ex officia eveniet, nisi, similique ad ullam repudiandae molestias aspernatur qui autem, nam? Cupiditate ut quasi iste, eos perspiciatis maiores
                molestiae.</p>
            </div> -->
            <!-- <div class="profile-reviews tab">
              <h1>User reviews</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam pariatur officia, aperiam quidem quasi, tenetur molestiae. Architecto mollitia laborum possimus iste esse. Perferendis tempora consectetur, quae qui nihil voluptas. Maiores debitis
                repellendus excepturi quisquam temporibus quam nobis voluptatem, reiciendis distinctio deserunt vitae! Maxime provident, distinctio animi commodi nemo, eveniet fugit porro quos nesciunt quidem a, corporis nisi dolorum minus sit eaque error
                sequi ullam. Quidem ut fugiat, praesentium velit aliquam!</p>
            </div>
            <div class="profile-settings tab">
              <div class="account-setting">
                <h1>Acount Setting</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit omnis eaque, expedita nostrum, facere libero provident laudantium. Quis, hic doloribus! Laboriosam nemo tempora praesentium. Culpa quo velit omnis, debitis maxime, sequi
                  animi dolores commodi odio placeat, magnam, cupiditate facilis impedit veniam? Soluta aliquam excepturi illum natus adipisci ipsum quo, voluptatem, nemo, commodi, molestiae doloribus magni et. Cum, saepe enim quam voluptatum vel debitis
                  nihil, recusandae, omnis officiis tenetur, ullam rerum.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!--  <app-members-slider></app-members-slider>-->
<!-- </section> -->


<!-- ======= Header ======= -->
<!-- <header id="header">
  <div class="d-flex flex-column">

    <div class="profile">
      <img src="../../../assets/images/img/profile-img.jpg" alt="" class="img-fluid rounded-circle">
      <h1 class="text-light"><a href="index.html">Alex Smith</a></h1>
      <div class="social-links mt-3 text-center">
        <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
        <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
        <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
      </div>
    </div> -->

    <!-- <nav id="navbar" class="nav-menu navbar">
      <ul>
        <li><a href="#hero" class="nav-link scrollto active"><i class="bx bx-home"></i> <span>Home</span></a></li>
        <li><a href="#about" class="nav-link scrollto"><i class="bx bx-user"></i> <span>About</span></a></li>
        <li><a href="#resume" class="nav-link scrollto"><i class="bx bx-file-blank"></i> <span>Resume</span></a></li>
        <li><a href="#portfolio" class="nav-link scrollto"><i class="bx bx-book-content"></i> <span>Portfolio</span></a></li>
        <li><a href="#services" class="nav-link scrollto"><i class="bx bx-server"></i> <span>Services</span></a></li>
        <li><a href="#contact" class="nav-link scrollto"><i class="bx bx-envelope"></i> <span>Contact</span></a></li>
      </ul>
    </nav> -->
    <!-- .nav-menu -->
  <!-- </div>
</header> -->
<!-- End Header -->

<!-- ======= Hero Section ======= -->
<ng-container *ngIf="profile">
  <section id="hero" class="d-flex flex-column justify-content-center align-items-center">
    <div class="hero-container" *ngIf="isBrowser"  data-aos="fade-in">
      <h1>{{profile.fullname_en}}</h1>
      <ngx-typed-js [backSpeed]="100" [backDelay]="1000"  [loop]="true" [strings]="['I am',profile.job]">
        <h1 class="typing"></h1>
      </ngx-typed-js>
<!--      <p>I'm <span class="typed" data-typed-items="{{profile.job}}"></span></p>-->
    </div>
  </section>
</ng-container>
<!-- End Hero -->
<main id="main">
  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container">

      <div class="section-title">
        <h2 translate>home.about</h2>
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
      </div>

      <div class="row" *ngIf="profile">
        <div class="col-lg-4" data-aos="fade-right">
          <img src="{{profile.image}}" class="img-fluid" alt="">
          <!-- <img src="../../../assets/images/img/profile-img.jpg" class="img-fluid" alt=""> -->
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
          <h3>{{profile.job}}.</h3>
          <!-- <p class="fst-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.
          </p> -->
          <div class="row">
            <div class="col-lg-7">
              <ul>
                <li><i class="bi bi-chevron-right"></i> <strong  >{{ 'home.birthday' | translate }} </strong> <span>{{profile.birthdate}}</span></li>
                <!-- <li><i class="bi bi-chevron-right"></i> <strong>Website:</strong> <span>www.example.com</span></li> -->
                <li><i class="bi bi-chevron-right"></i> <strong >{{ 'home.phone' | translate }} :</strong> <span>{{profile.phone}}</span></li>
                <li><i class="bi bi-chevron-right"></i> <strong >{{ 'home.city' | translate }} :</strong> <span>{{profile.address}}</span></li>
              </ul>
            </div>
            <div class="col-lg-5">
              <ul>
                <li><i class="bi bi-chevron-right"></i> <strong >{{ 'home.age' | translate }} :</strong> <span>{{profile.age}}</span></li>
                <li><i class="bi bi-chevron-right"></i> <strong >{{ 'home.National-ID' | translate }} :</strong> <span>{{profile.national_id}}</span></li>
                <li><i class="bi bi-chevron-right"></i> <strong >{{ 'home.email' | translate }} :</strong> <span>{{profile.email}}</span></li>
                <!-- <li><i class="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li> -->
              </ul>
            </div>
          </div>
          <p>
           {{profile.bio}}
          </p>
        </div>
      </div>

    </div>
  </section>
  <!-- End About Section -->


  <!-- ======= Donations Section ======= *ngIf="mydonations?.length !== 0 " -->
  <section id="about" class="donations" *ngIf="mydonations?.length !== 0 ">
  <div class="container mb-5">
    <div class="section-title">
      <h2>Donations</h2>
    </div>
    <div class="table-container">
      <table>
        <thead>
          <tr>
          <th scope="col" translate>home.purpose</th>
          <th scope="col" translate>home.donor-name</th>
          <th scope="col" translate>home.date</th>
          <th scope="col" translate>home.amount</th>
          <th scope="col" ></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of mydonations">
          <td>{{item.purpose.name.slice(0,30)}}...</td>
          <td>{{item.donor_name.name}}</td>
          <td>{{item.date | date}}</td>
          <td>{{item.amount | currency}}</td>
          <td class="action-column" >
            <a (click)="openDonation(item)" class="cta cta-link cta-apply partial-dynamic" translate>home.details
              <svg xmlns="http://www.w3.org/2000/svg" width="9.277" height="17.141" viewBox="0 0 9.277 17.141" class="chevron">
               <path d="M7302.571-856.191l8.216,8.217-8.216,8.217" transform="translate(-7302.218 856.544)" fill="none" stroke="currentColor" stroke-width="1"></path>
             </svg>
           </a>
          </td>
        </tr>
<!--        <tr>-->
<!--          <td>October 28, 2023</td>-->
<!--          <td>April 28, 2023</td>-->
<!--          <td>Hybrid</td>-->
<!--          <td>Hybrid</td>-->
<!--          <td class="action-column" >-->
<!--            <a href="" class="cta cta-link cta-apply partial-dynamic">Details <svg xmlns="http://www.w3.org/2000/svg" width="9.277" height="17.141" viewBox="0 0 9.277 17.141" class="chevron">-->
<!--            <path d="M7302.571-856.191l8.216,8.217-8.216,8.217" transform="translate(-7302.218 856.544)" fill="none" stroke="currentColor" stroke-width="1"></path>-->
<!--          </svg>-->
<!--          </a>							</td>-->
<!--        </tr>-->
        </tbody>
      </table>
    </div>
  </div>
  </section>

  <!-- End Donations Section -->


  <!-- ======= Certificates Section ======= -->
  <section id="about" class="Certificates" *ngIf="Certificates?.activityzz?.length !== 0">
    <div class="container " >
      <div class="section-title">
        <h2>Certificates</h2>
      </div>
      <div>
        
      </div>
  <ng-container>
    <div class="row" >
      <div class="col-lg-4 col-md-6" *ngFor="let item of Certificates">
        <button mat-button class="bg-card book" (click)="openDialog(item)">{{item.activity.name.slice(0,16)}}...</button>
      </div>
<!--      <div class="col-lg-4 col-md-6" >-->
<!--        <button mat-button class="bg-card book" >Ramadan Kareem</button>-->
<!--      </div>-->
<!--      <div class="col-lg-4 col-md-6" >-->
<!--        <button mat-button class="bg-card book" >A 68 year old lady</button>-->
<!--      </div>-->
<!--      <div class="col-lg-4 col-md-6" >-->
<!--        <button mat-button class="bg-card book" >Support Leprosy</button>-->
<!--      </div>-->
    </div>
  </ng-container>
    </div>
  </section>
  <!-- End Certificates Section -->

  <!-- ======= Portfolio Section ======= -->
   <!-- <section id="portfolio" class="portfolio section-bg">
    <div class="container">

      <div class="section-title">
        <h2>Portfolio</h2> -->
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
      <!-- </div> -->

      <!-- <div class="row" data-aos="fade-up">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active">All</li>
            <li data-filter=".filter-app">App</li>
            <li data-filter=".filter-card">Card</li>
            <li data-filter=".filter-web">Web</li>
          </ul>
        </div>
      </div> -->

      <!-- <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="100">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="../../../assets/images/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
            <div class="portfolio-links">
              <a href="../../../assets/images/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="../../../assets/images/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
            <div class="portfolio-links">
              <a href="../../../assets/images/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="../../../assets/images/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
            <div class="portfolio-links">
              <a href="../../../assets/images/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 2"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="../../../assets/images/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
            <div class="portfolio-links">
              <a href="../../../assets/images/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 2"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="../../../assets/images/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
            <div class="portfolio-links">
              <a href="../../../assets/images/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 2"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="../../../assets/images/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
            <div class="portfolio-links">
              <a href="../../../assets/images/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="../../../assets/images/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
            <div class="portfolio-links">
              <a href="../../../assets/images/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 1"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="../../../assets/images/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
            <div class="portfolio-links">
              <a href="../../../assets/images/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="../../../assets/images/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
            <div class="portfolio-links">
              <a href="../../../assets/images/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              <div _ngcontent-serverapp-c354="" class="work-content"><div _ngcontent-serverapp-c354="" class="row"><div _ngcontent-serverapp-c354="" class="col-sm-8"><h2 _ngcontent-serverapp-c354="" class="w-title">Lorem impsum dolor</h2><div _ngcontent-serverapp-c354="" class="w-more"><span _ngcontent-serverapp-c354="" class="w-ctegory">Web Design</span> / <span _ngcontent-serverapp-c354="" class="w-date">18 Sep. 2018</span></div></div><div _ngcontent-serverapp-c354="" class="col-sm-4"><div _ngcontent-serverapp-c354="" class="w-like"><a _ngcontent-serverapp-c354="" href="portfolio-details.html"><span _ngcontent-serverapp-c354="" class="bi bi-plus-circle"></span></a></div></div></div></div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section> -->
  <!-- End Portfolio Section -->

<!-- ======= Portfolio Section ======= #table   *ngIf="user?.role == 'volunteer'" -->
<section id="work" class="portfolio-mf Portfolio sect-pt4 route" #table *ngIf="casesBelongVolunteer">
  <div class="container">
    <div class="row">
      <div class="col-sm-10">
        <div class="section-title title-box ">
      <h2 translate>
        home.cases
      </h2>
    </div>
  </div>
      <div class="col-sm-2" style="cursor: pointer;">
        <img  src="../../assets/icons/icons8-search.svg">
      </div>
      </div>
    <div class="row">
      <div class="col-sm-12">
      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class=" card-body">
          <div class="row mb-2" [formGroup]="filterForm">
            <div class="col-12">
              <label class="title" translate>home.Full-Name</label>
               <mat-form-field appearance="fill" class="w-100">
                  <input matInput type="text" (blur)="getCasesBelongVolunteer(currentPage , filterForm.value)"
                    formControlName="fullname">
                </mat-form-field>
            </div>
            <div class="col-6">
              <label class="title" translate>home.goverments</label>
              <mat-form-field appearance="fill" class="w-100">
                <mat-select formControlName="goverment_id" ng-model="myModel">
                  <mat-option *ngFor="let option of govermnets"
                    (click)="getCasesBelongVolunteer(currentPage , filterForm.value)" [value]="option.id">
                    {{option.name_en}}
                  </mat-option>
                 </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6">
              <label class="title" translate>home.completed</label>
              <mat-form-field appearance="fill" class=" w-100">
                <mat-select formControlName="completed" ng-model="myModel">
                  <mat-option *ngFor="let option of compeletedList"
                    (click)="getCasesBelongVolunteer(currentPage , filterForm.value)" [value]="option.id">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6">
              <label class="title" translate>home.accepted</label>
              <mat-form-field appearance="fill" class="w-100">
                <mat-select formControlName="accepted" ng-model="myModel">
                  <mat-option *ngFor="let option of acceptedList"
                    (click)="getCasesBelongVolunteer(currentPage , filterForm.value)" [value]="option.id">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6">
              <label class="title" translate>home.DELIVERED</label>
              <mat-form-field appearance="fill" class="w-100">
                <mat-select formControlName="delivered" ng-model="myModel">
                  <mat-option *ngFor="let option of deliveredList"
                    (click)="getCasesBelongVolunteer(currentPage , filterForm.value)" [value]="option.id">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
           <!-- <p class="subtitle-a">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          </p>
          <div class="line-mf"></div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-4" *ngFor="let case of casesBelongVolunteer | keyvalue">
        <div class="work-box" >
          <!-- <a  data-gallery="portfolioGallery" class="portfolio-lightbox"> -->
            <div class="work-img">
              <!-- <img src="{{case.value[0].case_image}}" alt="" class="img-fluid"> -->

              <h2 class="w-title mt-2 text-center">{{case.value[0].fullname}}</h2>
              <p class=" small text-center">{{case.value[0].address}}</p>
              <p class=" small text-center">{{case.value[0].phone}}</p>
              <p class=" small text-center">{{case.value[0].purpose.name}}</p>

            </div>
            <!-- <div class="label-top shadow-sm text-center" >
                <span class="mx-1 px-2 fa--6x d-inline-block" [class]="case.value[0].completed != 0 ? 'bg-success':'bg-warning'">{{case.value[0].completed != 0 ? 'Completed':'Un Completed'}}</span>
                <span class="mx-1 px-2 fa--6x d-inline-block" [class]="case.value[0].delivered != 0 ? 'bg-success':'bg-warning'">{{case.value[0].delivered != 0 ? 'Done':'shipped'}}</span>
                <span class="mx-1 px-2 fa--6x d-inline-block"
                [class]="case.value[0].accepted == 0 ? 'bg-warning': case.value[0].accepted == 1 ? 'bg-success' :
                case.value[0].accepted == 2 ? 'bg-danger' : 'bg-success'"
                >{{
                  case.value[0].accepted == 0 ? 'Under review':
                  case.value[0].accepted == 1 ? 'Accept open budget' :
                  case.value[0].accepted == 2 ? 'Rejected' :
                  'Accept'}}</span>
              </div> -->
          <!-- </a> -->
          <div class="work-content">
            <div class="row">
              <!-- <div class="col-sm-2"> -->
                  <!-- <div class="text-center my-4"> <a (click)="openCases(case)" class="btn btn-warning">Details</a> </div> -->
                    <!-- <div class="d-flex justify-content-between mb-1"> -->
                      <!-- <span [matMenuTriggerFor]="menu" style="cursor: pointer;"><i class="far fa-question-circle fa-1x" ></i></span> -->
                      <!-- <span (click)="openCaseSearch(case)" style="cursor: pointer;"><i class="fas fa-plus fa-1x" ></i></span> -->
              <!-- <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openCases(case)">
                  <div class="d-flex">
                    <i class="bi bi-person-lines-fill position-absolute" style="left:10px;top:0"></i>
                    <span style="font-size: .8rem;left: 34px;top:0" class="position-absolute">Details</span>
                  </div>
                </button>
                <button mat-menu-item>
                    <label class="form-check-label cursor-pointer d-flex align-items-center justify-content-between" for="flexCheckDefault">
                      <input (click)="CaseSearchesDelivered(case.id)" class="form-check-input check-box" style="left:30px" type="checkbox" value="" id="flexCheckDefault"
                        [checked]="case.delivered == 1">
                      <span style="font-size: .8rem;left: 34px;" class="position-absolute">DELIVERED</span>
                    </label>
                </button>
              </mat-menu>
            </div>
            </div> -->
<!--              <div class="col-sm-10">-->
<!--                <div class="label-top shadow-sm text-center" >-->
<!--                  <span class="mx-1 px-2 fa&#45;&#45;6x d-inline-block" [class]="case.value[0].completed != 0 ? 'bg-success':'bg-warning'">{{case.value[0].completed != 0 ? 'Completed':'Un Completed'}}</span>-->
<!--                  <span class="mx-1 px-2 fa&#45;&#45;6x d-inline-block" [class]="case.value[0].delivered != 0 ? 'bg-success':'bg-warning'">{{case.value[0].delivered != 0 ? 'Done':'shipped'}}</span>-->
<!--                  <span class="mx-1 px-2 fa&#45;&#45;6x d-inline-block"-->
<!--                  [class]="case.value[0].accepted == 0 ? 'bg-warning': case.value[0].accepted == 1 ? 'bg-success' :-->
<!--                  case.value[0].accepted == 2 ? 'bg-danger' : 'bg-success'"-->
<!--                  >{{-->
<!--                    case.value[0].accepted == 0 ? 'Under review':-->
<!--                    case.value[0].accepted == 1 ? 'Accept open budget' :-->
<!--                    case.value[0].accepted == 2 ? 'Rejected' :-->
<!--                    'Accept'}}</span>-->
<!--                </div>-->
<!--                &lt;!&ndash; <h2 class="w-title">{{case.value[0].fullname}}</h2> &ndash;&gt;-->
<!--                <div class="w-more">-->
<!--                  &lt;!&ndash; <span class="w-ctegory">{{case.value[0].purpose.name}}</span> &ndash;&gt;-->
<!--                   &lt;!&ndash; / <span class="w-date">18 Sep. 2018</span> &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
              <div class="justify-content-center col-sm-3">
                <div class="w-like">
                  <span class="bi bi-plus-circle" (click)="openCaseSearch(case.value)" style="cursor: pointer;"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="casesBelongVolunteer.length!=0" class="w-100 d-flex justify-content-center">
        <ngb-pagination (pageChange)="filter($event)" [(page)]="currentPage" [collectionSize]="collectionSize"
           [boundaryLinks]="true" [maxSize]="4"></ngb-pagination>
      </div>
      <!-- <div class="col-md-4">
        <div class="work-box">
          <a href="../../../assets/images/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox">
            <div class="work-img">
              <img src="../../../assets/images/img/portfolio/portfolio-8.jpg"alt="" class="img-fluid">
            </div>
          </a>
          <div class="work-content">
            <div class="row">
              <div class="col-sm-8">
                <h2 class="w-title">Loreda Cuno Nere</h2>
                <div class="w-more">
                  <span class="w-ctegory">Web Design</span> / <span class="w-date">18 Sep. 2018</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="w-like">
                  <a href="portfolio-details.html"> <span class="bi bi-plus-circle"></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="work-box">
          <a href="../../../assets/images/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox">
            <div class="work-img">
              <img src="../../../assets/images/img/portfolio/portfolio-7.jpg" alt="" class="img-fluid">
            </div>
          </a>
          <div class="work-content">
            <div class="row">
              <div class="col-sm-8">
                <h2 class="w-title">Mavrito Lana Dere</h2>
                <div class="w-more">
                  <span class="w-ctegory">Web Design</span> / <span class="w-date">18 Sep. 2018</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="w-like">
                  <a href="portfolio-details.html"> <span class="bi bi-plus-circle"></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="work-box">
          <a href="../../../assets/images/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox">
            <div class="work-img">
              <img src="../../../assets/images/img/portfolio/portfolio-6.jpg" alt="" class="img-fluid">
            </div>
          </a>
          <div class="work-content">
            <div class="row">
              <div class="col-sm-8">
                <h2 class="w-title">Bindo Laro Cado</h2>
                <div class="w-more">
                  <span class="w-ctegory">Web Design</span> / <span class="w-date">18 Sep. 2018</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="w-like">
                  <a href="portfolio-details.html"> <span class="bi bi-plus-circle"></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="work-box">
          <a href="../../../assets/images/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox">
            <div class="work-img">
              <img src="../../../assets/images/img/portfolio/portfolio-5.jpg"alt="" class="img-fluid">
            </div>
          </a>
          <div class="work-content">
            <div class="row">
              <div class="col-sm-8">
                <h2 class="w-title">Studio Lena Mado</h2>
                <div class="w-more">
                  <span class="w-ctegory">Web Design</span> / <span class="w-date">18 Sep. 2018</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="w-like">
                  <a href="portfolio-details.html"> <span class="bi bi-plus-circle"></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="work-box">
          <a href="../../../assets/images/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox">
            <div class="work-img">
              <img src="../../../assets/images/img/portfolio/portfolio-4.jpg" alt="" class="img-fluid">
            </div>
          </a>
          <div class="work-content">
            <div class="row">
              <div class="col-sm-8">
                <h2 class="w-title">Studio Big Bang</h2>
                <div class="w-more">
                  <span class="w-ctegory">Web Design</span> / <span class="w-date">18 Sep. 2017</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="w-like">
                  <a href="portfolio-details.html"> <span class="bi bi-plus-circle"></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</section><!-- End Portfolio Section -->


  <!-- ======= Settings Section ======= -->
  <section id="contact" class="contact">
    <div class="container">

      <div class="section-title">
        <h2 translate>home.settings</h2>
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
      </div>

      <div class="row" >
        <div class="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
          <form  [formGroup]="settingForm"  class="php-email-form">
            <div class="row">
              <div class="form-group col-md-6">
                <label translate>home.Full-Name</label>
                <!-- <input type="text" name="name" class="form-control" id="name" required> -->
                <input
                type="text"
                class="form-control settings"
                name="fullname_en"
                formControlName="fullname_en"
                />
              </div>
              <div class="form-group col-md-6">
                <label translate>home.address</label>
                <input
                type="text"
                class="form-control settings"
                name="address"
                formControlName="address"
              />
              </div>
              <div class="form-group col-md-6">
                <label translate>home.job</label>
                <input
                type="text"
                class="form-control settings"
                name="job"
                formControlName="job"
              />
              </div>
              <div class="form-group col-md-6">
                <label translate>home.age</label>
                <input
                type="text"
                class="form-control settings"
                name="age"
                formControlName="age"
              />
              </div>
              <div class="form-group col-md-6">
                <label translate>home.phone</label>
                <input
                type="text"
                class="form-control settings"
                name="phone"
                formControlName="phone"
              />
              </div>
              <div class="form-group col-md-6">
                <label translate>home.email</label>
                <!-- <input type="email" class="form-control" name="email" id="email" required> -->
                <input
                type="text"
                class="form-control settings"
                name="email"
                formControlName="email"
              />
              </div>
              <div class="form-group col-md-6">
                <label translate>home.facebook-link</label>
                <input
                type="url"
                class="form-control settings"
                name="facebook"
                formControlName="facebook"
              />
            </div>
            <div class="form-group col-md-6">
              <label translate>home.twitter-link</label>
              <input
              type="url"
              class="form-control settings"
              name="twitter"
              formControlName="twitter"
            />
          </div>
          <div class="form-group col-md-6">
            <label translate>home.linkedin-link</label>
            <input
            type="url"
            class="form-control settings"
            name="linkedin"
            formControlName="linkedin"
          />
        </div>
        <div class="form-group col-md-6">
          <label translate>home.instagram-link</label>
          <input
          type="url"
          class="form-control settings"
          name="instagram"
          formControlName="instagram"
        />
      </div>
<!--              <div class="col-12">-->
<!--                <div class="row ">-->
                  <div class="form-group w-100 col-md-6">
                    <p class="" style="font-size: 17px; font-weight: 700" translate>home.date-of-birth</p>
                    <mat-form-field class="w-100" appearance="fill" (click)="birthdate.open()">
                      <mat-label class="" translate>home.date-of-birth</mat-label>
                      <input matInput placeholder="Due Date"  [matDatepicker]="birthdate"
                             formControlName="birthdate">
                      <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
                      <mat-datepicker #birthdate></mat-datepicker>
                      <div class="mat-error error-age"
                           *ngIf="settingForm.get('birthdate').errors?.someProp">{{settingForm.get('birthdate').errors?.someProp}}</div>
                      <div class="mat-error error-age"
                           *ngIf="settingForm.get('birthdate').errors?.required && settingForm.get('birthdate').errors?.touched">Please
                        enter a Birth Date
                      </div>
                    </mat-form-field>


<!--                    <div class="col-sm-12 my-2">-->
<!--                      <label for="fullName" class="form-label" translate>home.date-of-birth</label>-->
<!--                      <div class="position-relative">-->
<!--                        <input formControlName="birthdate" type="text" class="form-control settings" [matDatepicker]="birthdate"-->
<!--                               [style.backgroundPosition]=" lang == 'ar'?'left calc(2.375em + 0.1875rem) center':'right calc(2.375em + 0.1875rem) center'"-->
<!--                               [ngClass]="{ 'is-invalid': ( settingForm.get('birthdate')?.dirty && settingForm.get('birthdate')?.invalid) || (settingForm.get('birthdate')?.dirty && settingForm.get('birthdate')?.errors?.required ) ,'is-valid': ( settingForm.get('birthdate')?.dirty && settingForm.get('birthdate')?.valid)}">-->
<!--                        <mat-datepicker-toggle [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}" matSuffix-->
<!--                                               [for]="birthdate"></mat-datepicker-toggle>-->
<!--                        <mat-datepicker #birthdate></mat-datepicker>-->
<!--                      </div>-->
<!--                      <div class="invalid-feedback">-->
<!--                        birthdate is invalid.-->
<!--                      </div>-->
<!--                    </div>-->

                  </div>
                  <div class="form-group w-100 col-md-6">
                    <p class="" style="font-size: 17px; font-weight: 700" translate>home.profile-picture</p>
                    <div class="item d-flex border-dashed">
                      <button translate>home.choose-file
                        <i class="fas fa-image"></i>
                      </button>
                      <div *ngIf="image" class="image-cheque"><img width="70" [src]="image" alt="image profile"></div>

                      <input id="file" type="file" #selectFile formControlName="image"
                             (change)="onFileSelected($event.target)" accept="image/*">
<!--                      <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah"></div>-->
                    </div>
                  </div>
                </div>

<!--              </div>-->

<!--            </div>-->
            <div class="form-group">
              <label for="name" translate>home.bio</label>
              <!-- <textarea class="form-control" name="message" rows="10" required></textarea> -->
             <textarea
              class="form-control settings"
              id="text4"
              name="bio"
              rows="10"
              formControlName="bio"
              >
              </textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your message has been sent. Thank you!</div>
            </div>
            <div class="text-center">
              <button class="w-25 bg-card book" type="submit" (click)="submit()" translate>home.save</button>
            </div>
          </form>
        </div>

      </div>

    </div>
  </section>
  <!-- End Settings Section -->
