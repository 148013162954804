import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DatePipe} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './pages/home/home.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {GalleryModule} from 'ng-gallery';
import {LightboxModule} from 'ng-gallery/lightbox';
import {HomeChildrenSectionComponent} from './components/home/home-children-section/home-children-section.component';
import {ChildrenComponent} from './pages/children/children.component';
import {CaseDetailsComponent} from './pages/case-details/case-details.component';
import {HomeVideosSectionComponent} from './components/home/home-videos-section/home-videos-section.component';
import {ContactUsComponent} from './pages/contact-us/contact-us.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {ProjectsComponent} from './pages/projects/projects.component';
import {HomeProjectsSectionComponent} from './components/home/home-projects-section/home-projects-section.component';
import {ProjectDetailsComponent} from './pages/project-details/project-details.component';
import {OurMissionComponent} from './components/home/our-mission/our-mission.component';
import {RecentNewsComponent} from './components/home/recent-news/recent-news.component';
import {OurVisionComponent} from './components/home/our-vision/our-vision.component';
import {DonateComponent} from './components/donate/donate.component';
import {VideoDescriptionComponent} from './pages/video-description/video-description.component';
import {NewsDetailsComponent} from './pages/news-details/news-details.component';
import {NewsComponent} from './pages/news/news.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {HayahComponent} from './pages/hayah/hayah.component';
import {OurMessageComponent} from './components/our-message/our-message.component';
import {FooterHayahComponent} from './components/footer-hayah/footer-hayah.component';
import {NotifierModule} from 'angular-notifier';
import {NotifierOptions} from 'angular-notifier';
import { NewsSlideComponent } from './components/news-slide/news-slide.component';
import { DonationPartComponent } from './components/donation-part/donation-part.component';
import { NgxSpinnerModule} from 'ngx-spinner';
import { PresidentDocumentComponent } from './components/president-document/president-document.component';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { VideosComponent } from './components/videos/videos.component';
import { EventComponent } from './pages/event/event.component';
import { HayaNewsComponent } from './components/haya-news/haya-news.component';
import { OurHayaMessageComponent } from './components/our-haya-message/our-haya-message.component';
import { HayaNewsDetailsComponent } from './pages/haya-news-details/haya-news-details.component';
import { HayaHeaderComponent } from './components/haya-header/haya-header.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './components/calendar/calendar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SafePipeModule } from 'safe-pipe';
import { EventDetailsComponent } from './pages/event-details/event-details.component';
import { MembersFormComponent } from './pages/members-form/members-form.component';
import { AddMemberFormComponent } from './components/add-member-form/add-member-form.component';
import { MatInputModule} from '@angular/material/input';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { TestComponent } from './components/test/test.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { NgPipesModule} from 'ngx-pipes';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MembersComponent } from './pages/members/members.component';
import { VolunteersComponent } from './pages/volunteers/volunteers.component';
import { BoardsComponent } from './pages/boards/boards.component';
import { MemberDetailsComponent } from './pages/member-details/member-details.component';
import { MembersSliderComponent } from './components/members-slider/members-slider.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PersonalFormComponent } from './components/profile/personal-form/personal-form.component';
import { OrphansComponent } from './pages/orphans/orphans.component';
import { OrphansProfileComponent } from './components/orphans/orphans-profile.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { BookingComponent } from './pages/booking/booking.component';
import { MessageComponent} from './components/booking/message/message.component';
import { PersonalDataComponent} from './components/booking/personal-data/personal-data.component';
import { ServicesComponent} from './components/booking/services/services.component';
import { CalenderComponent} from './components/booking/calender/calender.component';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatRadioModule} from '@angular/material/radio';
import { CertificateComponent } from './components/certificate/certificate.component';
import { PleaseLoginComponent } from './components/please-login/please-login.component';
import { BecomeVolunteerFormComponent } from './components/become-volunteer-form/become-volunteer-form.component';
import { NgxPrintModule} from 'ngx-print';
import { MemberSliderComponent } from './components/member-slider/member-slider.component';
import { HomeMainSliderComponent } from './components/home/home-main-slider/home-main-slider.component';
import { Ramadan2022Component } from './pages/ramadan2022/ramadan2022.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { SendemailComponent } from './pages/sendemail/sendemail.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthInterceptor} from './shared/authconfig.interceptor';
import { GoogleMapComponent } from './google-map/google-map.component';
import { AgmCoreModule } from '@agm/core';
import { PopUpComponent } from './pop-up/pop-up.component';
import { MatDialogModule} from '@angular/material/dialog';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RecentActivitiesComponent } from './recent-activities/recent-activities.component';
import { JoinUsComponent } from './join-us/join-us.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { AddClubComponent} from "./add-club/add-club.component";
import { ClubMembersComponent} from "./club-members/club-members.component";
import {MatIconModule} from "@angular/material/icon";
import {MatMenu, MatMenuModule} from "@angular/material/menu";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DonationComponent } from './donation/donation.component';
import { DonationForActivityComponent } from './donation-for-activity/donation-for-activity.component';
import { ToastrModule } from 'ngx-toastr';
import {NgxStripeModule} from "ngx-stripe";
import { LocalStorageService } from './services/webApis/local-storage.service';
import { WindowService } from './services/webApis/window.service';
import { from, Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { DialogCertificatesComponent } from './dialog-certificates/dialog-certificates.component';
import { DialogDonationComponent } from './dialog-donation/dialog-donation.component';
import { DialogexpensesComponent } from './dialogexpenses/dialogexpenses.component';
import { PrintDonationDetailsComponent } from './print-donation-details/print-donation-details.component';
import { FieldSocialResearchComponent } from './field-social-research/field-social-research.component';
import {MatStepperModule} from "@angular/material/stepper";
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from "@angular/material/card";
import { SafePipe } from './pipe/safe.pipe';
import { ShareSocialComponent } from './layout/share-social/share-social.component';
import { CasesBelongVolunteerComponent } from './cases-belong-volunteer/cases-belong-volunteer.component';
import { CaseSearchComponent } from './field-social-research/case-search/case-search.component';
import { FormJourneyComponent } from './components/profile/personal-form/form-journey/form-journey.component';
import {NgxTypedJsModule} from "ngx-typed-js";
import { DialogToastComponent } from './dialog-toast/dialog-toast.component';
import { DonationFormComponent } from './donation-form/donation-form.component';
import { PrivacyPoliciesComponent } from './pages/privacy-policies/privacy-policies.component';
import { PolicyDetailsComponent } from './pages/policy-details/policy-details.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
// export function creatTranslateLoader(http:HttpClient){
//   return new TranslateHttpLoader(http,'./assets/i18n/','.json')
// }

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../src/assets/i18n/${lang}.json`));
  }
}
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    // AddClubPipe,
    FormJourneyComponent,
    FooterComponent,
    HomeVideosSectionComponent,
    AppComponent,
    HeaderComponent,
    HomeComponent,
    ContactUsComponent,
    MessageComponent,
    AboutUsComponent,
    ProjectsComponent,
    HomeProjectsSectionComponent,
    ProjectDetailsComponent,
    HomeChildrenSectionComponent,
    ChildrenComponent,
    CaseDetailsComponent,
    OurMissionComponent,
    PersonalDataComponent,
    RecentNewsComponent,
    OurVisionComponent,
    DonateComponent,
    VideoDescriptionComponent,
    NewsDetailsComponent,
    NewsComponent,
    HayahComponent,
    OurMessageComponent,
    FooterHayahComponent,
    NewsSlideComponent,
    DonationPartComponent,
    PresidentDocumentComponent,
    CalenderComponent,
    VideosComponent,
    EventComponent,
    HayaNewsComponent,
    OurHayaMessageComponent,
    HayaNewsDetailsComponent,
    ServicesComponent,
    HayaHeaderComponent,
    CalendarComponent,
    EventDetailsComponent,
    MembersFormComponent,
    AddMemberFormComponent,
    TestComponent,
    ActivitiesComponent,
    MembersComponent,
    VolunteersComponent,
    BoardsComponent,
    MemberDetailsComponent,
    MembersSliderComponent,
    ProfileComponent,
    PersonalFormComponent,
    OrphansComponent,
    SignInComponent,
    BookingComponent,
    CalenderComponent,
    OrphansProfileComponent,
    CertificateComponent,
    PleaseLoginComponent,
    BecomeVolunteerFormComponent,
    MemberSliderComponent,
    HomeMainSliderComponent,
    Ramadan2022Component,
    ResetpasswordComponent,
    SendemailComponent,
    LoginComponent,
    GoogleMapComponent,
    PopUpComponent,
    NotFoundComponent,
    RecentActivitiesComponent,
    JoinUsComponent,
    EditProfileComponent,
    AddClubComponent,
    ClubMembersComponent,
    DonationComponent,
    DonationForActivityComponent,
    DialogCertificatesComponent,
    DialogDonationComponent,
    DialogexpensesComponent,
    PrintDonationDetailsComponent,
    FieldSocialResearchComponent,
    SafePipe,
    ShareSocialComponent,
    CasesBelongVolunteerComponent,
    CaseSearchComponent,
    DialogToastComponent,
    DonationFormComponent,
    PrivacyPoliciesComponent,
    PolicyDetailsComponent,
    TermsAndConditionsComponent,
  ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),

        AppRoutingModule,
        CarouselModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSelectModule,
        FormsModule,
        MatCheckboxModule,
        NgxMaterialTimepickerModule,
        GalleryModule,
        LightboxModule,
        NgxSkeletonLoaderModule,
        MatIconModule,
        MatMenuModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB1KAbQnJIP11YSmWLb2OH8jMLyFpFluf4'
        }),
        NotifierModule.withConfig(
            customNotifierOptions
        ),
        NgxSpinnerModule,
        MatExpansionModule,
        MatTabsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        NgbModule,
        SafePipeModule,
        MatInputModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
        MatNativeDateModule,
        NgPipesModule,
        Ng2SearchPipeModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        NgxPrintModule,
        MatDialogModule,
        MatStepperModule,
        MatButtonModule,
        MatCardModule,
        NgxTypedJsModule,
        NgxStripeModule.forRoot(' pk_live_icmgpanW27vLYxG8o4fawX1m'),
        // pk_test_V4IgS3YdNKguXohuNj0mEU0C
        // pk_live_icmgpanW27vLYxG8o4fawX1m
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
                deps: [HttpClient]
            },
            isolate: false
        }),
        ToastrModule.forRoot({
            timeOut: 100000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            tapToDismiss: true,
            closeButton: true,

        }),
    ],
  providers: [
    [CookieService],
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    LocalStorageService,
    WindowService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

