
 <div class="contact-us-page">
   <div class="help-center-header">
     <div class="container">
       <h1 class="text-center mt-5" translate>home.contact-us</h1>
     </div>
   </div>
   <div class="help-center-body">
     <!-- start contact-us -->
     <div class="container">
       <div class="contact-us">
         <form [formGroup]="form" #myForm="ngForm">
           <div class="inside">
             <h2 translate>home.reach-you</h2>
             <!-- Full name -->
             <div class="field">
               <label for="name" translate>home.full-name</label>
               <input id="name" type="text"  required formControlName="fullname" />
             </div>
             <!-- Email -->
             <div class="field">
               <label for="email" translate>home.email-address</label>
               <input id="email" type="email"  required formControlName="email"/>
             </div>
             <!-- mobile number -->
             <div class="field">
               <label for="mobile" translate>home.mobile-number</label>
               <input id="mobile" type="text"  required formControlName="phone"/>
             </div>
             <!-- subject -->
             <div class="field">
               <label for="subject" translate>home.subject</label>
               <input id="subject" type="text"  required formControlName="subject"/>
             </div>
             <!-- message -->
             <div class="field">
               <label for="message" translate>home.your-message</label>
               <textarea id="message" placeholder="{{ 'home.tell-us' | translate }}" ng-model="myModel"
                          required formControlName="message"></textarea>
             </div>
             <div class="submit">
               <button class="button active" (click)="addContact()" [disabled]="myForm.invalid" translate>home.send</button>
               <!-- <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i> -->
             </div>
           </div>
         </form>
       </div>
     </div>
     <!-- end contact-us -->
   </div>
 </div>
