import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild,} from '@angular/core';
import {endOfDay, isSameDay, isSameMonth, startOfDay,} from 'date-fns';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView,} from 'angular-calendar';
import {ApisService} from '../../services/apis.service';
import {ActivatedRoute} from '@angular/router';

const colors: any = [
  {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  {
    primary: '#CCCCFF',
    secondary: '#CCCCFF',
  },
  {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  {
    primary: '#D1E8FF',
    secondary: '#D1E8FF',
  },
  {
    primary: '#40E0D0',
    secondary: '#FDF1BA',
  },
  {
    primary: '#9FE2BF',
    secondary: '#9FE2BF',
  },
];

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  styles: [`
    .full-width {
      height: 100vh;
    }

    .dark-modal .close {
      color: white;
    }

    .light-blue-backdrop {
      background-color: #5cb3fd;
    }
  `]
})
export class CalendarComponent implements OnInit, AfterViewInit {
  allEvents: any;
  allEventsOBj: any = [];
  objectKeys: any = [];
  eventObj: any;
  eventStr: any;
  slug: any;

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({event}: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({event}: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] =
    [
    ];

  activeDayIsOpen: boolean = true;

  constructor(private modal: NgbModal,
              private apis: ApisService,
              private activatedRoute: ActivatedRoute,
  ) {

  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd,
                    }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.eventObj = this._filterPersonalCountries(event.title);
    this.eventStr = JSON.stringify(this.eventObj);
    this.modalData = {event, action};
    this.modal.open(this.modalContent, {size: 'xl', centered: true, windowClass: 'popup-class'});
  }
  ngOnInit(): void {
    this.slug = this.activatedRoute.snapshot.params.slug;
    this.getAllEvents();
  }

  deleteEvent(eventToDelete: CalendarEvent): any {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView): any {
    this.view = view;
  }

  closeOpenMonthViewDay(): any {
    this.activeDayIsOpen = false;
  }

  ngAfterViewInit(): void {
  }

  getAllEvents(): any {
    this.apis.getEvents().subscribe((res: any) => {
      this.allEvents = res.data;
      this.objectKeys = Object.keys(this.allEvents);
      let i = 0;
      for (const item of this.allEvents) {
        if (i >= colors.length) {
          i = 0;
        }
        const obj: any = {};
        const key = Object.keys(item);
        obj.start = startOfDay(new Date(item[key[0]].start_date));
        item[key[0]].end_date ? obj.end = endOfDay(new Date(item[key[0]].end_date)) : obj.end = endOfDay(new Date(item[key[0]].start_date));
        obj.title = item[key[0]].name;
        obj.color = colors[i];
        obj.description = item[key[0]].details;
        // obj.eventVideo = this.allEvents[item].event_video;
        obj.logo = item[key[0]].logo;
        obj.slug = item[key[0]].slug;
        this.allEventsOBj.push(obj);
        i++;
      }
      this.events = [...this.allEventsOBj];
    }, err => {
      console.log(err);
    });
  }
  private _filterPersonalCountries(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.events.filter(option => option.title.toLowerCase().includes(filterValue));
  }

}
