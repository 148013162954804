import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-president-document',
  templateUrl: './president-document.component.html',
  styleUrls: ['./president-document.component.scss']
})
export class PresidentDocumentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
