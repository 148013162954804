<!--<app-header></app-header>-->
<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">

<div class="container mt-5">
  <form class="source-item sr7-w" [formGroup]="addClub">
    <div class="container">
      <div class="row ">
        <div class="col-lg-6 col-12 mt-2">
          <p class="" translate>home.name</p>
          <input
            type="text"
            class="form-control settings"
            name="name"
            placeholder="{{ 'home.fullname' | translate }}" ng-model="myModel"
            formControlName="name"
          />

        </div>
        <div class="col-lg-6 col-12 mt-2">
          <p class="" translate>home.address</p>
          <input
            type="text"
            class="form-control settings"
            name="address"
            placeholder="{{ 'home.address-placeholder' | translate }}" ng-model="myModel"
            formControlName="address"
          />
        </div>
        <div class="col-lg-6 col-12 mt-2">
          <p class="" translate>home.phone</p>
          <input
            type="text"
            class="form-control settings"
            name="phone"
            placeholder="{{ 'home.phone-placeholder' | translate }}" ng-model="myModel"
            formControlName="phone"
          />

        </div>
        <div class="col-lg-6 col-12 mt-2">
          <p class="" translate>home.email</p>
          <input
            type="text"
            class="form-control settings"
            name="email"
            placeholder="{{ 'home.email-placeholder' | translate }}" ng-model="myModel"
            formControlName="email"
          />

        </div>
        <div class="col-lg-6 col-12 mt-4">
          <mat-form-field appearance="fill">
            <mat-label class="star" translate>home.country</mat-label>
            <input style="cursor: pointer" matInput placeholder="Pick country" [matAutocomplete]="country" type="text"
                   formControlName="country"
                   required

            />
            <mat-autocomplete autoActiveFirstOption #country="matAutocomplete"
                              (optionSelected)='getStates($event)'>
              <mat-option *ngFor="let option of filteredCountries | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-12 mt-4">
          <mat-form-field appearance="fill">
            <mat-label class="star" translate>home.states</mat-label>
            <input style="cursor: pointer" matInput placeholder="Pick State" [matAutocomplete]="State" type="text"
             formControlName="state"
            />
            <mat-autocomplete autoActiveFirstOption #State="matAutocomplete"
                              (optionSelected)='getCities($event)'>
              <mat-option *ngFor="let option of filteredStates | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-12 mt-4 ">
          <mat-form-field appearance="fill">
            <mat-label class="star" translate="">home.cities</mat-label>
            <input style="cursor: pointer" matInput placeholder="Pick cities" [matAutocomplete]="city" type="text"
                   formControlName="city"
            >
            <mat-autocomplete autoActiveFirstOption #city="matAutocomplete"
                              (optionSelected)='selectedCity($event)'
            >
              <mat-option *ngFor="let option of filteredCities | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-12 mt-2">
          <p class="" translate>home.club-name</p>
          <input
            type="text"
            class="form-control settings"
            name="club_name"
            placeholder="{{ 'home.club_name-placeholder' | translate }}" ng-model="myModel"
            formControlName="club_name"
          />

        </div>
        <div class="col-lg-6 col-12  mt-2">
          <p class="" translate>home.club-activities</p>
          <input
            type="text"
            class="form-control settings"
            name="club_activities"
            placeholder="{{ 'home.club-activities-placeholder' | translate }}" ng-model="myModel"
            formControlName="club_activities"
          />

        </div>
        <div class=" col-lg-6 col-12  mt-2">
          <p class="" translate>home.club-info</p>
          <input
            type="text"
            class="form-control settings"
            name="club_info"
            placeholder="{{ 'home.club-info-placeholder' | translate }}" ng-model="myModel"
            formControlName="club_info"
          />

        </div>
        <div class="col-lg-12 col-12 mt-2">
          <p class="" translate>home.notes</p>
          <textarea
            class="form-control settings"
            id="text4"
            name="notes"
            placeholder="{{ 'home.add-notes-placeholder' | translate }}" ng-model="myModel"
            rows="6"
            formControlName="notes"
          >
                          </textarea>
        </div>
        <div class="row justify-content-center" style="margin: 0 auto">
          <button class="btn submit-btn offset-md-1" [disabled]="addClub.status == 'INVALID'" (click)="add()" translate>
            home.submit
          </button>
        </div>
      </div>
    </div>
  </form>


</div>


<!--<app-footer></app-footer>-->
