import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SeoService} from '../../services/seo.service';
import {AppComponent} from '../../app.component';
@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss']
})
export class ChildrenComponent implements OnInit {

  constructor(private router: Router, private seoService: SeoService) {
  }

  ngOnInit(): void {
    this.seoUpdate();
  }
  caseDetails(): void{
    this.router.navigate(['/case-details']);
  }
  seoUpdate(): any {
    this.seoService.updateConstMetaTags();

    this.seoService.updateTitle('Donate Children');
    this.seoService.updateDescription('Donate Children');
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/explore/header.png');
  }
}
