import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ApisService } from "../../services/apis.service";
import { find, map, startWith, take } from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DOCUMENT, DatePipe } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { log } from 'console';
@Component({
  selector: 'app-case-search',
  templateUrl: './case-search.component.html',
  styleUrls: ['./case-search.component.scss']
})
export class CaseSearchComponent implements OnInit {
  loading: boolean = false;
  listOfFather: any[] = [
    { name_ar: 'متوفي ', name_en: 'dead' },
    { name_ar: 'على قيد الحياه', name_en: 'live' }
  ];
  listOfMother: any[] = [
    { name_ar: 'متوفيه', name_en: 'dead' },
    { name_ar: 'على قيد الحياه', name_en: 'live' }
  ];
  listOfGrandfather: any[] = [
    { name_ar: 'متوفي ', name_en: 'dead' },
    { name_ar: 'على قيد الحياه', name_en: 'live' }
  ];
  listOfGrandmother: any[] = [
    { name_ar: 'متوفيه', name_en: 'dead' },
    { name_ar: 'على قيد الحياه', name_en: 'live' }
  ];
  listOfgender: any[] = [
    { name_ar: 'ذكر', name_en: 'male' },
    { name_ar: 'أنثى', name_en: 'Female' }
  ];
  firstFormGroup = this._formBuilder.group({
    fullname: ['', [Validators.required]],
    address: ['', [Validators.required]],
    age: ['', [Validators.required, Validators.pattern('[0-9]+')]],
    birthdate: ['', [Validators.required]],

  });
  secondFormGroup = this._formBuilder.group({
    email: ['', [Validators.email]],
    phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
    national_id: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.minLength(14), Validators.maxLength(14)]],
  });
  thirdFormGroup = this._formBuilder.group({
    city: ['',],
    village: [''],
    goverment_id: [null, [Validators.required]],
    club_id: [null, [Validators.required]],
    purpose_id: [[]],
  });
  ForthFormGroup = this._formBuilder.group({
    case_image: [''],
    image: ['', [Validators.required]],
  });
  FifthFormGroup = this._formBuilder.group({
    job_place: [''],
    water_source: [''],
    current_job: [''],
    housing_type: [''],
    house_status: [''],
    house_independance: [''],
    possession: [''],
    wc: [''],
    // when purpos id == 7
    user_id: [0],
    father: [''],
    mother: [''],
    grandfather: [''],
    grandmother: [''],
    live_with_whom: [''],
  });
  SixhlyFormGroup = this._formBuilder.group({
    family_health_status: [''],
    family_marital_status: [''],
    family_economic_status: [''],
    family_needs: [''],
    // researcher_opinion: [''],
    // who_creditor: [''],
    insolvency_reason: [''],
    // when purpos id == 7
    educational_level: [''],
    academic_level: [''],
    hobbies: [''],
    favorite_colors: [''],
    favorite_sports: [''],

  });
  VIIFormGroup = this._formBuilder.group({
    insolvency_date: [null],
    insurance_number: [0, [Validators.pattern('[0-9]+')]],
    housing_space: [0, [Validators.pattern('[0-9]+')]],
    monthly_rent: [0, [Validators.pattern('[0-9]+')]],
    room_numbers: [0, [Validators.pattern('[0-9]+')]],
    money_required: [0, [Validators.pattern('[0-9]+')]],
    money_paid: [0, [Validators.pattern('[0-9]+')]],
    // when purpos id == 7
    gender: [''],
    current_height: [0, [Validators.pattern('[0-9]+')]],
    current_weight: [0, [Validators.pattern('[0-9]+')]],
    shoe_size: [0, [Validators.pattern('[0-9]+')]],
  });
  // test
  // firstFormGroup = this._formBuilder.group({
  //   fullname: [''],
  //   address: [''],
  //   age: [''],
  // });
  // secondFormGroup = this._formBuilder.group({
  //   email: [''],
  //   phone: [''],
  //   national_id: [''],
  // });
  // thirdFormGroup = this._formBuilder.group({
  //   city: ['',],
  //   village: [''],
  //   goverment_id: [''],
  //   club_id: [null],
  //   purpose_id: [''],
  // });
  // ForthFormGroup = this._formBuilder.group({
  //   case_image: [''],
  //   image: [''],
  // });

  filteredGovermnets: Observable<string[]>;
  filteredCities: Observable<string[]>;
  filteredVillages: Observable<string[]>;
  filteredCasePurpose: Observable<string[]>;
  filteredClubs: Observable<string[]>;

  formDocument: FormGroup;
  isLinear = false;
  allClubs: any[] = [];
  govermnets: any[] = [];
  casePurpose: any[] = [];
  cities: any[] = [];
  villages: any[] = [];
  errorMessage!: string;
  fileToUpload: any[] = [];
  fileToUpload1: any[] = [];
  images1: any[] = [];
  image: any[] = [];
  village_id;
  purpose = new FormControl('');
  listOfCase: any[] = [];
  purpose_id: any[] = [];
  step: number = 2;
  lang: any;
  FilterPurpose: any[] = [];
  displayPupose: any[] = [6, 7];
  casePurposeId: any[] = [];
  DataCase: any[] = [];
  caseData: any =
    {
      fullname: '',
      age: '',
      address: '',
      birthdate: '',
      phone: '',
      national_id: '',
      email: '',
      city: '',
      village: '',
      goverment: '',
      club: '',
      purpose: '',
      images: '',
      case_image: '',
      job_place: '',
      water_source: '',
      current_job: '',
      housing_type: '',
      house_status: '',
      house_independance: '',
      possession: '',
      wc: '',
      father: '',
      mother: '',
      grandfather: '',
      grandmother: '',
      live_with_whom: '',
      family_marital_status: '',
      family_health_status: '',
      family_economic_status: '',
      family_needs: '',
      insolvency_reason: '',
      educational_level: '',
      academic_level: '',
      hobbies: '',
      favorite_colors: '',
      favorite_sports: '',
      insolvency_date: '',
      insurance_number: '',
      housing_space: '',
      monthly_rent: '',
      room_numbers: '',
      money_required: '',
      money_paid: '',
      gender: '',
      current_height: '',
      current_weight: '',
      shoe_size: '',
    };
  caseId: number = 0;
  constructor(private _formBuilder: FormBuilder,
    private apis: ApisService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CaseSearchComponent>,

  ) {
    this.apis.currentLang$.subscribe(res => {
      this.lang = res
    })
  }

  getPurposeId(id: any, caseId: number) {
    this.caseId = caseId
    this.purpose_id = [];
    this.thirdFormGroup.patchValue({
      purpose_id: [id],
    })
    this.purpose_id.push(id)
    console.log(this.purpose_id[0]);
    this.chosePurposeData()
  }
  chosePurposeData() {

    const before = this.data.case
    const caseData = before.filter(dataFilter => dataFilter.purpose.id == this.purpose_id)
    this.caseData = caseData[0] ? caseData : [before[before.length - 1]];
    console.log('this.befor[0]', before);
    console.log('this.caseData[0]', caseData);
    console.log('this.this.caseData[0]', this.caseData);
    this.setValue()

  }
  ngOnInit(): void {
    console.log('this.data.case', this.data);
    if (this.data.completed == 1) {
      this.caseData = this.data?.case[0]
    }
    this.getCasePurposes();
    this.getGovermnets();
    this.getclubs();


  }

  setValue() {
    if (this.data.completed == 1) {
      this.firstFormGroup.patchValue({
        fullname: this.caseData[0].fullname == null ? '' : this.caseData[0].fullname,
        age: this.caseData[0].age == null ? '' : this.caseData[0].age,
        address: this.caseData[0].address == null ? '' : this.caseData[0].address,
        birthdate: new Date(this.caseData[0].birthdate == null ? '' : this.caseData[0].birthdate),
      })
      this.secondFormGroup.patchValue({
        phone: this.caseData[0].phone == null ? '' : this.caseData[0].phone,
        national_id: this.caseData[0].national_id == null ? '' : this.caseData[0].national_id,
        email: this.caseData[0].email == null ? '' : this.caseData[0].email,
      })
      this.thirdFormGroup.patchValue({
        city: this.caseData[0].city == null ? '' : this.caseData[0].city,
        village: this.caseData[0].village == null ? '' : this.caseData[0].village,
        goverment_id: this.caseData[0].goverment == null ? '' : this.caseData[0].goverment.name,
        club_id: this.caseData[0].club == null ? '' : this.caseData[0].club.name,
        // purpose_id: [this.caseData[0].purpose==null?'':this.caseData[0].purpose.name],
      })
      this.listOfCase.push(this.caseData[0].purpose == null ? '' : this.caseData[0].purpose.name)
      // this.data.case.forEach(element => {
      //   this.purpose_id.push(element.purpose.id)
      //   console.log(element);
      // });

      this.caseData[0].images == null ? '' : this.caseData[0].images.forEach((ele) => {
        this.images1.push(ele)
      })
      this.image.push(this.caseData[0].case_image == null ? '' : this.caseData[0].case_image)
      this.ForthFormGroup.patchValue({
        image: this.caseData[0].case_image == null ? '' : this.caseData[0].case_image
      })
      this.FifthFormGroup.patchValue({
        job_place: this.caseData[0].job_place == null ? '' : this.caseData[0].job_place,
        water_source: this.caseData[0].water_source == null ? '' : this.caseData[0].water_source,
        current_job: this.caseData[0].current_job == null ? '' : this.caseData[0].current_job,
        housing_type: this.caseData[0].housing_type == null ? '' : this.caseData[0].housing_type,
        house_status: this.caseData[0].house_status == null ? '' : this.caseData[0].house_status,
        house_independance: this.caseData[0].house_independance == null ? '' : this.caseData[0].house_independance,
        possession: this.caseData[0].possession == null ? '' : this.caseData[0].possession,
        wc: this.caseData[0].wc == null ? '' : this.caseData[0].wc,
        father: this.caseData[0].father == null ? '' : this.caseData[0].father,
        mother: this.caseData[0].mother == null ? '' : this.caseData[0].mother,
        grandfather: this.caseData[0].grandfather == null ? '' : this.caseData[0].grandfather,
        grandmother: this.caseData[0].grandmother == null ? '' : this.caseData[0].grandmother,
        live_with_whom: this.caseData[0].live_with_whom == null ? '' : this.caseData[0].live_with_whom,
      })
      this.SixhlyFormGroup.patchValue({
        family_marital_status: this.caseData[0].family_marital_status == null ? '' : this.caseData[0].family_marital_status,
        family_health_status: this.caseData[0].family_health_status == null ? '' : this.caseData[0].family_health_status,
        family_economic_status: this.caseData[0].family_economic_status == null ? '' : this.caseData[0].family_economic_status,
        family_needs: this.caseData[0].family_needs == null ? '' : this.caseData[0].family_needs,
        insolvency_reason: this.caseData[0].insolvency_reason == null ? '' : this.caseData[0].insolvency_reason,
        educational_level: this.caseData[0].educational_level == null ? '' : this.caseData[0].educational_level,
        academic_level: this.caseData[0].academic_level == null ? '' : this.caseData[0].academic_level,
        hobbies: this.caseData[0].hobbies == null ? '' : this.caseData[0].hobbies,
        favorite_colors: this.caseData[0].favorite_colors == null ? '' : this.caseData[0].favorite_colors,
        favorite_sports: this.caseData[0].favorite_sports == null ? '' : this.caseData[0].favorite_sports,
      })
      this.VIIFormGroup.patchValue({
        insolvency_date: new Date(this.caseData[0].insolvency_date == null ? '' : this.caseData[0].insolvency_date),
        insurance_number: this.caseData[0].insurance_number == null ? '' : this.caseData[0].insurance_number,
        housing_space: this.caseData[0].housing_space == null ? '' : this.caseData[0].housing_space,
        monthly_rent: this.caseData[0].monthly_rent == null ? '' : this.caseData[0].monthly_rent,
        room_numbers: this.caseData[0].room_numbers == null ? '' : this.caseData[0].room_numbers,
        money_required: this.caseData[0].money_required == null ? '' : this.caseData[0].money_required,
        money_paid: this.caseData[0].money_paid == null ? '' : this.caseData[0].money_paid,
        gender: this.caseData[0].gender == null ? '' : this.caseData[0].gender,
        current_height: this.caseData[0].current_height == null ? '' : this.caseData[0].current_height,
        current_weight: this.caseData[0].current_weight == null ? '' : this.caseData[0].current_weight,
        shoe_size: this.caseData[0].shoe_size == null ? '' : this.caseData[0].shoe_size,
      })
    }
  }
  getPurposId(id: any) {
    this.purpose_id = [];
    this.purpose_id.push(id)
  }
  deletimage() {
    this.image = []
    this.fileToUpload = []
  }

  deletimages1(i: number) {
    this.fileToUpload1.splice(i, 1)
    this.images1.splice(i, 1)
  }

  toggleSelection(event: any, name: any, id: number) {
    if (event.checked) {
      this.listOfCase.push(name)
      this.purpose_id.push(id)
    } else {
      this.listOfCase.splice(this.listOfCase.findIndex((x) => x == name), 1)
      this.purpose_id.splice(this.purpose_id.findIndex((id) => id == id), 1)
    }
    console.log(this.listOfCase, this.purpose_id);

    this.thirdFormGroup.patchValue({
      purpose_id: this.listOfCase.length > 0 ? `${this.listOfCase[0]} ${this.listOfCase.length > 1 ? `(+${this.listOfCase.length - 1} ${this.listOfCase.length > 2 ? 'others' : 'other'})` : ''}` : this.listOfCase
    })
  }

  submitForm(): any {
    console.log(this.FifthFormGroup.value);

    let formData: FormData = new FormData();

    for (var i = 0; i < this.fileToUpload1.length; i++) {
      formData.append(`images[]`, this.fileToUpload1[i]);
    }
    let goverment_id = this.getGovermnetsObject(this.thirdFormGroup.value.goverment_id).id;
    let club_id = this.getClubsObject(this.thirdFormGroup.value.club_id).id;
    let dateSelected = this.datepipe.transform(this.VIIFormGroup.get('insolvency_date').value, 'yyyy-MM-dd');
    let birthdate = this.datepipe.transform(this.firstFormGroup.get('birthdate').value, 'yyyy-MM-dd');
    this.VIIFormGroup.patchValue({
      insolvency_date: dateSelected
    })
    this.firstFormGroup.patchValue({
      birthdate: birthdate
    })
    // formData.append('email', this.secondFormGroup.value.email)
    this.firstFormGroup.value.fullname != null ? formData.append('fullname', this.firstFormGroup.value.fullname) : EMPTY;
    this.firstFormGroup.value.age != null ? formData.append('age', this.firstFormGroup.value.age) : EMPTY;
    this.firstFormGroup.value.address != null ? formData.append('address', this.firstFormGroup.value.address) : EMPTY;
    this.firstFormGroup.get('birthdate').value != null ? formData.append('birthdate', this.firstFormGroup.get('birthdate').value) : EMPTY;
    this.secondFormGroup.value.email ? formData.append('email', this.secondFormGroup.value.email) : EMPTY;
    this.secondFormGroup.value.phone != null ? formData.append('phone', this.secondFormGroup.value.phone) : EMPTY;
    this.secondFormGroup.value.national_id != null ? formData.append('national_id', this.secondFormGroup.value.national_id) : EMPTY;
    goverment_id != null ? formData.append('goverment_id', goverment_id) : EMPTY;
    this.thirdFormGroup.value.city != null ? formData.append('city', this.thirdFormGroup.value.city) : EMPTY;
    club_id != null ? formData.append('club_id', club_id) : EMPTY;
    this.thirdFormGroup.value.village != null ? formData.append('village', this.thirdFormGroup.value.village) : EMPTY;
    this.fileToUpload.length > 0 ? formData.append('case_image', this.fileToUpload[0]) : EMPTY;

    this.FifthFormGroup.get('job_place').value != null ? formData.append('job_place', this.FifthFormGroup.get('job_place').value) : EMPTY;
    this.FifthFormGroup.get('current_job').value != null ? formData.append('current_job', this.FifthFormGroup.get('current_job').value) : EMPTY;
    this.FifthFormGroup.get('housing_type').value != null ? formData.append('housing_type', this.FifthFormGroup.get('housing_type').value) : EMPTY;
    this.FifthFormGroup.get('house_status').value != null ? formData.append('house_status', this.FifthFormGroup.get('house_status').value) : EMPTY;
    this.FifthFormGroup.get('house_independance').value != null ? formData.append('house_independance', this.FifthFormGroup.get('house_independance').value) : EMPTY;
    this.FifthFormGroup.get('possession').value != null ? formData.append('possession', this.FifthFormGroup.get('possession').value) : EMPTY;
    this.FifthFormGroup.get('wc').value != null ? formData.append('wc', this.FifthFormGroup.get('wc').value) : EMPTY;
    this.FifthFormGroup.get('water_source').value != null ? formData.append('water_source', this.FifthFormGroup.get('water_source').value) : EMPTY;
    //
    this.FifthFormGroup.get('father').value != null ? formData.append('father', this.FifthFormGroup.get('father').value) : EMPTY;
    this.FifthFormGroup.get('mother').value != null ? formData.append('mother', this.FifthFormGroup.get('mother').value) : EMPTY;
    this.FifthFormGroup.get('grandfather').value != null ? formData.append('grandfather', this.FifthFormGroup.get('grandfather').value) : EMPTY;
    this.FifthFormGroup.get('grandmother').value != null ? formData.append('grandmother', this.FifthFormGroup.get('grandmother').value) : EMPTY;
    this.FifthFormGroup.get('live_with_whom').value != null ? formData.append('live_with_whom', this.FifthFormGroup.get('live_with_whom').value) : EMPTY;
    //
    this.SixhlyFormGroup.get('educational_level').value != null ? formData.append('educational_level', this.SixhlyFormGroup.get('educational_level').value) : EMPTY;
    this.SixhlyFormGroup.get('academic_level').value != null ? formData.append('academic_level', this.SixhlyFormGroup.get('academic_level').value) : EMPTY;
    this.SixhlyFormGroup.get('hobbies').value != null ? formData.append('hobbies', this.SixhlyFormGroup.get('hobbies').value) : EMPTY;
    this.SixhlyFormGroup.get('favorite_colors').value != null ? formData.append('favorite_colors', this.SixhlyFormGroup.get('favorite_colors').value) : EMPTY;
    this.SixhlyFormGroup.get('favorite_sports').value != null ? formData.append('favorite_sports', this.SixhlyFormGroup.get('favorite_sports').value) : EMPTY;

    this.SixhlyFormGroup.get('family_marital_status').value != null ? formData.append('family_marital_status', this.SixhlyFormGroup.get('family_marital_status').value) : EMPTY;
    this.SixhlyFormGroup.get('family_health_status').value != null ? formData.append('family_health_status', this.SixhlyFormGroup.get('family_health_status').value) : EMPTY;
    this.SixhlyFormGroup.get('family_economic_status').value != null ? formData.append('family_economic_status', this.SixhlyFormGroup.get('family_economic_status').value) : EMPTY;
    this.SixhlyFormGroup.get('family_needs').value != null ? formData.append('family_needs', this.SixhlyFormGroup.get('family_needs').value) : EMPTY;
    this.SixhlyFormGroup.get('insolvency_reason').value != null ? formData.append('insolvency_reason', this.SixhlyFormGroup.get('insolvency_reason').value) : EMPTY;

    this.VIIFormGroup.get('gender').value != null ? formData.append('gender', this.VIIFormGroup.get('gender').value) : EMPTY;
    this.VIIFormGroup.get('current_height').value != null ? formData.append('current_height', this.VIIFormGroup.get('current_height').value) : EMPTY;
    this.VIIFormGroup.get('current_weight').value != null ? formData.append('current_weight', this.VIIFormGroup.get('current_weight').value) : EMPTY;
    this.VIIFormGroup.get('shoe_size').value != null ? formData.append('shoe_size', this.VIIFormGroup.get('shoe_size').value) : EMPTY;

    this.VIIFormGroup.get('insolvency_date').value != null ? formData.append('insolvency_date', this.VIIFormGroup.get('insolvency_date').value) : EMPTY;
    this.VIIFormGroup.get('insurance_number').value != null ? formData.append('insurance_number', this.VIIFormGroup.get('insurance_number').value) : EMPTY;
    this.VIIFormGroup.get('housing_space').value != null ? formData.append('housing_space', this.VIIFormGroup.get('housing_space').value) : EMPTY;
    this.VIIFormGroup.get('monthly_rent').value != null ? formData.append('monthly_rent', this.VIIFormGroup.get('monthly_rent').value) : EMPTY;
    this.VIIFormGroup.get('room_numbers').value != null ? formData.append('room_numbers', this.VIIFormGroup.get('room_numbers').value) : EMPTY;
    this.VIIFormGroup.get('money_required').value != null ? formData.append('money_required', this.VIIFormGroup.get('money_required').value) : EMPTY;
    this.VIIFormGroup.get('money_paid').value != null ? formData.append('money_paid', this.VIIFormGroup.get('money_paid').value) : EMPTY;

    if (this.data.completed == 1) {
      this.purpose_id.length > 0 ? formData.append(`purpose_id`, this.purpose_id[0]) : EMPTY;
    } else {
      this.purpose_id.length > 0 ? formData.append(`purpose_id`, this.purpose_id[0]) : EMPTY;

      // this.purpose_id.forEach((ele, i) => {

      //   this.purpose_id.length > 0 ? formData.append(`purpose_id[${i}]`, ele) : EMPTY;
      // })
    }

    return formData;
  }
  setFilterGovermnets() {
    this.filteredGovermnets = this.thirdFormGroup.controls.goverment_id.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  setFilterClubs() {
    this.filteredClubs = this.thirdFormGroup.controls.club_id.valueChanges.pipe(
      startWith(''),
      map(value => this._filterClubs(value || '')),
    );
  }


  private _filterClubs(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allClubs.filter(option => option.name.toLowerCase().includes(filterValue));
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.govermnets.filter(option => option.name_en?.toLowerCase().includes(filterValue));
  }

  getGovermnetsObject(govermnetName): any {
    const index = this.govermnets.findIndex(obj => obj.name_en === govermnetName);
    if (index > -1) {
      return this.govermnets[index];
    }
  }
  getClubsObject(clubName): any {
    const index = this.allClubs.findIndex(obj => obj.name === clubName);
    if (index > -1) {
      return this.allClubs[index];
    }
  }

  getCasePurposes() {
    this.apis.getCasePurposes().subscribe({
      next: (res: any) => {
        this.casePurpose = res.data;
        if (this.data.completed == 1) {
          this.data.case.forEach((ele: any, index) => {
            this.casePurposeId.push(ele.purpose.id)
          })
          let FilterPurpose = res.data.filter((data) => !this.casePurposeId.includes(data.id))
          this.FilterPurpose = FilterPurpose
        } else {
          this.casePurpose.forEach((ele: any, index) => {
            this.casePurposeId.push(ele.id)
          })
          this.FilterPurpose = this.casePurpose
        }
        // this.casePurpose = res.data.filter((data) => data.id == 6 || data.id == 7)
      }
    })
  }
  caseSearch(id: number) {
    this.dialogRef.close({ event: 'deliverd', deliverd: id });
  }
  getclubs() {
    this.apis.getclubs().subscribe({
      next: (res: any) => {
        this.allClubs = res.data
        this.setFilterClubs();
      }
    })
  }
  getGovermnets() {
    this.apis.getGovermnets().subscribe((data: any) => {
      this.govermnets = data.data
      this.setFilterGovermnets();
    },
      err => {
        console.log(err);
      });
  }

  onFileSelected(event: any): void {
    this.image = [];
    this.fileToUpload = [];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.fileToUpload.push(event.target.files.item(0));
      reader.onload = (event: any) => {
        this.image.push(event.target.result);
        this.ForthFormGroup.patchValue({
          case_image: this.image
        })
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  onFileSelected1(event: any): void {
    console.log(event)
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        this.fileToUpload1.push(event.target.files.item(i));
        reader.onload = (event: any) => {
          this.images1.push(event.target.result);
          this.ForthFormGroup.patchValue({
            image: this.images1
          })
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }


  add() {
    this.loading = true;
    if (this.data.completed == 1 && this.caseId != null) {
      this.apis.updateCaseSearches(this.submitForm(), this.caseId).subscribe({
        next: data => {
          this.loading = false;
          this.toastr.success(data.message, 'Success');
          this.dialog.closeAll();
        }, error: (err: any) => {
          this.loading = false;
          console.log(err);
          this.errorMessage = err.error.errors;
          for (const key in err.error.errors) {
            this.toastr.error(err.error.errors[key], 'faild')
          }
        }
      })
    } else {
      this.apis.addCaseSearches(this.submitForm()).subscribe({
        next: data => {
          this.loading = false;
          this.toastr.success(data.message, 'Success');
          this.dialog.closeAll();
        }, error: (err: any) => {
          this.loading = false;
          console.log(err);
          this.errorMessage = err.error.errors;
          for (const key in err.error.errors) {
            this.toastr.error(err.error.errors[key], 'faild')
          }
        }
      }
      );
    }
  }

  checkDate(e): void {
    console.log(e);

    let date = new Date().getTime();
    let dateSelected = this.datepipe.transform(e.value, 'yyyy-MM-dd');
    this.VIIFormGroup.patchValue({
      insolvency_date: dateSelected
    })
    if (date >= e) {
      this.VIIFormGroup.get('insolvency_date').setErrors({ 'incorrect': true, someProp: 'You must choose a day after today' });
    }
  }
}
