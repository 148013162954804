<nav class="navbar navbar-expand-lg navbar-light white-nav justify-content-between mobileFixPadding">
  <div class="container-fluid">
    <div class="collapse navbar-collapse justify-content-center" id="navbarText">
      <div class="d-flex flex-column align-items-center">
        <div class="nav-item logo">
          <a class=" nav-link-image" routerLink="/">
            <img alt="" src="../../../assets/images/haya/HAYA%20KARIMA%20FOUNDATION.png">
          </a>
        </div>
        <ul class="navbar-nav pr-0">
          <li class="nav-item active">
            <a class="nav-link" routerLinkActive="underline-decoration"
               [routerLinkActiveOptions]="{exact:true}" routerLink="/">Home <span class="sr-only">(current)</span></a>
          </li>

        </ul>
      </div>
    </div>
  </div>
</nav>
<app-news-slide *ngIf="donorsNames" [donorsNames]="donorsNames"></app-news-slide>
<div class="container mb-5">
  <app-calendar *ngIf="allEvents"></app-calendar>
  <div class="row">
    <div class="d-flex  justify-content-center  w-100">
      <!--        <button class="aplos-donation-button mr-3" data-widget-id="97EF7E128ABE7D1557C67221F2A6B9BC">Donate By Debit/Credit-->
      <!--          Card-->
      <!--        </button>-->
      <a class="aplos-donation-button mr-3" href="https://www.aplos.com/aws/give/HayaKarima/HayaKarimaUSA">Donate By Debit/Credit Card</a>
    </div>
  </div>
</div>
<div class="container mb-5">
  <div class="row mb-5">
    <iframe
      src="https://www.facebook.com/plugins/video.php?height=341&href=https%3A%2F%2Fwww.facebook.com%2FMoemigegy%2Fvideos%2F1030932741054349%2F&show_text=false&width=560&t=0"
      width="1080" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
  </div>
  <div class="row">
    <div id="event" class="event pr-30px">
      <div class="row">
        <div class="col-12">
          <ng-container *ngIf="!totalAmount">
            <ngx-skeleton-loader animation="progress" count="1" appearance="line"></ngx-skeleton-loader>
            <!-- above line will produce the rendering of 5 circles with the pulse animation and the aria-valuetext attribute set with "This item is actually loading..." -->
          </ng-container>
          <div *ngIf="totalAmount" class="donation w-100 d-flex flex-column align-items-center">
            <span>Total Donation <b>${{numberWithCommas(creditAmount)}}</b></span>
          </div>
        </div>
        <div class="col-12">
          <ng-container *ngFor="let event of events">
            <div id="event-header" class="event-content direction-ltr">
              <div class="event-header">
                <h3>{{event.name}}</h3>
              </div>
              <ng-container *ngIf="event.details.length > 926">
                <p>{{event.details | slice:0:926}}  </p>
                <ng-container *ngIf="show4">
                  <p>{{event.details | slice:926}}</p>
                </ng-container>
                <span  style="color:#f47321; cursor: pointer "
                      (click)="show4 = !show4">
                                 <span class="position-special" *ngIf="!show4">Read More ...</span>
                         <span  *ngIf="show4">Read Less</span>
              </span>
              </ng-container>
            </div>
          </ng-container>
          <a (click)="seoUpdateFace()" class="share-button mt-3 d-block">
            <button class="share btn">
              Share
              <i class="ml-2 fab fa-facebook"></i>
            </button>
          </a>
        </div>
        <div class="col-12">
          <article class="text-left direction-rtl important-article mb-4 mt-3">All donations made by those living in usa
            are 100%
            tax deductible, and 100% of donations go directly to the project with no administrative costs
          </article>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer-hayah></app-footer-hayah>
