<!-- <div *ngIf="false">
    <form [formGroup]="form">
        <div class="details-traveler">
            <div class="mt-5 ml-3 mr-3">
                <h4 class="tell-us" translate>home.about-yourself</h4>
            </div>
            <div class="col-12 d-flex overflow-auto" id="style-15">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="fill">
                            <mat-label class="star" translate>home.full-name</mat-label>
                            <input matInput placeholder="John" type="text" formControlName="fullName_en">
                        </mat-form-field>
                        <div class="mat-error error-age"
                            *ngIf="form.get('fullName_en').errors?.required && form.get('fullName_en').touched">full
                            Name is
                            Required
                        </div>
                    </div>
                    <div class="col-12 offset-md-1">
                        <h4 translate>home.contact-you</h4>
                    </div>
                    <div class="col-12 position-relative">
                        <mat-form-field appearance="fill">
                            <mat-label class="star" translate>home.your-email</mat-label>
                            <input matInput placeholder="examle@gmail.com" [(ngModel)]="email" type="text"
                                formControlName="email" (keyup)="checkMail()">
                            <div class="mat-error error-age " *ngIf="form.get('email').errors?.someProp">
                                {{form.get('email').errors?.someProp}}</div>
                        </mat-form-field>
                        <div class="marks position-absolute">
                            <i *ngIf="spinner" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
                            <i *ngIf="right" class="fa fa-check ml-2"></i>
                        </div>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="fill">
                            <mat-label class="star" translate>home.your-mobile</mat-label>
                            <input matInput placeholder="Phone Number" type="text" [(ngModel)]="phone"
                                formControlName="phone" (keyup)="checkPhone()">
                            <div class="mat-error error-age" *ngIf="form.get('phone').errors?.pattern">Please Type
                                Numbers Only.
                            </div>
                            <div class="mat-error error-age"
                                *ngIf="form.get('phone').errors?.required && form.get('phone').touched">phone is
                                Required
                            </div>
                            <div class="mat-error error-age " *ngIf="form.get('phone').errors?.someProp">
                                {{form.get('phone').errors?.someProp}}</div>
                        </mat-form-field>
                        <div class="marks position-absolute">
                            <i *ngIf="spinner2" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
                            <i *ngIf="right2" class="fa fa-check ml-2"></i>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="egyptCase">
                        <mat-form-field appearance="fill">
                            <mat-label class="star" translate>home.national-number</mat-label>
                            <input matInput placeholder="SSN" type="text" formControlName="ssn" [(ngModel)]="ssn"
                                (focusout)="checkSsn()">
                            <div class="mat-error error-age" *ngIf="form.get('ssn').errors?.pattern">Please Type Numbers
                                Only.
                            </div>
                        </mat-form-field>
                        <div class="marks position-absolute">
                            <i *ngIf="spinnerSsn" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
                            <i *ngIf="rightSsn" class="fa fa-check ml-2"></i>
                        </div>
                    </div>
                    <div class="col-12 offset-md-1">
                        <h4 translate>home.Serve</h4>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="fill">
                            <mat-label class="star" translate>home.Current-address</mat-label>
                            <input matInput placeholder="John" type="text" formControlName="address">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="fill">
                            <mat-label class="star" translate>home.country</mat-label>
                            <input matInput placeholder="Pick country" [matAutocomplete]="country" type="text"
                                formControlName="country">
                            <mat-autocomplete autoActiveFirstOption #country="matAutocomplete"
                                (optionSelected)='getPosts($event.option.value)'>
                                <mat-option *ngFor="let option of filteredCountries | async" [value]="option.name">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="fill">
                            <mat-label class="" translate>home.job</mat-label>
                            <input matInput placeholder="Join" type="text" formControlName="job">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="fill">
                            <mat-label class="" translate>home.age</mat-label>
                            <input matInput placeholder="Join" type="number" formControlName="age">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="fill" (click)="join_date.open()">
                            <mat-label class="star" translate>home. date-of-join</mat-label>
                            <input matInput placeholder="Due Date" (dateChange)="checkDate($event)"
                                [matDatepicker]="join_date" formControlName="join_date">
                            <mat-datepicker-toggle matSuffix [for]="join_date"></mat-datepicker-toggle>
                            <mat-datepicker #join_date></mat-datepicker>
                            <div class="mat-error error-age" *ngIf="form.get('join_date').errors?.someProp">
                                {{form.get('join_date').errors?.someProp}}</div>
                            <div class="mat-error error-age"
                                *ngIf="form.get('join_date').errors?.required && form.get('join_date').errors?.touched">
                                Please
                                enter a Joined Date
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-12 " *ngIf="egyptCase">
                        <div class="item d-flex border-dashed justify-content-center">
                            <button translate>home.profile-picture
                                <i class="fas fa-image"></i>
                            </button>
                            <input id="pic" type="file" #selectFile formControlName="image"
                                (change)="onFileSelected($event.target.files)" accept="image/*">
                            <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah"></div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="egyptCase">
                        <div class="item d-flex border-dashed justify-content-center">
                            <button translate>
                                <i class="fas fa-image"></i>
                                home.front-side
                            </button>
                            <input id="pic" type="file" #selectFile formControlName="image_front"
                                (change)="onFileSelectedFront($event.target.files)" accept="image/*">
                            <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah1"></div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="egyptCase">
                        <div class="item d-flex border-dashed justify-content-center">
                            <button translate>
                                <i class="fas fa-image"></i>
                                home.back-side
                            </button>
                            <input id="pic" type="file" #selectFile formControlName="image_back"
                                (change)="onFileSelectedBack($event.target.files)" accept="image/*">
                            <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah2"></div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="egyptCase">
                        <div class="item d-flex border-dashed justify-content-center">
                            <button translate>
                                <i class="fas fa-image"></i>
                                home.criminal-chip
                            </button>
                            <input id="pic" type="file" #selectFile formControlName="image_clearance"
                                (change)="onFileSelectedClearance($event.target.files)" accept="image/*">
                            <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah3"></div>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button class="btn submit-btn" [disabled]="form.status == 'INVALID'" (click)="close()"
                            translate>home.submit</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div> -->

<div [dir]="lang == 'ar' ? 'rtl':'ltr'">
    <!-- <div class="mt-4">
        <div class="col-3 mb-4">
            <i class=" fa fa-close" mat-dialog-close=""> </i>
        </div>
    </div> -->
    <div class="container">
        <div class="mt-1">
          <div class="col-12 mb-5">
            <mat-dialog-actions align="end">
              <button class="close"  mat-button mat-dialog-close>X</button>
            </mat-dialog-actions>
          </div>
        </div>
      </div>

    <div class="mb-4 ">
        <div class="progress">
            <!-- ID of prBar (By default width is 25%) -->
            <div id="prgBar" class="progress-bar" [style.width.%]="step"></div>
        </div>
    </div>

    <mat-horizontal-stepper class="justify-content-center" #stepper>
        <mat-step [stepControl]="0">
            <form [formGroup]="firstFormGroup">
                <div class="row g-3">

                    <div class="col-sm-12 my-2">
                        <label for="fullName" class="form-label" translate>home.full-name</label>
                        <input type="text" class="form-control" formControlName="fullName_en"
                            [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                            [ngClass]="{ 'is-invalid': ( firstFormGroup.get('fullName_en')?.dirty && firstFormGroup.get('fullName_en')?.invalid) || (firstFormGroup.get('fullName_en')?.dirty && firstFormGroup.get('fullName_en')?.errors?.required ) ,'is-valid': ( firstFormGroup.get('fullName_en')?.dirty && firstFormGroup.get('fullName_en')?.valid)}"
                            placeholder="">
                        <div class="invalid-feedback">
                            Valid full name is required.
                        </div>
                    </div>


                    <div class="col-sm-12  my-2">
                        <label for="lastName" class="form-label" translate>home.Current-address</label>
                        <input
                            [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                            [ngClass]="{ 'is-invalid': ( firstFormGroup.get('address')?.dirty && firstFormGroup.get('address')?.invalid) || (firstFormGroup.get('address')?.dirty && firstFormGroup.get('address')?.errors?.required ) ,'is-valid': ( firstFormGroup.get('address')?.dirty && firstFormGroup.get('address')?.valid)}"
                            type="text" class="form-control" formControlName="address" placeholder="">
                        <div class="invalid-feedback">
                            Valid address is required.
                        </div>
                    </div>


                    <div class="col-sm-12 my-2">
                        <label for="address" class="form-label" translate>home.age</label>
                        <input
                            [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                            [ngClass]="{ 'is-invalid': ( firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.invalid) || (firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.errors?.required ) ,'is-valid': ( firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.valid)}"
                            type="text" class="form-control" placeholder="" formControlName="age">
                        <div class="invalid-feedback"
                            *ngIf="firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.errors?.pattern">
                            Please enter only number.
                        </div>
                        <div class="invalid-feedback"
                            *ngIf="firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.errors?.required">
                            Please enter your shipping address.
                        </div>
                    </div>

                    <div class="col-sm-12 my-2">
                        <label for="country" class="form-label" translate>home.country</label>
                        <div class="position-relative">
                            <input
                                [style.backgroundPosition]=" lang == 'ar'?'left calc(1.375em + 0.1875rem) center':'right calc(1.375em + 0.1875rem) center'"
                                [ngClass]="{ 'is-invalid': (firstFormGroup.get('country')?.dirty && firstFormGroup.get('country')?.errors?.required ) ,'is-valid': ( firstFormGroup.get('country')?.dirty && firstFormGroup.get('country')?.valid)}"
                                style="cursor: pointer" class="form-control" ng-model="myModel"
                                [matAutocomplete]="country" type="text" formControlName="country" />
                                <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="firstFormGroup.get('country')?.dirty && firstFormGroup.get('country')?.errors?.required">
                                Please enter purpose.
                            </div>
                        <mat-autocomplete (optionSelected)='getPosts($event.option.value)' #country="matAutocomplete">
                            <mat-option *ngFor="let country of filteredCountries | async" [value]="country.name">
                                {{country.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
                <div class="submitBtn mt-4">
                    <button mat-button matStepperNext class="btn btn-success submitBtn"
                         (click)="step=45"><span translate>home.next</span></button>
<!--                  [disabled]="firstFormGroup.invalid"-->
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="1">
            <form [formGroup]="secondFormGroup">
                <div class="row g-3">

                    <div class="col-sm-12 my-2">
                        <label for="email" class="form-label" translate>home.email</label>
                        <input
                            [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                            [ngClass]="{ 'is-invalid': (secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.errors?.required ) || (secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.errors?.email ) ,'is-valid': ( secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.value != '')}"
                            type="text" class="form-control" formControlName="email" placeholder="">
                        <div class="invalid-feedback"
                            *ngIf="secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.errors?.required">
                            Email is required.
                        </div>
                        <div class="invalid-feedback"
                            *ngIf="secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.errors?.email">
                            Email is invalid.
                        </div>
                    </div>
                    <div class="col-sm-12 my-2">
                        <label for="phone" class="form-label" translate>home.phone-number</label>
                        <input
                            [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                            [ngClass]="{ 'is-invalid': ( secondFormGroup.get('phone')?.dirty && secondFormGroup.get('phone')?.errors?.pattern) || (secondFormGroup.get('phone')?.dirty && secondFormGroup.get('phone')?.errors?.required ) ,'is-valid': ( secondFormGroup.get('phone')?.dirty && secondFormGroup.get('phone')?.valid)}"
                            type="phone" class="form-control" formControlName="phone" placeholder="">
                        <div class="invalid-feedback">
                            Please enter a valid phone address for shipping updates.
                        </div>
                    </div>
                    <div class="col-sm-12 my-2" *ngIf="egyptCase">
                        <label for="National-ID" class="form-label" translate>home.National-ID</label>
                        <input
                            [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                            [ngClass]="{ 'is-invalid':(secondFormGroup.get('ssn')?.dirty && (secondFormGroup.get('ssn')?.errors?.maxlength || secondFormGroup.get('ssn')?.errors?.minlength)) || (secondFormGroup.get('ssn')?.dirty && secondFormGroup.get('ssn')?.errors?.pattern) || (secondFormGroup.get('ssn')?.dirty && secondFormGroup.get('ssn')?.errors?.required ) ,'is-valid': ( secondFormGroup.get('ssn')?.dirty && secondFormGroup.get('ssn')?.valid)}"
                            type="text" class="form-control" placeholder="" formControlName="ssn">
                        <div class="invalid-feedback"
                            *ngIf="secondFormGroup.get('ssn')?.dirty && secondFormGroup.get('ssn')?.errors?.required">
                            The national ID is invalid.
                        </div>
                        <div class="invalid-feedback"
                            *ngIf="secondFormGroup.get('ssn')?.dirty && (secondFormGroup.get('ssn')?.errors?.maxlength || secondFormGroup.get('ssn')?.errors?.minlength)">
                            The national ID is invalid, it must consist of 14 digits.
                        </div>
                        <div class="invalid-feedback"
                            *ngIf="secondFormGroup.get('ssn')?.dirty && secondFormGroup.get('ssn')?.errors?.pattern">
                            Please enter only number.
                        </div>
                    </div>

                </div>
                <div class="submitBtn my-3">
                    <button mat-button matStepperNext class="btn btn-success submitBtn"
                         (click)="step=70"><span translate>home.next</span></button>
<!--                  [disabled]="secondFormGroup.invalid"-->
                </div>
                <div class="submitBtn ">
                    <button mat-button matStepperPrevious (click)="step = 20" class="btn btn-primary submitBtn"><span
                            translate>home.back</span></button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="2">
            <form [formGroup]="thirdFormGroup">
                <!-- <div class="col-12"> -->
                <div class="row g-3">
                    <div class="col-sm-12 my-2">
                        <label for="address" class="form-label" translate>home.clubs</label>
                        <div class="position-relative">
                          <input
                          [style.backgroundPosition]=" lang == 'ar'?'left calc(1.375em + 0.1875rem) center':'right calc(1.375em + 0.1875rem) center'"
                            [ngClass]="{ 'is-invalid': (thirdFormGroup.get('club_id')?.dirty && thirdFormGroup.get('club_id')?.errors?.required ) ,'is-valid': ( thirdFormGroup.get('club_id')?.dirty && thirdFormGroup.get('club_id')?.valid)}"

                          style="cursor: pointer" class="form-control" ng-model="myModel" [matAutocomplete]="clubs" type="text"
                            formControlName="club_id" />
                            <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
                        </div>
                        <div class="invalid-feedback" *ngIf="thirdFormGroup.get('club_id')?.dirty && thirdFormGroup.get('club_id')?.errors?.required">
                          Please enter club.
                        </div>
                        <mat-autocomplete autoActiveFirstOption #clubs="matAutocomplete">
                          <mat-option *ngFor="let club of filteredClubs | async" [value]="club.name">
                            {{club.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    <div class="col-sm-12 my-2">
                        <label for="fullName" class="form-label" translate>home.job</label>
                        <input type="text" class="form-control" formControlName="job"
                            [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                            [ngClass]="{ 'is-invalid': ( thirdFormGroup.get('job')?.dirty && thirdFormGroup.get('job')?.invalid) || (thirdFormGroup.get('job')?.dirty && thirdFormGroup.get('job')?.errors?.required ) ,'is-valid': ( thirdFormGroup.get('job')?.dirty && thirdFormGroup.get('job')?.value != '')}"
                            placeholder="">
                        </div>
                    <div class="col-sm-12 my-2">
                        <label for="fullName" class="form-label" translate>home.date-of-join</label>
                        <div class="position-relative">

                            <input type="text" class="form-control" [matDatepicker]="join_date"
                                (dateChange)="checkDate($event)" formControlName="join_date"
                                [style.backgroundPosition]=" lang == 'ar'?'left calc(2.375em + 0.1875rem) center':'right calc(2.375em + 0.1875rem) center'"
                                [ngClass]="{ 'is-invalid': ( thirdFormGroup.get('join_date')?.dirty && thirdFormGroup.get('join_date')?.invalid) || (thirdFormGroup.get('join_date')?.dirty && thirdFormGroup.get('join_date')?.errors?.required ) ,'is-valid': ( thirdFormGroup.get('join_date')?.dirty && thirdFormGroup.get('join_date')?.valid)}"
                                placeholder="">
                            <mat-datepicker-toggle [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}" matSuffix
                                [for]="join_date"></mat-datepicker-toggle>
                            <mat-datepicker #join_date></mat-datepicker>
                        </div>
                        <div class="invalid-feedback">
                            Valid join date is required.
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <div class="submitBtn mt-3">
                    <button mat-button matStepperNext class="btn btn-success submitBtn"
                         (click)="step = 100"><span
                            translate>home.next</span></button>
<!--                  [disabled]="thirdFormGroup.invalid"-->
                </div>
                <div class="submitBtn my-3">
                    <button mat-button matStepperPrevious (click)="step = 45" class="btn btn-primary submitBtn"><span
                            translate>home.back</span></button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="3">
            <form [formGroup]="ForthFormGroup">
                <!-- <ng-template matStepLabel><h1 translate>home.third</h1> </ng-template> -->
                <div class="row g-3">

                    <div class="col-12 mb-2 ">
                        <div class="item d-flex border-dashed justify-content-center flex-column">
                            <button translate *ngIf="!volunteerImag">home.profile-picture
                                <i class="fas fa-image"></i>
                            </button>
                            <input id="pic" type="file" #selectFile formControlName="image"
                                (change)="onFileSelected($event.target.files)" accept="image/*">
                            <div class="image-after-btn" (click)="selectFile.click()"><img alt="" id="blah"></div>
                        </div>
                    </div>
                    <!-- <div class="col-12 mb-2" *ngIf="egyptCase">
                        <div class="item d-flex border-dashed justify-content-center">
                            <button translate>
                                home.front-side
                                <i class="fas fa-image"></i>
                            </button>
                            <input id="pic" type="file" #selectFile formControlName="image_front"
                                (change)="onFileSelectedFront($event.target.files)" accept="image/*">
                            <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah1"></div>
                        </div>
                    </div> -->
                    <!-- <div class="col-12 mb-2" *ngIf="egyptCase">
                        <div class="item d-flex border-dashed justify-content-center">
                            <button translate>
                                home.back-side
                                <i class="fas fa-image"></i>
                            </button>
                            <input id="pic" type="file" #selectFile formControlName="image_back"
                                (change)="onFileSelectedBack($event.target.files)" accept="image/*">
                            <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah2"></div>
                        </div>
                    </div> -->
                    <!-- <div class="col-12 mb-2" *ngIf="egyptCase">
                        <div class="item d-flex border-dashed justify-content-center">
                            <button translate>
                                home.criminal-chip
                                <i class="fas fa-image"></i>
                            </button>
                            <input id="pic" type="file" #selectFile formControlName="image_clearance"
                                (change)="onFileSelectedClearance($event.target.files)" accept="image/*">
                            <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah3"></div>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="submitBtn mt-5 my-3">
                    <button mat-button class="btn btn-success" [disabled]="ForthFormGroup.invalid" (click)="close()">
                        <span translate>home.submit</span>
                        <div *ngIf="loading" class="spinner-border m-2 text-light" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
                <div class="submitBtn">
                    <button mat-button matStepperPrevious (click)="step = 70" class="btn btn-primary "><span
                            translate>home.back</span></button>
                </div> -->


                <div class="">
                    <div class="submitBtn mt-5">
                      <button mat-button class="btn btn-success submitBtn" [disabled]="ForthFormGroup.invalid" (click)="close()">
                        <span translate>home.submit</span>
                        <div *ngIf="loading" class="spinner-border m-2 text-light" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                    <div class="submitBtn mt-3">
                      <button mat-button matStepperPrevious (click)="step = 70" class="submitBtn btn btn-primary "><span
                        translate>home.back</span></button>
                    </div>
                  </div>

            </form>
        </mat-step>
    </mat-horizontal-stepper>
</div>
                                    