<img class="img" style="width: 100%; margin-bottom: 4px;" src="assets/images/gray-plane-wing-62623.png">

<section>
  <div class="container-fluid direction-rtl">
    <div class="row">
      <div class="profile-header">
        <div class="profile-img">
          <!-- <img *ngIf="profile"  src="{{profile.image}}" width="200" alt="Profile Image"> -->
          <!-- <img *ngIf="!profile"  src="../../assets/images/avatar/user-anonymous.png" width="200" alt="Profile Image"> -->
        </div>
        <div class="profile-nav-info">
          <div class="memName d-flex" *ngIf="profile">
            <h3 class="user-name">{{profile.fullname_en}} </h3>
            <div class="position-absolute justify-content-center ">
              <a style="text-decoration: none; margin-left: 50em; ">
                <button class="projects-btn" routerLink="/add-club" translate>home.create-club</button>
              </a>
            </div>
          </div>
          <div class="address">
            <p id="state" class="state" *ngIf="profile">{{profile.job}}</p>
          </div>
        </div>
        <!--        <div class="profile-option">-->
        <!--          <div class="notification">-->
        <!--            <i class="fa fa-bell"></i>-->
        <!--            <span class="alert-message">3</span>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="main-bd">
        <div class="left-side">
          <div class="profile-side direction-ltr text-left" *ngIf="profile">
            <p class="mobile-no"><i class="bi bi-phone-fill"></i>{{profile.phone}}</p>
            <p class="user-mail"><i class="bi bi-envelope-fill"></i>{{profile.email}}</p>
            <p class=" text-justify"><i class="bi bi-house-door-fill"></i>{{profile.address}}</p>
            <ul class="social-icons">
              <li class="facebook" *ngIf="profile.facebook">
                <a href="{{profile.facebook}}">
                  <i class="bi bi-facebook"></i>
                </a>
              </li>
              <li class="twitter" *ngIf="profile.twitter">
                <a href="{{profile.twitter}}">
                  <i class="bi bi-twitter"></i>
                </a>
              </li>
              <li class="linkedin" *ngIf="profile.linkedin">
                <a href="{{profile.linkedin}}">
                  <i class="bi bi-linkedin"></i>
                </a>
              </li>
              <li class="instagram" *ngIf="profile.instagram">
                <a href="{{profile.instagram}}">
                  <i class="bi bi-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <!-- <div class="col-4">
              <div class="row justify-content-center dst-img">
                <ng-container *ngFor="let item of clubMembers ; let i = index">
                  <div class="column" *ngIf="i<4 && i>1">
                    <div class="card bord"
                         (click)="memberDetails(item.id)"> -->
            <!--                    <img src="{{item.image}}">-->
            <!-- <img *ngIf="!item.image" class="circle" width="180" src="./assets/images/avatar/user-anonymous.png">
                      <img *ngIf="item.image"  style="border-radius: 50%; width: 180px"  src="{{item.image}}">
                      <h5 class="tit">{{item.fullname}}</h5>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="d-flex justify-content-center mt-4" >
                <a style="text-decoration: none">
                  <button class="projects-btn" routerLink="/club-members" translate>home.all-members</button>
                </a>
              </div>
            </div> -->
            <div class="col-12">
              <div class="right-side w-100 direction-ltr">
                <div class="nav" class="w-100">
                  <mat-tab-group class="w-100 memBio">
                    <div class="action">
                      <mat-tab class="bio w-100" label="{{ 'home.member-bio' | translate }}" ng-model="myModel">

                        <span class="member-bio" *ngIf="profile">{{profile.bio}}</span>

                      </mat-tab>
                    </div>
                    <mat-tab label="{{ 'home.donation' | translate }}" ng-model="myModel">
                      <table class="donate table table-hover table-bordered mt-3">
                        <thead>
                          <tr>
                            <th scope="col" translate>home.donor-name</th>
                            <th scope="col" translate>home.gross</th>
                            <th scope="col" translate>home.fee</th>
                            <th scope="col" translate>home.amount</th>
                            <th scope="col" translate>home.purpose</th>
                            <th scope="col" translate>home.date</th>
                            <th scope="col" class="action" translate>home.actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of mydonations">
                            <th scope="row">{{item.donor_name.name}}</th>
                            <td>{{item.gross | currency}}</td>
                            <td>{{item.fee | currency}}</td>
                            <td>{{item.amount | currency}}</td>
                            <td>{{item.purpose.name.slice(0,30)}}...</td>
                            <td>{{item.date | date}}</td>
                            <td class="action">
                              <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu"
                                style="border: none;background:none;height: 35px;width: 35px;">
                                <mat-icon>more_vert</mat-icon>
                              </button>
                            </td>
                            <mat-menu #menu="matMenu">
                              <!--                            <button mat-menu-item  (click)="openPopUp(item)">-->
                              <!--                              <mat-icon>-->
                              <!--                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit ml-3 mb-3">-->
                              <!--                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>-->
                              <!--                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>-->
                              <!--                                </svg>-->
                              <!--                              </mat-icon>-->
                              <!--                              <span >details</span>-->
                              <!--                            </button>-->
                              <!--                            <button mat-menu-item  (click)="openDonation(item)">-->
                              <!--                              <mat-icon>-->
                              <!--                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-printer ml-3 mb-3" viewBox="0 0 16 16">-->
                              <!--                                  <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>-->
                              <!--                                  <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>-->
                              <!--                                </svg>-->
                              <!--                              </mat-icon>-->
                              <!--                              <span >Print</span>-->
                              <!--                            </button>-->
                              <button mat-menu-item (click)="openDonation(item)">
                                <mat-icon>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-edit ml-3 mb-3">
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg>
                                </mat-icon>
                                <span translate>home.details</span>
                              </button>
                            </mat-menu>
                          </tr>
                        </tbody>
                      </table>
                      <!--                      <div class="px-3 d-flex justify-content-center mt-2">-->
                      <!--                        <a class="btn btn-outline-secondary hide-print btn-block"-->
                      <!--                           href="javascript:window.print();"-->
                      <!--                           rippleEffect-->
                      <!--                        >-->
                      <!--                          Print-->
                      <!--                        </a>-->
                      <!--                      </div>-->
                    </mat-tab>
                    <mat-tab class="certificates" label="{{ 'home.Certificates' | translate }}" ng-model="myModel"
                      *ngIf="user?.role == 'volunteer'">
                      <ng-container>
                        <div class="row">
                          <div class="col-lg-4 ml-4" *ngFor="let item of Certificates">
                            <button mat-button class="animated-button1"
                              (click)="openDialog(item)">{{item.activity.name.slice(0,20)}}...</button>
                          </div>
                        </div>
                      </ng-container>
                    </mat-tab>
  
                    <div class="action">
                      <mat-tab label="{{ 'home.settings' | translate }}" ng-model="myModel">
                        <form class="source-item sr7-w" [formGroup]="settingForm">
                          <div class="container">
                            <div class="row">
                              <div class=" col-lg-6 col-12  mt-2">
                                <!-- <p class="" translate>home.Full-Name</p>
                                <input
                                  type="text"
                                  class="form-control settings"
                                  name="fullname_en"
                                  placeholder="{{ 'home.fullname' | translate }}" ng-model="myModel"
                                  formControlName="fullname_en"
                                /> -->

                                <label translate>home.Full-Name</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="text" formControlName="fullname_en">
                                </mat-form-field>


                              </div>
                              <div class="col-lg-6 col-12  mt-2">
                                <!-- <p class="" translate>home.address</p>
                                <input
                                  type="text"
                                  class="form-control settings"
                                  name="address"
                                  placeholder="{{ 'home.address-placeholder' | translate }}" ng-model="myModel"
                                  formControlName="address"
                                /> -->

                                <label translate>home.address</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="text" formControlName="address">
                                </mat-form-field>
                              </div>
                              <div lass="col-lg-6 col-12  mt-2">
                                <p class="" translate>home.date-of-birth</p>
                                <mat-form-field class="w-100" appearance="fill" (click)="birth_date.open()">
                                  <mat-label class="" translate>home.date-of-birth</mat-label>
                                  <input matInput placeholder="Due Date" (dateChange)="checkDate($event)"
                                    [matDatepicker]="birth_date" formControlName="birth_date">
                                  <mat-datepicker-toggle matSuffix [for]="birth_date"></mat-datepicker-toggle>
                                  <mat-datepicker #birth_date></mat-datepicker>
                                  <div class="mat-error error-age"
                                    *ngIf="settingForm.get('birth_date').errors?.someProp">
                                    {{settingForm.get('birth_date').errors?.someProp}}</div>
                                  <div class="mat-error error-age"
                                    *ngIf="settingForm.get('birth_date').errors?.required && settingForm.get('birth_date').errors?.touched">
                                    Please
                                    enter a Birth Date
                                  </div>
                                </mat-form-field>
                              </div>
                              <div class="col-lg-6 col-12 mt-2">
                                <p class="" translate>home.profile-picture</p>

                                <div class="item d-flex border-dashed">
                                  <button translate>home.choose-file
                                    <i class="fas fa-image"></i>
                                  </button>
                                  <input id="pic" type="file" #selectFile formControlName="image"
                                    (change)="onFileSelected($event.target.files)" accept="image/*">
                                  <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah"></div>
                                </div>
                              </div>
                              <div class=" col-lg-6 col-12 mt-2">
                                <!-- <p class="" translate>home.job</p>
                                <input
                                  type="text"
                                  class="form-control settings"
                                  name="job"
                                  placeholder="{{ 'home.job-placeholder' | translate }}" ng-model="myModel"
                                  formControlName="job"
                                /> -->

                                <label translate>home.job</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="text" formControlName="job">
                                </mat-form-field>

                              </div>
                              <div class="col-lg-6 col-12 mt-2">
                                <!-- <p class="" translate>home.phone</p>
                                <input
                                  type="text"
                                  class="form-control settings"
                                  name="phone"
                                  placeholder="{{ 'home.phone-placeholder' | translate }}" ng-model="myModel"
                                  formControlName="phone"
                                /> -->
                                <label translate>home.phone</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="text" formControlName="phone">
                                </mat-form-field>

                              </div>
                              <div class="col-lg-6 col-12 mt-2">
                                <!-- <p class="" translate>home.email</p>
                                <input
                                  type="text"
                                  class="form-control settings"
                                  name="email"
                                  placeholder="{{ 'home.email-placeholder' | translate }}" ng-model="myModel"
                                  formControlName="email"
                                /> -->
                                <label translate>home.email</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="text" formControlName="email">
                                </mat-form-field>

                              </div>
                              <div class="col-lg-6 col-12 mt-2">
                                <!-- <p class="" translate>home.facebook-link</p>
                                <input
                                  type="url"
                                  class="form-control settings"
                                  name="facebook"
                                  placeholder="{{ 'home.link' | translate }}" ng-model="myModel"
                                  formControlName="facebook"
                                /> -->
                                <label translate>home.facebook-link</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="url" formControlName="facebook">
                                </mat-form-field>

                              </div>
                              <div class="col-lg-6 col-12 mt-2">
                                <!-- <p class="" translate>home.twitter-link</p>
                                <input
                                  type="url"
                                  class="form-control settings"
                                  name="twitter"
                                  placeholder="{{ 'home.link' | translate }}" ng-model="myModel"
                                  formControlName="twitter"
                                /> -->
                                <label translate>home.twitter-link</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="url" formControlName="twitter">
                                </mat-form-field>

                              </div>
                              <div class="col-lg-6 col-12 mt-2">
                                <!-- <p class="" translate>home.linkedin-link</p>
                                <input
                                  type="url"
                                  class="form-control settings"
                                  name="linkedin"
                                  placeholder="{{ 'home.link' | translate }}" ng-model="myModel"
                                  formControlName="linkedin"
                                /> -->
                                <label translate>home.linkedin-link</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="url" formControlName="linkedin">
                                </mat-form-field>

                              </div>
                              <div class="col-lg-6 col-12 mt-2">
                                <!-- <p class="" translate>home.instagram-link</p>
                                <input
                                  type="url"
                                  class="form-control settings"
                                  name="instagram"
                                  placeholder="{{ 'home.link' | translate }}" ng-model="myModel"
                                  formControlName="instagram"
                                /> -->
                                <label translate>home.instagram-link</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="url" formControlName="instagram">
                                </mat-form-field>

                              </div>
                              <div class="col-lg-6 col-12 mt-2">
                                <!-- <p class="" translate>home.instagram-link</p>
                                <input
                                  type="url"
                                  class="form-control settings"
                                  name="instagram"
                                  placeholder="{{ 'home.link' | translate }}" ng-model="myModel"
                                  formControlName="instagram"
                                /> -->
                                <label translate>home.national_id</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="url" formControlName="national_id">
                                </mat-form-field>

                              </div>
                              <div class="col-lg-12 col-12 mt-2">
                                <!-- <p class="" translate>home.bio</p> -->
                                <!-- <textarea
                                  class="form-control settings"
                                  id="text4"
                                  name="bio"
                                  placeholder="{{ 'home.add-bio' | translate }}" ng-model="myModel"
                                  rows="6"
                                  formControlName="bio"
                                >
                          </textarea> -->
                                <label translate>home.bio</label>
                                <mat-form-field appearance="fill" class="input w-100">
                                  <input matInput type="text" formControlName="bio">
                                </mat-form-field>


                              </div>
                            </div>
                          </div>
                        </form>
                        <div class="col-12 px-0 ">
                          <div class="col-12 px-0 d-flex justify-content-end">
                            <button type="button" class="btn btn-primary sr7-m"
                              style="margin-right: 38px; margin-top: 16px;width: 120px;height: 40px; font-size: 20px;background: #9d2121;border: none;"
                              (click)="submit()" rippleEffect>
                              <i data-feather="check" class="mr-25"></i>
                              <span class="align-middle" translate>home.save</span>
                            </button>
                          </div>
                        </div>
                      </mat-tab>
                    </div>
                  </mat-tab-group>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
