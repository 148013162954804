<div class="container">
<!--  <mat-tab-group>-->
<!--    <mat-tab label="First">-->
      <div class="row mt-5 mb-5">
<!--        <div class="col-md-3" >-->
<!--          <div class="post" tabindex="0">-->
<!--            <div class="photo"-->
<!--                 style="background-image: url('https://www.w3schools.com/w3images/team2.jpg')">-->
<!--              <div class="overlay text-left"><a class="label">Allison Walker</a></div>-->
<!--            </div>-->
<!--            <div class="description">-->
<!--              <h3 class="text-center mb-3">Allison Walker</h3>-->
<!--              <h6 class="text-center">Member</h6>-->
<!--              <p class="post_description d-block">Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.</p></div>-->
<!--            <div class="mb-3">-->
<!--              <button class="btn btn-outline-success mr-2" routerLink="/memberDetails">More Info <i class="fa fa-arrow-right ml-1"></i></button>-->
<!--            </div>-->
<!--            <footer class="footer-card">-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--        </div>-->
        <div class="col-md-3 col-sm-6" *ngFor="let item of members">
          <div class=" card card-two">
            <header>
              <div class="avatar">
                <img src="{{item.image}}">
              </div>
            </header>

            <h3>{{item.fullname}}</h3><h6 class="text-center">Member</h6>
            <div class="desc">
              {{item.bio}}
            </div>
            <div class="contacts">
              <a href="{{item.phone}}"><i class="fa fa-plus"></i></a>
              <a href="{{item.phone}}"><i class="fa fa-whatsapp"></i></a>
              <a href="{{item.email}}"><i class="fa fa-envelope"></i></a>
              <div class="clear"></div>
            </div>
            <div class="mb-3">
              <button class="btn btn-outline-success mr-2" (click)="memberDetails(item.id)" >More Info <i class="fa fa-arrow-right ml-1"></i></button>
            </div>
            <footer>
              <p>
                <a href=""><i class="fa fa-facebook"></i></a>
                <a href=""><i class="fa fa-linkedin"></i></a>
                <a href=""><i class="fa fa-twitter"></i></a>
                <a href=""><i class="fa fa-instagram"></i></a>
              </p>
            </footer>
          </div>
          <div class="clear"></div>
        </div>
      </div>
<!--    </mat-tab>-->
<!--    <mat-tab label="Second">-->
<!--      <div class="row mt-3">-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->
<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--        <div class="col-md-3 col-sm-6">-->
<!--          <div class=" card card-two">-->
<!--            <header>-->
<!--              <div class="avatar">-->
<!--                <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="Allison Walker"/>-->
<!--              </div>-->
<!--            </header>-->

<!--            <h3>Allison Walker</h3><h6 class="text-center">Member</h6>-->
<!--            <div class="desc">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.-->
<!--            </div>-->
<!--            <div class="contacts">-->
<!--              <a href=""><i class="fa fa-plus"></i></a>-->
<!--              <a href=""><i class="fa fa-whatsapp"></i></a>-->
<!--              <a href=""><i class="fa fa-envelope"></i></a>-->
<!--              <div class="clear"></div>-->
<!--            </div>-->

<!--            <footer>-->
<!--              <p>-->
<!--                <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                <a href=""><i class="fa fa-instagram"></i></a>-->
<!--              </p>-->
<!--            </footer>-->
<!--          </div>-->
<!--          <div class="clear"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </mat-tab>-->
<!--  </mat-tab-group>-->
</div>
