<!-- start navbar -->
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">
  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center Font-Family ">Privacy Policy</h1>
      
      
    </div>
  </div>
  <!-- start skeleton preloader -->
  <div class="help-center-body" *ngIf="!privacyPolicyExist">
    <!-- start search-results -->
    <div class="container">
      <div class="search-results">
        <div class="search-results-header Font-Family">
          <h4 class="Font-Family">
            <ngx-skeleton-loader
              count="1"
              [theme]="{ width: '200px' }"
            ></ngx-skeleton-loader>
          </h4>
        </div>

        <div class="search-results-body">
          <div class="item Font-Family">
            <ngx-skeleton-loader
              count="4"
              [theme]="{
                width: '100%',
                height: '15px',
                'margin-bottom': '0px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
    <!-- end search-results -->  
  </div>
  <!-- ======= Help Section ======= -->
 <section id="help" class="help section-bg mt-5"> 
  <div class="container " data-aos="fade-up">

    <div class="section-title mt-5">
      <h2 *ngIf="privacyPolicyExist" >Privacy Policy</h2>
    </div>
    <div>
      <span class="mt-5 pt-3" style="font-size: 25px; padding: 10px; font-weight: 500;" *ngIf="privacyPolicyExist"> - If you have any issues or complaints, please contact us at<span class="bolder mx-1" style="font-weight: 700;">+1 212-381-1167</span>  or email <span class="bolder mx-1" style="font-weight: 700;">support@flyallover.com</span> </span>
      <p class="mb-3 " style="font-size: 25px; padding: 10px; font-weight: 500;" *ngIf="privacyPolicyExist"> - We do not share any personal information with third parties</p>
    </div>

    <!-- <div>
      <p class="mt-5 pt-3" style="font-size: 25px; padding: 10px; font-weight: 500;" [innerHTML]="privacyPolicy.description"></p>
    </div> -->

    <!-- --------------------------------------------------new --------------------------------------------// -->
    <!-- <div class="row icon-box " >
        <div class=" col-4 mt-4" data-aos="zoom-in" data-aos-delay="100" *ngFor="let item of privacyPolicy.details; let i = index"> 
           <li><a class="linkHandel" href="" (click)="scrollToElement(item.id)" >{{item.title}}</a></li>
        </div>
      </div> -->
    
   

    <div class="row">
      <div class="col-xl-12 col-md-12 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100" *ngFor="let item of privacyPolicy.details; let i = index" >
        <div class="icon-box" (click)="privacyDetails(item.id)" >
          <h4><a>{{item.title}}</a></h4>
          <p [innerHTML]="item.description"> </p>
        </div>
      </div>
     
    </div>

  </div>
</section><!-- End Help Section -->
 
</div>
