<nav class="navbar navbar-expand-lg navbar-light white-nav justify-content-between mobileFixPadding">
  <div class="container-fluid">
    <div class="collapse navbar-collapse justify-content-center" id="navbarText">
      <div class="d-flex flex-column align-items-center">
        <div class="nav-item logo">
          <a class=" nav-link-image" routerLink="/">
            <img alt="" src="../../../assets/images/haya/HAYA%20KARIMA%20FOUNDATION.png">
          </a>
        </div>
        <ul class="navbar-nav pr-0">
          <li class="nav-item active">
            <a class="nav-link" routerLinkActive="underline-decoration"
               [routerLinkActiveOptions]="{exact:true}" routerLink="/">Home <span class="sr-only">(current)</span></a>
          </li>

        </ul>
      </div>
    </div>
  </div>
</nav>
