<h1 class="header mt-5" translate>home.activities</h1>
<!-- ======= Events Section ======= -->
<div class="event">
<ngb-carousel class="py-5" [interval]="9000">
  <ng-template ngbSlide  *ngFor="let item of allEvents?.activities">
    <section id="events" class="events" >
      <div class="container" data-aos="fade-up">
        <div class="section-title mb-4">
          <h2 translate>home.activities</h2>
<!--          <p>{{item.name}}</p>-->
        </div>
        <div class="events-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <div class="row event-item">
                <div class="col-lg-6">
                  <img *ngIf="item.image"  src="{{item.image}}" class="handel-img" alt="{{item.name}}">
                </div>
                <div class="col-lg-6 pt-4 pt-lg-0 content">
                  <div class="section-title">
                    <p>{{item.name}}</p>
                  </div>
                  <div class="price mb-2 mt-4">
                    <p>{{timeFormat(item.start_at) }} - {{timeFormat(item.end_at)}}</p>
                   <p>{{item.start_date | date :'d  MMM  yyy'}}</p>
                    <p>{{item.address}} </p>
                    <span></span>
                  </div>
                  <p class="fst-italic handel-des mt-2">
                    {{item.excerpt}}
                  </p>
<!--                  <ul>-->
<!--                    <li><i class="bi bi-check-circled"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>-->
<!--                    <li><i class="bi bi-check-circled"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>-->
<!--                    <li><i class="bi bi-check-circled"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>-->
<!--                  </ul>-->
                  <div class="mt-4">
                  <a (click)="eventDetails(item.slug)" class="book-a-table-btn scrollto "> {{'home.details' | translate}}</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- End testimonial item -->

          </div>
          <div class="swiper-pagination"></div>
        </div>

      </div>
     </section>
  </ng-template>
</ngb-carousel>
</div>
<!-- End Events Section -->
<div class="d-flex justify-content-center mt-4">
  <a style="text-decoration: none" routerLink="activities">
    <button class="projects-btn" translate>home.all-activities</button>
  </a>
</div>
