<img style="width: 100%; margin-bottom: 4px ;height: 23vh; object-fit: cover;" src="assets/images/gray-plane-wing-62623.png">

<div class="container" *ngIf="activities">
  <div  *ngIf="activities" class="mt-5">
    <h1 class="popular-city neg_title_large">{{activities.activities.name}}</h1>
  </div>
  <div class="row w-100">
    <div class="col-md-8 col-sm-12 ">
      <ngb-carousel>
        <ng-container *ngFor="let item of activities.activities.images; index as i ">
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper mt-3">
              <img class="mt-2 rounded" *ngIf="activities"
                   [src]=item.url
                   alt="Random second slide"
                   width="100%"
                   height="540"
  
              />
            </div>
            <div class="carousel-caption"></div>
          </ng-template>
        </ng-container>
      </ngb-carousel>
      
      <div class="row">
        <!-- *ngIf="activities.activities.donations_count !=0 " -->
        <div class="col-6 icon-box2  raised2 " >
          <h2 class=""> Donors Numbers : <span class="colorNum"> {{activities.activities.donations_count}} </span></h2>
        </div>
        <!-- *ngIf="activities.activities.defciency_count != 0" -->
        
        <div class="col-6 icon-box2  raised2 " >
          <h2 class=""> Defciency Numbers :  <span class="colorNum"> {{activities.activities.defciency_count}} </span></h2>
        </div>
      </div>
      <div class="row ">
        <div class="col-7 p-0 mt-4 buttonClick  d-flex justify-content-around " *ngIf="activities.activities.open_donation_form == 1 ">
          <!--              <a [routerLink]="['/donation-for-activity/', activities.activities.slug]" class="button2d" translate>home.support-this-activity</a>-->
                        <h2  class="mt-5 handel-Support" translate>home.support-this-activity </h2>
                        <i class="bi bi-caret-right-square-fill mt-5 handel-Support"></i>
        
                        
        
        
        
        
                        <!-- <button [routerLink]="['/donation-for-activity/', activities.activities.slug]" translate>home.donate</button> -->
                      </div >
                      <div *ngIf="activities.activities.open_donation_form == 1 "  class="col-4 p-0 mt-5 w-50 btn-donate-sec" >
                        <!-- "['/donation-for-activity/', activities.activities.slug]" -->
                        <a  class="button3d" (click)="openDonationForm()" translate>home.donate</a>
                      </div>
      </div>
      <div class="row">
        <div class="">
          
        <!-- <div class="row"> -->

      
            <!-- *ngIf="user?.role == 'volunteer'" -->
            <div *ngIf="currentUser" class="col-6  btn-donate-sec" >
              <a  class="button3d " (click)="getVolunteerActivities(activities.activities.id)" translate>home.become-a-volunteer</a>
            </div>
     

          

        <!-- <div class="row"> -->
        <!-- <div class="col-12 mt-4">
          <div class="row">
            <div class="col-12" *ngIf="activities">
              <h1 class="description-1 my-1">{{activities.activities.details | slice:0:1000}}</h1>
            </div>
          </div>
        </div> -->
        <!-- </div> -->

        <!--        <ng-container *ngFor="let item of news; let i = index">-->
        <!--            <div class=" col-xxl-4 col-lg-4 col-md-4 col-sm-6" *ngIf="i<15 && i>8">-->
        <!--                <div class="news" [routerLink]="['/news-details/', item.slug]" (click)="refresh(item.slug)">-->
        <!--                    <img src="{{item.image}}"/>-->
        <!--                    <div class="info" [routerLink]="['/news-details/', item.slug]" (click)="refresh(item.slug)">-->
        <!--                        <h2>{{item.title}}</h2>-->
        <!--                        <p></p>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </ng-container>-->
        <!-- </div> -->
      </div>
      <div class="mt-5">
        <div class="row">
          <h1 class="sub-title">{{activities.activities.excerpt}} </h1>
          <div *ngFor="let iem of activities.activities.details">
            <p *ngIf="iem.sort == 1"  class="description-1 my-1 ">{{iem.details}}</p>
          </div>
        </div>
        <div class="row my-3">
     
          <div  *ngFor="let iem of activities.activities.details">
            <p *ngIf="iem.sort == 2"  class="description-1 my-1">{{iem.details}}</p>
          </div>
    
          <!-- <div class="col-4 " style=" margin-top: -135px">
            <span class="activity mb-3 w-100" translate>home.Up-Coming-Event</span>    
            <div class="">
              <ngb-carousel>
                <ng-container *ngFor="let item of allUpcoming">
                  <ng-template ngbSlide>
                    <div class="picsum-img-wrapper" style="cursor: pointer;">
                      <img [routerLink]="['/eventDetails/', item.slug]"  [src]=item.image
                        alt="Random second slide" width="100%" >
                    </div>
                    <div class="carousel-caption"></div>
                  </ng-template>
                </ng-container>
              </ngb-carousel>
          
          </div>
          </div> -->
              
           
        </div>
        <div class="row">
          <section>
            <div class="col-5 my-3">
              <span class="format-date">{{activities.activities.start_date | date}}</span>
            </div>
            <div class="col-12 order-lg-first ">
              <div class="sticky-content">
                <div class="social-icons social-icons-colored social-icons-vertical">
                  <div class="icon-share">
                    <span class="social-label " translate>home.share</span>
                    <a class="social-icon image-item" (click)="cop()"
                       target="_blank">
                      <img *ngIf="!copied" title="copy" style="width: 22px"
                           alt="copy"
                           src="./assets/newIcon/copy-2.png"/>
                      <img *ngIf="copied" title="copied" style="width: 28px"
                           alt="copied"

                           src="./assets/newIcon/copied.png"/>
                    </a>
                    <a (click)="fbc($event)" class="social-icon social-facebook"
                       title="Facebook"><i
                      class="icon-facebook-f"></i></a>
                    <a (click)="tw()" class="social-icon social-twitter"
                       title="Twitter"><i
                      class="icon-twitter"></i></a>
                    <a (click)="in()" class="social-icon social-linkedin"
                       title="Linkedin"><i
                      class="icon-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="row w-100">


          <!-- <div class="col-8">
            <div class="pt-5">
              <div class="flexDiv" style="justify-content: space-between">
                <div class="flexDiv" *ngIf="activities">
                  <h1 class="popular-city">{{activities.activities.name}}</h1>
                </div>
              </div>
            </div>
        
          </div> -->


          <div class="col-8">
            <!-- <div class="">
              <div class="flexDiv" style="justify-content: space-between">
                <div class="flexDiv">
                  <h1 class="activity w-100" translate>support</h1>
                </div>
              </div>
            </div> -->
            <!-- <div class="flexDiv" *ngFor="let item of activitiey">
              <h2 class="activity-title w-100" [routerLink]="['/donation-for-activity/', item.slug]"  (click)="openDonationForm()">
                {{item.name.slice(0,35)}}</h2>
            </div> -->
          </div>
          
        

       
      
      </div>
      </div>
      </div>
    
    </div>
     
    <div class="col-md-4 mt-3 w-100 col-sm-12 m-top">
      <!-- <app-calendar></app-calendar> -->
     
      <ng-container *ngIf="activities.activities.open_donation_form == 1 ">


        <div class="raised row icon-box">
         
          <div class="col-12">
            <span class="income mx-3" translate>home.We’ve-raised</span>
          </div>
          <div class="col-12 mt-3">
            <span class="budget">{{activities.activities.budget | currency}}</span>
          </div>
          <div class="col-12 mt-3">
            <span class="of-our" data-amounts="300000" translate>home.OF-OUR</span>
            
          </div>
          <div class="col-12 mt-3">
            <span class="target">{{activities.activities.target | currency}}</span>
          </div>
          <div class="col-12 mt-3">

            <span class="goal" translate>home.GOAL</span>
          </div>
          <div class="progress mt-4 mb-3">
            <div class="progress-bar" [class]="bgProgress" role="progressbar" [style.width]="(activities.activities.budget / activities.activities.target) * 100 +'%'"  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>

        
       
        <div *ngIf=" role == 'super_admin' && 'main_admin'  && 'donor' "  class="col-12 px-3 mt-2   mx-auto d-flex justify-content-center" >
          <a  class="buttonDow text-center"  > <span  class="mt-3 mx-2" translate>home.download file</span> <img src="../../../assets/images/download/download.png" width="30px" ></a>
        </div>
        <div *ngIf=" role != 'super_admin' && 'main_admin'  && 'donor'  " class="Download mt-4">
          <p> To download the event file, contact us </p>
          <div   class="col-12 px-3 mt-2   mx-auto d-flex justify-content-center" >
            <a  class="buttonDow text-center" (click)="openTransfer(contactBox, activities.activities.id)" > <span  class="mt-3 mx-2" translate> Click Here </span> <img src="../../../assets/images/download/download.png" width="30px" ></a>
          </div>
        </div>
         


      </ng-container>

    </div>
  </div>


    <div class="row w-100 mb-5 mt-5">
      <div class="col-12">
        <!-- <div class="">
          <div class="row">
            <div class="col-12" *ngFor="let iem of latestNews.articles">
              <h1 *ngIf="iem.sort == 1" class="description-1 my-1">{{iem.article}}</h1>
            </div>
          </div>
        </div> -->

        <div class="row p-0">
          <!-- <div class="col-12 mt-5 mb-5">
            <ngb-carousel>
              <ng-container *ngFor="let item of latestNews.images">
                <ng-template ngbSlide>
                  <div class="picsum-img-wrapper">
                    <img [src]=item.image_url alt="Random second slide" width="100%" height="540">
                   
                  </div>
                  <div class="carousel-caption"></div>

                </ng-template>
              </ng-container>
            </ngb-carousel>

          </div> -->
          <!-- <div class="row"> -->


            <div class="col-4 col-md-4 col-lg-4 col-sm-4  mt-4" *ngFor="let val of activities.activities.transfered_to_activities">
              <div class="post-item position-relative h-50 justify-content-between" (click)="eventDetails(val.slug)">
                <div class="post-img position-relative overflow-hidden">
                  <img *ngIf="val.image" src="{{val.image}}" class="img-fluid" alt="{{val.name}}"
                    style="height: 250px !important;width: 100%">
                  <span class="post-date">{{val.address}}</span>
                </div>
        
                <div class="post-content d-flex flex-column">
                  <div class="handelTitle">
                    <h3 class="post-title">{{val.name | slice:0:30}}</h3>
                  </div>
                 
        
                </div>
        
              </div>
            </div>
          <!-- <div class="row"> -->
          
          <!-- </div> -->

          <!--        <ng-container *ngFor="let item of news; let i = index">-->
          <!--            <div class=" col-xxl-4 col-lg-4 col-md-4 col-sm-6" *ngIf="i<15 && i>8">-->
          <!--                <div class="news" [routerLink]="['/news-details/', item.slug]" (click)="refresh(item.slug)">-->
          <!--                    <img src="{{item.image}}"/>-->
          <!--                    <div class="info" [routerLink]="['/news-details/', item.slug]" (click)="refresh(item.slug)">-->
          <!--                        <h2>{{item.title}}</h2>-->
          <!--                        <p></p>-->
          <!--                    </div>-->
          <!--                </div>-->
          <!--            </div>-->
          <!--        </ng-container>-->
          <!-- </div> -->
        </div>
      </div>
      
      
      
    </div>

    <!-- <div class="row mb-5">
     
      <div class="col-8" *ngFor="let iem of activities.activities.details">
        <p *ngIf="iem.sort == 2"  class="description-1 my-1">{{iem.details}}</p>
      </div>

      <div class="col-4 " style=" margin-top: -135px">
        <span class="activity mb-3 w-100" translate>home.Up-Coming-Event</span>    
        <div class="">
          <ngb-carousel>
            <ng-container *ngFor="let item of allUpcoming">
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper" style="cursor: pointer;">
                  <img [routerLink]="['/eventDetails/', item.slug]"  [src]=item.image
                    alt="Random second slide" width="100%" >
                </div>
                <div class="carousel-caption"></div>
              </ng-template>
            </ng-container>
          </ngb-carousel>
      
      </div>
      </div>
          
       
    </div> -->
  

    <!-- <h1 class="popular-cit">{{activities.activities.name}}</h1> -->
    <!--    <h1 class="popular-cit">{{activities.activities.name}}</h1>-->
  
  <!-- <div class="row">
    <div class="col-md-7 col-sm-12">
      <div class="row g-0 mainRow">
        <div class="col-12 ml-2">
          <ngb-carousel>
            <ng-container *ngFor="let item of activities.activities.images; index as i ">
              <ng-template ngbSlide>
                <div class="picsum-img-wrapper mt-3">
                  <img class="mt-2" *ngIf="activities"
                       [src]=item.url
                       alt="Random second slide"
                       width="100%"
                       height="540"
                       style="border-radius: 12px"
                  />
                </div>
                <div class="carousel-caption"></div>
              </ng-template>
            </ng-container>
          </ngb-carousel>
          <ng-container class="mt-5 mb-5" *ngIf="user?.role == 'volunteer'">

              <h2 class="sectionTitle mt-5 mb-5" translate>home.tasks</h2>
              <ul>
                <li class="listItem" *ngFor="let item of tasksbelongactivity">
                  <h5 class="task-name">{{item.name}}</h5>
                  <p class="taske-details">{{item.description}}</p>
                </li>
              </ul>

          </ng-container>
          <div class="row mt-5">
            <div class="col-12 mt-5 buttonClick  d-flex justify-content-around " *ngIf="activities.activities.open_donation_form == 1 ">
              <h2  class="mt-5 handel-Support" translate>home.support-this-activity </h2>
              <i class="bi bi-caret-right-square-fill mt-5 handel-Support"></i>
              <button [routerLink]="['/donation-for-activity/', activities.activities.slug]" translate>home.donate</button>
            </div>
            <div  class="col-6  btn-donate-sec" >
              <a  class="button3d" (click)="getVolunteerActivities(activities.activities.id)" translate>home.become-a-volunteer</a>
            </div>
          </div>

          <div class="portfolio-description  pt-4 readMore">
            <p class="sub_description"></p>
            <span class="aboutText" *ngIf="showAll"> {{activities.activities.details| slice:0:320}}.....<p
              *ngIf="showAll&&activities.activities.details.length>200" (click)="changeFlag()" class="readMore" translate>home.read-more</p> </span>

            <span class="aboutText" *ngIf="!showAll"> {{activities.activities.details}}<p *ngIf="!showAll" (click)="changeFlag();" class="readMore" translate>home.read-less</p> </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12 py-5">
        <div id="services" class="services section-bg">
          <div class="container">
            <div class="col-12 p-0 m-0 border-radius">
              <ngb-carousel
              *ngIf="videos.length > 0"
              [showNavigationIndicators]="showNavigationIndicators"
              >
                <ng-container>
                  <ng-template ngbSlide *ngFor="let item of videos">
                    <div class="picsum-img-wrapper ">
                      <iframe width="100%" height="400px" [src]="item.url | safe">
                      </iframe>
                    </div>
                  </ng-template>
                </ng-container>
              </ngb-carousel>
            </div>
            <div class="row w-100 my-5 justify-content-center mx-0">
              <div class="col-md-4 col-sm-12 d-flex align-items-stretch">
                <div class="icon-box date">
                  <div class="icon"><img class="calendar-icon m-3" src="assets/icons/calendar.png"></div>
                  <h4 translate>home.date</h4>
                  <p><span translate>home.event-start</span> {{activities.activities.start_date | date : 'fullDate'}}
                  </p>
                  <p class="my-2"><span
                    translate> home.event-end </span>{{activities.activities.end_date | date: 'fullDate' }} </p>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 d-flex align-items-stretch"
                   (click)="openDialog(activities.activities.start_date)" >
                <div class="icon-box">
                  <div class="icon"><i class="bx bx-file"></i></div>
                  <div class="time">
                    <div class="icon"><img class="calendar-icon m-3" src="assets/icons/clock.png"></div>
                    <h4 translate>home.time</h4>
                    <p>{{timeFormat(activities.activities.start_at) }}
                      - {{timeFormat(activities.activities.end_at)}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 d-flex align-items-stretch" (click)="showMap('map')"
                   >
                <div class="icon-box event-location">
                  <div class="icon"><img class="calendar-icon m-3" src="assets/icons/pin.png"></div>
                  <h4 translate>home.location</h4>
                  <p class="description">{{activities.activities.address}}</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  </div> -->




  <!-- <app-google-map id="map"></app-google-map> -->
  <!-- ======= ACTIVITIES Section ======= -->
  <!-- <div class="container mt-5">
    <h1 translate>home.other-activities</h1>
    <div _ngcontent-dmi-c79="" style="background-color: #9D2121; height: 3px; width: 75px; margin: auto;"></div>
    <div class="row mx-16 mt-5" >
      <ng-container *ngFor="let val of read?.activities; let i = index">
        <div class="col-4 col-md-4 col-lg-4 col-sm-4 my-3" *ngIf="i<8 && i>1" >
          <div class="post-item position-relative h-100" (click)="refresh(val.slug)">
            <div class="post-img position-relative overflow-hidden" >
              <img *ngIf="val.image"
                   src="{{val.image}}" class="w-100 " alt="{{val.title}}" height="250px">
              <span class="post-date"> <img src="assets/icons/clock-circular-outline.png" class="handelClock">
                {{timeFormat(val.start_at) }} - {{timeFormat(val.end_at)}}</span>
            </div>

            <div class="post-content d-flex flex-column">
              <div class="handelTitle">
                <h3 class="post-title">{{val.name.slice(0,50)}}</h3>
              </div>
              <div class="location">
                <i class="fa-solid fa-location-dot"></i>
                {{val.address}}
              </div>
              <p>
                {{val.details.substring(0, 120)}}...
              </p>
              <hr>
              <a [routerLink]="['/eventDetails/', val.slug]" (click)="refresh(val.slug)" class="readmore stretched-link"><span translate>home.details</span>
              </a>

            </div>

          </div>
        </div>
      </ng-container>
    </div>

  </div> -->
 

</div>

<div class="contaner">
  <!-- Team Section - Home Page -->
<section id="team" class="team mb-5">

  <!--  Section Title -->
  <div class="container section-title"  *ngIf="activities">
    <h2>Volunteers Team</h2>
    <p>Becoming a New Egypt Group volunteer offers a chance to make a difference in your community</p>
  </div><!-- End Section Title -->

  <div class="container">

    <div class="row gy-5">


      <div class="col-lg-4 col-md-6 member"  data-aos-delay="100" *ngFor="let item of activities.activities.volunteers">
        <div class="member-img">
          <img src={{item.image}} class="img-fluid" alt="">
          <div class="social">
            <a href="#"><i class="bi bi-twitter"></i></a>
            <a href="#"><i class="bi bi-facebook"></i></a>
            <a href="#"><i class="bi bi-instagram"></i></a>
            <a href="#"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
        <div class="member-info text-center">
          <h4>{{item.fullname_en}}</h4>
          <span> {{ item.job}}</span>
          <!-- <p>Aliquam iure quaerat voluptatem praesentium possimus unde laudantium vel dolorum distinctio dire flow</p> -->
        </div>
      </div><!-- End Team Member -->
    </div>

  </div>

</section><!-- End Team Section -->

</div>
<div class="my-5"></div>


<ng-template #contactBox >
  <div class="container" >
    <div class="row  mx-auto px-5 py-4">
      <div class="row">
        <span class="custom_close" (click)="closeModal()">x</span>
      </div>
      <form [formGroup]="formD">
        <div class="row">
          <div class=" col-12 mb-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" class="form-control" id="name" formControlName="name">
          </div>
          
          <div class=" col-12 mb-3">
            <label for="email" class="form-label">Email address</label>
            <input type="email" class="form-control" id="email" formControlName="email">
          </div>
          <div class=" col-12 mb-3">
            <label for="phone" class="form-label"> phone </label>
            <input type="text" class="form-control" id="phone" formControlName="phone">
          </div>
        </div>
    
        
        <div class="d-flex justify-content-center w-100">
          <button type="submit" (click)="contactUsDown()" class="btn sendButton">Send</button>
        </div>
        
      </form>


    </div>
  </div>
</ng-template>