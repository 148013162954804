<div class="row m-0">
<div class="col-md-3 col-sm-6">
  <div class="post" tabindex="0">
    <div class="photo"
         style="background-image: url('https://www.borgenmagazine.com/wp-content/uploads/2015/03/orphanage.jpg')">
      <div class="overlay text-left"><a class="label">Allison Walker</a></div>
    </div>
    <div class="description">
      <h3 class="text-center mb-3">{{names[0].name}}</h3>
      <h6 class="text-center">{{names[0].desc}} </h6>
      <p class="post_description d-block">{{names[0].date_of_birth}}</p></div>
  </div>
</div>
  <div class="col-md-3 col-sm-6">
    <div class="post" tabindex="0">
      <div class="photo"
           style="background-image: url('https://www.borgenmagazine.com/wp-content/uploads/2015/03/orphanage.jpg')">
        <div class="overlay text-left"><a class="label">Allison Walker</a></div>
      </div>
      <div class="description">
        <h3 class="text-center mb-3">Allison Walker</h3>
        <h6 class="text-center">newegypt siger , Gharbiya ,Egypt </h6>
        <p class="post_description d-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate deleniti.</p></div>

    </div>
  </div>
  <div class="col-md-3 col-sm-6">
    <div class="post" tabindex="0">
      <div class="photo"
           style="background-image: url('https://www.borgenmagazine.com/wp-content/uploads/2015/03/orphanage.jpg')">
        <div class="overlay text-left"><a class="label">Allison Walker</a></div>
      </div>
      <div class="description">
        <h3 class="text-center mb-3">Allison Walker</h3>
        <h6 class="text-center">newegypt siger , Gharbiya ,Egypt </h6>
        <p class="post_description d-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit et
          cupiditate
          deleniti Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate
          deleniti.</p></div>

    </div>
  </div>
  <div class="col-md-3 col-sm-6">
    <div class="post" tabindex="0">
      <div class="photo"
           style="background-image: url('https://www.borgenmagazine.com/wp-content/uploads/2015/03/orphanage.jpg')">
        <div class="overlay text-left"><a class="label">Allison Walker</a></div>
      </div>
      <div class="description">
        <h3 class="text-center mb-3">Allison Walker</h3>
        <h6 class="text-center">newegypt siger , Gharbiya ,Egypt </h6>
        <p class="post_description d-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit et
          cupiditate
          deleniti Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate
          deleniti.</p></div>

    </div>
  </div>
  <div class="col-md-3 col-sm-6">
    <div class="post" tabindex="0">
      <div class="photo"
           style="background-image: url('https://www.borgenmagazine.com/wp-content/uploads/2015/03/orphanage.jpg')">
        <div class="overlay text-left"><a class="label">Allison Walker</a></div>
      </div>
      <div class="description">
        <h3 class="text-center mb-3">Allison Walker</h3>
        <h6 class="text-center">newegypt siger , Gharbiya ,Egypt </h6>
        <p class="post_description d-block"> Lorem ipsum dolor sit amet, consectetur adipisicing elit et
          cupiditate
          deleniti Lorem ipsum dolor sit amet, consectetur adipisicing elit et cupiditate
          deleniti.</p></div>

    </div>
  </div>
</div>

<div id="test"></div>
