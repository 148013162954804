<section>
<h1>Our Services</h1>
<div class="parent">
  <div class="child">
  </div>
</div>

<p class="para">must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and i
  will give you</p>
<div class="item">
  <div class="GreatItem">
    <div class="item1">

      <Img src="	https://mariyamhasham.files.wordpress.com/2021/10/japanese-ct.jpg?w=350&h=200&crop=1" width="100"
           height="100" alt="this is no Image" title="hello"/>
      <h2>Strategy</h2>
      <p class="paragraph">how can i how can i how can i how can i how can i how can i
        how can i how can i</p>

    </div>
  </div>
  <div class="GreatItem">
    <div class="item1">

      <Img src="	https://mariyamhasham.files.wordpress.com/2021/10/japanese-ct.jpg?w=350&h=200&crop=1" width="100"
           height="100" alt="this is no Image" title="hello"/>
      <h2>Strategy</h2>
      <p class="paragraph">how can i how can i how can i how can i how can i how can i
        how can i how can i</p>

    </div>
  </div>
  <div class="GreatItem">
    <div class="item1">

      <Img src="	https://mariyamhasham.files.wordpress.com/2021/10/japanese-ct.jpg?w=350&h=200&crop=1" width="100"
           height="100" alt="this is no Image" title="hello"/>
      <h2>Strategy</h2>
      <p class="paragraph">how can i how can i how can i how can i how can i how can i
        how can i how can i</p>
    </div>
  </div>
</div>
</section>
