import { Component, OnInit } from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {Router} from '@angular/router';
import {ApisService} from '../../../services/apis.service';

@Component({
  selector: 'app-recent-news',
  templateUrl: './recent-news.component.html',
  styleUrls: ['./recent-news.component.scss']
})
export class RecentNewsComponent implements OnInit {
  news: any[]=[];
  // direction : boolean = false;
  carouselOptions2: OwlOptions = {
    // rtl: true,
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 1},
      992: {items: 3},
      1600: {items: 3}

    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };
  constructor(private router: Router, private apis: ApisService,){
    // this.apis.currentLang$.subscribe((res:any)=>{
    //   if (res == "ar"){
    //     this.direction = true
    //   }
    //   this.direction = false
    //   console.log(res,this.direction)
    // })
  }

  ngOnInit(): void {
  this.getNews();
  }
  transform(n: string): string {
    const date = new Date(n);
    const months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    const days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    const delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }
  getNews(): void{
    this.apis.newsApi().subscribe((data: any) => {
      this.news = data.data.news;
      console.log(this.news);
      
    }, (err: any) => {
    });
  }
  newsDetails(id): void{
    this.router.navigate(['/news-details/' + id]);
  }
  validDateFormat(dateString) {
  if(dateString) {
    return dateString.replace(/\s/, 'T');
  }

  return null;

}
}
