import {Component, OnInit} from '@angular/core';
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {Observable} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApisService} from "../services/apis.service";
import {ToastrService} from "ngx-toastr";
import {map, startWith} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {SeoService} from "../services/seo.service";
import {MatDialog} from "@angular/material/dialog";
import {CaseSearchComponent} from "./case-search/case-search.component";

@Component({
    selector: 'app-field-social-research',
    templateUrl: './field-social-research.component.html',
    styleUrls: ['./field-social-research.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
    }]
})
export class FieldSocialResearchComponent implements OnInit {
    firstFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
    });
    secondFormGroup = this._formBuilder.group({
        secondCtrl: ['', Validators.required],
    });
    thirdFormGroup = this._formBuilder.group({
        thirdCtrl: ['', Validators.required],
    });
    filteredGovermnets: Observable<string[]>;
    filteredCities: Observable<string[]>;
    filteredVillages: Observable<string[]>;
    formDocument: FormGroup;
    isLinear = false;
    casePurpose: any;
    govermnets: any[] = [];
    cities: any[] = [];
    villages: any[] = [];
    errorMessage!: string;
    fileToUpload: any = '';
    fileToUpload1: any = [];
    images1: any = [];
    image: any;
    village_id;
    lang: any;
    metaTitle: string;
    meatDescription: string;
    mainImage:any='https://newegyptgroup.com/assets/images/case-form/fill-up-a-form.jpg';
    langLocal = localStorage.getItem('lang') || 'ar';

    constructor(private _formBuilder: FormBuilder,
                private apis: ApisService,
                private fb: FormBuilder,
                private toastr: ToastrService,
                public router: Router,
                private seoService: SeoService,
                private activaterouter:ActivatedRoute,

                public dialog: MatDialog

    ) {
        this.seoService.updateTitle('auu')

}
    ngOnInit(): void {

        this.getCasePurposes();
        this.getGovermnets();
        console.log('title',this.metaTitle)
        this.formDocument = this.fb.group({
            fullname: ['', [Validators.required]],
            age: ['', [Validators.required, Validators.pattern('[0-9]+')]],
            phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
            address: ['', [Validators.required]],
            national_id: ['', [Validators.required, Validators.pattern('[0-9]+')]],
            goverment_id: ['', [Validators.required]],
            city_id: [null, [Validators.required]],
            village_id: [null],
            purpose_id: [null, [Validators.required]],
            case_image: ['',],
            image: ['',],
        });
    }

    submitForm(): any {
        console.log(this.formDocument.value)
        let formData: FormData = new FormData();
        for (var i = 0; i < this.fileToUpload1.length; i++) {
            formData.append(`images[]`, this.fileToUpload1[i]);
        }
        let goverment_id = this.getGovermnetsObject(this.formDocument.value.goverment_id).id
        let city_id = this.getCitiesObject(this.formDocument.value.city_id).id
        this.village_id = this.getVillagesObject(this.formDocument.value.village_id) ?
            this.village_id = this.getVillagesObject(this.formDocument.value.village_id).id : 0
        console.log(this.village_id)
        // let village_id='0';
        formData.append('fullname', this.formDocument.value.fullname);
        formData.append('age', this.formDocument.value.age);
        formData.append('phone', this.formDocument.value.phone);
        formData.append('address', this.formDocument.value.address);
        formData.append('national_id', this.formDocument.value.national_id);
        formData.append('goverment_id', goverment_id);
        formData.append('city_id', city_id);
        formData.append('village_id', this.village_id);
        formData.append('purpose_id', this.formDocument.value.purpose_id);
        formData.append('case_image', this.fileToUpload);
        // formData.append('image', this.fileToUpload1);

        // @ts-ignore
        return formData;
    }

    getpurpos(id: number) {
        this.formDocument.patchValue({
            purpose_id: id
        })
    }

    setFilterGovermnets() {
        this.filteredGovermnets = this.formDocument.controls.goverment_id.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );

    }

    setFilterCities() {
        this.filteredCities = this.formDocument.controls.city_id.valueChanges.pipe(
            startWith(''),
            map(value => this._filterCities(value || '')),
        );
    }

    setFilterVillages() {
        this.filteredVillages = this.formDocument.controls.village_id.valueChanges.pipe(
            startWith(''),
            map(value => this._filterVillages(value || '')),
        );
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.govermnets.filter(option => option.name_en?.toLowerCase().includes(filterValue));
    }

    private _filterCities(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.cities.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    private _filterVillages(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.villages.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    getGovermnetsObject(govermnetName): any {
        const index = this.govermnets.findIndex(obj => obj.name_en === govermnetName);
        console.log(this.govermnets[index].id)
        if (index > -1) {
            return this.govermnets[index];
        }
    }

    getCitiesObject(cityName): any {
        const index = this.cities.findIndex(obj => obj.name === cityName);
        console.log(this.cities[index].id)
        if (index > -1) {
            return this.cities[index];
        }
    }

    getVillagesObject(villageName): any {
        const index = this.villages.findIndex(obj => obj.name === villageName);
        console.log(this.villages[index])
        if (index > -1) {
            return this.villages[index];
        }
    }

    selectedVillage(event): any {
        console.log(event.option.value)
        this.getVillagesObject(event.option.value).id

    }

    getCasePurposes() {
        this.apis.getCasePurposes().subscribe({
            next: (res: any) => {
                this.casePurpose = res.data
                console.log(this.casePurpose)
            }
        })
        this.activaterouter.queryParams.subscribe(query=>{
            console.log('query' , query)
            this.lang = query.lang;
            console.log('test', this.lang)
            if (this.lang == 'ar') {
                this.metaTitle = 'نموذج استمارة بحث حالة';
                this.meatDescription = 'الخطوة الأولى في الحصول على الدعم الذي قد تحتاجه هي ملء نموذج تقرير الحالة الخاص بنا. سنبذل قصارى جهدنا لمساعدتك ، لذا يرجى ملء النموذج الخاص بنا ودعنا نفهم حالتك بأفضل شكل ';
            }
            if(this.lang == 'en'){
                this.metaTitle = 'Case Report Form | New Egypt Website';
                this.meatDescription = ' The first step in getting the support you may need is filling out our case report form. We will do our best to help you, so please fill out our form and let us understand your case better.';
            }
            this.seoUpdate(this.metaTitle,this.meatDescription,this.mainImage)
            console.log('djjd',this.metaTitle)
        })
    }

    getGovermnets() {
        this.apis.getGovermnets().subscribe((data: any) => {
                this.govermnets = data.data
                console.log(this.govermnets)
                this.setFilterGovermnets();
            },
            err => {
                console.log(err);
            });
    }

    getCities(event): any {
        console.log(event.option.value)
        this.apis.getCities(this.getGovermnetsObject(event.option.value).id).subscribe((data: any) => {
                this.cities = data.data;
                console.log('city', this.cities)
                this.setFilterCities();
            },
            err => {
                console.log(err);
            });
    }

    getVillages(event): any {
        // if (event == undefined) {
        //   this.formDocument.patchValue({
        //     village_id: null
        //   });
        // }
        console.log(event.option.value)
        this.apis.getVillages(this.getCitiesObject(event.option.value).id).subscribe((data: any) => {
                this.villages = data.data;
                console.log(this.villages)
                this.setFilterVillages();
            },
            err => {
                console.log(err);
            });
    }
    onFileSelected(files: FileList): void {
        this.fileToUpload = files.item(0);
        (document.getElementById('blah') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUpload);
        (document.getElementById('blah') as HTMLInputElement).style.height = '70px';
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.image = event.target.result;

            };
            reader.readAsDataURL(files[0]);
        }
    }

    onFileSelected1(event: any): void {
        console.log(event)
        if (event.target.files && event.target.files[0]) {
            var filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
                this.fileToUpload1.push(event.target.files.item(i));
                reader.onload = (event: any) => {
                    this.images1.push(event.target.result);
                    console.log('images1 =>', this.images1)
                };
                reader.readAsDataURL(event.target.files[i]);
            }
            // this.cd.markForCheck();
        }
    }


    add() {
        this.apis.addCaseSearches(this.submitForm()).subscribe({
                next: data => {
                    // this.messageSuccess= data['message']
                    // this.router.navigate(['/']);
                    this.toastr.success(data.message, 'Success');

                }, error: (err: any) => {
                    console.log(err);
                    this.errorMessage = err.error.errors;
                    for (const key in err.error.errors) {
                        this.toastr.error(err.error.errors[key], 'faild')
                    }
                    // this.messageError= error
                }
            }
        );
    }
  openDialog() {
    const dialogRef = this.dialog.open(CaseSearchComponent , {
      height: '90vh',
      width: '25vw',
      minWidth:'390px',
      data :{ completed: 0}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


    seoUpdate(metaTitle,meatDescription,mainImage) {
        this.seoService.updateConstMetaTags();
        this.seoService.updateUrl()
            this.seoService.updateTitle(metaTitle);
            this.seoService.updateDescription(meatDescription);
            this.seoService.updateImage(mainImage)
    }
}
