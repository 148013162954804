import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ApisService} from "../../services/apis.service";

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  videoUrl = 'https://player.vimeo.com/external/590636003.hd.mp4?s=c3df8f46b6465c63f82fcc546ec565f27ac05d46&profile_id=175';
  carouselOptionsVideos: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 1},
      992: {items: 1}
    },
    items: 1,
    loop: true,
    // autoplay: true,
    dots: false,
    nav: true,
    autoplaySpeed: 10000,
    autoplayTimeout: 10000,
    smartSpeed: 1000,
  };
  video:any[]=[];
  constructor(private router: Router,private apis: ApisService,
  ) {
  }

  ngOnInit(): void {
    this.getVideos();
  }
  navigate(): void{
    this.router.navigate(['/']).then(result => (window.location.href = 'https://newegyptgroup.com/donation-for-activity/a-donation-campaign-from-egyptians-in-the-united-states-to-support-a-haya-karima-for-our-families'));

  }
  getVideos() {
    this.apis.videosApi().subscribe(
        (data: any) => {
          this.video = data.data;
          console.log(this.video)
        });
  }
}
