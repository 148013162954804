
<div class="auth-wrapper">
  <form class="form-signin" [formGroup]="signinForm" (ngSubmit)="loginUser()">
    <h3 class="h3 mb-3 font-weight-normal text-center">Please sign in</h3>
    <div class="form-group">
      <label>Email</label>
      <input
        type="email"
        class="form-control"
        formControlName="email"
        placeholder="Enter email"
        required
      />
    </div>
    <div class="form-group">
      <label>Password</label>
      <input
        type="password"
        class="form-control"
        formControlName="password"
        placeholder="Password"
      />
    </div>
    <button type="submit" class="btn btn-block btn-primary">Sign in</button>
  </form>
</div>
