import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {ActivatedRoute} from '@angular/router';
import {Router, NavigationEnd} from '@angular/router';
import {ViewportScroller} from '@angular/common';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ServicesComponent} from '../../components/booking/services/services.component';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
  @Output() setUserEvent :EventEmitter<any>= new EventEmitter();
  resend = false;
  user: any;
  date: any;
  dateHomeSelected: any;
  serviceId: any;
  bookTime: any;
  bookingNumber: any;
  personalData: any;
  message: any;
  closedStatus: true;
  time: any;
  services: any;
  times: any;
  error: any = false;
  checkUser: any = false;
  dataServicesSelected: any;
  checkSsn: boolean = false;

  constructor(
    private apis: ApisService,
    private route: ActivatedRoute,
    private router: Router,
    private viewportScroller: ViewportScroller,
    public dialog: MatDialog,
    private seoService: SeoService
  ) {
  }

  ngOnInit(): void {
    if (this.date) {
      this.dateHomeSelected = this.date;
    } else {
      this.dateHomeSelected = this.route.snapshot.queryParamMap.get('date');
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  showProgressbar(): any {
    if (!this.bookingNumber) {
      document.getElementById('progress').classList.remove('hide');
    }
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(ServicesComponent, {
      maxHeight: '95vh',
      maxWidth: '80vw',
      panelClass: 'custom-modalbox',
      data: {}
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.dataServicesSelected = data.dataServicesSelected;
      this.services = data.services;
      this.times = data.times;
    });
  }

  ssnChecker(e): any {
    this.checkSsn = e;
  }
  setUser(user): void {
    this.user = user;

  }

  setDate(date): void {
    this.date = date;
  }

  setPersonalData(data): void {
    this.personalData = data;
  }

  setTime(data): void {
    this.bookTime = data;
  }

  timeAndServices(e): any {
    this.time = e.time;
    this.services = e.services;
  }

  setServices(e): any {
    this.dataServicesSelected = e;
    this.services = e['services'];
    this.times = e['times'];
  }
  trnsformTime(time): any {
    if (time == '11:00 am') {
      return '11:00 صباحا';
    }
    if (time == '11:30 am') {
      return '11:30 صباحا';
    }
    if (time == '12:00 pm') {
      return '12:00 ظهرا';
    }
    if (time == '12:30 pm') {
      return '12:30 ظهرا';
    }
    if (time == '1:00 pm') {
      return '01:00 ظهرا';
    }
    if (time == '9:00 am') {
      return '09:00 صباحا';
    }
    if (time == '9:30 am') {
      return '09:30 صباحا';
    }
    if (time == '10:00 am') {
      return '10:00 صباحا';
    }
    if (time == '10:30 am') {
      return '10:30 صباحا';
    }
    if (time == '1:30 pm') {
      return '01:30 مساء';
    }
    if (time == '2:00 pm') {
      return '02:00 مساء';
    }
    if (time == '2:30 pm') {
      return '02:30 مساء';
    }
    if (time == '3:00 pm') {
      return '03:00 مساء';
    }
  }

}
