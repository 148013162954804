import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
// import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  loadings = [

    { width: '300px' },
    { width: '300px' },
    { width: '300px' },    { width: '300px' },

  ];
  loadingsShare = [
    { width: '40px' },
    { width: '40px' },
    { width: '40px' },
    { width: '50px' },
  ];
  privacyPolicy: any = {};
  privacyPolicyExist: boolean = false;
  searchText = '';
  formSubmitted: boolean = false;

  
  url: string;
  roundSpinner: boolean = false;
  id: any;
  @ViewChild('target') target: ElementRef;
  toScrollInto: string;
  
  constructor( 
    private router: Router,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

}
