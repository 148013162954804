import { Component, OnInit } from '@angular/core';
import {ApisService} from "../../../services/apis.service";

@Component({
  selector: 'app-home-main-slider',
  templateUrl: './home-main-slider.component.html',
  styleUrls: ['./home-main-slider.component.scss']
})
export class HomeMainSliderComponent implements OnInit {
  slider: any;

  constructor(private apis: ApisService,) { }

  ngOnInit(): void {
    this.getSlider();
  }
  getSlider(): void{
    this.apis.SliderApi().subscribe((data: any) => {
      this.slider = data.data;
      console.log(this.slider);
    }, (err: any) => {
    });
  }

}
