import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { th } from 'date-fns/locale';
import { ApisService } from 'src/app/services/apis.service';
import {log} from "util";

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent implements OnInit {
  all:boolean=false;
  boardMember:any;
  boardMembers:any;
  id:any;
  boardId:any;
  constructor(private apis: ApisService,private activatedRoute: ActivatedRoute) {
    this.id=this.activatedRoute.snapshot.paramMap.get('id')
    this.boardId=this.activatedRoute.snapshot.paramMap.get('board')
    this. getMember();
    this.activatedRoute.params
      .subscribe(
        (params: Params) => {
          this.id=this.activatedRoute.snapshot.paramMap.get('id')
         this.boardId=this.activatedRoute.snapshot.paramMap.get('board')
          this.getMember();
          this.id === this.boardId ?  this.getMyClubMembers() : this.getMember()

        }
      );
  }


  ngOnInit(): void {
    this.id === this.boardId ?  this.getMyClubMembers() : this.getMember()


  }
  getMember()
  {
    this.boardMember=null;
    this.apis.getMemberInfo(this.id).subscribe((data: any)=>{
      this.boardMember=data.data;
      console.log(this.boardMember,this.id)
    });

  }
  // getMembers() {
  //   // this.boardMember=null;
  //   this.apis.getBoardMembersExcept(this.boardId,this.id).subscribe((data: any)=>{
  //     this.boardMembers=data.data;
  //   });
  //
  // }
  getMyClubMembers(){
    // this.boardMember=null;
    this.apis.getMyClubMembers().subscribe({
      next:(res:any)=>{this.boardMembers= res[0],console.log(this.boardMembers)},
      // error:(err:any)=>{}
    })
  }
  showAll()
  {
    this.all=!this.all;
  }
}
