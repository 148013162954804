<app-header></app-header>
<div class="calendar-form">
  <div class="col-12">
    <div class="row">
      <mat-spinner class="hide" id="progress"></mat-spinner>
      <div class="col-12" *ngIf="message && bookingNumber">
        <app-message (closeEvent)="message = false" [date]="date" [bookingNumber]="bookingNumber"
                     [personalData]="personalData" [dataSelected]="dataServicesSelected"
                     [message]="message"></app-message>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div *ngIf="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
          </div>
          <ng-container *ngIf="!user">
            <ng-container *ngIf="!checkUser">
            </ng-container>
          </ng-container>
          <ng-container>
            <ng-container>
              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <div class="custom-container">
                      <span class="mat-error-2">2</span>
                      <div class="col-12">
                        <div class="row justify-content-end">
                          <div class="col-12">
                            <div class="button-services row">
                              <button class="btn btn-dark col-12" (click)="openDialog()">اضغط لإختيار خدمتك </button>
                            </div>
                            <div class="row f-2" *ngIf="times||services">
                              <div class="col-4 small-screen mt-3" *ngIf="times">
                                <span class="d-block font-size-700-18 mb-2">وقت الخدمه</span>
                                <span *ngFor="let selectedTime of times" class="d-block mb-3 color-ff0000 direction-rtl font-size-15-600" style="font-size: 28px;font-weight: 600;margin-top: 23px;">{{trnsformTime(selectedTime['time_name'])}}</span>
                              </div>
                              <div class="col-8 small-screen mt-3">
                                <div class="selected-services" *ngIf="services">
                                  <span class="d-block font-size-700-18 mb-2">الخدمات المطلوبه</span>
                                  <div class="services-sec mb-2 font-size-15-600 d-flex flex-row-reverse" style=" line-height:30px;" *ngFor="let service of services">
                                    <span style="font-size: 25px; padding-left: 4px">-</span>
                                    {{service['service_name']}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <app-personal-data (ssnChecker)="ssnChecker($event)" (setPersonalDataEvent)="setPersonalData($event)"></app-personal-data>
                  </div>
                  <div class="col-12">
                    <div class=" text-small-center">
                      <button [disabled]="!checkSsn" class="btn btn-danger bookBtn">حجز الموعد</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 order-small">
                <div class="row">
                  <div class="col-12">
                    <app-calender [dateHomeSelected]="dateHomeSelected"
                                  (setDateEvent)="setDate($event)"></app-calender>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
