import {ChangeDetectionStrategy, Component, Inject, PLATFORM_ID} from '@angular/core';
import {AuthService} from './services/auth.service';
import {ApisService} from "./services/apis.service";
import {isPlatformBrowser} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  static hostUrl: string = 'https://newegyptgroup.com/';
  title = 'NewEgyptGroup';
  constructor(
              private _ApisService:ApisService,
              @Inject(PLATFORM_ID) platformId: Object
  ) {
    if (isPlatformBrowser(platformId)){
      this._ApisService.currentLang$.subscribe((res:any)=>{
        if(res == 'ar' ){
          document.getElementById('body').classList.add('direction-rtl')
        }
        document.getElementById('body').classList.add('direction-ltr')
      })
    }
  }
}
