<div class="bg-white pt-5">
  <div class="container">
    <div class="projects">
      <owl-carousel-o [options]="carouselOptions2" >
        <ng-container *ngFor="let member of boardMembers?.members" >
          <ng-template carouselSlide  >
            <div class=" card card-two" (click)="getMemberInfo(member.id)">
              <header>
                <div class="avatar">
                  <img *ngIf="!member.image" height="60px" width="60px" class="circle" src="./assets/images/avatar/user-anonymous.png">
                  <img *ngIf="member.image" height="60px" width="60px" class="circle" src="{{member.image}}">
<!--                  <img src="{{member.image}}" alt="Allison Walker"/>-->
                </div>
              </header>
              <!-- {{member.fullname}} -->
              <h3 class="user-name">{{member.fullname}}</h3><h4 class="text-center">{{member.job_name}}</h4>
              <div class="desc" >
                {{member.bio|slice:0:180}}
                <span *ngIf="member.bio">...</span>
              </div>
              <!-- <div class="contacts">
                <a href=""><i class="fa fa-plus"></i></a>
                <a href=""><i class="fa fa-whatsapp"></i></a>
                <a href=""><i class="fa fa-envelope"></i></a>
                <div class="clear"></div>
              </div> -->

               <footer>
                <p>
                  <a *ngIf="member.facebook" href="{{member.facebook}}"><i class="fa fa-facebook"></i></a>
                  <a *ngIf="member.linkedin" href="{{member.linkedin}}"><i class="fa fa-linkedin"></i></a>
                  <a *ngIf="member.twitter" href="{{member.twitter}}"><i class="fa fa-twitter"></i></a>
                  <a *ngIf="member.instagram" href="{{member.instagram}}"><i class="fa fa-instagram"></i></a>
                </p>
              </footer>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>

