<app-haya-header></app-haya-header>
<div style="background-color: #F8F7F5 ">
  <div class="container-fluid">
    <nav>
      <ol class="breadcrumb direction-ltr">
        <li class="breadcrumb-item"><a [routerLink]="['/']"></a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/news']">News</a></li>
        <li class="breadcrumb-item active">{{title}}</li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-md-3 col-sm-12 small-screen direction-ltr">
        <div class="sidebar direction-ltr">
          <div class="latest-news">
            <div class="items">
              <div class="header-news">
                <span>Other News</span>
              </div>
              <hr class="w-100 mt-0">
              <ng-container *ngIf="length !== 0">
                <div (click)="setPostId(news.id)" class="item" *ngFor="let news of news | slice:0:6; let i=index">
                  <div class="inner-content">
                    <div class="image-news">
                      <img src="{{news.images[0].image}}">
                    </div>
                    <p>{{news['title'].slice(0, 400)}}....</p>
                  </div>
                  <div class="date-news">
                    <label>{{transform(news['date'] | date: 'yyyy-MM-dd')}}</label>
                  </div>
                  <hr>
                </div>
              </ng-container>
            </div>
            <div class="items">
              <div>
                <div class="inner-content">
                  <div class="image-news">
                    <div class="text-center  btn-donate-sec mt-4 mb-4">
                      <button class="aplos-donation-button button2c btn donateBtn"
                              data-widget-id="97EF7E128ABE7D1557C67221F2A6B9BC">
                        Donate via Debit/Credit Card
                        Bank Account(ACH)
                      </button>
                    </div>
                    <hr class="w-100 mt-2">
                    <div id="donation-section" class="large-sc">
                      <div id="donation" class="donation">
                        <span>Our Target</span>
                        <br>
                        <span class="target-donation">$1,000,000</span>
                        <br>
                        Donations Earned <br>
                        <span class="today">Today</span>
                        <br>
                        <span class="price">{{numberWithCommas(amount)}}</span>
                      </div>
                    </div>
                  </div>
                  <p></p>
                </div>
                <div class="date-news">
                  <!--                    <label>{{transform(news.date | date: 'yyyy-MM-dd')}}</label>-->
                </div>
                <hr>
              </div>
              <div class="header-news">
                <span>Target Groups</span>
              </div>
              <div class="item">
                <div class="row">
                  <ul class="direction-ltr">
                    <li>Volunteers</li>
                    <li>The families most in <br> need in rural communities.</li>
                    <li>Young people who are <br> capable to work.</li>
                    <li>Orphans and children.</li>
                    <li>The elderly.</li>
                    <li>Orphans and children.</li>
                    <li>People with special needs.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 col-sm-12 direction-ltr">
        <div class="container project">
          <div class="head">
            <div class="p-absolute title ">
              <h1 class="project-name-header">{{title}}</h1>
              <div class="location">{{transform(date)}}</div>
            </div>
          </div>
          <div class="image-container">
            <img style="height: 400px; width: 80%"
                 src="{{image}}">
          </div>
          <div class="d-flex flex-row justify-content-between align-items-baseline mt-3">
            <h2 class="project-name">{{title}}</h2>
          </div>
          <div class="article" [innerHTML]="description"></div>
          <article class="text-left direction-ltr important-article mt-4">All donations made by those living in usa are
            100% tax deductible, and 100% of donations go directly to the project with no administrative costs.
          </article>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer-hayah></app-footer-hayah>
