<div class="container print" >
  <div class="row">
    <div class="col-12">
      <mat-dialog-actions align="end">
        <button class="close"  mat-button mat-dialog-close>X</button>
      </mat-dialog-actions>
      <h1 *ngIf="expenses">Expenses Of {{expenses[0]?.activity_name}}</h1>
      <h3>{{myDate |date: 'medium'}}</h3>
      <mat-tab-group >
        <mat-tab label="Expenses">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col">ITEM</th>
              <th scope="col">ITEM PRICE</th>
              <th scope="col">QUANTITY</th>
              <th scope="col">RESIDUAL QUANTITY</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">RESIDUAL AMOUNT</th>
              <th scope="col">DETAILS</th>
              <th scope="col">ACTIONS</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of expenses">
              <td>{{item.item.item_name}}</td>
              <td>{{item.item_price}} {{item.currency.symbol}}</td>
              <td>{{item.quantity}}</td>
              <td>{{item.residual_quantity}}</td>
              <td>{{item.amount}} {{item.currency.symbol}}</td>
              <td>{{item.residual_amount}} {{item.currency.symbol}}</td>
              <td>{{item.details}} </td>
              <td>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="border: none;background:none;height: 35px;width: 35px;">
                <mat-icon>more_vert</mat-icon>
              </button>
              </td>
              <mat-menu #menu="matMenu" >
                <button mat-menu-item (click)="openeExpensesDetailsReports(item.id)">
                  <mat-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit ml-3 mb-3">
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  </mat-icon>
                  <span>DETAILS</span>
                </button>
              </mat-menu>
            </tr>
            </tbody>
          </table>
        </mat-tab>
      </mat-tab-group>
      <mat-tab-group>
        <mat-tab label="Children Expenses" *ngIf="expensesDetailsReportIsExist">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col">NAME OF CHILD</th>
              <th scope="col">ITEM</th>
              <th scope="col">QUANTITY</th>
              <th scope="col">AMOUNT</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of expensesdetailsreport?.children_expenses">
              <td>{{item.child.name}}</td>
              <td>{{item.item}}</td>
              <td>{{item.quantity}}</td>
              <td>{{item.amount}} {{item.currency.symbol}}</td>
            </tr>
            </tbody>
          </table>

        </mat-tab>
        <mat-tab label="Families Expenses" *ngIf="expensesDetailsReportIsExist" >
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col">NAME OF FAMILY</th>
              <th scope="col">ITEM</th>
              <th scope="col">QUANTITY</th>
              <th scope="col">AMOUNT</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of expensesdetailsreport?.families_expenses">
              <td>{{item.family.name}}</td>
              <td>{{item.item}}</td>
              <td>{{item.quantity}}</td>
              <td>{{item.amount}} {{item.currency.symbol}}</td>
            </tr>
            </tbody>
          </table>
        </mat-tab>
      </mat-tab-group>

      <div class="px-3 d-flex justify-content-center mt-2">
        <a class="btn btn-outline-secondary hide-print btn-block"
           href="javascript:window.print();"
           rippleEffect
        >
          Print
        </a>
      </div>
    </div>
  </div>
</div>

