<!--<div class="container-fluid">-->
<!--<ngb-carousel  [showNavigationIndicators]="false">-->
<!--  <ng-template ngbSlide *ngFor="let item of slider">-->
<!--      <video onload='this.play()' [muted]="true" [autoplay]="true" [loop]="true">-->
<!--          <source src="{{item.video_desktop}}">-->
<!--      </video>video-->
<!--  </ng-template>-->
<!--</ngb-carousel>-->
<ngb-carousel
  class="text-lg-center"
  [interval]="5000"
  [showNavigationArrows]="true"
  [showNavigationIndicators]="true"
  [keyboard]="true"
  [pauseOnHover]="true"
  [wrap]="true"
  [activeId]="'secondSlide'"
>
  <ng-template ngbSlide>
    <div id="overLay1">
      <div class="picsum-img-wrapper">
        <img
          class="image-slider"
          src="assets/images/header/1.JPG"
          alt="Random first slide"
          title="first slide"
        />
        <div class="filter"></div>
      </div>
      <div class="carousel-caption">
        <div class="mt-5 handelMargin">
          <h1 class="handelCaptionh" translate>home.Our Mission</h1>
          <h2 class="handelCaption" translate>
            home.Increase employment and food distribution
          </h2>
          <!-- <h2 class="handelCaption">المساهمة في زيادة فرص العمل و توزيع الغذاء </h2> -->
          <!-- <h2 class="handelCaptionh"> مهمتنا  </h2> -->
        </div>
        <!--        <div class="btn-donate-sec mr-lg-4 mt-5">-->
        <!--          <button class="btn donation">-->
        <!--            <a routerLinkActive="underline-decoration" routerLink="/donation"  translate>home.donate</a>-->
        <!--            &lt;!&ndash;                href="https://www.aplos.com/aws/give/newegyptgroup/Supportourmission"&ndash;&gt;-->
        <!--          </button>-->
        <!--        </div>-->
        <!-- <a  routerLinkActive="underline-decoration" routerLink="/donation" class="animated-button1" translate>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          home.donate
        </a> -->
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div id="overLay2">
      <div class="picsum-img-wrapper">
        <img
          class="image-slider"
          src="assets/images/header/2.jpg"
          alt="Random first slide"
          title="first slide"
        />
        <div class="filter"></div>
      </div>
      <div class="carousel-caption">
        <div class="mt-5 handelMargin">
          <h1 class="handelCaptionh" translate>home.Our Mission</h1>
          <h2 class="handelCaption" translate>
            home.Reconstruct villages around the world in rural poverty areas
          </h2>
          <!-- <h2 class="handelCaption"> إعادة بناء القرى في المناطق الريفية الفقيرة حول العالم  </h2> -->
        </div>
        <!--          <div class="btn-donate-sec mr-lg-4 mt-5">-->
        <!--            <button class="btn donation">-->
        <!--              <a routerLinkActive="underline-decoration" routerLink="/donation"  translate>home.donate</a>-->
        <!--              &lt;!&ndash;                href="https://www.aplos.com/aws/give/newegyptgroup/Supportourmission"&ndash;&gt;-->
        <!--            </button>-->
        <!--          </div>-->
        <!-- <a
          routerLinkActive="underline-decoration"
          routerLink="/donation"
          class="animated-button1"
          translate
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          home.donate
        </a> -->
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div id="overLay">
      <div class="picsum-img-wrapper">
        <img
          class="image-slider"
          src="assets/images/header/3.jpg"
          alt="Random first slide"
          title="first slide"
        />
        <div class="filter"></div>
      </div>
      <div class="carousel-caption">
        <div class="mt-5 handelMargin">
          <h1 class="handelCaptionh" translate>home.Our Mission</h1>
          <h2 class="handelCaption" translate>
            home.Improve education systems
          </h2>
          <!-- <h2 class="handelCaption"> المساهمة في تحسين الأنظمة التعليمية </h2> -->
        </div>
        <!--          <div class="btn-donate-sec mr-lg-4 mt-5">-->
        <!--            <button class="btn donation">-->
        <!--              <a routerLinkActive="underline-decoration" routerLink="/donation"  translate>home.donate</a>-->
        <!--              &lt;!&ndash;                href="https://www.aplos.com/aws/give/newegyptgroup/Supportourmission"&ndash;&gt;-->
        <!--            </button>-->
        <!--          </div>-->
        <!-- <a
          routerLinkActive="underline-decoration"
          routerLink="/donation"
          class="animated-button1"
          translate
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          home.donate
        </a> -->
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div id="overLay9">
      <div class="picsum-img-wrapper">
        <img
          class="image-slider"
          src="assets/images/header/4.jpg"
          alt="Random first slide"
          title="first slide"
        />
        <div class="filter"></div>
      </div>
      <div class="carousel-caption">
        <div class="mt-5 handelMargin">
          <h1 class="handelCaptionh" translate>home.Our Mission</h1>
          <h2 class="handelCaption" translate>home.Improve water supplies</h2>
          <!-- <h2 class="handelCaption">المساهمة في تحسين و تطوير إمدادات المياه </h2> -->
        </div>
        <!--          <div class="btn-donate-sec mr-lg-4 mt-5">-->
        <!--            <button class="btn donation">-->
        <!--              <a routerLinkActive="underline-decoration" routerLink="/donation"  translate>home.donate</a>-->
        <!--              &lt;!&ndash;                href="https://www.aplos.com/aws/give/newegyptgroup/Supportourmission"&ndash;&gt;-->
        <!--            </button>-->
        <!--          </div>-->
        <!-- <a
          routerLinkActive="underline-decoration"
          routerLink="/donation"
          class="animated-button1"
          translate
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          home.donate
        </a> -->
      </div>
    </div>
  </ng-template>
  <!-- <ng-template ngbSlide >
      <div id="overLay4">
        <div class="picsum-img-wrapper">
          <img
            class="image-slider"
            src="assets/images/header/5.jpg"
            alt="Random first slide"
            title="first slide"
          />
          <div class="filter"></div>
        </div>
        <div class="carousel-caption">
          <div class="mt-5 handelMargin">
            <h1 class="handelCaption"> Increase employment and food distribution </h1>
            <h2 class="handelCaption">المساهمة في زيادة فرص العمل و توزيع الغذاء </h2>
          </div>
          <a  routerLinkActive="underline-decoration" routerLink="/donation" class="animated-button1" translate>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            home.donate
          </a>
        </div>
      </div>

    </ng-template> -->
  <ng-template ngbSlide>
    <div id="overLay5">
      <div class="picsum-img-wrapper">
        <img
          class="image-slider"
          src="assets/images/header/6.jpg"
          alt="Random first slide"
          title="first slide"
        />
        <div class="filter"></div>
      </div>
      <div class="carousel-caption">
        <div class="mt-5 handelMargin">
          <h1 class="handelCaptionh" translate>home.Our Mission</h1>
          <h2 class="handelCaption" translate>
            home.Alleviating poverty is our first priority
          </h2>
          <!-- <h2 class="handelCaption"> التخفيف من حدة الفقر هي أولويتنا الأولى  </h2> -->
        </div>
        <!--          <div class="btn-donate-sec mr-lg-4 mt-5">-->
        <!--            <button class="btn donation">-->
        <!--              <a routerLinkActive="underline-decoration" routerLink="/donation"  translate>home.donate</a>-->
        <!--              &lt;!&ndash;                href="https://www.aplos.com/aws/give/newegyptgroup/Supportourmission"&ndash;&gt;-->
        <!--            </button>-->
        <!--          </div>-->
        <!-- <a
          routerLinkActive="underline-decoration"
          routerLink="/donation"
          class="animated-button1"
          translate
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          home.donate
        </a> -->
      </div>
    </div>
  </ng-template>
</ngb-carousel>
