<form [formGroup]="form">
  <div class="details-traveler">
    <div class="formTitle">
      <h3>
        Become A Volunteer
      </h3>
    </div>
    <div class="col-12 d-flex overflow-auto" id="style-15">
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Full Name Arabic</mat-label>
            <input matInput placeholder="John" type="text" formControlName="fullName_ar">
            <div class="mat-error error-age"
                 *ngIf="form.get('fullName_ar').errors?.required && form.get('fullName_ar').touched">fullName is
              Required

            </div>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Full Name English</mat-label>
            <input matInput placeholder="John" type="text" formControlName="fullName_en">
            <div class="mat-error error-age"
                 *ngIf="form.get('fullName_en').errors?.required && form.get('fullName_en').touched">fullName English is
              Required
            </div>
          </mat-form-field>
        </div>
        <div class="col-6 position-relative">
          <mat-form-field appearance="fill">
            <mat-label class="star">Email</mat-label>
            <input matInput placeholder="examle@gmail.com" [(ngModel)]="email" type="text" formControlName="email"
                   (focusout)="checkMail()">
            <div class="mat-error error-age "
                 *ngIf="form.get('email').errors?.someProp">{{form.get('email').errors?.someProp}}</div>
          </mat-form-field>
          <div class="marks position-absolute">
            <i *ngIf="spinner" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
            <i *ngIf="right" class="fa fa-check ml-2"></i>
          </div>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Phone Number</mat-label>
            <input matInput placeholder="Phone Number" type="text" [(ngModel)]="phone" formControlName="phone"
                   (focusout)="checkPhone()">
            <div class="mat-error error-age" *ngIf="form.get('phone').errors?.pattern">Please Type Numbers Only.
            </div>
            <div class="mat-error error-age"
                 *ngIf="form.get('phone').errors?.required && form.get('phone').touched">phone is Required
            </div>
            <div class="mat-error error-age "
                 *ngIf="form.get('phone').errors?.someProp">{{form.get('phone').errors?.someProp}}</div>
          </mat-form-field>
          <div class="marks position-absolute">
            <i *ngIf="spinner2" class="fas fa-spinner fa-spin airLineSpinner ml-2"></i>
            <i *ngIf="right2" class="fa fa-check ml-2"></i>
          </div>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Address</mat-label>
            <input matInput placeholder="John" type="text" formControlName="address">
            <div class="mat-error error-age"
                 *ngIf="form.get('address').errors?.required && form.get('address').touched">Address is
              Required
            </div>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" (click)="join_date.open()">
            <mat-label class="star">Joined Date</mat-label>
            <input matInput placeholder="Due Date" (dateChange)="checkDate($event)" [matDatepicker]="join_date"
                   formControlName="join_date">
            <mat-datepicker-toggle matSuffix [for]="join_date"></mat-datepicker-toggle>
            <mat-datepicker #join_date></mat-datepicker>
            <div class="mat-error error-age"
                 *ngIf="form.get('join_date').errors?.someProp">{{form.get('join_date').errors?.someProp}}</div>
            <div class="mat-error error-age"
                 *ngIf="form.get('join_date').errors?.required && form.get('join_date').errors?.touched">Please
              enter a Joined Date
            </div>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Governorate</mat-label>
            <input matInput placeholder="country" [matAutocomplete]="governorate" type="text"
                   formControlName="governorate">
            <mat-autocomplete autoActiveFirstOption #governorate="matAutocomplete"
                              (optionSelected)='appendMainClubs($event.option.value)'>
              <mat-option *ngFor="let option of governorates" [value]="option.name_en">
                {{option.name_en}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Country</mat-label>
            <input matInput placeholder="country" [matAutocomplete]="country" type="text"
                   formControlName="country">
            <mat-autocomplete autoActiveFirstOption #country="matAutocomplete"
                              (optionSelected)='getPosts($event.option.value)'>
              <mat-option *ngFor="let option of countries" [value]="option.name_en">
                {{option.name_en}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Education</mat-label>
            <input matInput placeholder="Education" type="text" formControlName="education">
            <div class="mat-error error-age"
                 *ngIf="form.get('education').errors?.required && form.get('education').touched">Address is
              Required
            </div>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Club</mat-label>
            <input matInput placeholder="Club" [matAutocomplete]="main_club" type="text"
                   formControlName="clubs">
            <mat-autocomplete autoActiveFirstOption #main_club="matAutocomplete">
              <mat-option *ngFor="let option of clubs" [value]="option.name_en">
                {{option.name_en}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Work</mat-label>
            <input matInput placeholder="work" type="text" formControlName="work">
            <div class="mat-error error-age"
                 *ngIf="form.get('work').errors?.required && form.get('work').touched">work is
              Required
            </div>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Voluntary Work</mat-label>
            <input matInput placeholder="Voluntary Work" type="text" formControlName="voluntaryWork">
            <div class="mat-error error-age"
                 *ngIf="form.get('voluntaryWork').errors?.required && form.get('voluntaryWork').touched">Address is
              Required
            </div>
          </mat-form-field>
        </div>
        <div class="col-12 text-center">
          <button class="btn submit-btn" [disabled]="form.status == 'INVALID'" (click)="close()">SUBMIT</button>
        </div>
      </div>
    </div>
  </div>
</form>
