<section class="direction-ltr">
  <div class="container">
    <div class="row mt-3">
      <div class="col-md-3 col-sm-6" *ngFor="let orphan of orphans ">
        <div *ngIf="cardLogin" class=" card card-two">
          <header>
            <div class="avatar d-flex justify-content-center align-items-center">
              <img src="{{orphan['image']}}" alt="Allison Walker"/>
            </div>
          </header>

          <h3>{{orphan['name']}}</h3><h6 class="text-center">{{orphan['address']}}</h6>
          <div class="desc">
            {{orphan['governorates']['name_ar']}}
            <p class="font-weight-bold">{{orphan['clubs']['name_ar']}}</p>
          </div>
          <div class="mb-3">
            <button class="btn btn-outline-success mr-2" routerLink="/memberDetails">More Info <i class="fa fa-arrow-right ml-1"></i></button>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>
