<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">

<div class="container" *ngIf="activities">
  <div class="row">
    <div class="col-12">
      <h1 class="mt-5">
        <div class="mt-5 tilte">{{activities.activities.name}}</div>
      </h1>
    </div>
  </div>
    <div class="row">
        <div class="col-8 mt-4">
            <ngb-carousel>
                <ng-container *ngFor="let item of activities.activities.images; index as i ">
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                            <img *ngIf="activities"
                                 [src]=item.url
                                 alt="Random second slide"
                                 width="100%"
                                 height="100%"
                                 style="border-radius: 12px;"
                            />
                        </div>
                        <div class="carousel-caption"></div>
                    </ng-template>
                </ng-container>
            </ngb-carousel>
            <div class="text-center mt-5 mb-0">
              <button class="bg-card book" (click)="openDonationForm()" translate>home.donate</button>
            </div>
        </div>
        <div class="col-4 mt-5 mb-5">
          <div class="row">
            <section>
              <div class="col-lg-1 order-lg-first mt-4 mb-5 mx-5">
                <div class="sticky-content">
                  <div class="social-icons social-icons-colored social-icons-vertical">
                    <div class="icon-share">
                      <span class="social-label " translate>home.share</span>
                      <a class="social-icon image-item" (click)="cop()"
                         target="_blank">
                        <img *ngIf="!copied" title="copy" style="width: 22px"
                             alt="copy"
                             src="./assets/newIcon/copy-2.png"/>
                        <img *ngIf="copied" title="copied" style="width: 28px"
                             alt="copied"

                             src="./assets/newIcon/copied.png"/>
                      </a>
                      <a (click)="fbc($event)" class="social-icon social-facebook"
                         title="Facebook"><i
                        class="icon-facebook-f"></i></a>
                      <a (click)="tw()" class="social-icon social-twitter"
                         title="Twitter"><i
                        class="icon-twitter"></i></a>
                      <a (click)="in()" class="social-icon social-linkedin"
                         title="Linkedin"><i
                        class="icon-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="">
            <ng-container class="mt-5" *ngIf="activities.activities.open_donation_form == 1 ">
              <div class="raised row">
<!--                <span class="income" data-amounts="85200"></span>-->
                <div class="col-12">
                  <span class="income mx-3" translate>home.We’ve-raised</span>
                </div>
                <div class="col-12 mt-3">
                  <span class="budget">{{activities.activities.budget | currency}}</span>
                </div>
                <div class="col-12 mt-3">
                  <span class="of-our" data-amounts="300000" translate>home.OF-OUR</span>
                  <!--                <span class=" mt-5">raised</span>-->
                </div>
                <div class="col-12 mt-3">
                  <span class="target">{{activities.activities.target | currency}}</span>
                </div>
                <div class="col-12 mt-3">

                  <span class="goal" translate>home.GOAL</span>
                </div>
              </div>
                <div class="progress mt-4 mb-3">
                  <div class="progress-bar" [class]="bgProgress" role="progressbar" [style.width]="(activities.activities.budget / activities.activities.target) * 100 +'%'"  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </ng-container>

          </div>

        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="copy">
                <!-- <div>
                    <p>{{activities.activities.details| slice:0:200}}....</p>
                </div> -->
                <div class="portfolio-description   readMore">
                  <p class="sub_description"></p>
                  <span class="aboutText" *ngIf="showAll"> {{activities.activities.excerpt| slice:0:320}}.....<p
                    *ngIf="showAll&&activities.activities.excerpt.length>200" (click)="changeFlag()" class="readMore" translate>home.read-more</p> </span>
      
                  <span class="aboutText" *ngIf="!showAll"> {{activities.activities.excerpt}}<p *ngIf="!showAll" (click)="changeFlag();" class="readMore" translate>home.read-less</p> </span>
                </div>

            </div>
        </div>
    </div>

    <div class="text-center mt-5 mb-4">
      <button class="bg-card book" (click)="openDonationForm()" translate>home.donate</button>
    </div>

</div>
<!-- <form [formGroup]="myForm" (ngSubmit)="createToken()">
    <div class="d-flex overflow-auto" id="style-15">
        <div class="container text-center mt-5">
            <div class="section-title" *ngIf="!user" translate>
                home.know-you
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-11" *ngIf="!user">
                            <div class="mt-5">
                                <h4 class="tell-us" translate>home.full-name</h4>
                            </div>
                            <mat-form-field appearance="fill">
                                <mat-label></mat-label>
                                <input matInput placeholder="full name" type="text" formControlName="fullname">
                            </mat-form-field>
                        </div>
                        <div class="col-11 " *ngIf="!user">
                            <div class="mt-5">
                                <h4 class="tell-us" translate>home.phone-number</h4>
                            </div>
                            <mat-form-field appearance="fill">
                                <mat-label></mat-label>
                                <input matInput placeholder="phone number" type="text" formControlName="phone">
                            </mat-form-field>
                        </div>
                        <div class="col-11" *ngIf="!user">
                            <div class="mt-5">
                                <h4 class="tell-us" translate>home.email</h4>
                            </div>
                            <mat-form-field appearance="fill">
                                <mat-label></mat-label>
                                <input matInput placeholder="email" type="text" formControlName="email">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10 section-title ml-3 mt-3" translate>
                            home.DONATE
                          <div class="row justify-content-center">
                            <div class="col-12 section ml-5 mt-4" translate>
                              home.custom-amount
                            </div>
                            <div class="col-6 mt-3">
                              <div class="input-group">
                                <span class="input-group-text" style="z-index: 66">$</span>
                                <input id="custom_amount" class="position-absolute" type="number"
                                       formControlName="amount"
                                       style="width: 100%; min-height: 100%;padding-left: 50px">
                              </div>
                            </div>
                            <div class="col-4"></div>
                            <div class="col-2">
                              <label class="checkbox mt-4 ">
                                <input type="checkbox" name="vehicle1" value="Bike" formControlName="checked_fee" (click)="checkedFee"   style="cursor: pointer; min-width: 58px; min-height: 50px ;">
                                </label>
                            </div>
                          </div>
                        </div>

                        <div class="col-4" *ngFor="let donate of donateSelect">
                            <button class="pill" [class]="select == donate ? 'pill-select':''"
                                    (click)="selectDonate(donate)" type="button">${{donate}}</button>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-12 section-title ml-2 mt-5" translate>
                      home.payment-details
                    </div> -->
                    <!--            <div class="col-12">-->
                    <!--              <div class="mt-5">-->
                    <!--                <h4 class="tell-us">Name on Credit Card </h4>-->
                    <!--              </div>-->
                    <!--              <mat-form-field appearance="fill">-->
                    <!--                <mat-label></mat-label>-->
                    <!--                <input matInput placeholder="" type="text">-->
                    <!--              </mat-form-field>-->
                    <!--            </div>-->
                    <!-- <div class="col-12">
                      <div class="mt-5">
                        <h4 class="tell-us" translate>home.card-information</h4>
                      </div>
                      <mat-form-field appearance="fill" class="">
                        <mat-label></mat-label>
                        <input style="display: none;" matInput placeholder="" type="text">
                        <ngx-stripe-card
                          [options]="cardOptions"
                        ></ngx-stripe-card>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row mt-5 justify-content-center" style="margin-bottom: 30px; padding-left: 25px">
                        <button class="btn submit-btn" [disabled]="disabled" type="submit" translate>home.donate</button>
                    </div>
                </div> -->
<!--                <div class="col-6" style="margin-bottom: 30px;">-->
<!--                    <img class="img"-->
<!--                         src="./assets/images/610X860.jpg">-->
<!--                </div>-->
            <!-- </div>
        </div>
    </div>
</form> -->
