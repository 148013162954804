import { Component, OnInit } from '@angular/core';
import {PleaseLoginComponent} from '../../components/please-login/please-login.component';
import {MatDialog} from '@angular/material/dialog';
import {ApisService} from '../../services/apis.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {
  members: any;


  constructor(private router: Router,public dialog: MatDialog,
              private apis: ApisService,
              private activatedRoute: ActivatedRoute,
              ) { }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.openDialog();
    // }, 10000);
    this.getMembersApi();
  }
  openDialog(): any {
    const x = document.querySelectorAll('.col-md-3');
    x.forEach((ele: any) => {
      ele.style.opacity = '0.1';
      ele.style.filter = 'blur(8px)';
    });
    const dialogRef = this.dialog.open(PleaseLoginComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getMembersApi(): void {
    this.apis.membersApi().subscribe((data: any) => {
      this.members = data.data;
    }, (err: any) => {
    });
  }
  memberDetails(id): void{
    // this.router.navigate(['/memberDetails/' + id]);
  }
}


