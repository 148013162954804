import {Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {ApisService} from "../services/apis.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatAutocomplete} from "@angular/material/autocomplete";
import {SeoService} from "../services/seo.service";
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {Clipboard} from "@angular/cdk/clipboard";
import {id} from "date-fns/locale";
import { DonationFormComponent } from '../donation-form/donation-form.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-donation-for-activity',
  templateUrl: './donation-for-activity.component.html',
  styleUrls: ['./donation-for-activity.component.scss']
})
export class DonationForActivityComponent implements OnInit {
  activity: any;
  videos:any = [];
  slug: any;
  showAll: boolean = true;

  @Input() total;
  disabled: boolean = false;
  stripeCardValid: boolean = false;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '30px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  // elementsOptions: StripeElementsOptions = {
  //   locale: 'en'
  // };
  myForm: FormGroup;
  user:any;
  token: any;
  generalpurposes: any;
  donateactivities: any;
  donateforactivity: any;
  show: boolean = true;
  hidden: boolean = true;
  activities: any;
  tasksId: any;
  // activities: MatAutocomplete;
  purpose: MatAutocomplete;
  donateSelect: any[] =[100,500,2000,4000,8000,10000];
  select:number;
  errorMessage!: string;
  copied:boolean=false;
  activitiesId:number;
  bgProgress: string = '';
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private seoService: SeoService,
              private apis: ApisService,
              private fb: FormBuilder,
              private stripeService: StripeService,
              private toastr: ToastrService,
              private activatedRoute : ActivatedRoute,
              private router : Router,
              private clipboard : Clipboard,
              public dialog: MatDialog,


  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('access_token')
    }
  }
  ngOnInit(): void {
    this.slug = this.activatedRoute.snapshot.params.id;
    console.log('slug  ==>', this.slug)
    this.getActivitiesApi();
    // this.seoUpdate();
    if (isPlatformBrowser(this.platformId)) {
      this.user= JSON.parse(localStorage.getItem('currentUser'))
      console.log(this.user)
    }

    this.myForm = this.fb.group({
      fullname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      // card_number: ['', Validators.required],
      // exp_month: ['', Validators.required],
      // exp_year: ['', Validators.required],
      // cvc: ['', Validators.required],
      amount: ['', Validators.required],
      stripeToken: ['', Validators.required],
      checked_fee: ['', Validators.required],

    });
  }
  checkedFee(fee){
    if(fee){
      this.myForm.controls['checked_fee']
      this.myForm.patchValue({
        checked_fee: 1
      })
    }else{
      this.myForm.patchValue({
        checked_fee:''
      })
    }
  }
  createToken(): void {
    const stripeToken = this.myForm.get('stripeToken').value;
    this.stripeService
      .createToken(this.card.element, { name: stripeToken })
      .subscribe((result) => {
        if (result.token) {
          this.myForm.patchValue({
            stripeToken: result.token.id
          })
          this.onSubmit()
          // this.onSubmit(this.myForm);
          // this.formSubmited = true;
          // Use the token
          console.log(result.token.id);
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }

  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('New Egypt International Group | Donate and Make a Difference');
    this.seoService.updateDescription('Your donation will help support our work to improve the quality of life for people in need all around the world. With your help, we can make a difference.');
    this.seoService.updateUrl();
    this.seoService.updateImage( this.activities.activities.images[0].url);
  }
  getActivitiesApi(): void {
    this.apis.getActivities(this.slug).subscribe((data: any) => {
      console.log(data,"ass");
      this.activities = data.data;
      this.tasksId = this.activities.activities.id;
      console.log(this.tasksId)
      this.seoUpdate();
      console.log(this.activities);

      if ((this.activities.activities.budget / this.activities.activities.target) * 100 < 25) {
        this.bgProgress = 'bg-danger';
      }
      if ((this.activities.activities.budget / this.activities.activities.target) * 100 >= 25 && (this.activities.activities.budget / this.activities.activities.target) * 100 <= 50) {
        this.bgProgress = 'bg-warning';
      }
      if ((this.activities.activities.budget / this.activities.activities.target) * 100 >= 50 && (this.activities.activities.budget / this.activities.activities.target) * 100 < 100) {
        this.bgProgress = '';
      }
      if ((this.activities.activities.budget / this.activities.activities.target) * 100 >= 100) {
        this.bgProgress = 'bg-success';
      }
      // this.videos = this.activity.activities.public_videos;
      // this.seoUpdate(this.activities.activities.meta_title, this.activities.activities.meta_description, this.activities.activities.meta_image);
    }, (err: any) => {
    });
  }
  changeFlag() {
    this.showAll = !this.showAll;
}
  selectDonate(select:number){
    this.select = select
    this.myForm.patchValue({
      amount: select
    })
  }
  onSubmit() {
    this.disabled = true;

    if (this.user){
      this.myForm.controls['fullname'].disable();
      this.myForm.controls['email'].disable();
      this.myForm.controls['phone'].disable();
    }
    this.apis.getDonateForActivity(this.tasksId,this.myForm.value).subscribe({
      next:(res:any)=>{
        console.log('donation =>', res);
        this.toastr.success(res.message, 'Success');
        this.disabled = false;

      },error:(err:any)=>{
        console.log(err)
        this.errorMessage = err.error.errors
        for (const key in err.error.errors) {
          this.toastr.error( err.error.errors[key] , 'faild')
        }
        this.disabled = false;
      }
    })
    console.log(this.myForm.value)
  }

  donateForActivityApi(id): any {
    this.apis.getDonateForActivity(id,this.myForm.value).subscribe((data: any) => {
        this.donateforactivity = data;
        console.log(this.donateforactivity)
      },
      err => {
        console.log(err);
      });
  }

  cop() {
    this.clipboard.copy('https://newegyptgroup.com' + this.router.url)
    this.copied = !this.copied;
    setTimeout(() => {
      this.copied = false;
    }, 1500)

  }

  fbc(e) {
    let url = "www.google.com";
    e.preventDefault();
    var facebookWindow = window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
        "newegyptgroup.com" +
        this.router.url,
        "facebook-popup",
        "height=700px,width=555px"
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }

    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://twitter.com/intent/tweet?url=' + 'newegyptgroup.com' + this.router.url,
        '-popUp',
        'width= 555px, height= 450px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  in() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://linkedin.com/shareArticle?mini=true&url=' + 'newegyptgroup.com' + this.router.url,
        '-popUp',
        'width= 555px, height= 603px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }
  openDonationForm() {
    const dialogRef = this.dialog.open(DonationFormComponent , {
      height: '90vh',
      width: '25vw',
      data :{id: this.tasksId}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
