import {Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() message: any;
  @Input() date: any;
  @Input() bookingNumber: any;
  @Input() dataSelected: any;
  @Input() personalData: any;
  @Output() closeEvent:EventEmitter<any> = new EventEmitter();
  services: any;
  times: any;

  ngOnInit(): void {
    this.services = this.dataSelected['services'];
    this.times = this.dataSelected['times'];
    // this.dateFormat();
  }

  constructor(private router: Router) {
  }

  dateFormat(): any {
    this.date = this.date.toISOString().slice(0, 10);
  }

  redirectHome(): any {
    this.router.navigate(['']);
  }

  trnsformTime(time): any {
    if (time == '11:00 am') {
      return '11:00 صباحا';
    }
    if (time == '11:30 am') {
      return '11:30 صباحا';
    }
    if (time == '12:00 pm') {
      return '12:00 ظهرا';
    }
    if (time == '12:30 pm') {
      return '12:30 ظهرا';
    }
    if (time == '1:00 pm') {
      return '01:00 ظهرا';
    }
    if (time == '9:00 am') {
      return '09:00 صباحا';
    }
    if (time == '9:30 am') {
      return '09:30 صباحا';
    }
    if (time == '10:00 am') {
      return '10:00 صباحا';
    }
    if (time == '10:30 am') {
      return '10:30 صباحا';
    }
    if (time == '1:30 pm') {
      return '01:30 مساء';
    }
    if (time == '2:00 pm') {
      return '02:00 مساء';
    }
    if (time == '2:30 pm') {
      return '02:30 مساء';
    }
    if (time == '3:00 pm') {
      return '03:00 مساء';
    }
  }

  // tConvert(time): any {
  //   // Check correct time format and split into components
  //   time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  //
  //   if (time.length > 1) { // If time format correct
  //     time = time.slice(1);  // Remove full string match value
  //     const removeItem = (items, i) =>
  //       items.slice(0, i - 1).concat(items.slice(i, items.length))
  //     time = removeItem(time, 2);
  //     time = removeItem(time, 1);
  //     time[5] = +time[0] < 12 ? 'صباحا' : 'مساءً'; // Set AM/PM
  //     time[0] = +time[0] % 12 || 12; // Adjust hours
  //     if (time[0] < 10) {
  //       time[0] = '0' + time[0];
  //     }
  //   }
  //   return time.join(''); // return adjusted time or original string
  // }

}
