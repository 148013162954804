import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ApisService} from "../services/apis.service";
import {Observable} from "rxjs";
import {map, startWith} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-add-club',
  templateUrl: './add-club.component.html',
  styleUrls: ['./add-club.component.scss']
})
export class AddClubComponent implements OnInit {
  addClub: FormGroup;
  filteredCountries: Observable<string[]>;
  countries: any;
  states: any;
  errorMessage!: string;
  filteredStates: Observable<string[]>;
  filteredCities: Observable<string[]>;
  cities: any;
  countryName: any;
  countryId: any;
  stateName: any;
  stateId: any;
  cityName: any;
  cityId: any;
  public messageSuccess = '';
  public messageError = '';


  constructor(private activatedRoute: ActivatedRoute,
              private apis: ApisService,
              public fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService

  ) {

  }

  ngOnInit(): void {
    this.getCountries()
    this.addClub = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]],
      club_name: ['', [Validators.required]],
      club_activities: ['', [Validators.required]],
      club_info: ['', [Validators.required]],
      notes: ['',],
    });

  }

  setFilterCountries() {
    this.filteredCountries = this.addClub.controls.country.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  setFilterStates() {
    this.filteredStates = this.addClub.controls.state.valueChanges.pipe(
      startWith(''),
      map(value => this._filterStates(value || '')),
    );
  }
  setFilterCities() {
    this.filteredCities = this.addClub.controls.city.valueChanges.pipe(
      startWith(''),
      map(value => this._filterCities(value || '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  private _filterCities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getCountries(): any {
    this.apis.countries().subscribe((data: any) => {
        this.countries = data.data;
        this.setFilterCountries();
      },
      err => {
        console.log(err);
      });
  }

  getStates(event): any {
    console.log(event.option.value)

    this.apis.states(this.getCountryObject(event.option.value).id).subscribe((data: any) => {
        this.states = data.data;
        this.setFilterStates();
      },
      err => {
        console.log(err);
      });

  }

  getCities(event): any {
    console.log(event)

    this.apis.cities(this.getStatesObject(event.option.value).id).subscribe((data: any) => {
        this.cities = data.data;
        this.setFilterCities();
      },
      err => {
        console.log(err);
      });
  }

  selectedCity(event): any {
    console.log(event.option.value)
    this.getCitiesObject(event.option.value).id

  }

  close(): void {
    // const obj = this.addMemberForm();
    // this.callAddMemberApi(this.addMemberForm());
    // this.dialogRef.close(this.addMemberForm());
  }

  submitForm(): any {
    console.log(this.addClub.value)
    const formData: FormData = new FormData();
    formData.append('name', this.addClub.value.name);
    formData.append('email', this.addClub.value.email);
    formData.append('phone', this.addClub.value.phone);
    formData.append('address', this.addClub.value.address);
    formData.append('country_id', this.getCountryObject(this.addClub.value.country).id);
    formData.append('state_id', this.getStatesObject(this.addClub.value.state).id);
    formData.append('city_id', this.getCitiesObject(this.addClub.value.city).id);
    formData.append('club_name', this.addClub.value.club_name);
    formData.append('club_activities', this.addClub.value.club_activities);
    formData.append('club_info', this.addClub.value.club_info);
    formData.append('notes', this.addClub.value.notes);
    // @ts-ignore
    return formData;
  }

  getCountryObject(countryName): any {
    const index = this.countries.findIndex(obj => obj.name === countryName);
    console.log(this.countries[index])
    if (index > -1) {
      return this.countries[index];
    }
  }
  getStatesObject(stateName): any {
    const index = this.states.findIndex(obj => obj.name === stateName);
    console.log(this.states[index])
    if (index > -1) {
      return this.states[index];
    }
  }
  getCitiesObject(cityName): any {
    const index = this.cities.findIndex(obj => obj.name === cityName);
    console.log(this.cities[index])
    if (index > -1) {
      return this.cities[index];
    }
  }

  add() {
    this.apis.addClubApi(this.submitForm()).subscribe( {next:data => {
        // this.messageSuccess= data['message']
        // this.router.navigate(['/']);
        this.toastr.success(data.message, 'Success');

      },error:
      error => {
        console.log(error);
        this.errorMessage = error.errors
        this.toastr.error( error.message, 'Failed');
        // this.messageError= error
      }}
    );
  }
}

