import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApisService} from '../../services/apis.service';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  videoUrl = 'https://player.vimeo.com/external/567641203.hd.mp4?s=c04076dd9a63e7d8dd2c7312cf1178db4066063b&profile_id=175';
  projects: any;

  constructor(private router: Router, private apis: ApisService, private seoService: SeoService) {
  }

  ngOnInit(): void {
    this.videoHandler();
    this.getProjects();
    this.seoUpdate();
  }

  videoHandler(): void {
    if (window.screen.availWidth < 600) {
      this.videoUrl = 'https://player.vimeo.com/external/567151192.hd.mp4?s=db1921b9a7500a7a0cf1846bdaf69cec317782c5&profile_id=175';
    }
  }

  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('New Egypt Projects');
    this.seoService.updateDescription('New Egypt Projects');
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/explore/header.png');
  }

  projectDetails(id): void {
    this.router.navigate(['/project-details/' + id]);
  }

  getProjects(): void {
    this.apis.projectsApi().subscribe((data: any) => {
      this.projects = data.data;
      console.log(this.projects);
    }, (err: any) => {
    });
  }

  transform(n: string): string {
    var date = new Date(n);
    var months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    var days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }
}
