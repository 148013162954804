import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { ChildrenComponent } from './pages/children/children.component';
import { CaseDetailsComponent } from './pages/case-details/case-details.component';
import { VideoDescriptionComponent } from './pages/video-description/video-description.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { NewsDetailsComponent } from './pages/news-details/news-details.component';
import { NewsComponent } from './pages/news/news.component';
import { HayahComponent } from './pages/hayah/hayah.component';
import { EventComponent } from './pages/event/event.component';
import { HayaNewsDetailsComponent } from './pages/haya-news-details/haya-news-details.component';
import { EventDetailsComponent } from './pages/event-details/event-details.component';
import { AddMemberFormComponent } from './components/add-member-form/add-member-form.component';
import { MembersFormComponent } from './pages/members-form/members-form.component';
import { TestComponent } from './components/test/test.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { MembersComponent } from './pages/members/members.component';
import { VolunteersComponent } from './pages/volunteers/volunteers.component';
import { BoardsComponent } from './pages/boards/boards.component';
import { MemberDetailsComponent } from './pages/member-details/member-details.component';
import { MembersSliderComponent } from './components/members-slider/members-slider.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { SendemailComponent } from './pages/sendemail/sendemail.component';
import { BookingComponent } from './pages/booking/booking.component';
import { OrphansComponent } from './pages/orphans/orphans.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { Ramadan2022Component } from './pages/ramadan2022/ramadan2022.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { JoinUsComponent } from "./join-us/join-us.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ClubsComponent } from "./clubs/clubs.component";
import { ClubMembersComponent } from "./club-members/club-members.component";
import { AddClubComponent } from "./add-club/add-club.component";
import { DonationComponent } from "./donation/donation.component";
import * as path from "path";
import { AuthGuard } from "./shared/auth.guard";
import { DonationForActivityComponent } from "./donation-for-activity/donation-for-activity.component";
import { PersonalFormComponent } from "./components/profile/personal-form/personal-form.component";
import { FieldSocialResearchComponent } from "./field-social-research/field-social-research.component";
import { PrivacyPoliciesComponent } from './pages/privacy-policies/privacy-policies.component';
import { PolicyDetailsComponent } from './pages/policy-details/policy-details.component';

const routes: Routes = [
  // { path: '', redirectTo:'home',pathMatch:'full'},
  { path: '', component: HomeComponent }, //,canActivate: [AuthGuard]//
  { path: 'donation', component: DonationComponent },
  { path: 'donation-for-activity/:id', component: DonationForActivityComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'project-details/:id', component: ProjectDetailsComponent },
  { path: 'children', component: ChildrenComponent },
  { path: 'case-details', component: CaseDetailsComponent },
  { path: 'videos-description/:id', component: VideoDescriptionComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news-details/:slug', component: NewsDetailsComponent },
  { path: 'haya-news-details/:id', component: HayaNewsDetailsComponent },
  { path: 'haya', component: HayahComponent },
  { path: 'event', component: EventComponent },
  { path: 'eventDetails/:slug', component: EventDetailsComponent },
  { path: 'add-member', component: MembersFormComponent },
  { path: 'join-us', component: JoinUsComponent },
  { path: 'edit-profile', component: EditProfileComponent },
  { path: 'activities', component: ActivitiesComponent },
  { path: 'members', component: MembersComponent },
  { path: 'volunteers', component: VolunteersComponent },
  { path: 'boards', component: BoardsComponent },
  { path: 'clubs', component: ClubsComponent },
  { path: 'club-members', component: ClubMembersComponent },
  { path: 'add-club', component: AddClubComponent },
  { path: 'memberDetails/:board/:id', component: MemberDetailsComponent },
  { path: 'test', component: TestComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'personal-form', component: PersonalFormComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'sendemail', component: SendemailComponent },
  { path: 'booking', component: BookingComponent },
  { path: 'orphans', component: OrphansComponent },
  { path: 'cer', component: CertificateComponent },
  { path: 'ramadan2022', component: Ramadan2022Component },
  { path: 'login', component: LoginComponent },
  { path: 'case-form', component: FieldSocialResearchComponent },
  { path: 'privacy-policy', component: PrivacyPoliciesComponent },
  { path: 'policy-details/:privacyId', component: PolicyDetailsComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
