import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-please-login',
  templateUrl: './please-login.component.html',
  styleUrls: ['./please-login.component.scss']
})
export class PleaseLoginComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PleaseLoginComponent>) { }

  ngOnInit(): void {
  }
  closeDialog() {
    this.dialogRef.close('Pizza!');
  }
}
