import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    this.reversString('pesr');
  }

  reversString(word): any {
    let wordReverse = '';
    if (word.length > 3) {
      for (let i = word.length - 1; i >= 0; i--) {
         wordReverse = wordReverse + word[i];
      }
    }else {
      console.log('your Length less than three chars');
    }

  }

}
