<ng-container>
  <div class="services-popup">
    <div class="available-services">
      <span>الخدمات المتاحه</span>
    </div>
    <div class="row height-26rem">
      <div class="col-12">
        <ng-container *ngFor="let specialServiceType of specialServicesType">
          <div class="services">
            <div class="national-id-service">
              <span class="head-line">{{translateSpecial(specialServiceType)}}</span>
              <div class="checkboxes">
                <ng-container *ngFor="let service of specialServices[specialServiceType]">
                  <mat-checkbox [checked]="checkIfSelectedService(service)"
                                (change)="servicesHandler(service,$event);">{{service.name}}</mat-checkbox>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let serviceType of servicesTypes">
          <div class="services">
            <div class="national-id-service">
              <span class="head-line">{{translate(serviceType)}}</span>
              <div class="checkboxes row">
                <div class="col-md-4 col-sm-12" *ngFor="let service of services[serviceType]">
                  <mat-checkbox [checked]="checkIfSelectedService(service)"
                                (change)="servicesHandler(service,$event);">{{service.name}}</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-12">
        <div class="time-reserve row ">
          <div class="other-services col-md-6" *ngIf="checkedExcellentServices">
            <mat-form-field appearance="fill">
              <mat-label>اختر موعد لخدمة الرقم القومى</mat-label>
              <mat-select required (selectionChange)="setTime($event)">
                <mat-option *ngFor="let time of timeChosen" [value]="time">{{trnsformTime(time.time)}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="national-id">
            <div class="col-12 mb-3 mt-3">
              <mat-label class="head-line"> اختر موعد للخدمات</mat-label>
            </div>
            <div class="w-100">
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="w-100 d-flex  justify-content-between flex-row flex-wrap"  [(ngModel)]="selectedTimes2"
              >
                <mat-radio-button class="example-radio-button" *ngFor="let time of timeChosen" [value]="time">
                  {{trnsformTime(time.time)}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="text-left follow-button d-flex justify-content-between align-items-center">
      <a (click)="confirm();" class="follow-btn btn btn-danger">متابعه</a>
      <mat-error *ngIf="errorMessage" class="mat-error font-weight-bold ">
        {{errorMessage}}
      </mat-error>
    </div>
  </div>
</ng-container>
