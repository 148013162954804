<!-- <img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png"> -->

<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="mt-5">
        <div class="mt-5 tilte" translate>home.support-our-mission</div>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-8 mt-4">
                <div class="picsum-img-wrapper">
                  <img src="./assets/images/610X360%203.jpg"
                       alt="Random second slide"
                       width="100%"
                       height="100%"
                       style="border-radius: 12px;"
                  />
                </div>
                <div class="carousel-caption"></div>
          </div>
    <div class="col-4 mt-5 mb-5">
      <section>
        <div class="col-lg-1 order-lg-first mt-4">
          <div class="sticky-content">
            <div class="social-icons social-icons-colored social-icons-vertical">
              <div class="icon-share">
                <span class="social-label" translate>home.share</span>
                <a class="social-icon image-item" (click)="cop()"
                   target="_blank">
                  <img *ngIf="!copied" title="copy" style="width: 22px"
                       alt="copy"
                       src="./assets/newIcon/copy-2.png"/>
                  <img *ngIf="copied" title="copied" style="width: 28px"
                       alt="copied"

                       src="./assets/newIcon/copied.png"/>
                </a>
                <a (click)="fbc($event)" class="social-icon social-facebook"
                   title="Facebook"><i
                  class="icon-facebook-f"></i></a>
                <a (click)="tw()" class="social-icon social-twitter"
                   title="Twitter"><i
                  class="icon-twitter"></i></a>
                <a (click)="in()" class="social-icon social-linkedin"
                   title="Linkedin"><i
                  class="icon-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="copy">
        <div>
          <p translate>home.mission-today</p>
          <p translate>home.your-generous</p>
        </div>

      </div>
    </div>
  </div>
  <div class="text-center mt-5 mb-4">
    <button class="bg-card book" (click)="openDonationForm()" translate>home.donate</button>
  </div>
</div>
<!-- <form [formGroup]="myForm" (ngSubmit)="createToken()">
  <div class="d-flex overflow-auto" id="style-15">
    <div class="container text-center mt-5">
      <div class="section-title"  *ngIf="!user" translate>
        home.know-you
      </div>
      <div class="row">
        <div class="col-6">
          <div class="alert alert-danger mt-4" *ngIf="errorMessage">
            <ng-container *ngFor="let err of errorMessage | keyvalue">
              <p class="text-left">{{err.value}}</p>
            </ng-container>
          </div>
          <div class="row">
            <div class="col-11" *ngIf="!user">
              <div class="mt-5">
                <h4 class="tell-us" translate>home.full-name</h4>
              </div>
              <mat-form-field appearance="fill">
                <mat-label></mat-label>
                <input matInput placeholder="full name" type="text" formControlName="fullname">
              </mat-form-field>
            </div>
            <div class="col-11 " *ngIf="!user">
              <div class="mt-5">
                <h4 class="tell-us" translate>home.phone-number</h4>
              </div>
              <mat-form-field appearance="fill">
                <mat-label></mat-label>
                <input matInput placeholder="phone number" type="text" formControlName="phone">
              </mat-form-field>
            </div>
            <div class="col-11" *ngIf="!user">
              <div class="mt-5">
                <h4 class="tell-us" translate>home.email</h4>
              </div>
              <mat-form-field appearance="fill">
                <mat-label></mat-label>
                <input matInput placeholder="email" type="text" formControlName="email">
              </mat-form-field>
            </div>
          </div>
          <div class="row"> -->
        <!-- <div class="col-12">
          <div class="mt-5">
            <h4 class="tell-us">Name on Credit Card </h4>
          </div>
          <mat-form-field appearance="fill">
            <mat-label></mat-label>
            <input matInput placeholder="" type="text">
          </mat-form-field>
        </div> -->
<!--        <div class="col-4">-->
<!--          <div class="mt-5">-->
<!--            <h4 class="tell-us">Expiration Month *</h4>-->
<!--          </div>-->
<!--          <mat-form-field appearance="fill">-->
<!--            <mat-label></mat-label>-->
<!--            <input matInput placeholder="MM" type="number" formControlName="exp_month">-->
<!--          </mat-form-field>-->
<!--        </div>-->
<!--        <div class="col-4">-->
<!--          <div class="mt-5">-->
<!--            <h4 class="tell-us">Expiration Year *</h4>-->
<!--          </div>-->
<!--          <mat-form-field appearance="fill">-->
<!--            <mat-label></mat-label>-->
<!--            <input matInput placeholder="YYY" type="number" formControlName="exp_year">-->
<!--          </mat-form-field>-->
<!--        </div>-->
<!--            <div class="col-4">-->
<!--              <div class="mt-5">-->
<!--                <h4 class="tell-us">CVC *</h4>-->
<!--              </div>-->
<!--              <mat-form-field appearance="fill">-->
<!--                <mat-label></mat-label>-->
<!--                <input matInput placeholder="ex.222" type="number" formControlName="cvc">-->
<!--              </mat-form-field>-->
<!--            </div>-->
        <!-- <div class="col-11" >
          <div class="mt-5">
            <h4 class="tell-us" translate>home.purpose</h4>
          </div>
          <mat-form-field appearance="fill">
            <mat-select formControlName="general_purpose_id" (ngModelChange)="checkselect(true)">
              <mat-option  *ngFor="let option of generalpurposes" (click)="getIdPurpose(option.id)" [value]="option.id" style="font-size: 30px">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-11">
          <div class="mt-5">
            <h4 class="tell-us" translate>home.following-activities</h4>
          </div>
          <mat-form-field appearance="fill">
            <mat-select formControlName="activity_id" (ngModelChange)="checkselect(false)">
              <mat-option *ngFor="let option of donateactivities" (click)="getIdActivitis(option.id)" [value]="option.id" style="font-size: 30px">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
<!--        <div class="col-2">-->
<!--          <div class="mt-5">-->
<!--            <h4 class="tell-us"></h4>-->
<!--          </div>-->
<!--        <label class="checkbox mt-4" (click)="show=!show ; checkActivity(show)" >-->
<!--          <input type="checkbox" name="vehicle1" value="Bike"   style="cursor: pointer; min-width: 35px; min-height: 35px">-->
<!--        </label>-->
<!--        </div>-->
      <!-- </div>
          <div class="row">
          <div class="col-12 section-title ml-3" translate>
            home.DONATE
            <div class="row">
              <div class="col-8 section mt-4" translate>
                home.custom-amount
              </div>
              <div class="col-4 section mt-4">

                </div>

              <div class="col-6 mt-3">
                <div class="input-group">
                  <span class="input-group-text" style="z-index: 66">$</span>
                  <input id="custom_amount" class="position-absolute" type="number"
                         formControlName="amount"
                         style="width: 100%; min-height: 100%; padding-left: 50px;">
                </div>
              </div>
              <div class="col-4"></div>
              <div class="col-2"> -->
                <!-- <div class="">
                    <h4 class="tell-us"></h4>
                 </div> -->
                 <!-- <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style="cursor: pointer; min-width: 35px; min-height: 35px">
                  <label class="form-check-label" for="flexCheckDefault">
                  </label>
                </div> -->
                 <!-- checkActivity(show) -->
                 <!-- <mat-checkbox class="example-margin" style="cursor: pointer; min-width: 58px; min-height: 50px ;"></mat-checkbox> -->
                    <!-- <label class="checkbox mt-4 ">
                    <input type="checkbox" checked name="vehicle1" value="Bike" formControlName="checked_fee" (click)="checkedFee"   style="cursor: pointer; min-width: 58px; min-height: 50px ;">
                    </label>
               </div>

            </div> -->

<!--            <div class="row justify-content-center">-->
<!--              <div class="col-6 section ml-5 mt-4" (click)="f()"  translate>-->
<!--                home.custom-amount-->
<!--              </div>-->
<!--              <div class="col-6 mt-3">-->
<!--                <div class="input-group">-->
<!--                  <span class="input-group-text" style="z-index: 66">$</span>-->
<!--                  <input id="custom_amount" class="position-absolute" type="number" formControlName="amount" style="width: 100%; min-height: 100%;padding-left: 38px">-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="col-6 mt-2">&ndash;&gt;-->
<!--              &lt;!&ndash;              <div class="mt-4">&ndash;&gt;-->
<!--              &lt;!&ndash;                <h4 class="tell-us"></h4>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              <label class="checkbox ">&ndash;&gt;-->
<!--              &lt;!&ndash;                <input type="checkbox" name="vehicle1" value="Bike" (click)="hidden=!hidden"  [checked]="!hidden"  style="cursor: pointer; min-width: 35px; min-height: 35px">&ndash;&gt;-->
<!--              &lt;!&ndash;              </label>&ndash;&gt;-->
<!--              &lt;!&ndash;              <span> Make this a recurring donation</span>&ndash;&gt;-->
<!--              &lt;!&ndash;            </div>&ndash;&gt;-->
<!--              &lt;!&ndash;            <div class="col-4">&ndash;&gt;-->
<!--              &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            </div>-->

          <!-- </div>
            <div class="col-4" *ngFor="let donate of donateSelect">
              <button class="pill" [class]="select == donate ? 'pill-select':''" (click)="selectDonate(donate)" type="button" >${{donate}}</button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 section-title ml-3 mt-5" translate>
              home.payment-details
            </div>
            <div class="col-12">
              <div class="mt-5">
                <h4 class="tell-us" translate>home.card-information</h4>
              </div>
              <mat-form-field appearance="fill">
                <mat-label></mat-label>
                <input style="display: none;" matInput placeholder="" type="text">
                <ngx-stripe-card
                  [options]="cardOptions"
                ></ngx-stripe-card>
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-4 justify-content-center" style="margin-bottom: 50px">
            <button class="btn submit-btn" [disabled]="disabled" type="submit" translate>home.donate</button>
          </div> -->
<!--          <div class="row" *ngIf="!hidden">-->
<!--            <div class="col-12 mt-3">-->
<!--          <div class="col-12" id="aplos-periodicity-selectors">-->
<!--            <div class="row">-->
<!--              <div class="col-12">-->
<!--            <p class="text-justify">-->
<!--              Schedule-->
<!--            </p>-->
<!--              </div>-->
<!--&lt;!&ndash;            <div class="frow gutters">&ndash;&gt;-->
<!--              <div class="col-6">-->
<!--                <div class="">-->
<!--                  &lt;!&ndash;&ndash;&gt;  <div class="aplos-select-wrapper">-->
<!--                  <select id="periodicity-selector" style="width: 100%">-->
<!--                    &lt;!&ndash;&ndash;&gt;        <option value="weekly">-->
<!--                    weekly-->
<!--                  </option>-->
<!--                    <option value="biweekly">-->
<!--                      every 2 weeks-->
<!--                    </option>-->
<!--                    <option value="semimonthly">-->
<!--                      semimonthly-->
<!--                    </option>-->
<!--                    <option value="monthly">-->
<!--                      monthly-->
<!--                    </option>-->
<!--                    <option value="bimonthly">-->
<!--                      every 2 months-->
<!--                    </option>-->
<!--                    <option value="quarterly">-->
<!--                      every 3 months-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--                  <div class="input-hint">-->
<!--                    &lt;!&ndash;&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-6">-->
<!--                <div id="">-->
<!--                  <div class="">-->
<!--                    &lt;!&ndash;&ndash;&gt;  <div class="aplos-select-wrapper">-->
<!--                    <select id="periodicity-day-selector" style="width: 100%">-->
<!--                      &lt;!&ndash;&ndash;&gt;        <option value="1">-->
<!--                      1st-->
<!--                    </option>-->
<!--                      <option value="2">-->
<!--                        2nd-->
<!--                      </option>-->
<!--                      <option value="3">-->
<!--                        3rd-->
<!--                      </option>-->
<!--                      <option value="4">-->
<!--                        4th-->
<!--                      </option>-->
<!--                      <option value="5">-->
<!--                        5th-->
<!--                      </option>-->
<!--                      <option value="6">-->
<!--                        6th-->
<!--                      </option>-->
<!--                      <option value="7">-->
<!--                        7th-->
<!--                      </option>-->
<!--                      <option value="8">-->
<!--                        8th-->
<!--                      </option>-->
<!--                      <option value="9">-->
<!--                        9th-->
<!--                      </option>-->
<!--                      <option value="10">-->
<!--                        10th-->
<!--                      </option>-->
<!--                      <option value="11">-->
<!--                        11th-->
<!--                      </option>-->
<!--                      <option value="12">-->
<!--                        12th-->
<!--                      </option>-->
<!--                      <option value="13">-->
<!--                        13th-->
<!--                      </option>-->
<!--                      <option value="14">-->
<!--                        14th-->
<!--                      </option>-->
<!--                      <option value="15">-->
<!--                        15th-->
<!--                      </option>-->
<!--                      <option value="16">-->
<!--                        16th-->
<!--                      </option>-->
<!--                      <option value="17">-->
<!--                        17th-->
<!--                      </option>-->
<!--                      <option value="18">-->
<!--                        18th-->
<!--                      </option>-->
<!--                      <option value="19">-->
<!--                        19th-->
<!--                      </option>-->
<!--                      <option value="20">-->
<!--                        20th-->
<!--                      </option>-->
<!--                      <option value="21">-->
<!--                        21st-->
<!--                      </option>-->
<!--                      <option value="22">-->
<!--                        22nd-->
<!--                      </option>-->
<!--                      <option value="23">-->
<!--                        23rd-->
<!--                      </option>-->
<!--                      <option value="24">-->
<!--                        24th-->
<!--                      </option>-->
<!--                      <option value="25">-->
<!--                        25th-->
<!--                      </option>-->
<!--                      <option value="26">-->
<!--                        26th-->
<!--                      </option>-->
<!--                      <option value="27">-->
<!--                        27th-->
<!--                      </option>-->
<!--                      <option value="28">-->
<!--                        28th-->
<!--                      </option>-->
<!--                      <option value="29">-->
<!--                        29th-->
<!--                      </option>-->
<!--                      <option value="30">-->
<!--                        30th-->
<!--                      </option>-->
<!--                      <option value="31">-->
<!--                        31st-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                    <div class="input-hint">-->
<!--                      &lt;!&ndash;&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;&ndash;&gt;        </div>-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--            </div>-->
<!--          </div>-->
    <!-- </div>
        <div class="col-6" style="margin-bottom: 50px;">
          <img class="img"
               src="./assets/images/610X860.jpg">
        </div>
      </div>
   </div>
  </div>
</form> -->
