import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '../shared/user';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './webApis/local-storage.service';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private newDomain = environment.mainDomain;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {};
  // isLogin:BehaviorSubject<boolean> = new BehaviorSubject(false)
  public isLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.localStorage.getItem('access_token') ? true : false);
  public isUser = this.isLogin.asObservable();

  constructor(private http: HttpClient, public router: Router,
    private localStorage : LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieService: CookieService
) {
  }

  // Sign-in
  signIn(user: User) {
    return this.http
      .post<any>(`${this.newDomain}/login`, user)
      .pipe(
        map(user => {
          if (user.success.token) {
              this.cookieService.set('access_token', user.success.token);
              this.localStorage.setItem('access_token', user.success.token);
            this.localStorage.setItem('currentUser', JSON.stringify(user.success));

                this.isLogin.next(true)
            // localStorage.setItem('access_token', user.success.token);
            this.currentUser = user.success;
          }
          return user;
        })
      );
  }
  getToken() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('access_token');
    }
  }
  loggedIn() {

      return this.localStorage.getItem('access_token');
  }

  get isLoggedIn(): boolean {

      let authToken =  this.cookieService.get('access_token');
      return authToken !== undefined ? true : false;

  }
  logOutApi(): any {
    return this.http.post(`${this.newDomain}/dashboard/logout`, '');
  }
  doLogout() {

      let removeToken = this.localStorage.removeItem('access_token');
      if (removeToken == null) {
        this.router.navigate(['/']);
      }
  }
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
