import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApisService } from '../services/apis.service';
import { MatAutocomplete } from '@angular/material/autocomplete';
import {StripeCardCvcElement, StripeCardElementOptions, StripeCardExpiryElement, StripeCardNumberElement,
  StripeElements, StripeElementsOptions} from '@stripe/stripe-js';import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { SeoService } from '../services/seo.service';
// import { Router } from 'express';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import {MatStepperModule} from '@angular/material/stepper';
import { log } from 'console';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-donation-form',
  templateUrl: './donation-form.component.html',
  styleUrls: ['./donation-form.component.scss']
})
export class DonationFormComponent implements OnInit {
  firstFormGroup = this._formBuilder.group({
    fullname: ['', [Validators.required]],
    email: ['', [Validators.email]],
    phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
  });
  secondFormGroup = this._formBuilder.group({
    is_activity: ['', [Validators.required]],
    activity_id: ['', [Validators.required]],
    general_purpose_id: ['', [Validators.required]],
  });
  thirdFormGroup = this._formBuilder.group({
    amount: ['', [Validators.required]],
    checked_fee: ['', [Validators.required]],
  });
  ForthFormGroup = this._formBuilder.group({
    stripeToken: ['', [Validators.required]],
  });
  @Input() total;
  disabled: boolean = false;
  stripeCardValid: boolean = false;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  elements: StripeElements;
  cardNumber: StripeCardNumberElement;
  cardCvc: StripeCardCvcElement;
  cardExpiry: StripeCardExpiryElement;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        lineHeight: '3.25',

        // iconColor: '#666EE8',
        // color: '#31325F',
        // fontWeight: '300',
        // fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        // fontSize: '22px',
        // '::placeholder': {
        //   color: '#CFD7E0'
        // }
      }
    }
  };


  emptyOptions: StripeCardElementOptions = {
    style: {
      base: {
        // lineHeight: '3.5',
        // iconColor: '#666EE8',
        // color: '#31325F',
        // fontWeight: '400',
        // fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        // fontSize: '16px',
        // '::placeholder': {
        //   color: '#CFD7E0'
        // }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: { labels: 'floating' }
  };
  // elementsOptions: StripeElementsOptions = {
  //   locale: 'en'
  // };
  myForm: FormGroup;
  user: any;
  token: any;
  formSubmited = false;
  generalpurposes: any;
  donateactivities: any;
  donateforactivity: any;
  show: boolean;
  hidden: boolean = true;
  activities: any;
  purpose: MatAutocomplete;
  donateSelect: any[] = [100, 500, 2000, 4000, 8000, 10000];
  select: number;
  activitiesId: number;
  purposeId: number;
  errorMessage!: string;
  copied:boolean=false;
  lang:string = '';
  step: number = 20;
  loading:boolean = false;
  tasksId: any;
  slug: any;
  teamInitial = '';


  constructor(private _formBuilder: FormBuilder,
              private apis: ApisService,
              private fb: FormBuilder,
              @Inject(PLATFORM_ID) private platformId: Object,
              private seoService: SeoService,
              private clipboard: Clipboard,
              private router: Router,
              private stripeService: StripeService,
              private toastr: ToastrService,
              private activatedRoute : ActivatedRoute,
              @Inject(MAT_DIALOG_DATA) public data
    ) {

      this.apis.currentLang$.subscribe(res => {
        this.lang = res
      })
      this.activatedRoute.queryParamMap.subscribe((param:any)=>{
        this.lang = param.params.lang
        console.log(this.lang);

      })
      if (isPlatformBrowser(this.platformId)) {
        this.token = localStorage.getItem('access_token')
      }

     }

  ngOnInit(): void {

    console.log(this.data);

    this.slug = this.activatedRoute.snapshot.params.id;

    if (isPlatformBrowser(this.platformId)) {
      this.user = JSON.parse(localStorage.getItem('currentUser'))
    }



    this.generalPurposesApi();
    this.getActivities();


    this.myForm = this.fb.group({
      // fullname: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      // phone: ['', Validators.required],
      // card_number: ['', Validators.required],
      // exp_month: ['', Validators.required],
      // exp_year: ['', Validators.required],
      // cvc: ['', Validators.required],
      amount: ['', Validators.required],
      is_activity: ['', Validators.required],
      activity_id: ['', Validators.required],
      general_purpose_id: ['', Validators.required],
      stripeToken: ['', Validators.required],
      checked_fee: ['1', Validators.required],

    });
    if (!this.user) {
      this.myForm.addControl('fullname', new FormControl('', Validators.required));
      this.myForm.addControl('email', new FormControl('', Validators.required));
      this.myForm.addControl('phone', new FormControl('', Validators.required));
    }


  }
  // addForm(): any {
  //   const formData: FormData = new FormData();
  //   formData.append('fullname', this.firstFormGroup.value.fullName);
  //   formData.append('email', this.firstFormGroup.value.email);
  //   formData.append('phone', this.firstFormGroup.value.phone);
  //   formData.append('is_activity', this.secondFormGroup.value.is_activity);
  //   formData.append('activity_id', this.secondFormGroup.value.activity_id);
  //   formData.append('general_purpose_id', this.secondFormGroup.value.general_purpose_id);
  //   formData.append('amount', this.thirdFormGroup.value.amount);
  //   formData.append('stripeToken', this.ForthFormGroup.value.stripeToken);
  //   formData.append('general_purpose_id', this.secondFormGroup.value.general_purpose_id);
  //   formData.append('general_purpose_id', this.secondFormGroup.value.general_purpose_id);

  //   // @ts-ignore
  //   return formData;
  // }

  f() {
    console.log('form sss=>', this.myForm)
  }

  payment(){
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.cardNumber) {
          this.cardNumber = this.elements.create('cardNumber', this.emptyOptions);
          this.cardNumber.mount('#floatingNumber1');
        }
        if (!this.cardCvc) {
          this.cardCvc = this.elements.create('cardCvc', this.emptyOptions);
          this.cardCvc.mount('#floatingCvc1');
        }
        if (!this.cardExpiry) {
          this.cardExpiry = this.elements.create('cardExpiry', this.emptyOptions);
          this.cardExpiry.mount('#floatingExpiry1');
        }
    });
  }
  checkedFee(fee){
    if(fee){
      this.myForm.controls['checked_fee']
      this.myForm.patchValue({
        checked_fee: 1
      })
    }else{
      this.myForm.patchValue({
        checked_fee:''
      })
    }
  }

  checkselect(event) {
    if (event) {
      console.log(event);

      // console.log('mahmoudayman')
      this.myForm.controls['activity_id'].enable();
      this.myForm.patchValue({
        is_activity: 0
      })
    } else {
      this.myForm.controls['general_purpose_id'].enable();
      this.myForm.patchValue({
        is_activity: 1
      })
    }
  }
  reset() {
    this.teamInitial = '';
  }
  // checkActivity(show){
  //   if(show){
  //     this.myForm.addControl('general_purpose_id', new FormControl('', Validators.required));
  //     this.myForm.addControl('is_activity', new FormControl(0, Validators.required));
  //     this.myForm.removeControl('activity_id')
  //   }else{
  //     this.myForm.addControl('activity_id', new FormControl('', Validators.required));
  //     this.myForm.removeControl('general_purpose_id')
  //     }
  // }
  createToken(): void {
    const stripeToken = this.myForm.get('stripeToken').value;
    this.stripeService
      .createToken(this.cardNumber, {name: stripeToken})
      .subscribe((result) => {
        if (result.token) {
          this.myForm.patchValue({
            stripeToken: result.token.id
          })
          this.onSubmit()
          // this.onSubmit(this.myForm);
          // this.formSubmited = true;
          // Use the token
          console.log('result =>', result.token.id);
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }

  selectDonate(select: number) {
    this.select = select
    this.myForm.patchValue({
      amount: select
    })
  }

  getIdActivitis(id: any) {
    this.activitiesId = id;
    this.purposeId = null
  }

  getIdPurpose(id: any) {
    this.purposeId = id;
    this.activitiesId = null
  }

  onSubmit() {
    this.disabled = true;
    if (this.purposeId) {
      console.log('mahmoud',this.myForm);
      this.apis.getDonate(this.token, this.myForm.value).subscribe({
        next: (res: any) => {
          console.log('donation =>', res);
          this.toastr.success(res.message, 'Success');
          this.disabled = false;
        }, error: (err: any) => {
          console.log(err)
          this.errorMessage = err.error.errors
          for (const key in err.error.errors) {
            this.toastr.error( err.error.errors[key] , 'faild')
          }
          this.disabled = false;
        }
      })
    } else if (this.activitiesId||this.data.id) {
      this.donateForActivityApi(this.activitiesId||this.data.id)
      console.log(this.myForm.value)
       }
      //  else if(this.data){
      //   this.apis.getDonateForActivity(this.data,this.myForm.value).subscribe({
      //     next:(res:any)=>{
      //       console.log('donation =>', res);
      //       this.toastr.success(res.message, 'Success');
      //       this.disabled = false;

      //     },error:(err:any)=>{
      //       console.log(err)
      //       this.errorMessage = err.error.errors
      //       for (const key in err.error.errors) {
      //         this.toastr.error( err.error.errors[key] , 'faild')
      //       }
      //       this.disabled = false;
      //     }
      //   })
      //  }

    console.log(this.myForm.value)
  }

  generalPurposesApi(): any {
    this.apis.getGeneralPurposes().subscribe((data: any) => {
        this.generalpurposes = data.data;
        console.log(this.generalpurposes);
      },
      err => {
        console.log(err);
      });
  }

  getActivities(): any {
    this.apis.getActivitiesApi().subscribe((data: any) => {
        this.donateactivities = data.data;
        console.log(this.donateactivities);
      },
      err => {
        console.log(err);
      });
  }

  donateForActivityApi(id): any {
    this.apis.getDonateForActivity(id||this.data.id, this.myForm.value).subscribe((res: any) => {
        this.donateforactivity = res;
        console.log(this.donateforactivity)
        this.toastr.success(res.message, 'Success');
        this.disabled = false;
      },
      err => {
        // error: (err: any) => {
        //   console.log(err)
        //   this.errorMessage = err.error.errors
        //   for (const key in err.error.errors) {
        //     this.toastr.error( err.error.errors[key] , 'faild')
        //   }
        //   this.disabled = false;
        // }
        console.log(err);
        this.errorMessage = err.error.errors
        for (const key in err.error.errors) {
          this.toastr.error( err.error.errors[key] , 'faild')
            }
          this.disabled = false;
      });
  }


}


