import {Inject, Injectable, Injector, PLATFORM_ID} from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { AuthService } from "../services/auth.service";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(Injector) private readonly injector: Injector) { }
  private get authService() {
    return this.injector.get(AuthService);
  }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    
    const authToken = this.authService.getToken();
    if(authToken){
    req = req.clone({
      setHeaders: {
        Authorization: "Bearer " + authToken
      }
    });
  }
    return next.handle(req);
  }}
