import { Component, OnInit } from '@angular/core';
import {PleaseLoginComponent} from '../../components/please-login/please-login.component';
import {MatDialog} from '@angular/material/dialog';
import {ApisService} from "../../services/apis.service";

@Component({
  selector: 'app-volunteers',
  templateUrl: './volunteers.component.html',
  styleUrls: ['./volunteers.component.scss']
})
export class VolunteersComponent implements OnInit {
  allVolunteers: any;
  VolunteersAtClub: any;
  clubs: any;
  club_id: any;
  loading:boolean = false ;
  lang: any;
  page = 1;
  pageSize = 10;
  collectionSize = 20;

  constructor(public dialog: MatDialog,
              private apis: ApisService,
  ) { }

  ngOnInit(): void {
    // setTimeout(() => {
      // this.openDialog();
    // }, 1000);
    this.getAllVolunteers(1);
    this.getClubs();
  }
  getAllVolunteers(pageNumber: any): void {
    if (typeof document !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth'});
    }
    this.apis.getAllVolunteers( pageNumber).subscribe((data: any) => {
        this.allVolunteers = data.data;
        console.log(this.allVolunteers)
        this.collectionSize = data.data.paginator.number_of_pages;
        this.pageSize = data.data.paginator.number_of_items_per_page;
        this.page = +pageNumber;
        this.loading = false;

      },
      err => {
        console.log(err);

      });
  }
  getClubs(): any {

    this.apis.getAllClubs().subscribe((data: any) => {
        this.clubs = data.data;
        console.log(this.clubs)

      },
      err => {
        console.log(err);

      });
  }
  allVolunteersAtClub(id: number): any {
    this.loading = true;

    this.apis.allVolunteersAtClub(id).subscribe(
      (data: any) => {
        this.allVolunteers = data.data;
        this.loading = false;
      },
      err => {
        console.log(err);
        this.loading = false;

      });
  }

  // openDialog(): any {
  //   let x = document.querySelectorAll('.col-md-3');
  //   x.forEach((ele: any) => {
  //     ele.style.opacity = '0.1';
  //     ele.style.filter = 'blur(8px)';
  //   });
  //   const dialogRef = this.dialog.open(PleaseLoginComponent);
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
}
