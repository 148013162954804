<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">

<!--  <h2>Login Form</h2>-->
<div class="container">
  <div class="row justify-content-center mt-5">
    <div class="col-6">
      <form action="/action_page.php" method="post" [formGroup]="signinForm" (ngSubmit)="loginUser()">
        <!--  <div class="imgcontainer">-->
        <!--    <img src="./assets/images/User_Avatar_2.png" alt="Avatar" class="avatar">-->
        <!--  </div>-->

        <div class="container text-left">
          <label class="d-flex" for=""><b translate>home.e-mail</b></label>
          <input type="text"
                 formControlName="email"
                 placeholder="{{ 'home.your-email' | translate }}" ng-model="myModel"
                 name="uname" required
                 [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
          >
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">*Email is required</div>
            <div *ngIf="f.email.errors.email">*Email must be a valid email address</div>
          </div>
          <label class="d-flex mt-2" for=""><b translate>home.password</b></label>
          <input
            type="password"
            formControlName="password"
            placeholder="{{ 'home.enter-password' | translate }}" ng-model="myModel"
            name="psw" required
            [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
          >
          <div
            *ngIf="submitted && f.password.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.password.errors }"
          >
            <div *ngIf="f.password.errors.required">*Password is required</div>
          </div>
          <button type="submit" translate>home.login</button>
          <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
            <div class="alert-body">
              <p>{{ error }} </p>
            </div>
          </ngb-alert>
          <div class="ml-2 d-flex mb-5">
            <label class="checkbox ">
              <input type="checkbox" name="vehicle1" value="Bike"> <span class="m-2" translate>home.remember-me</span>
            </label>
            <!--    <button type="button" class="cancelbtn">الغاء</button>-->
            <span class="psw ml-5 mt-2"><a href="https://api.newegyptgroup.com/forget-password" translate>home.forgot-password</a></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

