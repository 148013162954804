import { Component, OnInit } from '@angular/core';
import {ApisService} from "../services/apis.service";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'app-club-members',
  templateUrl: './club-members.component.html',
  styleUrls: ['./club-members.component.scss']
})
export class ClubMembersComponent implements OnInit {
  Members: any;
  clubMember: any;
  id:any;
  page = 1;
  pageSize = 10;
  collectionSize = 30;
  memberId:any;


  constructor(private apis: ApisService, private router: Router,private activatedRoute: ActivatedRoute) {
    // this. getMember();
    // this.activatedRoute.params
    //   .subscribe(
    //     (params: Params) => {
    //       this.id=this.activatedRoute.snapshot.paramMap.get('id')
    //       this.memberId=this.activatedRoute.snapshot.paramMap.get('member')
    //       this. getMember();
    //       this.clubMembersApi();
    //     }
    //   );
  }

  ngOnInit(): void {
    this.clubMembersApi(this.page);
    // this.getMember();
  }
  clubMembersApi(pageNumber: any) {
    if (typeof document !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth'});
    }
    this.apis.clubMembers(pageNumber).subscribe((res: any) => {
        this.Members = res.data.members;
        console.log(this.Members);
        // console.log(page,res.data)
        // this.id = this.clubMembers[0].id ;
        // console.log(this.id);
        // this.getMember();
        this.collectionSize = res.data.paginator.number_of_pages;
        this.pageSize = res.data.paginator.number_of_items_per_page;
        this.page = +pageNumber;
      },
      err => {
        console.log(err);
      });
  }

  getMember() {
    this.apis.getMemberInfo(this.id).subscribe((data: any) => {
        this.clubMember = data.data;
        console.log(this.clubMember);
      },
      err => {
        console.log(err);
      });
  }
  // getMember()
  // {
  //   this.clubMember=null;
  //   this.apis.getMemberInfo(this.id).subscribe((data: any)=>{
  //     this.clubMember=data.data;
  //     console.log(this.clubMember);
  //   });
  //
  // }
  memberDetails(id): void{
    console.log(id);
    console.log(this.Members.id);
    this.router.navigate(['/memberDetails/',id ,id]);

  }

}
