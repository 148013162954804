import {Component, Output, EventEmitter, OnInit} from '@angular/core';
import {ServicesComponent} from '../services/services.component';
import {MatDialog} from '@angular/material/dialog';
import {ApisService} from '../../../services/apis.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})

export class PersonalDataComponent implements OnInit {
  @Output() setPersonalDataEvent:EventEmitter<any> = new EventEmitter();
  @Output() ssnChecker:EventEmitter<any> = new EventEmitter();
  name: any;
  mobile: any;
  nationalId: any;
  mobilePhone: any;
  notes: any;
  countryCode: any;
  checked: any = false;
  selectedValue: string;
  selectedCar: string;
  selectedId: any;
  errorMessage: any;
  foods: any = [
    {value: 'steak-0', viewValue: 'رخصة قيادة'},
    {value: 'pizza-1', viewValue: 'جواز سفر'},
    {value: 'tacos-2', viewValue: 'ماستر كارد'}
  ];
  idTypes: any;

  constructor(public dialog: MatDialog, private apis: ApisService,) {
  }

  ngOnInit(): void {
  }

  validForm(): any {
    const re = /^\d{14}$/;
    const valid = re.test(this.nationalId);
    if (valid) {
      this.errorMessage = '';
      this.ssnChecker.emit(true);
      document.getElementById('national_id').style.borderColor = '#ced4da';
    } else {
      document.getElementById('national_id').style.borderColor = 'red';
      this.ssnChecker.emit(false);
      this.errorMessage = 'Please Type Only Numbers with 14 Digits';
    }
    return false;
  }

  validSsn(e): any {
    if (this.nationalId) {
     this.ssnChecker.emit(true);
    }else {
      this.ssnChecker.emit(false);
    }
  }

  setPersonalData(): void {
    if (this.countryCode) {
      this.mobilePhone = '+' + this.countryCode + this.mobile;
    } else {
      this.mobilePhone = '+1' + this.mobile;
    }
    const data = {
      name: this.name,
      mobile: this.mobilePhone,
      notes: this.notes,
      SSN: this.nationalId,
      id_type: this.selectedId
    };
    this.setPersonalDataEvent.emit(data);
  }

  onCountryChange(event): any {
    this.countryCode = event.dialCode;
    this.mobile = '';
  }

  getIdObject(id): any {
    const index = this.idTypes.findIndex(obj => obj.id === id);
    if (index > -1) {
      return this.idTypes[index];
    }
  }

  check(e): void {
    this.ssnChecker.emit(false);
    this.selectedValue = '';
    this.selectedId = '';
    this.nationalId = '';

  }

  getType(e): any {
    this.ssnChecker.emit(false);
    this.getIdObject(e.value);
    this.selectedValue = this.getIdObject(e.value).name;
  }
}
