import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from "ngx-toastr";
import {map, startWith} from "rxjs/operators";
import {EMPTY, Observable} from "rxjs";
import {Router} from "@angular/router";
import { ApisService } from 'src/app/services/apis.service';
import { is } from 'date-fns/locale';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-form-journey',
  templateUrl: './form-journey.component.html',
  styleUrls: ['./form-journey.component.scss']
})
export class FormJourneyComponent implements OnInit {

  form: FormGroup;
  filteredCountries: Observable<string[]>;
  countries: any;
  fileToUpload: any = '';
  fileToUploadFront: any = '';
  fileToUploadBack: any = '';
  fileToUploadClearance: any = '';
  errorMessage!: string;
  image: any;
  imageFront: any;
  imageBack: any;
  imageClearance: any;
  ImagePath: any;
  message: any;
  selected: any = 'A week';
  emailKey: any = 'email';
  phoneKey: any = 'phone';
  nationalIdKey: any = 'national_id';
  date: any;
  governorates: any;
  clubs: any;
  email: any;
  spinner: any = false;
  right: any = false;
  right2 = false;
  spinner2 = false;
  rightSsn = false;
  spinnerSsn = false;
  phone: any;
  ssn: any;
  egyptCase = false;
  copied = false;
  UnaitedStateCase = false;
  lang: any;
  step: number = 20;
  loading:boolean = false;
  filteredClubs: Observable<string[]>;
  allClubs: any[] = [];

  firstFormGroup = this._formBuilder.group({
    fullName_en: ['', [Validators.required]],
    address: ['', ],
    // [Validators.required]
    age: ['',],
    // [Validators.required, Validators.pattern('[0-9]+')]
    country: ['',],
    // [Validators.required]
  });
  secondFormGroup = this._formBuilder.group({
    email: ['', [Validators.email]],
    phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
    // ssn: [''],
  });
  thirdFormGroup = this._formBuilder.group({
    club_id: [''],
    job: [''],
    join_date: ['', ],
    // [Validators.required]
    // purpose_id: ['', [Validators.required]],
  });
  ForthFormGroup = this._formBuilder.group({
    image: ['',[Validators.required]],
    // image_front: [''],
    // image_back: [''],
    // image_clearance: [''],
  });
  volunteerImag: any;
  constructor(
    private _formBuilder: FormBuilder,
    private apis: ApisService,
              private route: Router,
              // private clipboard: Clipboard,
              private toastr: ToastrService,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.apis.currentLang$.subscribe(res => {
      this.lang = res
    })
  }


  ngOnInit(): void {
    console.log(this.data);




    if(this.data.type == 1){
      this.getCountries();
      this.getAllClubs();
    }else{
      this.secondFormGroup.controls.email.setValidators([Validators.required,Validators.email]);
      this.thirdFormGroup.controls.club_id.setValidators([Validators.required]);
    this.getCountriesMember();
    }
    let today = new Date();
    let dateTime = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
    this.date = dateTime;
    this.form = this.initForm();
    // this.getDonatePurpose();
    this.form.patchValue({
      purpose_id: 'Haya Karima',
      pledge_date: this.date
    });
  }
  getCountriesMember(): any {
    this.apis.getCountries().subscribe((data: any) => {
            this.countries = data.data;
            console.log(this.countries);
        this.setFilterCountries();
        },
        err => {
            console.log(err);
        });
}
  initForm(): FormGroup {
    return new FormGroup({
      fullName_en: new FormControl('', [Validators.required, Validators.minLength(3)]),
      address: new FormControl('',),
      age: new FormControl('',),
      email: new FormControl('', [Validators.minLength(3), Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]),
      ssn: new FormControl('',),
      job: new FormControl('',),
      join_date: new FormControl('',),
      country: new FormControl('', ),
      image: new FormControl(''),
      image_front: new FormControl('',),
      image_back: new FormControl('',),
      image_clearance: new FormControl('',),
      // clubs: new FormControl('', [Validators.required]),
    });
  }

  addVolunteerForm(): any {
    const formData: FormData = new FormData();
    this.data.activityId ? formData.append('activityId', this.data.activityId) : EMPTY;
    formData.append('image', this.fileToUpload);
    formData.append('front_id', this.fileToUploadFront);
    formData.append('back_id', this.fileToUploadBack);
    formData.append('clearance', this.fileToUploadClearance);
    formData.append('fullname_en', this.firstFormGroup.value.fullName_en);
    formData.append('email', this.secondFormGroup.value.email);
    formData.append('phone', this.secondFormGroup.value.phone);
    formData.append('address', this.firstFormGroup.value.address);
    formData.append('job', this.thirdFormGroup.value.job);
    formData.append('age', this.firstFormGroup.value.age);
    formData.append('joined_at', this.dateFormat(this.thirdFormGroup.value.join_date));
    this.egyptCase ? formData.append('national_id', this.secondFormGroup.value.ssn) : EMPTY;
    formData.append('club_id', this.getClubsObject(this.thirdFormGroup.value.club_id).id) ;
    // @ts-ignore
    return formData;
  }

  getPosts(e): any {
    if (e == 'Egypt') {
      console.log('eg',this.secondFormGroup);
      this.secondFormGroup.addControl('ssn', new FormControl('', [Validators.required, Validators.pattern('[0-9]+'),Validators.minLength(14),Validators.maxLength(14)]));
      //
      this.ForthFormGroup.addControl('image_front', new FormControl('', ));
      this.ForthFormGroup.addControl('image_back', new FormControl('',));
      this.ForthFormGroup.addControl('image_clearance', new FormControl('',));
      // [Validators.required]
      this.egyptCase = true;
    } else {
      this.egyptCase = false;
      console.log('cleatr',this.ForthFormGroup);
      this.secondFormGroup.removeControl('ssn');
      this.ForthFormGroup.removeControl('image_front');
      this.ForthFormGroup.removeControl('image_back');
      this.ForthFormGroup.removeControl('image_clearance');
      // this.ForthFormGroup.controls.image.clearValidators();
      // this.ForthFormGroup.controls.image_front.clearValidators();
      // this.ForthFormGroup.controls.image_back.clearValidators();
      // this.ForthFormGroup.controls.image_clearance.clearValidators();
    }
    if(this.data.type == 0){
      const obj = this.getCountriesObject(e);
      this.getClubs(obj.id);
    }

  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  setFilterCountries() {
    this.filteredCountries = this.firstFormGroup.controls['country'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }
  getCountries(): any {
    this.apis.countries().subscribe((data: any) => {
        this.countries = data.data;
        this.setFilterCountries();
      },
      err => {
        console.log(err);
      });
  }

  // getClubs(id): any {
  //   this.apis.getClubs(id).subscribe((data: any) => {
  //       this.clubs = data.data;
  //     },
  //     err => {
  //       console.log(err);
  //     });
  // }
  getCountriesObject(purpose): any {
    const index = this.countries.findIndex(obj => obj.name === purpose);
    if (index > -1) {
      return this.countries[index];
    }
  }

  getClubsObject(club): any {
    console.log('clup' , club );

    const index = this.allClubs.findIndex(obj => obj.name == club);
    console.log(index);

    if (index > -1) {
      return this.allClubs[index];
    }
  }

        // this.ForthFormGroup.controls.image.clearValidators();
      // this.ForthFormGroup.controls.image_front.clearValidators();
      // this.ForthFormGroup.controls.image_back.clearValidators();
      // this.ForthFormGroup.controls.image_clearance.clearValidators();

    onFileSelected(files: FileList): void {
        this.fileToUpload = files.item(0);
        (document.getElementById('blah') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUpload);
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
              this.volunteerImag= event.target.result
              this.ForthFormGroup.patchValue({
                image: event.target.result
              })
            };
            reader.readAsDataURL(files[0]);
        }
    }

    onFileSelectedFront(files: FileList): void {
        this.fileToUploadFront = files.item(0);
        (document.getElementById('blah1') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUploadFront);
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
              this.ForthFormGroup.patchValue({
                image_front: event.target.result
              })
            };
            reader.readAsDataURL(files[0]);
        }
    }

    onFileSelectedBack(files: FileList): void {
        this.fileToUploadBack = files.item(0);
        (document.getElementById('blah2') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUploadBack);
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
              this.ForthFormGroup.patchValue({
                image_back: event.target.result
              })
            };
            reader.readAsDataURL(files[0]);
        }
    }

    onFileSelectedClearance(files: FileList): void {
        this.fileToUploadClearance = files.item(0);
        (document.getElementById('blah3') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUploadClearance);
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
              this.ForthFormGroup.patchValue({
                image_clearance: event.target.result
              })
            };
            reader.readAsDataURL(files[0]);
        }
    }

  setFilterClubs() {
    this.filteredClubs = this.thirdFormGroup.controls.club_id.valueChanges.pipe(
      startWith(''),
      map(value => this._filterClubs(value || '')),
    );
  }
  private _filterClubs(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allClubs.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getAllClubs(): any {
    this.apis.getAllClubs().subscribe((data: any) => {
        this.allClubs = data.data;
        this.setFilterClubs();

      },
      err => {
        console.log(err);
      });
  }

    getClubs(id): any {
      this.apis.getClubs(id).subscribe((data: any) => {
              this.allClubs = data.data;
              this.setFilterClubs();
          },
          err => {
              console.log(err);
          });
  }
    close(): void {
      this.loading = true;
      if(this.data.type == 1){
        this.callAddVolunteerApi(this.addVolunteerForm());
      }else{
        this.callAddMemberApi(this.addVolunteerForm());
      }
        // this.dialogRef.close(this.addMemberForm());
    }
  //   addMemberForm(): any {
  //     const formData: FormData = new FormData();
  //     if (this.fileToUpload == undefined) {
  //         this.fileToUpload = ''
  //     }
  //     if (this.fileToUploadFront == undefined) {
  //         this.fileToUploadFront = ''
  //     }
  //     if (this.fileToUploadBack == undefined) {
  //         this.fileToUploadBack = ''
  //     }
  //     if (this.fileToUploadClearance == undefined) {
  //         this.fileToUploadClearance = ''
  //     }
  //     formData.append('image', this.fileToUpload);
  //     formData.append('front_id', this.fileToUploadFront);
  //     formData.append('back_id', this.fileToUploadBack);
  //     formData.append('clearance', this.fileToUploadClearance);
  //     formData.append('fullname_en', this.form.value.fullName_en);
  //     formData.append('fullname_ar', this.form.value.fullName_ar);
  //     formData.append('email', this.form.value.email);
  //     formData.append('phone', this.form.value.phone);
  //     formData.append('address', this.form.value.address);
  //     formData.append('joined_at', this.dateFormat(this.form.value.join_date));
  //     formData.append('club_id', this.getClubsObject(this.form.value.clubs).id);
  //     formData.append('national_id', this.form.value.ssn);
  //     // @ts-ignore
  //     console.log([...formData]);
  //     return formData;
  // }
  callAddVolunteerApi(data): any {
    this.apis.addVolunteerApi(data).subscribe((res: any) => {
        this.toastr.success(res.message, 'Success');
        this.loading = false;
        this.dialog.closeAll();
      },
      err => {
        this.errorMessage = err.error.data;
        for (const key in err.error.data) {
          this.toastr.error( err.error.data[key] , 'faild')
        }
      this.loading = false;
      });
  }

  callAddMemberApi(data): any {
    console.log(data);
    this.apis.addMemberApi(data).subscribe((res: any) => {
        this.toastr.success(res.message, 'Success');
        this.loading = false;
        this.dialog.closeAll();

            // this.route.navigate(['']); // navigate to other page
        },
        err => {
          this.loading = false;

          // this.errorMessage = err.error.errors
          // this.toastr.error(err.error.message, 'Failed');

          this.errorMessage = err.error.data;
        for (const key in err.error.data) {
          this.toastr.error( err.error.data[key] , 'faild')
        }
        });
}

    dateFormat(date): any {
        date = new Date(date);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();

        if (dt < 10) {
            dt = +'0' + dt;
        }
        if (month < 10) {
            month = +'0' + month;
        }
        return (year + '-' + month + '-' + dt);
    }

    checkMail(): void {
        this.message = '';
        const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (this.form.value.email?.match(mailFormat)) {
            this.message = '';
            this.right = false;
            this.spinner = true;
            this.apis.validate(this.emailKey, this.email).subscribe((data: any) => {
                    if (data.success === true) {
                        this.spinner = false;
                        this.right = true;
                    } else {
                        this.spinner = false;
                        this.message = 'The email has already been taken.';
                        this.form.controls.email.setErrors({
                            incorrect: true,
                            someProp: 'The email has already been taken.'
                        });
                    }
                },
                err => {


                });

    } else {
      this.message = 'You have entered an invalid email address!';
      this.form.controls.email.setErrors({incorrect: true, someProp: 'You have entered an invalid email address'});
    }
  }

    checkPhone(): void {
        this.message = '';
        if (this.form.get('phone').errors == null) {
            this.message = '';
            this.right2 = false;
            this.spinner2 = true;
            this.apis.validate(this.phoneKey, this.phone).subscribe((data: any) => {
                    if (data.success === true) {
                        this.spinner2 = false;
                        this.right2 = true;
                    } else {
                        this.spinner2 = false;
                        this.message = 'The phone number has already been taken.';
                        this.form.controls.phone.setErrors({
                            incorrect: true,
                            someProp: 'The email has already been taken.'
                        });
                    }
                },
                err => {


                });

        } else if (this.form.get('phone').errors) {
            this.message = 'You have entered an invalid phone number !';
            this.form.controls.phone.setErrors({incorrect: true, someProp: 'You have entered an invalid Phone number'});
        }
    }

    checkSsn(): void {
        this.message = '';
        this.rightSsn = false;
        if (this.form.get('ssn').errors == null) {
            this.message = '';
            this.rightSsn = false;
            this.spinnerSsn = true;
            this.apis.validate(this.nationalIdKey, this.ssn).subscribe((data: any) => {
                    if (data.success === true) {
                        this.spinnerSsn = false;
                        this.rightSsn = true;
                    } else {
                        this.spinnerSsn = false;
                        this.message = 'The SSN has already been taken.';
                        this.form.controls.ssn.setErrors({incorrect: true, someProp: 'The SSN has already been taken.'});
                    }
                },
                err => {


                });

        } else if (this.form.get('ssn').errors) {
            this.message = 'You have entered an invalid ssn !';
            this.form.controls.ssn.setErrors({incorrect: true, someProp: 'You have entered an invalid Ssn'});
        }
    }

    checkDate(e): void {
      console.log(e , this.thirdFormGroup.value.join_date);

        this.date = new Date().getTime();
        let due = new Date(this.dateFormat(e.value)).getTime();
        if (this.date >= due) {
            // this.form.controls['due_date'].setErrors({'incorrect': true, someProp: 'You must choose a day after today'});
        }
    }


}
