import {Component, OnInit} from '@angular/core';
import {Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';
import {SeoService} from '../../services/seo.service';
import {AppComponent} from '../../app.component';
@Component({
  selector: 'app-project-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss']
})
export class CaseDetailsComponent implements OnInit {
  videoUrl = 'https://player.vimeo.com/external/567641203.hd.mp4?s=c04076dd9a63e7d8dd2c7312cf1178db4066063b&profile_id=175';
  items: GalleryItem[];
  data: any = [
    {
      srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
      previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
    },
    {
      srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
      previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
    },
    {
      srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
      previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
    },
    {
      srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
      previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
    },
  ];
  constructor( public gallery: Gallery,
               public lightbox: Lightbox,
               private seoService: SeoService
  ) {
  }

  ngOnInit(): void {
    this.videoHandler();
    this.items = this.data.map(item =>
      new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
    );

    // Load items into the lightbox
    this.basicLightboxExample();

    // Load item into different lightbox instance
    // With custom gallery config
    this.withCustomGalleryConfig();
    this.seoUpdate();
  }
  basicLightboxExample(): void {
    this.gallery.ref().load(this.items);
  }
  seoUpdate(): any {
    this.seoService.updateConstMetaTags();

    this.seoService.updateTitle('Case details');
    this.seoService.updateDescription('Health system support project Health system support project Health system support projectHealth system support project Health system support project Health system support project Health system support projectHealth system support project Health system support project Health system support project Health system support projectHealth system support project Health system support project Health system support project Health system support projectHealth system support project Health system support project Health system support project Health system support projectHealth system support project');
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/explore/header.png');
  }
  /**
   * Use custom gallery config with the lightbox
   */
  withCustomGalleryConfig(): void {

    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }
  videoHandler(): void {
    if (window.screen.availWidth < 600) {
      this.videoUrl = 'https://player.vimeo.com/external/567151192.hd.mp4?s=db1921b9a7500a7a0cf1846bdaf69cec317782c5&profile_id=175';
    }
  }

}
