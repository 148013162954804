<!--<div class="wrapper" style="background-image: url('./assets/images/become-a-member2/become-a-member.jpg')" be_marvel="1">--><!--  <img class="d-flex justify-content-center img"-->
<!--    src="./assets/images/become-a-member3/js.jpg">-->
<!-- <img style="width: 100%; margin-bottom: 1px" src="assets/images/gray-plane-wing-62623.png"> -->
<div class="header-one">
  <div class="overlay">
  <div class="container ">      <!--      <h1 class="text-center"></h1>-->
    <div class="row mt-5">
      <h1 class="handel-tit mt-5 " translate>home.success<br>home.begins-here</h1>
      <p class="handel-p" translate>home.join-new-egypt-international</p>
    </div>
    <div class="row">
      <div class="col-12 share">
        <section>
        <div class="col-lg-1 order-lg-first ">
          <div class="sticky-content">
           <div class="social-icons social-icons-colored social-icons-vertical">
             <div class="icon-share share-ico-mrg">
            <span class="social-label" late>home.share</span>
            <a class="social-icon image-item" (click)="cop()" target="_blank">
              <img *ngIf="!copied" title="copy" style="width: 22px" alt="copy" src="./assets/newIcon/copy-2.png"/>
              <img *ngIf="copied" title="copied" style="width: 28px" alt="copied" src="./assets/newIcon/copied.png"/>
            </a>
            <a (click)="fbc($event)" class="social-icon social-facebook" title="Facebook">
              <i class="icon-facebook-f"></i>
            </a>
               <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                 <i class="icon-twitter"></i>
               </a>
            <a (click)="in()" class="social-icon social-linkedin"title="Linkedin">
              <i class="icon-linkedin"></i>
            </a>
             </div>
          </div>
          </div>
        </div>
        </section>
      </div>
    </div>
    <div class="row  mt-5 text-center">
      <div class=" col-6 btn-join">
        <button class="book my-4 join-us" (click)="routToAddMember()"  translate>
        home.join
        </button>
      </div>
      <div class="col-6 btn-join">
      <button class="book my-4 join-as-volunteer" (click)="routerLink()" translate>
        home.join-as-a-volunteer
      </button>
      </div>
    </div>
  </div>
</div>
</div>
