import {Component, Input, OnInit} from '@angular/core';
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-share-social',
  templateUrl: './share-social.component.html',
  styleUrls: ['./share-social.component.scss']
})
export class ShareSocialComponent implements OnInit {

  constructor( private clipboard:Clipboard) { }
  copied: boolean = false;
  @Input() router:any;

  ngOnInit(): void {
  }
  cop() {
    this.clipboard.copy('https://newegyptgroup.com/' + this.router)
    this.copied = !this.copied;
    setTimeout(() => {
      this.copied = false;
    }, 1500)

  }

  fbc(e) {
    let url = "www.google.com";
    e.preventDefault();
    var facebookWindow = window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
        "https://newegyptgroup.com/" +
        this.router,
        "facebook-popup",
        "height=350,width=600"
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }

    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://twitter.com/intent/tweet?url=' + 'https://newegyptgroup.com/' + this.router,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  in() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://linkedin.com/shareArticle?mini=true&url=' + 'https://newegyptgroup.com/' + this.router,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }
}
