<app-header></app-header>
<section class="bg-F8F8F8">
  <div class="head-line-image">
    <h3>تفاصيل الفيديو</h3>
  </div>
  <div class="container-fluid">
    <div class="country-lists">
      <div class="row">
        <div class="col-md-3 col-sm-12 small-screen">
          <div class="sidebar">
            <!--            <div class="buttons-forms">-->
            <!--              <div class="example-button-row">-->
            <!--                <button routerLink="/booking" class="red-btn" mat-raised-button color="warn">حجز موعد</button>-->
            <!--                <button mat-raised-button class="bg-white color-black" color="warn">إتمام الخدمات بالبريد</button>-->
            <!--                <button routerLink="/prices-list"  class="bg-black" mat-raised-button color="accent">أسعار الخدمات-->
            <!--                </button>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="latest-news">
              <div class="items">
                <div class="header-news">
                  <span>أخبار أخرى</span>
                </div>
                <hr class="w-100 mt-0">
                <div (click)="newsDetails(news.id)" class="item"
                     *ngFor="let news of latestNews | slice:0:5; let i=index">
                  <div class="inner-content">
                    <div class="image-news">
                      <img *ngIf="news.image" src="{{news.image}}" alt="">
                    </div>
                    <p>{{news.title_ar.slice(0, 300)}}....</p>
                  </div>
                  <div class="date-news">
                    <label>{{transform(news.date | date: 'yyyy-MM-dd')}}</label>
                  </div>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-sm-12 text-end news-description">
          <div id="news">
            <div class="d-flex justify-content-between align-items-center flex-row-reverse">
              <div class="h2 mb-0">
                {{title}}
              </div>
              <div class="p-absolute btn ">
                <button class="projects-btn"><a class="text-decoration-none color-white"
                                                href="https://www.aplos.com/aws/give/newegyptgroup/give">DONATE</a>
                </button>
              </div>
            </div>
            <div class="d-flex mb-2 flex-row-reverse">
              <div class="list-headline location">
                <span class="ml-2">{{transform(date | date: 'yyyy , MM , dd')}}</span>
                <span>
                  <span>{{date | date: 'hh:mm'}}</span>
                </span>
                <span class="pr-1">صباحا</span>
              </div>
            </div>
            <video class="img-fluid home-video"
                   title="EGYPT"
                   aria-roledescription=""
                   muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
                   autoplay
                   oncanplay="this.play()"
                   src="{{video}}" (contextmenu)="prevent($event)">
            </video>
            <div class="h3">
              <span>{{title}}</span>
            </div>
            <div class="content-news">
              {{description}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
