import {Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild} from '@angular/core'; 
import {ApisService} from "../services/apis.service"; 
import {MatAutocomplete} from "@angular/material/autocomplete"; 
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms"; 
import {StripeService, StripeCardComponent} from 'ngx-stripe'; 
import {StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js'; 
import {SeoService} from "../services/seo.service"; 
import {isPlatformBrowser, isPlatformServer} from '@angular/common'; 
import {ToastrService} from 'ngx-toastr'; 
import {el} from 'date-fns/locale'; 
import {Clipboard} from "@angular/cdk/clipboard"; 
import {Router} from "@angular/router"; 
import { DonationFormComponent } from '../donation-form/donation-form.component'; 
import { MatDialog } from '@angular/material/dialog'; 
 
// import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap"; 
@Component({ 
  selector: 'app-donation', 
  templateUrl: './donation.component.html', 
  styleUrls: ['./donation.component.scss'] 
}) 
export class DonationComponent implements OnInit { 
  @Input() total; 
  disabled: boolean = false; 
  stripeCardValid: boolean = false; 
  @ViewChild(StripeCardComponent) card: StripeCardComponent; 
  cardOptions: StripeCardElementOptions = { 
    style: { 
      base: { 
        iconColor: '#666EE8', 
        color: '#31325F', 
        fontWeight: '300', 
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif', 
        fontSize: '30px', 
        '::placeholder': { 
          color: '#CFD7E0' 
        } 
      } 
    } 
  }; 
  // elementsOptions: StripeElementsOptions = { 
  //   locale: 'en' 
  // }; 
  myForm: FormGroup; 
  user: any; 
  token: any; 
  formSubmited = false; 
  generalpurposes: any; 
  donateactivities: any; 
  donateforactivity: any; 
  show: boolean; 
  hidden: boolean = true; 
  activities: MatAutocomplete; 
  purpose: MatAutocomplete; 
  donateSelect: any[] = [100, 500, 2000, 4000, 8000, 10000]; 
  select: number; 
  activitiesId: number; 
  purposeId: number; 
  errorMessage!: string; 
  copied:boolean=false; 
  constructor(@Inject(PLATFORM_ID) private platformId: Object, 
              private seoService: SeoService, 
              private apis: ApisService, 
              private fb: FormBuilder, 
              private router: Router, 
              private clipboard: Clipboard, 
              private stripeService: StripeService, 
              private toastr: ToastrService, 
              public dialog: MatDialog, 
 
              // public activeModal: NgbActiveModal, 
  ) { 
    if (isPlatformBrowser(this.platformId)) { 
      this.token = localStorage.getItem('access_token') 
    } 
  } 
 
  ngOnInit(): void { 
    this.seoUpdate(); 
    if (isPlatformBrowser(this.platformId)) { 
      this.user = JSON.parse(localStorage.getItem('currentUser')) 
    } 
 
    this.generalPurposesApi(); 
    this.getActivities(); 
    this.myForm = this.fb.group({ 
      // fullname: ['', Validators.required], 
      // email: ['', [Validators.required, Validators.email]], 
      // phone: ['', Validators.required], 
      // card_number: ['', Validators.required], 
      // exp_month: ['', Validators.required], 
      // exp_year: ['', Validators.required], 
      // cvc: ['', Validators.required], 
      amount: ['', Validators.required], 
      is_activity: ['', Validators.required], 
      activity_id: ['', Validators.required], 
      general_purpose_id: ['', Validators.required], 
      stripeToken: ['', Validators.required], 
      checked_fee: ['1', Validators.required], 
 
    }); 
    if (!this.user) { 
      this.myForm.addControl('fullname', new FormControl('', Validators.required)); 
      this.myForm.addControl('email', new FormControl('', Validators.required)); 
      this.myForm.addControl('phone', new FormControl('', Validators.required)); 
    } 
    // this.checkActivity(this.show) 
 
  } 
 
  f() { 
    console.log('form sss=>', this.myForm) 
  } 
  checkedFee(fee){ 
    if(fee){ 
      this.myForm.controls['checked_fee'],
      this.myForm.patchValue({ 
        checked_fee: 1 
      }) 
    }else{ 
      this.myForm.patchValue({ 
        checked_fee:'' 
      }) 
    } 
  } 
  checkselect(event) { 
    if (event) { 
      // console.log('mahmoudayman') 
      this.myForm.controls['activity_id'].disable(); 
      this.myForm.patchValue({ 
        is_activity: 0 
      }) 
    } else { 
      this.myForm.controls['general_purpose_id'].disable(); 
      this.myForm.patchValue({ 
        is_activity: 1 
      }) 
    } 
  } 
 
  // checkActivity(show){ 
  //   if(show){ 
  //     this.myForm.addControl('general_purpose_id', new FormControl('', Validators.required)); 
  //     this.myForm.addControl('is_activity', new FormControl(0, Validators.required)); 
  //     this.myForm.removeControl('activity_id') 
  //   }else{ 
  //     this.myForm.addControl('activity_id', new FormControl('', Validators.required)); 
  //     this.myForm.removeControl('general_purpose_id') 
  //     } 
  // } 
  createToken(): void { 
    const stripeToken = this.myForm.get('stripeToken').value; 
    this.stripeService 
      .createToken(this.card.element, {name: stripeToken}) 
      .subscribe((result) => { 
        if (result.token) { 
          this.myForm.patchValue({ 
            stripeToken: result.token.id 
          }) 
          this.onSubmit() 
          // this.onSubmit(this.myForm); 
          // this.formSubmited = true; 
          // Use the token 
          console.log('result =>', result.token.id); 
        } else if (result.error) { 
          // Error creating the token 
          console.log(result.error.message); 
        } 
      }); 
  } 
 
  seoUpdate(): any { 
    this.seoService.updateConstMetaTags(); 
    this.seoService.updateTitle('Support our Mission | New Egypt Group'); 
    this.seoService.updateDescription('Your donations will help improve education systems, water supplies, employment and food distribution, and much more. Alleviating poverty is our first priority, and with your support, we can make a difference. Donate now to help us make the world a better place for everyone. Thank you for your support'); 
    this.seoService.updateUrl(); 
    this.seoService.updateImage('https://newegyptgroup.com/assets/images/610X360%203.jpg'); 
  } 
 
  selectDonate(select: number) { 
    this.select = select 
    this.myForm.patchValue({ 
      amount: select 
    }) 
  } 
 
  getIdActivitis(id: any) { 
    this.activitiesId = id; 
    this.purposeId = null 
  } 
 
  getIdPurpose(id: any) { 
    this.purposeId = id; 
    this.activitiesId = null 
  } 
 
  onSubmit() { 
    this.disabled = true; 
    if (this.purposeId) { 
      console.log(this.myForm); 
      this.apis.getDonate(this.token, this.myForm.value).subscribe({ 
        next: (res: any) => { 
          console.log('donation =>', res); 
          this.toastr.success(res.message, 'Success'); 
          this.disabled = false; 
        }, error: (err: any) => { 
          console.log(err) 
          this.errorMessage = err.error.errors 
          for (const key in err.error.errors) { 
            this.toastr.error( err.error.errors[key] , 'faild') 
          } 
          this.disabled = false; 
        } 
      }) 
    } else if (this.activitiesId) { 
      this.donateForActivityApi(this.activitiesId) 
    } 
    console.log(this.myForm.value) 
  } 
 
  generalPurposesApi(): any { 
    this.apis.getGeneralPurposes().subscribe((data: any) => { 
        this.generalpurposes = data.data; 
        console.log(this.generalpurposes); 
      }, 
      err => { 
        console.log(err); 
      }); 
  } 
 
  getActivities(): any { 
    this.apis.getActivitiesApi().subscribe((data: any) => { 
        this.donateactivities = data.data; 
        console.log(this.donateactivities); 
      }, 
      err => { 
        console.log(err); 
      }); 
  } 
 
  donateForActivityApi(id): any { 
    this.apis.getDonateForActivity(id, this.myForm.value).subscribe((res: any) => { 
        this.donateforactivity = res; 
        console.log(this.donateforactivity) 
        this.toastr.success(res.message, 'Success');
        this.disabled = false; 
      }, 
      err => { 
        console.log(err); 
        this.errorMessage = err.error.errors 
        this.toastr.error(err.error.message, 'Failed'); 
        this.disabled = false; 
      }); 
  } 
 
  cop() { 
    this.clipboard.copy('https://newegyptgroup.com' + this.router.url) 
      this.copied = !this.copied; 
    setTimeout(()=>{ 
      this.copied = false; 
    },1500) 
 
  } 
 
  fbc(e) { 
    let url = "www.google.com"; 
    e.preventDefault(); 
    var facebookWindow = window.open( 
      "https://www.facebook.com/sharer/sharer.php?u=" + 
      "newegyptgroup.com" + 
      this.router.url, 
      "facebook-popup", 
      "height=350,width=600" 
    ); 
    if (facebookWindow.focus) { 
      facebookWindow.focus(); 
    } 
 
    return false; 
  } 
 
  tw() { 
    let url = 'www.google.com'; 
    var Window = window.open( 
      'https://twitter.com/intent/tweet?url=' + 'newegyptgroup.com' + this.router.url, 
      '-popUp', 
      'width= 600px, height= 350px' 
    ); 
    if (Window.focus) { 
      Window.focus(); 
    } 
  } 
 
  in() { 
    let url = 'www.google.com'; 
    var Window = window.open( 
      'https://linkedin.com/shareArticle?mini=true&url=' + 'newegyptgroup.com' + this.router.url, 
      '-popUp', 
      'width= 600px, height= 350px' 
    ); 
    if (Window.focus) { 
      Window.focus(); 
    } 
  } 
  openDonationForm() { 
    const dialogRef = this.dialog.open(DonationFormComponent , { 
      height: '90vh', 
      width: '25vw', 
    }); 
 
    dialogRef.afterClosed().subscribe(result => { 
      console.log(`Dialog result: ${result}`); 
    }); 
  } 
}
