import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router'; // import router from angular router
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApisService} from '../../services/apis.service';
import {HayahComponent} from '../../pages/hayah/hayah.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Clipboard} from "@angular/cdk/clipboard";
import {ToastrService} from "ngx-toastr";
import { FormJourneyComponent } from '../profile/personal-form/form-journey/form-journey.component';

@Component({
  selector: 'app-add-member-form',
  templateUrl: './add-member-form.component.html',
  styleUrls: ['./add-member-form.component.scss']
})
export class AddMemberFormComponent implements OnInit {
  form: FormGroup;
  countries: any;
  fileToUpload: any = '';
  fileToUploadFront: any = '';
  fileToUploadBack: any = '';
  fileToUploadClearance: any = '';
  errorMessage!: string;
  image: any;
  imageFront: any;
  imageBack: any;
  imageClearance: any;
  ImagePath: any;
  message: any;
  selected: any = 'A week';
  emailKey: any = 'email';
  phoneKey: any = 'phone';
  nationalIdKey: any = 'national_id';
  date: any;
  governorates: any;
  clubs: any;
  email: any;
  spinner: any = false;
  right: any = false;
  right2 = false;
  spinner2 = false;
  rightSsn = false;
  spinnerSsn = false;
  phone: any;
  ssn: any;
  egyptCase = false;
  router :string='/add-member';
  copied=false;
  UnaitedStateCase = false;

  constructor(private apis: ApisService,
              private route: Router,
              private clipboard: Clipboard,
              private toastr: ToastrService,
              public dialog: MatDialog,

  ) {
  }

  ngOnInit(): void {
    this.getCountries();
    let today = new Date();
    let dateTime = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
    this.date = dateTime;
    this.form = this.initForm();
    // this.getDonatePurpose();
    this.form.patchValue({
      purpose_id: 'Haya Karima',
      pledge_date: this.date
    });
  }

  initForm(): FormGroup {
    return new FormGroup({
      fullName_en: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.minLength(3), Validators.required]),
      address: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]),
      ssn: new FormControl('',),
      image: new FormControl('',),
      image_front: new FormControl('',),
      image_back: new FormControl('',),
      image_clearance: new FormControl('',),
      join_date: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      clubs: new FormControl('', [Validators.required]),
    });
  }

  addMemberForm(): any {
    const formData: FormData = new FormData();
    formData.append('image', this.fileToUpload);
    formData.append('front_id', this.fileToUploadFront);
    formData.append('back_id', this.fileToUploadBack);
    formData.append('clearance', this.fileToUploadClearance);
    formData.append('fullname_en', this.form.value.fullName_en);
    formData.append('email', this.form.value.email);
    formData.append('phone', this.form.value.phone);
    formData.append('address', this.form.value.address);
    formData.append('joined_at', this.dateFormat(this.form.value.join_date));
    formData.append('club_id', this.getClubsObject(this.form.value.clubs).id);
    formData.append('national_id', this.form.value.ssn);
    // @ts-ignore
    return formData;
  }

  getPosts(e): any {
    if (e == 'Egypt') {
      this.egyptCase = true;
      this.form.controls.ssn.setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      this.form.controls.image.setValidators([Validators.required,]);
      this.form.controls.image_front.setValidators([Validators.required,]);
      this.form.controls.image_back.setValidators([Validators.required,]);
      this.form.controls.image_clearance.setValidators([Validators.required,]);
    } else {
      this.egyptCase = false;
    }
    const obj = this.getCountriesObject(e);
    this.getClubs(obj.id);
  }
  // getPosts(us): any {
  //   if (us == 'Unaited State') {
  //     this.egyptCase = true;
  //     this.form.controls.ssn.setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
  //     this.form.controls.image.setValidators([Validators.required,]);
  //   } else {
  //     this.UnaitedStateCase = false;
  //   }
  //   const obj = this.getCountriesObject(us);
  //   this.getClubs(obj.id);
  // }


  getCountries(): any {
    this.apis.getCountries().subscribe((data: any) => {
        this.countries = data.data;
      },
      err => {
        console.log(err);
      });
  }

  getClubs(id): any {
    this.apis.getClubs(id).subscribe((data: any) => {
        this.clubs = data.data;
      },
      err => {
        console.log(err);
      });
  }

  getCountriesObject(purpose): any {
    const index = this.countries.findIndex(obj => obj.name === purpose);
    if (index > -1) {
      return this.countries[index];
    }
  }

  getClubsObject(club): any {
    const index = this.clubs.findIndex(obj => obj.name === club);
    if (index > -1) {
      return this.clubs[index];
    }
  }

  onFileSelected(files: FileList): void {
    this.fileToUpload = files.item(0);
    (document.getElementById('blah') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUpload);
    (document.getElementById('blah') as HTMLInputElement).style.height = '70px';
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = event.target.result;

      };
      reader.readAsDataURL(files[0]);
    }
  }

  onFileSelectedFront(files: FileList): void {
    this.fileToUploadFront = files.item(0);
    (document.getElementById('blah1') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUploadFront);
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageFront = event.target.result;
      };
      reader.readAsDataURL(files[0]);
    }
  }

  onFileSelectedBack(files: FileList): void {
    this.fileToUploadBack = files.item(0);
    (document.getElementById('blah2') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUploadBack);
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageBack = event.target.result;
      };
      reader.readAsDataURL(files[0]);
    }
  }

  onFileSelectedClearance(files: FileList): void {
    this.fileToUploadClearance = files.item(0);
    (document.getElementById('blah3') as HTMLInputElement).src = window.URL.createObjectURL(this.fileToUploadClearance);
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageClearance = event.target.result;
      };
      reader.readAsDataURL(files[0]);
    }
  }

  close(): void {
    const obj = this.addMemberForm();
    this.callAddMemberApi(this.addMemberForm());
    // this.dialogRef.close(this.addMemberForm());
  }

  callAddMemberApi(data): any {
    this.apis.addMemberApi(data).subscribe((res: any) => {
        this.toastr.success(res.message, 'Success');
        // this.route.navigate(['']); // navigate to other page
      },
      err => {
        console.log(err);
        this.errorMessage = err.error.errors
        this.toastr.error(err.error.message, 'Failed');
      });
  }
  openDialog() {
    const dialogRef = this.dialog.open(FormJourneyComponent , {
      height: '90vh',
      width: '25vw',
      data :{type: 0}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  dateFormat(date): any {
    date = new Date(date);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = +'0' + dt;
    }
    if (month < 10) {
      month = +'0' + month;
    }
    return (year + '-' + month + '-' + dt);
  }

  checkMail(): void {
    this.message = '';
    const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (this.form.value.email?.match(mailFormat)) {
      this.message = '';
      this.right = false;
      this.spinner = true;
      this.apis.validate(this.emailKey, this.email).subscribe((data: any) => {
          if (data.success === true) {
            this.spinner = false;
            this.right = true;
          } else {
            this.spinner = false;
            this.message = 'The email has already been taken.';
            this.form.controls.email.setErrors({incorrect: true, someProp: 'The email has already been taken.'});
          }
        },
        err => {


        });

    } else {
      this.message = 'You have entered an invalid email address!';
      this.form.controls.email.setErrors({incorrect: true, someProp: 'You have entered an invalid email address'});
    }
  }

  checkPhone(): void {
    this.message = '';
    if (this.form.get('phone').errors == null) {
      this.message = '';
      this.right2 = false;
      this.spinner2 = true;
      this.apis.validate(this.phoneKey, this.phone).subscribe((data: any) => {
          if (data.success === true) {
            this.spinner2 = false;
            this.right2 = true;
          } else {
            this.spinner2 = false;
            this.message = 'The email has already been taken.';
            this.form.controls.phone.setErrors({incorrect: true, someProp: 'The email has already been taken.'});
          }
        },
        err => {


        });

    } else if (this.form.get('phone').errors) {
      this.message = 'You have entered an invalid phone number !';
      this.form.controls.phone.setErrors({incorrect: true, someProp: 'You have entered an invalid Phone number'});
    }
  }

  checkSsn(): void {
    this.message = '';
    this.rightSsn = false;
    if (this.form.get('ssn').errors == null) {
      this.message = '';
      this.rightSsn = false;
      this.spinnerSsn = true;
      this.apis.validate(this.nationalIdKey, this.ssn).subscribe((data: any) => {
          if (data.success === true) {
            this.spinnerSsn = false;
            this.rightSsn = true;
          } else {
            this.spinnerSsn = false;
            this.message = 'The SSN has already been taken.';
            this.form.controls.ssn.setErrors({incorrect: true, someProp: 'The SSN has already been taken.'});
          }
        },
        err => {


        });

    } else if (this.form.get('ssn').errors) {
      this.message = 'You have entered an invalid ssn !';
      this.form.controls.ssn.setErrors({incorrect: true, someProp: 'You have entered an invalid Ssn'});
    }
  }

  checkDate(e): void {
    this.date = new Date().getTime();
    let due = new Date(this.dateFormat(e.value)).getTime();
    if (this.date >= due) {
      // this.form.controls['due_date'].setErrors({'incorrect': true, someProp: 'You must choose a day after today'});
    }
  }

  cop() {
    this.clipboard.copy('https://newegyptgroup.com' + this.router)
    this.copied = !this.copied;
    setTimeout(() => {
      this.copied = false;
    }, 1500)

  }

  fbc(e) {
    let url = "www.google.com";
    e.preventDefault();
    var facebookWindow = window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
        "newegyptgroup.com" +
        this.router,
        "facebook-popup",
        "height=350,width=600"
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }

    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://twitter.com/intent/tweet?url=' + 'newegyptgroup.com' + this.router,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  in() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://linkedin.com/shareArticle?mini=true&url=' + 'newegyptgroup.com' + this.router,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }
}
