import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {AppComponent} from '../../app.component';     
  import {SeoService} from '../../services/seo.service';
import {ApisService} from '../../services/apis.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  videoUrl = 'https://player.vimeo.com/external/567641203.hd.mp4?s=c04076dd9a63e7d8dd2c7312cf1178db4066063b&profile_id=175';
  users = [
    {name: 'John', age: 23},
    {name: 'Andrew', age: 3},
    {name: 'Peter', age: 8},
    {name: 'Hanna', age: 14},
    {name: 'Adam', age: 37}
  ];
  slug: any;
  nextevent: any;
  panelOpenState = false;
  showAll = true;
  lang:string=localStorage.getItem('lang');
  constructor(private seoService: SeoService,
              private router:Router,
              private apis: ApisService, ) {

  }
  ngOnInit(): void {
    this.getNextEventApi();
  this.seoUpdate()
  }

  prevent(e): void {
    e.preventDefault();
  }

  seoUpdate(): any {
    this.seoService.updateTitle('New Egypt Group');
    this.seoService.updateDescription('New Egypt Group is a non-profit organization that builds bridges between the Egyptian community in America and their beloved country, Egypt. It carries out many activities including education initiatives for children as well empowerment of women so they can build futures generations upon generation sweep');
    this.seoService.updateUrl();
    this.seoService.updateImage('https://newegyptgroup.com/assets/newIcon/home-share.png');
  }
  getNextEventApi(): void {
    this.apis.getNextEvent().subscribe((data: any) => {
      this.nextevent = data.data;
      console.log(data);
    }, (err: any) => {
    });
  }
  changeFlag()
  {
    this.showAll = ! this.showAll;
  }
  routerLink(){
    console.log('dddddddddddddddd' , this.lang)
    this.router.navigate(['/case-form'], { queryParams: {lang:this.lang}});
  }
}
