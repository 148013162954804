<!-- <img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">
<div class="bg-white pt-5">
  <div class="container" >
    <div class="row">
      <div class="col-4" *ngFor="let item of clubMembers">
        <div class="projects"> -->
          <!--      <owl-carousel-o  >-->
          <!--        <ng-container  >-->
          <!--          <ng-template carouselSlide  >-->
          <!-- <div class=" card card-two" (click)="memberDetails(item.id)" style="cursor: pointer">
            <header>
              <div class="avatar">
                <img *ngIf="!item.image" height="60px" width="60px" class="circle" src="./assets/images/avatar/user-anonymous.png">
                <img *ngIf="item.image" height="60px" width="60px" class="circle" src="{{item.image}}"> -->
<!--                <img src="{{item.image}}" alt="Allison Walker"/>-->
              <!-- </div>
            </header> -->
            <!-- {{member.fullname}} -->
            <!-- <h3 class="user-name">{{item.fullname_en}}</h3><h4 class="text-center">{{item.clubname}}</h4>
            <div class="desc" >
              {{item.bio|slice:0:180}}

              <span *ngIf="item.bio">...</span>
            </div> -->
            <!-- <div class="contacts">
              <a href=""><i class="fa fa-plus"></i></a>
              <a href=""><i class="fa fa-whatsapp"></i></a>
              <a href=""><i class="fa fa-envelope"></i></a>
              <div class="clear"></div>
            </div> -->
            <!-- <footer>
              <p>
                <a *ngIf="item.facebook"  href="{{item.facebook}}"><i class="fa fa-facebook"></i></a>
                <a *ngIf="item.linkedin"  href="{{item.linkedin}}"><i class="fa fa-linkedin"></i></a>
                <a *ngIf="item.twitter"   href="{{item.twitter}}"><i class="fa fa-twitter"></i></a>
                <a *ngIf="item.instagram" href="{{item.instagram}}"><i class="fa fa-instagram"></i></a>
              </p>
            </footer>
          </div> -->
          <!--          </ng-template>-->
          <!--        </ng-container>-->
          <!--      </owl-carousel-o>-->
        <!-- </div>
      </div>
    </div>
    <ngb-pagination class="d-flex justify-content-center my-5 py-5" [collectionSize]="collectionSize"
                    (pageChange)="clubMembersApi(page)" [(page)]="page" aria-label="custom pagination">
    </ngb-pagination>
  </div>
</div> -->






<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">

<div class="container">
  <!-- <div class="row">
    <div class="col-3">
    </div>
  <div class="col-6 justify-content-center">
    <mat-form-field appearance="fill" class=" mt-5 w-100">
      <mat-label class="mb-1 clubs" translate>home.clubs</mat-label>
      <mat-select   ng-model="myModel" class="select">
        <mat-option *ngFor="let option of clubs" (click)="allVolunteersAtClub(option.id)" [value]="option.id" >
          {{option.name}}
        </mat-option>
      </mat-select> -->
      <!--          <mat-error *ngIf="club_id.hasError('required')" translate>home.board-members</mat-error>-->
    <!-- </mat-form-field>
  </div>
  <div class="col-3">
  </div>
</div> -->

<div class="tpath-team-grid-wrapper team-type-grid mt-5">
      <div id="tpath-team-grid1" class="row tpath-grid-inner team-columns-4">
        <div class="team-item col-md-3 col-xs-12" *ngFor="let item of Members">
          <div class="team-item-wrapper">
            <div class="team-image-wrapper">
              <div class="team-item-img">
                <img *ngIf="!item.image"  alt="{{item.fullname_en}}"  height="250px" width="450px" class="img-responsive" src="assets/images/avatar/user-anonymous.png">
                <img *ngIf="item.image" src="{{item.image}}" alt="{{item.fullname_en}}" width="450" height="250px"  class="img-responsive">
              </div>
              <div class="team-label"></div>
            </div>
            <div class="team-content-wrapper">
              <h5 class="team-member-name">
                <a href="" title="Jonathan Doe">{{item.fullname_en}}</a>
              </h5>
<!--                <div class="team-member_phone">-->
<!--                  <i class="bi bi-phone-fill"></i>-->
<!--                  <span>Mobile</span> :{{item.phone}}</div>-->
<!--                <div class="team-member_email">-->
<!--                  <i class="bi bi-envelope-fill"></i>-->
<!--                  <span>Email</span> :-->
<!--                  <a href="">{{item.email}}</a>-->
<!--                </div>-->
              <div class="member-social"
              ><ul class="tpath-member-social-icons list-inline">
                <li class="facebook">
                  <a *ngIf="item.facebook" target="_blank" href="{{item.facebook}}">
                    <img src="assets/icons/287739_facebook_icon.svg">
                  </a>
                    <a *ngIf="!item.facebook"target="_blank" href="https://www.facebook.com/">
<!--                      <i class="fa fa-facebook"></i>-->
                    <img src="assets/icons/287739_facebook_icon.svg">
                  </a>
                </li>
                <li class="twitter">
                  <a *ngIf="item.twitter" target="_blank" href="{{item.twitter}}">
<!--                      <i class="fa fa-twitter"></i>-->
                    <img src="assets/icons/294709_circle_twitter_icon.svg">
                  </a>
                  <a *ngIf="!item.twitter" target="_blank" href="https://twitter.com/">
                    <img src="assets/icons/294709_circle_twitter_icon.svg">
                  </a>
                </li>
                <li class="instagram">
                  <a *ngIf="item.instagram" target="_blank" href="{{item.instagram}}">
<!--                      <i class="fa fa-instagram"></i>-->
                    <img src="assets/icons/3225191_app_instagram_logo_media_popular_icon.svg">
                  </a>
                  <a *ngIf="!item.instagram "target="_blank" href="https://www.instagram.com/">
                    <img src="assets/icons/3225191_app_instagram_logo_media_popular_icon.svg">
                  </a>
                </li>
                <li class="linkedin">
                  <a *ngIf="item.linkedin" target="_blank" href="{{item.linkedin}}">
<!--                    <i class="fa fa-google-plus"></i>-->
                  <img src="assets/icons/317750_linkedin_icon.svg">
                </a>
                  <a *ngIf="!item.linkedin" target="_blank" href="https://www.linkedin.com/">
                    <img src="assets/icons/317750_linkedin_icon.svg">
                  </a>
                </li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<nav aria-label="..." class="d-flex justify-content-center my-5 py-5 paginator">
  <ul class="pagination">
    <li class="page-item " [style]="page == 1 ? 'pointer-events: none':''">
      <a class="page-link" href="javascript:void(0);" tabindex="-1"
         (click)="clubMembersApi(page-1)">Previous</a>
    </li>
    <li [class]="page == i+1 ? 'active': '' " class="page-item " *ngFor="let _ of [].constructor(collectionSize); let i = index ">
      <a class="page-link" href="javascript:void(0);" (click)="clubMembersApi(i+1)">{{i + 1}}
      </a>
    </li>
    <li class="page-item" [style]="page == collectionSize ? 'pointer-events: none':''">
      <a class="page-link" href="javascript:void(0);" (click)="clubMembersApi(page+1)">Next</a>
    </li>
  </ul>
</nav>

</div>
