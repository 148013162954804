import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ApisService} from '../../services/apis.service';
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-member-slider',
  templateUrl: './member-slider.component.html',
  styleUrls: ['./member-slider.component.scss']
})
export class MemberSliderComponent implements OnInit {
  @Input() members: any;
  boardMember: any = null;
  getBoardClubsMember: any
  animalControl = new FormControl('', Validators.required);
  carouselOptions2: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 1},
      992: {items: 3}
    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };

  constructor(private router: Router, private apis: ApisService,) {
    // this.apis.getBoardClubs(10).subscribe((data: any) => {
    //   this.getBoardClubsMember = data.data;
    //   console.log(this.boardMember , 'data getBoardClubs');
    // });
  }

  ngOnInit(): void {

  }

  memberDetails(id): void {
    this.router.navigate(['/memberDetails/', this.members.board_id, id]);
  }

  // getBoardClubs(id: any) {
  //   this. getBoardClubsMember = null;
  //   this.apis.getBoardClubs(id).subscribe((data: any) => {
  //     this.getBoardClubsMember = data.data;
  //     console.log(this.boardMember , 'data getBoardClubs');
  //   });

  // }
}
