<div class="bg-white pt-5 news" >
  <div class="container">
    <div class="projects">
      <h1 class="header mb-5" translate>home.latest-news</h1>

      <owl-carousel-o  [options]="carouselOptions2">
        <ng-container>
          <ng-template carouselSlide *ngFor="let item of news">
            <div class="col-12">
            <div class="post-item position-relative h-100" (click)="newsDetails(item.slug)">
              <div class="post-img position-relative overflow-hidden" >
                <img *ngIf="item.image" src="{{item.image}}" class="img-fluid" alt="{{item.title}}" style="height: 250px !important;">
                <span class="post-date">{{item.date | date :'d  MMM  yyy'}}</span>
              </div>

              <div class="post-content d-flex flex-column">
              <div class="handelTitle">
                <h3 class="post-title">{{item.title.slice(0,40)}}</h3>
              </div>
                <p class="text-left">
                  {{item.excerpt.slice(0,90)}}...
                </p>
                <hr>
                <a routerLink="/news" class="readmore stretched-link">
                  <span translate>home.details</span>
<!--                  <i class="bi bi-arrow-right"></i>-->
                </a>

              </div>

            </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
      <div class="d-flex justify-content-center mt-4">
        <a style="text-decoration: none" routerLink="/news">
          <button class="projects-btn" translate>home.all-news</button>
        </a>
      </div>
    </div>
  </div>
</div>
