import { Component, OnInit } from '@angular/core';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-members-form',
  templateUrl: './members-form.component.html',
  styleUrls: ['./members-form.component.scss']
})
export class MembersFormComponent implements OnInit {

  constructor( private seoService: SeoService,) { }

  ngOnInit(): void {
    this.seoUpdate();
  }
  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('New Egypt Group | Join Us And Make A Difference');
    this.seoService.updateDescription('Join New Egypt Group and become part of a community that is passionate about making a difference. We are a non-profit organization that welcomes everybody with open arms. With one heart, many hands, we can make change happen.');
    this.seoService.updateImage('https://newegyptgroup.com/assets/newIcon/members.jpg');
    this.seoService.updateUrl();
  }
}
