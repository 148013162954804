<app-header></app-header>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-6">
<h2 class="card-title font-weight-bold mb-1">  Forgot your password ? 🔒  </h2>
      <p class="card-text mb-2">Enter your email and we will send you instructions to reset your passwordp</p>
<form novalidate="" class="auth-forgot-password-form mt-2 ng-pristine ng-invalid ng-touched">
  <div class="form-group">
    <label  class="form-label d-flex mb-4">E-mail</label>
    <input type="text" formcontrolname="email" placeholder="john@example.com" aria-describedby="forgot-password-email" autofocus="" tabindex="1" class="form-control ng-pristine ng-invalid ng-touched"><!---->
  </div>
  <button tabindex="2" rippleeffect="" class="btn btn-primary btn-block waves-effect waves-float waves-light" [routerLink]="['/sendemail/']">Send Reset link</button>
</form>
      <p class="text-center mt-2">
        <a routerlink="" href="sign-in"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left mb-25">
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
          Back to login
        </a>
      </p>
    </div>
  </div>
</div>
<div class="footer">
<app-footer></app-footer>
</div>
