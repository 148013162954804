<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">
<section>
  <div class="container direction-rtl">
    <div class="row">
      <div class="profile-header" *ngIf="boardMember">
        <div class="profile-img" >
          <img src="{{boardMember.image}}" width="200" alt="Profile Image">
        </div>
        <!-- <div class="memName"> -->
          <h1 class="user-name">{{boardMember.fullname_en}}</h1>
        <!-- </div> -->
<!--        <div class="profile-option">-->
<!--          <div class="notification">-->
<!--            <i class="fa fa-bell"></i>-->
<!--            <span class="alert-message">3</span>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="main-bd" *ngIf="boardMember">
        <div class="left-side">
          <div class="profile-side direction-ltr text-left" >
            <!-- <p class="mobile-no"><i class="fa fa-phone"></i> +23470xxxxx700</p>
            <p class="user-mail"><i class="fa fa-envelope"></i> Brightisaac80@gmail.com</p> -->
            <div class="user-bio text-center" >
              <!-- <h3 *ngIf="boardMember.bio" >Bio</h3> -->
              <h3 class="bio">
              {{boardMember.job}}</h3>
            </div>
            <ul class="social-icons">
              <li class="facebook" *ngIf="boardMember.facebook">
                <a href="{{boardMember.facebook}}"><i class="bi bi-facebook"></i></a>
            </li>
              <li class="twitter" *ngIf="boardMember.twitter">
                <a href="{{boardMember.twitter}}"><i class="bi bi-twitter"></i></a>
              </li>
              <li class="linkedin" *ngIf="boardMember.linkedin">
                <a href="{{boardMember.linkedin}}"><i class="bi bi-linkedin"></i></a>
              </li>
              <li class="instagram" *ngIf="boardMember.instagram">
                <a href="{{boardMember.linkedin}}"><i class="bi bi-instagram"></i></a>
              </li>
            </ul>
          </div>

        </div>
        <div class="right-side w-100 direction-ltr">
          <div class="nav" class="w-100">
            <mat-tab-group class="w-100 memBio">
              <mat-tab   label="{{ 'home.member-bio' | translate }}" ng-model="myModel"></mat-tab>
            </mat-tab-group>
          </div>
          <p *ngIf="all==true" class="memBio">{{boardMember.bio}}<span *ngIf="boardMember.bio" (click)="showAll()" class="text-primary">&nbsp;show less</span></p>
          <p *ngIf="all==false"  class="memBio">{{boardMember.bio|slice:0:480}}<span *ngIf="boardMember.bio" (click)="showAll()" class="text-primary">&nbsp;show more</span></p>
        </div>
      </div>
    </div>
  </div>
  <!-- @Input() members: any; -->
  <app-members-slider [boardMembers]="boardMembers"></app-members-slider>
</section>
