import {Component, OnInit} from '@angular/core';
import {endOfDay, startOfDay} from 'date-fns';
import {ApisService} from '../../services/apis.service';
import {Pipe, PipeTransform} from '@angular/core';
import {ReversePipe} from 'ngx-pipes';
import {Router} from '@angular/router';
import {SeoService} from "../../services/seo.service";

@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss'],
    providers: [ReversePipe]
})

export class ActivitiesComponent implements OnInit {
    allEvents: any;
    allHappening: any;
    allUpcoming: any;
    allExpired: any;
    allEventsOBj: any = [];
    eventStr: any;
    pageExpired = 1;
    pageUpcoming = 1;
    pageHappening = 1;
    pageSizeExpired = 10;
    pageSizeUpcoming = 10;
    pageSizeHappening = 10;
    collectionSizeHappening: number;
    collectionSizeUpcoming: number;
    collectionSizeExpired: number;
    lang:string=localStorage.getItem('lang')
    term = '';

    constructor(private apis: ApisService, private reversePipe: ReversePipe, private router: Router, private seoService: SeoService) {
        this.seoUpdate();
    }

    ngOnInit(): void {
        this.getAllEvents();
        this.getHappening(1);
        this.getUpcoming(1);
        this.getExpired(1);

    }

    getAllEvents(): void {
        this.apis.getEventsApi().subscribe((data: any) => {
            console.log(data, "ev")
            this.allEvents = data.data;

        }, (err: any) => {
        });
    }

    getHappening(pageNumber: any): void {
        this.apis.getHappeningApi(pageNumber).subscribe((data: any) => {
            this.allHappening = data.data.activities;
            this.collectionSizeHappening = data.data.paginator.number_of_pages;
            this.pageSizeHappening = data.data.paginator.number_of_items_per_page;
            this.pageHappening = +pageNumber;

        }, (err: any) => {
        });
        if (typeof document !== 'undefined') {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    getUpcoming(pageNumber: any): void {
        this.apis.getUpcomingApi(pageNumber).subscribe((data: any) => {
            this.allUpcoming = data.data.activities;
            console.log(this.allUpcoming);
            this.collectionSizeUpcoming = data.data.paginator.number_of_pages;
            this.pageSizeUpcoming = data.data.paginator.number_of_items_per_page;
            this.pageUpcoming = +pageNumber;
        }, (err: any) => {
        });
        if (typeof document !== 'undefined') {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    getExpired(pageNumber: any): void {
        if (typeof document !== 'undefined') {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
        this.apis.getExpiredApi(pageNumber).subscribe((data: any) => {
            this.allExpired = data.data.activities;
            this.collectionSizeExpired = data.data.paginator.number_of_pages;
            this.pageSizeExpired = data.data.paginator.number_of_items_per_page;
            this.pageExpired = +pageNumber;
            console.log(this.allExpired)
        }, (err: any) => {
        });
    }

    eventDetails(e): any {
        const obj: any = {};
        obj.description = this.allEvents[e][0].details;
        obj.logo = this.allEvents[e][0].logo;
        obj.start = startOfDay(new Date(this.allEvents[e][0].start_date));
        obj.end = endOfDay(new Date(this.allEvents[e][0].end_date));
        obj.title = this.allEvents[e][0].name;
        // obj.eventVideo = this.allEvents[e][0].event_video;
        this.allEventsOBj.push(obj);
        this.eventStr = JSON.stringify(this.allEventsOBj);
        console.log(this.eventStr);
        this.router.navigate(['/eventDetails'], {queryParams: {events: this.eventStr}});
    }

    timeFormat(time: any) {
        let ts = time;
        const H = +ts.substr(0, 2);
        let h: any = (H % 12) || 12;
        h = (h < 10) ? ('0' + h) : h;  // leading 0 at the left for 1 digit hours
        const ampm = H < 12 ? ' AM' : ' PM';
        ts = h + ts.substr(2, 3) + ampm;
        return ts;
    }

    EventDetails(slug): void {
        this.router.navigate(['/eventDetails/' + slug],{ queryParams: {lang:this.lang}});
    }

    seoUpdate() {
        this.seoService.updateTitle(' New Egypt International Group | Activities');
        this.seoService.updateDescription('New Egypt International Group provides many activities to its community such as education, empowerment of women and supporting villages!');
        this.seoService.updateImage('https://newegyptgroup.com/assets/images/menu-photos/calender.png');
        this.seoService.updateUrl();
    }
}
