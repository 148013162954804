<!--<div class="bg-white pt-5">-->
<!--  <div class="container">-->
<!--    <div class="projects">-->
<!--      <h1 class="  header"></h1>-->
<!--      <owl-carousel-o [options]="carouselOptions2">-->
<!--        <ng-container *ngFor="let member of members">-->
<!--          <ng-template carouselSlide>-->
<!--            <div class="post" tabindex="0">-->
<!--              <div class="photo"-->
<!--                   [ngStyle]="{ 'background-image': 'url(' + member['image'] + ')'}">-->
<!--                <div class="overlay text-left">-->
<!--&lt;!&ndash;                  <a class="label">{{member['fullname_en']}}</a>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="description">-->
<!--                <h3 class=" mb-3">{{member['fullname_en']}}</h3>-->
<!--                <h6 class="">Member</h6>-->
<!--&lt;!&ndash;                                <p class="post_description d-block">Lorem ipsum dolor sit amet, consectetur adipisicing elit et&ndash;&gt;-->
<!--&lt;!&ndash;                                  cupiditate deleniti.</p>&ndash;&gt;-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="mb-3">&ndash;&gt;-->
<!--&lt;!&ndash;                <button class="btn btn-outline-success mr-2" routerLink="/memberDetails">More Info <i&ndash;&gt;-->
<!--&lt;!&ndash;                  class="fa fa-arrow-right ml-1"></i></button>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <footer class="footer-card">&ndash;&gt;-->
<!--&lt;!&ndash;                <p>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a href=""><i class="fa fa-facebook"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a href=""><i class="fa fa-linkedin"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a href=""><i class="fa fa-twitter"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a href=""><i class="fa fa-instagram"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                </p>&ndash;&gt;-->
<!--&lt;!&ndash;              </footer>&ndash;&gt;-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </ng-container>-->
<!--      </owl-carousel-o>-->
<!--      <div class="row">-->
<!--        <ng-container *ngFor="let member of members; let i = index" class="col-6">-->
<!--          <div class="col-6" *ngIf="i== 1 || i== 2" (mouseover)="flag = !flag" >-->
<!--            <div class="post" tabindex="0" >-->
<!--              <div class="photo"-->
<!--                   [ngStyle]="{ 'background-image': 'url(' + member['image'] + ')'}">-->
<!--                <div class="overlay text-left">-->
<!--&lt;!&ndash;                  <a class="label">{{member['fullname_en']}}</a>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="description">-->
<!--                <h3 class=" mb-3">{{member['fullname_en']}}</h3>-->
<!--                <div class="top-left" *ngIf="flag">-->
<!--                  <h3 class=" mb-3">{{member['fullname_en']}}</h3>-->
<!--                  <footer class="">- -->
<!--                                    <p>-->
<!--                                      <a href=""><i class="fa fa-facebook"></i></a>-->
<!--                                      <a href=""><i class="fa fa-linkedin"></i></a>-->
<!--                                      <a href=""><i class="fa fa-twitter"></i></a>-->
<!--                                      <a href=""><i class="fa fa-instagram"></i></a>-->
<!--                                    </p>-->
<!--                                  </footer>-->

<!--                </div>-->
<!--                <h6 class="">Co-Founder</h6>-->
<!--                &lt;!&ndash;                <p class="post_description d-block">Lorem ipsum dolor sit amet, consectetur adipisicing elit et&ndash;&gt;-->
<!--                &lt;!&ndash;                  cupiditate deleniti.</p>&ndash;&gt;-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="mb-3">&ndash;&gt;-->
<!--&lt;!&ndash;                <button class="btn btn-outline-success mr-2" routerLink="/memberDetails">More Info <i&ndash;&gt;-->
<!--&lt;!&ndash;                  class="fa fa-arrow-right ml-1"></i></button>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <footer class="footer-card">&ndash;&gt;-->
<!--&lt;!&ndash;                <p>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a href=""><i class="fa fa-facebook"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a href=""><i class="fa fa-linkedin"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a href=""><i class="fa fa-twitter"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a href=""><i class="fa fa-instagram"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                </p>&ndash;&gt;-->
<!--&lt;!&ndash;              </footer>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-container>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->



<div class="container">
  <h2 translate>home.members</h2>
<!--<div class="text-center">-->
<!--  <mat-form-field appearance="fill"  class="w-50 m-auto text-center">-->
<!--    <mat-select [formControl]="animalControl" required  placeholder="{{ 'home.board-members' | translate }}" ng-model="myModel">-->
<!--      <mat-option *ngFor="let item of getBoardClubsMember" (click)="getBoardClubs(item.id)" [value]="item.name">-->
<!--        {{item.name}}</mat-option>-->
<!--    </mat-select>-->
<!--    <mat-error *ngIf="animalControl.hasError('required')" translate>home.board-members</mat-error>-->
<!--  </mat-form-field>-->
<!--</div>-->
  <div class="wrapper row" style="margin-left: 0px" *ngIf="members.members.length > 0">
  <ng-container *ngFor="let member of members?.members ; let i = index">
  <div class=" col-xxl-5 col-lg-5 col-md-5 col-sm-12" (click)="memberDetails(member.id)">
    <div class="card ">
     <img [ngStyle]="{ 'background-image': member?.image ? 'url(' + member?.image + ')':'url(assets/images/avatar/pngwing.1.png)'}"/>
    <div class="info">
      <h1 class=" fw-bold">{{member.fullname}}</h1>
      <h5 class="" *ngIf="member.job_name.length > 0">{{member.job_name[0].job.name}}</h5>
      <p *ngIf="member.bio"><span>{{member.bio|slice:0:220}}</span><span >&nbsp;...</span></p>
            <ul class="social-icons">
              <li class="facebook" *ngIf="member.facebook">
                <a href="{{member.facebook}}"><i class="bi bi-facebook"></i></a>
            </li>
              <li class="twitter" *ngIf="member.twitter">
                <a href="{{member.twitter}}"><i class="bi bi-twitter"></i></a>
              </li>
              <li class="linkedin" *ngIf="member.linkedin">
                <a href="{{member.linkedin}}"><i class="bi bi-linkedin"></i></a>
              </li>
              <li class="instagram" *ngIf="member.instagram">
                <a href="{{member.instagram}}"><i class="bi bi-instagram"></i></a>
              </li>
              <li><button class="btn btn-outline-success mr-2" (click)="memberDetails(member.id)" translate>home.more-info
                <!-- <i class="fa fa-arrow-left ml-1"></i> -->
              </button>
            </li>
            </ul>

            </div>
            </div>
          </div>
 </ng-container>
</div>
  <div class="w-100 notfound" *ngIf="members.members.length == 0">
    <div class="face">
      <div class="band">
        <div class="red"></div>
        <div class="white"></div>
        <div class="blue"></div>
      </div>
      <div class="eyes"></div>
      <div class="dimples"></div>
      <div class="mouth"></div>
    </div>

    <h1>Oops!</h1>
    <h3 style="margin: 0 auto ;max-width: max-content">Not Found Results!</h3>

  </div>
</div>
