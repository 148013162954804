<div class="container-fluid  mb-4rem">
  <h1 class="header" translate>home.our-message</h1>
  <div class="mission-section row pb-4">
    <div class="col-md-6 col-sm-12 p-4 mb-4">
      <div class="title ml-1 "><h1 class="font-weight-bolder" translate>home.hand</h1></div>
      <p class="article ml-1" translate>home.message</p>
      <!--        <div class="d-flex justify-content-center">-->
      <!--      <button class="projects-btn">معرفة المزيد</button>-->
      <!--        </div>-->
    </div>
<!--    <div class="">-->
<!--    <img-->
<!--      src="./assets/images/slide_show/1920x960.jpg"-->
<!--      width="300"-->
<!--      height="300"-->
<!--    />-->
<!--    </div>-->
    <div class="col-md-6 col-sm-12 mb-4">
      <img
        class="mt-5 img"
        src="./assets/images/slide_show/1920x960.jpg"
            />
<!--      <video class="home-video"-->
<!--             title="EGYPT" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"-->
<!--             (contextmenu)="prevent($event)">-->
<!--      </video>-->
    </div>
  </div>
</div>
