<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">
<h1 class="title"></h1>
<section id="offers" class="section-with-bg" >
  <div class="container-fluid" >
    <div class="row mx-16 ">
    <div class="col-4 col-md-4 col-lg-4 col-sm-4 my-3" *ngFor="let item of news ">
      <div class="post-item position-relative h-100" (click)="newsDetails(item.slug)">
        <div class="post-img position-relative overflow-hidden" >
          <img *ngIf="item.image" src="{{item.image}}" class="img-fluid" alt="{{item.title}}" style="height: 250px !important; ">
          <span class="post-date">{{item.date | date :'d  MMM  yyy'}}</span>
        </div>

        <div class="post-content d-flex flex-column">
          <div class="handelTitle">
            <h3 class="post-title">{{item.title.slice(0,40)}}</h3>
          </div>
          <p>
            {{item.excerpt.slice(0,90)}}...
          </p>
          <hr>
          <a routerLink="/news" class="readmore stretched-link"><span translate>home.details</span>
<!--            <i class="bi bi-arrow-right"></i>-->
          </a>

        </div>

      </div>
    </div>
    </div>
  </div>
</section>



<nav aria-label="..." class="d-flex justify-content-center my-5 py-5 paginator">
  <ul class="pagination">
    <li class="page-item " [style]="page == 1 ? 'pointer-events: none':''">
      <a class="page-link" href="javascript:void(0);" tabindex="-1"
         (click)="getNews(page-1)">Previous</a>
    </li>
    <li [class]="page == i+1 ? 'active': '' " class="page-item " *ngFor="let _ of [].constructor(collectionSize); let i = index ">
      <a class="page-link" href="javascript:void(0);" (click)="getNews(i+1)">{{i + 1}}
      </a>
    </li>
    <li class="page-item" [style]="page == collectionSize ? 'pointer-events: none':''">
      <a class="page-link" href="javascript:void(0);" (click)="getNews(page+1)">Next</a>
    </li>
  </ul>
</nav>
<!--<div class="projects">-->
<!--    <div class="project-cards">-->
<!--      <div class="container" *ngFor="let item of news | keyitemue">-->
<!--        <div class="row">-->
<!--          <div class="col-sm-4 col-md-4 col-lg-4 mt-4" *ngFor="let item of item.itemue" (click)="newsDetails(item.slug)">-->
<!--            <div class="project">-->
<!--              <img style="height: 250px; padding: 5px; border-radius: 10px" *ngIf="item.image" src="{{item.image}}">-->
<!--              <div class="project-details">-->
<!--                <p class="project-name">{{item.title.slice(0,90)}}.....</p>-->
<!--                <div _ngcontent-serverapp-c261=""-->
<!--                     style="background-color: #ddd4d4; height: 1px; width: 95%; ;margin:auto;"></div>-->
<!--                <p class="article p-3">{{item.article.substring(0, 150)}}......</p>-->
<!--                <div _ngcontent-serverapp-c261=""-->
<!--                     style="background-color: #ddd4d4; height: 1px; width: 95%; ;margin-bottom: 10px;"></div>-->
<!--                <div class="d-flex justify-content-between">-->
<!--                  <div class="duration"><img class="ml-1" src=""> News Date :-->
<!--                  </div>-->
<!--                  <div class="text-right">{{item.date | date :'M / d / yyy'}}</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--</div>-->
