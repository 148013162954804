import { Component, OnInit } from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {Router} from "@angular/router";
import {ApisService} from "../services/apis.service";

@Component({
  selector: 'app-recent-activities',
  templateUrl: './recent-activities.component.html',
  styleUrls: ['./recent-activities.component.scss']
})
export class RecentActivitiesComponent implements OnInit {
  allEvents: any;
  eventStr: any;
  // direction : boolean = false;
  carouselOptions2: OwlOptions = {
    // rtl: true,
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 1},
      992: {items: 3},
      1600: {items: 3}

    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };
  constructor(private router: Router, private apis: ApisService,) {
    // this.apis.currentLang$.subscribe((res:any)=>{
    //   if (res == "en"){
    //     this.direction = true
    //   }
    //   this.direction = false
    //   console.log(res,this.direction)
    // })
  }

  ngOnInit(): void {
    this.getAllEvents();
  }
  getAllEvents(): any {
    this.apis.getEventsApi().subscribe((data: any) => {
      this.allEvents = data.data;
      console.log('all activies =>',this.allEvents);
      this.eventStr = JSON.stringify(this.allEvents);
    }, err => {
      console.log(err);
    });
  }
  timeFormat(time: any) {
    let ts = time;
    const H = +ts.substr(0, 2);
    let h: any = (H % 12) || 12;
    h = (h < 10) ? ('0' + h) : h;  // leading 0 at the left for 1 digit hours
    const ampm = H < 12 ? ' AM' : ' PM';
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }

  eventDetails(id): void{
    this.router.navigate(['/eventDetails/' + id]);
  }

}
