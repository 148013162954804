@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
    aboutus: any;
    copied = false;

    constructor(private seoService: SeoService,
                private apis: ApisService,
                private clipboard: Clipboard,
                private router: Router,
    ) {
    this.seoUpdate();
    }

    ngOnInit(): void {
        this.getAboutUs();


    }

    getAboutUs(): void {
        this.apis.aboutUsApi().subscribe((data: any) => {
            this.aboutus = data.data;
            console.log(this.aboutus);
        });
    }

    seoUpdate(): any {
        this.seoService.updateConstMetaTags();
        this.seoService.updateTitle('New Egypt Group | Non-profit Organization');
        this.seoService.updateDescription('New Egypt Group is a non-profit organization, helping reconstruct villages in rural areas around the world. It aims to aid and reconstruct villages around the world in rural poverty areas. Through donations of kind hearted people, we aim to improve education systems, water supplies, increase employment and food distribution, and much more!');
        this.seoService.updateUrl();
        this.seoService.updateImage( 'https://newegyptgroup.com/assets/images/menu-photos/Who-are-we_1.png');
    }

    /* seoUpdate(): any {
       this.seoService.updateTitle('New Egypt Group | building bridges between Egyptians in America and their Home Country');
       this.seoService.updateDescription('The New Egypt Group is a non-profit organization that builds bridges between Egyptians in America and their home country of Egypt. They undertake many activities, including education & development projects for women to empower them into the future they want it be bright with opportunity both inside');
       this.seoService.updateUrl();
       this.seoService.updateImage( 'https://newegyptgroup.com/assets/images/about-us/about-us.jpg');
     }*/

    cop() {
        this.clipboard.copy('https://newegyptgroup.com' + this.router.url)
        this.copied = !this.copied;
        setTimeout(() => {
            this.copied = false;
        }, 1500)

    }

    fbc(e) {
        let url = "www.google.com";
        e.preventDefault();
        var facebookWindow = window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" +
            "newegyptgroup.com" +
            this.router.url,
            "facebook-popup",
            "height=700px,width=555px"
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }

        return false;
    }

    tw() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://twitter.com/intent/tweet?url=' + 'newegyptgroup.com' + this.router.url,
            '-popUp',
            'width= 555px, height= 450px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' + 'newegyptgroup.com' + this.router.url,
            '-popUp',
            'width= 555px, height= 603px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }
}
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {SeoService} from '../../services/seo.service';
import {AppComponent} from '../../app.component';
import {ApisService} from '../../services/apis.service';
import {Clipboard} from "@angular/cdk/clipboard";

import {Router} from "@angular/router";
