<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">
<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="mt-5 report-form">
        <div class="mt-5 "translate>home.case-report-form</div>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-8 mt-4">
      <div class="picsum-img-wrapper">
        <img src="./assets/images/case-form/fill-up-a-form.jpg"
             alt="Random second slide"
             width="100%"
             height="100%"
             style="border-radius: 0;"
        />
      </div>
      <div class="carousel-caption"></div>
    </div>
        <div class="col-4 mt-5">
    <app-share-social  [router]="'/case-form?lang='+langLocal"></app-share-social>
    <!--      <section>-->
<!--        <div class="col-lg-1 order-lg-first mt-4">-->
<!--          <div class="sticky-content">-->
<!--            <div class="social-icons social-icons-colored social-icons-vertical">-->
<!--              <div class="icon-share">-->
<!--                <span class="social-label" translate>home.share</span>-->
<!--                <a class="social-icon image-item" (click)="cop()"-->
<!--                   target="_blank">-->
<!--                  <img *ngIf="!copied" title="copy" style="width: 22px"-->
<!--                       alt="copy"-->
<!--                       src="./assets/newIcon/copy-2.png"/>-->
<!--                  <img *ngIf="copied" title="copied" style="width: 28px"-->
<!--                       alt="copied"-->

<!--                       src="./assets/newIcon/copied.png"/>-->
<!--                </a>-->
<!--                <a (click)="fbc($event)" class="social-icon social-facebook"-->
<!--                   title="Facebook"><i-->
<!--                  class="icon-facebook-f"></i></a>-->
<!--                <a (click)="tw()" class="social-icon social-twitter"-->
<!--                   title="Twitter"><i-->
<!--                  class="icon-twitter"></i></a>-->
<!--                <a (click)="in()" class="social-icon social-linkedin"-->
<!--                   title="Linkedin"><i-->
<!--                  class="icon-linkedin"></i></a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </section>-->
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="copy">
        <div>
          <p translate>home.case-form</p>
<!--          <p translate>home.your-generous</p>-->
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-5 submitBtn justify-content-center">
      <button mat-button class="btn btn-success submitBtn"  (click)="openDialog()"><span translate>home.next</span></button>
    </div>
  </div>
<!--  <div class="">-->
<!--      <mat-horizontal-stepper class="justify-content-center" #stepper>-->
<!--        <mat-step [stepControl]="firstFormGroup">-->
<!--          <form [formGroup]="formDocument">-->
<!--            <ng-template matStepLabel>-->
<!--              <h1 translate="">home.firstly</h1>-->
<!--            </ng-template>-->
<!--            <div class="row">-->
<!--              <div class="col-6 ">-->
<!--                <mat-form-field appearance="fill" class="pt-3 title">-->
<!--                  <mat-label class="title" translate>home.full-name</mat-label>-->
<!--                  <input matInput placeholder="{{ 'home.enter-your-name' | translate }}" ng-model="myModel" formControlName="fullname" required>-->
<!--                </mat-form-field>-->

<!--              </div>-->
<!--              <div class="col-6 ">-->
<!--                <mat-form-field appearance="fill" class="pt-3 title">-->
<!--                  <mat-label class="title" translate>home.Current-address</mat-label>-->
<!--                  <input matInput placeholder="{{ 'home.Ex.Main' | translate }}" ng-model="myModel" formControlName="address" required>-->
<!--                </mat-form-field>-->

<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <mat-form-field appearance="fill" class="pt-3 title">-->
<!--                  <mat-label class="title" translate>home.phone-number</mat-label>-->
<!--                  <input matInput placeholder="01000000000" formControlName="phone" required>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--              <div class="col-6">-->
<!--                <mat-form-field appearance="fill" class="pt-3 title">-->
<!--                  <mat-label class="title" translate>home.age</mat-label>-->
<!--                  <input matInput placeholder="{{ 'home.enter-age' | translate }}" ng-model="myModel" formControlName="age" required>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <mat-form-field appearance="fill" class="pt-3 title">-->
<!--                  <mat-label class="title" translate>home.National-ID</mat-label>-->
<!--                  <input matInput placeholder="15421254875412" formControlName="national_id" required>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-12 submitBtn justify-content-center">-->
<!--                <button mat-button matStepperNext class="btn btn-success submitBtn"><span translate>home.next</span></button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </form>-->
<!--        </mat-step>-->
<!--        <mat-step [stepControl]="secondFormGroup">-->
<!--          <form [formGroup]="formDocument">-->
<!--            <ng-template matStepLabel>-->
<!--              <h1 translate>home.secondly</h1>-->
<!--            </ng-template>-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <mat-form-field appearance="fill" class="pt-3 title">-->
<!--                  <mat-label class="star title" translate>home.cas</mat-label>-->
<!--                  <input matInput placeholder="{{ 'home.pick-case' | translate }}" ng-model="myModel" [matAutocomplete]="casepurpose" type="text"-->
<!--                  >-->
<!--                  <mat-autocomplete autoActiveFirstOption #casepurpose="matAutocomplete">-->
<!--                    <mat-option (click)="getpurpos(purpose.id)" *ngFor="let purpose of casePurpose" [value]="purpose.name">-->
<!--                      {{purpose.name}}-->
<!--                    </mat-option>-->
<!--                  </mat-autocomplete>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--              <div class="col-6">-->
<!--                <mat-form-field appearance="fill" class="pt-3 title">-->
<!--                  <mat-label class="star title" translate>home.goverments</mat-label>-->
<!--                  <input style="cursor: pointer" matInput placeholder="{{ 'home.pick-goverments' | translate }}" ng-model="myModel" [matAutocomplete]="goverments" type="text"-->
<!--                         formControlName="goverment_id"-->
<!--                  />-->
<!--                  <mat-autocomplete autoActiveFirstOption #goverments="matAutocomplete"-->
<!--                                    (optionSelected)='getCities($event)'>-->
<!--                    <mat-option *ngFor="let govermnet of filteredGovermnets | async"  [value]="govermnet.name_en">-->
<!--                      {{govermnet.name_en}}-->
<!--                    </mat-option>-->
<!--                  </mat-autocomplete>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-6">-->
<!--                <mat-form-field  appearance="fill" class="pt-3 title">-->
<!--                  <mat-label class="star title" translate>home.city</mat-label>-->
<!--                  <input style="cursor: pointer" matInput placeholder="{{ 'home.pick-city' | translate }}" ng-model="myModel" [matAutocomplete]="city" type="text"-->
<!--                         formControlName="city_id" />-->
<!--                  <mat-autocomplete autoActiveFirstOption #city="matAutocomplete"-->
<!--                                    (optionSelected)='getVillages($event)'>-->
<!--                    <mat-option *ngFor="let city of filteredCities | async" [value]="city.name">-->
<!--                      {{city.name}}-->
<!--                    </mat-option>-->
<!--                  </mat-autocomplete>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--              <div class="col-6">-->
<!--                <mat-form-field appearance="fill" class="pt-3 title">-->
<!--                  <mat-label class="star title" translate>home.village</mat-label>-->
<!--                  <input style="cursor: pointer" matInput placeholder="{{ 'home.pick-village' | translate }}" ng-model="myModel" [matAutocomplete]="village" type="text"-->
<!--                         formControlName="village_id" />-->
<!--                  <mat-autocomplete autoActiveFirstOption #village="matAutocomplete"-->
<!--                                    (optionSelected)='selectedVillage($event)'>-->
<!--                    <mat-option *ngFor="let village of filteredVillages | async" [value]="village.name">-->
<!--                      {{village.name}}-->
<!--                    </mat-option>-->
<!--                  </mat-autocomplete>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--            </div>-->
<!--              <div class="row">-->
<!--                <div class="col-6 p-0 submitBtn">-->
<!--                  <button mat-button matStepperPrevious class="btn btn-primary submitBtn"><span translate>home.back</span></button>-->
<!--                </div>-->
<!--                <div class="col-6 p-0 submitBtn">-->
<!--                  <button mat-button matStepperNext class="btn btn-success submitBtn"><span translate>home.next</span></button>-->
<!--                </div>-->
<!--              </div>-->
<!--          </form>-->
<!--        </mat-step>-->
<!--        <mat-step [stepControl]="thirdFormGroup">-->
<!--          <form [formGroup]="formDocument">-->
<!--            <ng-template matStepLabel><h1 translate>home.third</h1> </ng-template>-->
<!--              <div class="row">-->
<!--                <div class="col-6 item d-flex border-dashed mt-3">-->
<!--                  <button translate>home.profile-picture-->
<!--                    <i class="fas fa-image"></i>-->
<!--                  </button>-->
<!--                  <input id="pic" type="file" #selectFile formControlName="case_image"-->
<!--                         (change)="onFileSelected($event.target.files)"  accept="image/*">-->
<!--                  <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah"></div>-->
<!--                </div>-->
<!--                <div class="col-6 item d-flex border-dashed mt-3">-->
<!--                  <button translate>home.pictures-support-your-case-->
<!--                    <i class="fas fa-image"></i>-->
<!--                  </button>-->
<!--                  <input type="file" #selectFile1 formControlName="image" multiple-->
<!--                         (change)="onFileSelected1($event)"  accept="image/*">-->
<!--                  <div class="image-cheque" (click)="selectFile1.click()">-->
<!--                    <img class="mx-2" *ngFor="let imgs of images1" src="{{imgs}}" style="height: 70px">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-6 mt-5 p-0 submitBtn">-->
<!--                  <button mat-button matStepperPrevious class="btn btn-primary submitBtn"><span translate>home.back</span></button>-->
<!--                </div>-->
<!--                <div class="col-6 mt-5 p-0 submitBtn">-->
<!--                  <button mat-button class="btn btn-success submitBtn" [disabled]="formDocument.status == 'INVALID'" (click)="add()">-->
<!--                    <span translate>home.submit</span>-->
<!--                  </button>-->
<!--                </div>-->
<!--              </div>-->
<!--          </form>-->
<!--        </mat-step>-->
<!--      </mat-horizontal-stepper>-->
<!--  </div>-->
</div>
