import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ramadan2022',
  templateUrl: './ramadan2022.component.html',
  styleUrls: ['./ramadan2022.component.scss']
})
export class Ramadan2022Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
