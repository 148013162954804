import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ApisService} from "../services/apis.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogData} from "../pages/booking/booking.component";

@Component({
  selector: 'app-print-donation-details',
  templateUrl: './print-donation-details.component.html',
  styleUrls: ['./print-donation-details.component.scss']
})
export class PrintDonationDetailsComponent implements OnInit {
  myDate = new Date();
  mydonations: any;
  donationId!:any;
  expensesId: any;
  expensesGeneralId: any;
  activitiesincomes: any;
  generalpurpose: any;
  activitiesfromgeneral: any;
  expenses = [];


  constructor(private apis: ApisService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }

  ngOnInit(): void {
    this.donationId = this.data['donationId']
    this.mydonations = this.data['donationdetails'];
    console.log(this.mydonations)
    this.getActivitiesIncomesApi(this.donationId);
    this.getGeneralPurposeApi(this.donationId);
    this.getActivitiesFromGeneralApi(this.donationId);
  }
  getActivitiesIncomesApi(id): any {
    console.log(id, "id")
    this.apis.getActivitiesIncomes(id).subscribe((data: any) => {
        this.activitiesincomes = data.data;
        console.log(this.activitiesincomes);
        this.expensesId  = this.activitiesincomes[0]?.activity.id;
        console.log(this.expensesId)
        this.getExpensesApi(this.expensesId)
      },
      err => {
        console.log(err);
      });
  }
  getGeneralPurposeApi(id): any {
    console.log(id, "id")
    this.apis.getGeneralPurpose(id).subscribe((data: any) => {
        this.generalpurpose = data.data;
        console.log(this.generalpurpose);
        // setTimeout(() => {window.print()}, 4000);
      },
      err => {
        console.log(err);
      });
  }
  getActivitiesFromGeneralApi(id): any {
    console.log(id, "id")
    this.apis.getActivitiesFromGeneral(id).subscribe((data: any) => {
        this.activitiesfromgeneral = data.data;
        console.log(this.activitiesfromgeneral);
        this.expensesGeneralId = this.activitiesfromgeneral[0]?.activity.id;
        console.log(this.expensesGeneralId)
      this.getExpensesApi(this.expensesGeneralId)
      },
      err => {
        console.log(err);
      });
  }
  getExpensesApi(id): any {
    console.log(id, "id")
    this.apis.getExpenses(id).subscribe((data: any) => {
        this.expenses = data.data;
        console.log(this.expenses);
      },
      err => {
        console.log(err);
      });
  }
}
