import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-orphans-profile',
  templateUrl: './orphans-profile.component.html',
  styleUrls: ['./orphans-profile.component.scss']
})
export class OrphansProfileComponent implements OnInit {
  names = [
    {
      name: 'mahmoud',
      desc: 'By default, JavaScript will use the browser\'s time zone and display a date as a full text string:',
      date_of_birth: new Date('2017-01-03'),
    },
    {
      name: 'Faten',
      desc: 'By default, JavaScript will use the browser\'s time zone and display a date as a full text string:',
      date_of_birth: new Date('2017-01-03'),
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
    for (let i = 0; i < this.names.length; i++) {

    }
  }

}
