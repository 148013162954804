import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {ApisService} from "../../services/apis.service";
import {Logger} from "protractor/built/logger";
import {TranslateService} from "@ngx-translate/core";
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import { AuthGuard } from 'src/app/shared/auth.guard';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class HeaderComponent implements OnInit {
  user:any;
  token: any;
  currentLang:string;
  show: boolean = false;
  hidden: boolean=true;
  index:number=0
  dataCategories:any[]=[];
  isLogin:boolean ;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private apis: ApisService,
  public translate: TranslateService,
  private _AuthService:AuthService,
  private router: Router,
  private localStorage:LocalStorageService

   ) {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('access_token');
      this.currentLang = localStorage.getItem('lang') || 'en';
      this.getLang(this.currentLang);
      setTimeout(()=>{
        this.apis.Lang$.subscribe((lang) => {
          this.translate.getTranslation(lang).subscribe((res) => {
            this.dataCategories = res.home.dataCategories
            console.log(this.dataCategories);
            
          })
        })
        this._AuthService.isUser.subscribe((login)=>{
          this.isLogin = login
          console.log('is login ==>', login);
        })
      })
    }
    // lang:string=localStorage.getItem('lang')
  }

  ngOnInit(): void {
    // console.log(this.user)
    this._AuthService.isUser.subscribe((res:any)=>{
      this.user =  JSON.parse(this.localStorage.getItem('currentUser'))
      console.log(res , this.user);
    })
  }
  getLang(lang:string){
    this.apis.getLanguage(lang)
  }
  // logOut(): void {
  //     window.localStorage.removeItem('currentUser');
  //     window.location.reload();
  // }
  logOut(): void {
    this._AuthService.logOutApi().subscribe((data: any) => {
      console.log(data);
      // if (isPlatformBrowser(this.platformId)) {
        localStorage.clear();
        // location.reload();
        this._AuthService.isLogin.next(false)
        this.router.navigateByUrl('/sign-in');
      // }
      // window.location.reload();

    }, (err: any) => {
    });
  }
  refresh(): void {
    window.location.reload();
  }
  routeToBoards(){
    this.router.navigate(['/boards/'],{queryParams:{lang:this.currentLang}})
    console.log('lang',this.currentLang);

  }
  routeToAboutUs(){
    this.router.navigate(['/about'],{queryParams:{lang:this.currentLang}})
  }
}
