<div class="container mb-4rem">
  <h1 class="header"> رسالتنا</h1>
  <div class="mission-section row">
    <div class="col-md-6 col-sm-12 p-4">
      <div class="title">رسالتنا</div>
      <p class="article">تهدف إلى التخفيف عن كاهل المواطنين بالمجتمعات الأكثر إحتياجاً في الريف والمناطق العشوائية في الحضر. وتعتمد المبادرة على تنفيذ مجموعة من الأنشطة الخدمية والتنموية التى من شأنها ضمان “حياة كريمة” لتلك الفئة وتحسين ظروف معيشتهم.</p>
      <!--        <div class="d-flex justify-content-center">-->
      <!--      <button class="projects-btn">معرفة المزيد</button>-->
      <!--        </div>-->
    </div>
    <div class="col-md-6 col-sm-12 height-div">
      <video class="home-video"
             title="EGYPT"
             autoplay="" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
             (contextmenu)="prevent($event)"
             oncanplay="this.play()"
             src="{{videoUrl3}}">
      </video>
    </div>
  </div>
</div>
