import {Component, Injectable, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ApisService} from '../../../services/apis.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Injectable()
@Component({
  selector: 'app-home-projects-section',
  templateUrl: './home-projects-section.component.html',
  styleUrls: ['./home-projects-section.component.scss']
})
export class HomeProjectsSectionComponent implements OnInit {
  projects: any;
  carouselOptions: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 3,
    nav: true,
    loop: true,
    autoplay: true,
    dots: false,
    autoplayHoverPause: true
  };
  videos: any;

  constructor(
    private apis: ApisService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  this.getProjects();
  }
  transform(n: string): string {
    var date = new Date(n);
    var months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    var days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }
  projectDetails(id): void{
    this.router.navigate(['/project-details/' + id]);
  }
  getProjects(): void {
    this.apis.projectsApi().subscribe((data: any) => {
      this.projects = data.data;
    }, (err: any) => {
    });
    }
}
