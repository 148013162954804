<div class="container">
  <div class="row">

    <div class="col-12">
      <mat-dialog-actions align="end">
        <button class="close"  mat-button mat-dialog-close>X</button>
      </mat-dialog-actions>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="tpath-team-grid-wrapper p-0 team-type-grid">
       <div id="tpath-team-grid1" class="row tpath-grid-inner team-columns-4">
        <div class="team-item">
          <div class="team-item-wrapper">
            <div class="team-image-wrapper">
              <div class="team-item-img">
                <img src="{{Cases.case_image}}" width="450" height="400" alt="{{Cases.fullname}}" class="img-responsive">
              </div>
            </div>
<!--              <div class="team-content-wrapper">-->
<!--                <h5 class="team-member-name">-->
<!--                  <a href="" title="Jonathan Doe">Jonathan Doe</a>-->
<!--                </h5>-->
<!--                <div class="team-member_phone">-->
<!--                  <i class="bi bi-phone-fill"></i>-->
<!--                   <span>Mobile</span> :+49 123 456 789</div>-->
<!--                <div class="team-member_email">-->
<!--                   <i class="bi bi-envelope-fill"></i>-->

<!--                  <span>Email</span> :-->
<!--                  <a href="">johndoe@email.com</a>-->
<!--                 </div>-->
<!--                    <div class="member-social">-->
<!--                       <ul class="tpath-member-social-icons list-inline">-->
<!--                          <li class="facebook">-->
<!--                             <a target="_blank" href="">-->
<!--                               &lt;!&ndash;                      <i class="fa fa-facebook"></i>&ndash;&gt;-->
<!--                             <img src="assets/icons/287739_facebook_icon.svg">-->
<!--                              </a>-->
<!--                          </li>-->
<!--                          <li class="twitter">-->
<!--                           <a target="_blank" href="">-->
<!--                                                          &lt;!&ndash;                      <i class="fa fa-twitter"></i>&ndash;&gt;-->
<!--                           <img src="assets/icons/294709_circle_twitter_icon.svg">-->
<!--                           </a>-->
<!--                          </li>-->
<!--                            <li class="instagram">-->
<!--                            <a target="_blank" href="">-->
<!--                            <img src="assets/icons/3225191_app_instagram_logo_media_popular_icon.svg">-->
<!--                            </a>-->
<!--                           </li>-->
<!--                            <li class="linkedin">-->
<!--                            <a target="_blank" href="">-->
<!--                                                          &lt;!&ndash;                    <i class="fa fa-google-plus"></i>&ndash;&gt;-->
<!--                            <img src="assets/icons/317750_linkedin_icon.svg">-->
<!--                             </a>-->
<!--                            </li>-->
<!--                         </ul>-->
<!--                    </div>-->
<!--                </div>-->
               </div>
       </div>
      </div>
    </div>
    </div>
    <div class="col-6 mt-3">
      <table class="donate table table-hover table-bordered mt-3">
        <thead >
        </thead>
        <tbody>
        <tr  class="details">
          <th scope="row">Full Name</th>
          <td>{{Cases.fullname}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">Purpose Name</th>
          <td>{{Cases.purpose.name}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">Email</th>
          <td>{{Cases.email}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">Phone</th>
          <td>{{Cases.phone}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">Address</th>
          <td>{{Cases.address}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">Age</th>
          <td>{{Cases.age}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">National ID</th>
          <td>{{Cases.national_id}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">Club</th>
          <td>{{Cases.club.name}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">Goverment</th>
          <td>{{Cases.goverment.name}}</td>
        </tr>
        <tr class="details" >
          <th scope="row">City</th>
          <td>{{Cases.city}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">Village</th>
          <td>{{Cases.village}}</td>
        </tr>
        <tr  class="details">
          <th scope="row">Current Job</th>
          <td>{{Cases.current_job}}</td>
        </tr>
        <tr class="details">
          <th scope="row">Job Place</th>
          <td>{{Cases.job_place}}</td>
        </tr>
        <tr class="details">
          <th scope="row">Code</th>
          <td>{{Cases.code}}</td>
        </tr>
        </tbody>
      </table>
    </div>
</div>
</div>
