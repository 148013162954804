import {Component, OnInit} from '@angular/core';
import {Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';
import {ActivatedRoute} from '@angular/router';
import {ApisService} from '../../services/apis.service';
import {image} from 'html2canvas/dist/types/css/types/image';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  projectId: any;
  project: any;
  loading: boolean = true;
  videoUrl = 'https://player.vimeo.com/external/567641203.hd.mp4?s=c04076dd9a63e7d8dd2c7312cf1178db4066063b&profile_id=175';
  items: GalleryItem[];
  images: any = [];

  constructor(public gallery: Gallery,
              public lightbox: Lightbox,
              private activatedRoute: ActivatedRoute,
              private apis: ApisService,
              private seoService: SeoService
  ) {
  }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.params.id;
    this.getProject(this.projectId);
    this.videoHandler();
    this.seoUpdate();
  }

  basicLightboxExample(): void {
    this.gallery.ref().load(this.items);
  }

  /**
   * Use custom gallery config with the lightbox
   */
  withCustomGalleryConfig(): void {

    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }

  videoHandler(): void {
    if (window.screen.availWidth < 600) {
      this.videoUrl = 'https://player.vimeo.com/external/567151192.hd.mp4?s=db1921b9a7500a7a0cf1846bdaf69cec317782c5&profile_id=175';
    }
  }

  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateUrl();
  }

  getProject(id): void {
    this.apis.getProject(id).subscribe((data: any) => {
      this.project = data;
      this.seoService.updateTitle(this.project.name_ar);
      this.seoService.updateDescription(this.project.description_ar);
      for (let image of this.project.project_media.slice(0, 4)) {
        if (image.url_type == 1) {
          this.videoUrl = image.url;
          this.seoService.updateImage(AppComponent.hostUrl + this.videoUrl);
        } else if (image.url_type == 0) {
          this.images.push({
            srcUrl: image.url,
            previewUrl: image.url
          });
        }
      }
      this.items = this.images.map(item =>
        new ImageItem({src: item.srcUrl, thumb: item.previewUrl})
      );
      this.basicLightboxExample();
      this.withCustomGalleryConfig();
    }, err => {
    });
  }

  transform(n: string): string {
    var date = new Date(n);
    var months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    var days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }
}
