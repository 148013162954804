<section>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h3>THIS AREA NOT ALLOWED FOR YOU</h3>
        <h2>PLEASE WRITE YOUR EMAIL BELOW TO BE ABLE SEE IT</h2>
        <form>
        <input type="text" id="username" name="username" placeholder="Type in your username.." autocomplete="off" required />
          <button (click)="closeDialog()" routerLink="/add-member">SEND</button>
        </form>
      </div>
    </div>
  </div>
</section>
