import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApisService} from '../../services/apis.service';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  videoUrl = 'https://player.vimeo.com/external/567641203.hd.mp4?s=c04076dd9a63e7d8dd2c7312cf1178db4066063b&profile_id=175';
  news: any;
  page = 1;
  pageSize = 10;
  collectionSize = 25;
  constructor(private router: Router, private apis: ApisService, private seoService: SeoService) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.getNews(1);
  }
  getNews(pageNumber: any): void{
    if (typeof document !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth'});
    }
    this.apis.newsPaginatorApi( pageNumber).subscribe((data: any) => {
      this.news = data.data.news;
      console.log(this.news);
      this.collectionSize = data.data.paginator.number_of_pages;
      this.pageSize = data.data.paginator.number_of_items_per_page;
      this.page = +pageNumber;
    }, (err: any) => {
    });
  }
  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('New Egypt International Group News Page');
    this.seoService.updateDescription(' The official news page of the New Egypt International Group. Non-profit organization. United Nation news, General News and International Egyptian Women\'s Club');
    this.seoService.updateUrl();
    this.seoService.updateImage( 'https://newegyptgroup.com/assets/images/menu-photos/news.png');
  }
  transform(n: string): string {
    var date = new Date(n);
    var months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    var days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }
  newsDetails(id): void{
    this.router.navigate(['/news-details/' + id],{queryParams:{lang:localStorage.getItem('lang')}});
    console.log('language',localStorage.getItem('lang'));

  }
}
