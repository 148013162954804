<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">

<div id="home_video_section" class="home-video-section mt-5">
  <video class="img-fluid home-video"
         title="EGYPT"
         autoplay="" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
         oncanplay="this.play();"
         (contextmenu)="prevent($event)"
         src="{{videoUrl}}"
  >
  </video>
  <div class="text-center  btn-donate-sec">
    <div class="button3 position-relative">
      <img src="assets/images/haya/cursor.png">
      <a href="https://hayakarimausa.com/events" title="go to hayaKarima website">
      <button class="event-btn mb-2" >Watch Event Meeting Live
        OCT-2-2021 <br> 10:00 AM PST - 01:00 PM EST - 07:00 pm Cairo <br> Click Here To View The Website
      </button></a>
    </div>
    <div class="button1 position-relative ">
      <img src="assets/images/haya/cursor.png">
      <button class="donation" (click)="navigate()">Donate
      </button>
    </div>

  </div>
  <app-donation-part [amount]="donateBount"></app-donation-part>
  <div id="snackbar" class="">
    <div class="chat-message">
      <p>{{message}}</p>
    </div>
  </div>
</div>
<!--<app-news-slide *ngIf="donorsNames" [donorsNames]="donorsNames"></app-news-slide>-->

<div class="btn-pop container">
  <!--  <button >-->
  <!--    The President's Document to Support the Decent Life Initiative to Support the Egyptian Rural-->
  <!--    <img src="assets/images/haya/cursor.svg">-->
  <!--  </button>-->
  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <button>
            President sisi announces The Launching of The National Project, a Decent Life aiming to develop the Egyptian
            countryside
          </button>
        </mat-panel-title>

      </mat-expansion-panel-header>
      <app-president-document></app-president-document>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div class="container">
  <div class="col-12">
    <div class="donate-content-section">
      <mat-tab-group class="message-haya">
        <mat-tab label="EN">
          <p style="text-align: left" class="btn donate-content">
            Please support Haya Karima from the USA, under the patronage of Dr. Mostafa Madbouly, Prime
            Minister of Egypt. Haya Karima, also known as Decent Life, is an initiative launched and
            sponsored by His Excellency President Abdel Fattah Al-Sisi
            The main objective of this initiative is to improve
            <ng-container *ngIf="show2">
              the quality of life in the poorest rural
              communities within the framework of the Sustainable Development Strategy: Egypt Vision 2030.
              This is achieved by decreasing multidimensional poverty and unemployment rates. For more
              information on how you can support Haya Karima, please visit
              <a href="https://hayakarimausa.com">https://hayakarimausa.com/</a>.
              You can also donate directly from your cell phone by texting
              +18334861528 .All donations made by
              those living in the USA are 100% tax-deductible and 100% of the proceeds go directly to the
              project with no administrative costs.
            </ng-container>
            <ng-container *ngIf="show2">
              <span (click)="show2 = !show2" style="color:#f47321; cursor: pointer ">show less</span>
            </ng-container>
            <ng-container *ngIf="!show2">
              <span (click)="show2 = !show2" style="color:#f47321; cursor: pointer ">show more</span>
            </ng-container>
          </p>
          <article class="text-left important-article">All donations made by those living in usa are 100%
            tax deductible, and 100% of donations go directly to the project with no administrative costs.
          </article>
        </mat-tab>
        <mat-tab label="AR">
          <p class="btn donate-content">
            ادعم المشروع القومى حياة كريمة
            الان من داخل الولايات المتحدة الامريكية
            تحت رعاية الدكتور مصطفى مدبولى رئيس مجلس الوزراء
          </p>
          <article class="text-left important-article">All donations made by those living in usa are 100%
            tax deductible, and 100% of donations go directly to the project with no administrative costs.
          </article>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<div id="logos" class="logos text-center mb-7">
  <div class="container w-39 mt-4">
    <div class="d-flex justify-content-between align-items-center event  col-12">
      <img alt="" class="logo-hijra col-2" src="assets/images/haya/logo-medium.png" style="height: auto; width: 142px">
      <div class="col-8">
        <!--        <iframe src="https://player.vimeo.com/video/608309135?h=2384f636b8"-->
        <!--                height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>-->
        <iframe class="video-frame"
                src="https://www.youtube.com/embed/watch?v=9xLTyasrXAQ&list=PLGpvKWej0htF5hqgxC7WZqDJlDhooxVWL&index=1"
                allow="accelerometerß; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
      </div>
      <img alt="" class="logo-hijra col-2" src="assets/images/haya/logo-haya.png"
           style="height: auto; width: 142px;position: relative; top: 30px">
    </div>
    <div class="message-minister  col-12">
      <mat-tab-group>
        <mat-tab label="EN">
          <h4> Ambassador Nabila Makram, Minister of Immigration & Egyptian Expatriates Affairs massage </h4>
          <p style="text-align: left">
            Fellow Egyptians around the world,
            Your country remains proud of you. Over the past few years, I have been witnessing and following with a
            sense of pride the great accomplishments that Egyptians
            <ng-container *ngIf="show2">
              are capable of bringing to life every day , in every sector and in every country across the four corners
              of the globe. You are the best representatives
              to your homeland and the incarnation of the true spirit of Egypt
              Egypt has been making great strides on all levels. Security, stability, economic development and social
              cohesion in Egypt have become a success story that the whole world recognizes and appreciates. Egyptian
              citizens have endured many challenges and made significant sacrifices in the course of this journey. Now
              it is time we moved to recognize, honor and reward those sacrifices.
              “Haya Karima” or Decent Life is an initiative launched and sponsored by H.E President Abdel Fattah
              Al-Sisi, with the main objective of improving the quality of life in the poorest rural communities within
              the framework of the Sustainable Development Strategy: Egypt Vision 2030, through decreasing
              multidimensional poverty and unemployment rates.
              In January 2019, the first phase was launched targeting to cover 375 villages across Egypt. By launching
              the second phase, in January 2021, the number of targeted villages increased to 1500, with the number of
              beneficiaries representing 20% of the overall Egyptian population
              Your role as Egyptians abroad has been pivotal in every stage of this success story. And we need your
              support once again.
              Let me mention 2 of the projects of the initiative where your contribution can make a difference.
              1- Haya Karima digital trucks; a mobile classroom aims to bring digital skills to the underserved
              communities. They can get to where it is needed most, reaching communities that conventional classrooms
              cannot. Each truck is equipped with 15 laptops, interactive screens, and built-in Wi Fi. It aspires to
              close the digital gap, expand digital literacy and make digital skills available to everyone.
              2- Training and operation aims to provide employment through micro-projects for breadwinner women,
              entrepreneurs, youth and disabled. It will support blue and red-collar workers through set up of
              carpentry, blacksmithing workshops, providing poultry shops material, clothes sewing machines,
              agricultural vehicles and gadgets and wheel chairs for the disabled.
              You have the power to empower. We have a goal of raising $1 million US to make a big impact. There are 2
              ways to give. One is through the Facebook donation link https://www.facebook.com/Hayakarimausa or
              https://hayakarimausa.com/and the other is mailing in a check to (Maple Valley Rotary P. O. Box 1102
              Maple
              Valley. WA 98038). We are partnering with New Egypt Group a Global non profit organization and Rotary, a
              Global non profit organization known for their humanitarian work globally. All donations made to Rotary or
              New Egypt Group on the Facebook link or mailed by check are 100% tax deductible for those living in the
              US. There are no administrative costs, so 100% of the money goes directly to the project. You will receive
              a receipt for tax purposes.
              Your contribution, no matter how small, matters to those who are genuinely in need. We are confident that
              Egyptians, irrespective of their location, will continue to amaze the world and exhibit the
              distinctiveness of the Egyptian character, through solidarity and support for their fellow compatriots, to
              drive our success story to even great advancements
            </ng-container>
            <ng-container *ngIf="show2">
              <span (click)="show2 = !show2" style="color:#f47321; cursor: pointer ">show less</span>
            </ng-container>
            <ng-container *ngIf="!show2">
              <span (click)="show2 = !show2" style="color:#f47321; cursor: pointer ">show more</span>
            </ng-container>
          </p>
          <div class="text-right mb-3">
            <span class="font-weight-bold header"> , Ambassador Nabila Makram </span>
            <br>
            <br>
            <span class="content-minister">Minister of Immigration & Egyptian Expatriates Affairs massage</span>
          </div>
          <article class="text-left important-article">All donations made by those living in usa are 100%
            tax deductible, and 100% of donations go directly to the project with no administrative costs.
          </article>
        </mat-tab>
        <mat-tab label="AR">
          <h4>كلمة السفيرة نبيلة مكرم <br> وزيرة الدولة للهجرة وشئون المصريين بالخارج</h4>
          <p>أعزائي المصريون حول العالم
            ‏‎أن بلدكم تفخر بكم. فعلى مدى السنوات القليلة الماضية ، كنت أشاهد وأتابع بكل فخر الإنجازات العظيمة التي
            يستطيع المصريون تحقيقها كل يوم ، في كل قطاع في جميع أنحاء العالم ،فأنتم خير ممثلين لوطنكم كما إنكم التجسيد
            الحقيقي للروح المصرية .

            <ng-container *ngIf="show2">
              ‏‎لقد خطت مصر خطوات كبيرة على جميع المستويات،فقد أصبح الأمن والاستقرار والتنمية الاقتصادية والتماسك
              الاجتماعي في مصر قصة نجاح يقدرها كل العالم.
              لقد تحمل المواطنون المصريون العديد من التحديات وقدموا تضحيات كبيرة خلال هذه الرحلة. لقد حان الوقت الآن لأن
              نتحرك للاعتراف بهذه التضحيات وتكريمها ومكافأتها.

              ‏‎"حياة كريمة" هي مبادرة أطلقها ورعاها فخامة الرئيس عبد الفتاح السيسي ، بهدف رئيسي وهو تحسين نوعية الحياة
              في
              المجتمعات الريفية الأكثر إحتياجاً في إطار استراتيجية التنمية المستدامة "رؤية مصر 2030 "، من خلال خفض
              معدلات
              الفقر والبطالة متعددة الأبعاد.

              ‏‎في يناير 2019 ، تم إطلاق المرحلة الأولى لتغطي 375 قرية في جميع أنحاء مصر ، وفي يناير 2021 تم إطلاق
              المرحلة
              الثانية ليرتفع عدد القرى المستهدفة إلى 1500 قرية ، ويمثل عدد المستفيدين منها 20٪ من إجمالي سكان مصر.

              ‏‎و لقد كان دوركم كمصريين في الخارج دوراً محوريًا في كل مرحلة من مراحل قصة النجاح الوطن ونحن بحاجة إلى
              دعمكم
              مرة أخرى.

              ‏‎اسمحوا لي أن أذكر اثنين من مشاريع المبادرة حيث يمكن لمساهمتك أن تحدث فرقًا:

              ‏‎1- شاحنات حياة كريمة الرقمية : يهدف الفصل الدراسي المتنقل إلى توفير المهارات الرقمية للمجتمعات الأكثر
              إحتياجاً ،تلك المجتمعات التي لا نستطيع تفعيل الفصول الدراسية التقليدية بها، وعليه فقد تم تجهيز كل شاحنة بـ
              15 جهاز كمبيوتر محمول وشاشات تفاعلية وشبكة Wi-Fi مدمجة. ويتمثل هدف المشروع في سد الفجوة الرقمية وتوسيع
              المعرفة الرقمية وإتاحة المهارات الرقمية للجميع.

              ‏‎2- تدريب وتشغيل : و يهدف إلى توفير فرص عمل من خلال المشاريع الصغيرة للنساء المعيلات ورجال الأعمال
              والشباب
              ومتحدي الإعاقة ، وستدعم التبرعات العمال ذوي الياقات الزرقاء من خلال إقامة ورش النجارة وورش الحدادة وآلات
              خياطة الملابس والمركبات الزراعية وتربية الدواجن والأدوات والكراسي المتحركة لمتحدي الاعاقة .

              ‏‎هدفنا هو جمع مليون دولار أمريكي لإحداث تأثير كبير. هناك طريقتان للتبرع. أحدهما من خلال رابط التبرع على
              facebook.com/Hayakarimausa أو من خلال الموقع الاليكتروني www. hayakarimausa.com أو يتم إرسال شيك
              بالبريد إلى
              Maple Valley Rotary P. O. Box 1102 Maple Valley. WA 9803 نحن نتشارك مع منظمة New Egypt الدولية و منظمة
              Rotary الدولية وهي منظمة عالمية غير هادفة للربح ومعروفة بعملها الإنساني على مستوى العالم. جميع التبرعات
              المقدمة إلى Rotary و New Egyptعلى رابط Facebook أو المرسلة بالبريد عن طريق الشيكات معفاة من الضرائب بنسبة
              100٪ لأولئك الذين يعيشون في الولايات المتحدة. لا توجد تكاليف إدارية ، لذلك يذهب 100٪ من الأموال مباشرة إلى
              المشروع. سوف تتلقى إيصالًا لأغراض الضريبة.

              ‏‎مساهمتك ، مهما كانت صغيرة ، مهمة لمن هم في أمس الحاجة إليها. نحن على ثقة من أن المصريون في الخارج
              سيستمرون
              في إبهار العالم وإظهار تميز الشخصية المصرية ، من خلال التضامن والدعم لمواطنيهم ، لدفع قصة نجاحنا إلى
              إنجازات
              كبيرة
            </ng-container>
            <ng-container *ngIf="show2">
              <span (click)="show2 = !show2" style="color:#f47321; cursor: pointer ">رؤية اقل</span>
            </ng-container>
            <ng-container *ngIf="!show2">
              <span (click)="show2 = !show2" style="color:#f47321; cursor: pointer ">رؤية المزيد</span>
            </ng-container>
          </p>


          <div class="text-left" style="margin-bottom: 50px !important">
            <span class="font-weight-bold header">السفيرة نبيلة مكرم</span>
            <br>
            <br>
            <span class="content-minister">وزيرة الدولة للهجرة وشئون المصريين بالخارج</span>
          </div>
          <article class="text-left important-article">All donations made by those living in usa are 100%
            tax deductible, and 100% of donations go directly to the project with no administrative costs.
          </article>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<div class="container w-39">
  <div id="event" class="event pr-30px">
    <div class="row">
      <div class="col-5">
        <ng-container *ngFor="let event of events">
          <div id="event-header" class="event-content">
            <div class="event-header">
              <h3>{{event.name}}</h3>
            </div>
            <ng-container *ngIf="event.details.length > 249">
              <p>{{event.details | slice:0:249}}  </p>
              <ng-container *ngIf="show4">
                <p>{{event.details | slice:249}}</p>
              </ng-container>
              <span style="color:#f47321; cursor: pointer "
                    (click)="show4 = !show4">
                                 <span *ngIf="!show4">Read More ...</span>
                         <span *ngIf="show4">Read Less</span>
              </span>
            </ng-container>
          </div>
        </ng-container>
        <div class="d-flex flex-row justify-content-between mt-4">

          <a (click)="seoUpdate()" class="share-button  d-block">
            <button class="share btn">
              Share
              <i class="ml-2 fab fa-facebook"></i>
            </button>
          </a>
          <a class="event-a" href="https://hayakarimausa.com/events" title="go to hayaKarima website">
          <button class="event-btn">Join Event Meeting</button></a>
        </div>
        <div class="mt-5">
          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState2 = true"
                                 (closed)="panelOpenState2 = false">
              <mat-expansion-panel-header>
                <mat-panel-title class="font-weight-bold pl-2 font-size-22">
                  Donation Details
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container *ngIf="!totalAmount">
                <ngx-skeleton-loader animation="progress" count="1" appearance="line"></ngx-skeleton-loader>
                <!-- above line will produce the rendering of 5 circles with the pulse animation and the aria-valuetext attribute set with "This item is actually loading..." -->
              </ng-container>
              <div *ngIf="totalAmount" class="donation w-100 d-flex flex-column align-items-center">
                <span>Total Amount <b>${{numberWithCommas(donateBount)}}</b></span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <article class="text-left important-article event-hint mt-4">All donations made by those living in
          usa are
          100% tax deductible, and 100% of donations go directly to the project with no administrative costs.
        </article>
      </div>
      <div class="col-7">
        <div class="event-image">
<!--          <app-calender (setDateEvent)="dateFormat($event)">-->
<!--            <div class="mat-error" *ngIf="error">-->
<!--              {{error}}-->
<!--            </div>-->
<!--          </app-calender>-->
          <app-calendar></app-calendar>
        </div>
      </div>
    </div>
  </div>
</div>
<app-videos></app-videos>
<app-haya-news></app-haya-news>
<div class="bg-image-2">
  <div class="container w-new">
    <div class="row justify-content-center text-center">
      <div class="col-2 page-border">
        <i class="fa fa-flag"></i>
        <h1 class="counter-value" data-count="1300">4584</h1>
        <div class="counter-text">Villages</div>
      </div>
      <div class="col-2 page-border">
        <i class="fa fa-file"></i>
        <h1 class="counter-value" data-count="20">20</h1>
        <div class="counter-text">Governorates</div>
      </div>
      <div class="col-2 page-border">
        <i class="fa fa-check"></i>
        <h1 class="counter-value" data-count="18000000">175</h1>
        <div class="counter-text">Centers</div>
      </div>
      <div class="col-2 page-border">
        <i class="fa fa-check"></i>
        <h1 class="counter-value" data-count="18000000">28000</h1>
        <div class="counter-text">Dependencies</div>
      </div>
      <div class="col-2 page-border">
        <i class="fa fa-check"></i>
        <h1 class="counter-value" data-count="90000000">58000000</h1>
        <div class="counter-text">Citizens</div>
      </div>
    </div>
  </div>
</div>
<app-our-haya-message></app-our-haya-message>
<div class=" bg-image">
  <div class="row w-100">
    <div class="col-5">
      <div class="content">
        <h4>Target Groups</h4>
        <div class="row d-flex justify-content-end">
          <ul class="">
            <li>Volunteers</li>
            <li>The families most in <br> need in rural communities.</li>
            <li>Young people who are <br> capable to work.</li>
            <li>Orphans and children.</li>
            <li>The elderly.</li>
            <li>Orphans and children.</li>
            <li>People with special needs.</li>
            <li class="list-style-type-none">
              <a href="https://hayakarima.com/inputs.html" class="btn button-inputs">Initiative Interventions</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-7"></div>
  </div>
</div>
<div class="container  companies-logo">
  <h4>Partners</h4>
  <div class="row bg-white d-flex partners  align-items-center">
    <div class=" text-center">
      <img src="assets/images/haya/logo-medium.png" style="width:73%">
    </div>
    <div class=" text-center">
      <img src="assets/images/haya/logo33.png" style="width: 100%">
    </div>
    <div class=" text-center">
      <img src="assets/images/haya/NEW%20EGYPT%20LOGO%20(1).png" style="width:73%">
    </div>
    <div class=" text-center">
      <img src="assets/images/haya/rotary.jpg" style="width: 98%">
    </div>
  </div>
  <article class="text-left important-article mt-4">All donations made by those living in usa are 100% tax
    deductible, and 100% of donations go directly to the project with no administrative costs.
  </article>
</div>

<app-footer-hayah></app-footer-hayah>
