import { Component, OnInit } from '@angular/core';
import {ApisService} from '../services/apis.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {
  title = 'My first AGM project';
  allLocation : any [] = [];
  lat = 51.678418;
  lng = 7.809007;
  slug;
  activities;
  constructor( private apis: ApisService,  private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    this.slug = this.activatedRoute.snapshot.params.slug;
    this.getActivitiesApi()
  }
  getActivitiesApi(): void {
    this.apis.getActivities(this.slug).subscribe((data: any) => {
      this.activities = data.data;
      console.log(this.activities)
      this.allLocation = this.activities.activities.locations
      console.log(this.allLocation)
      // this.lat = this.activities.activities.locations;
    }, (err: any) => {
    });
  }
}
