<app-header></app-header>

<div class="container">
<div class="row body">
  <div class="col-8">
    <div class="row g-0 mainRow headline add">
      <div class="col-12 pt-5">
        <div class="flexDiv" style="justify-content: space-between">
          <div class="flexDiv" >
            <h1 class="popular-city">Ramadan 2022</h1>
          </div>
        </div>
      </div>
    </div>
    <p class="sub_description">The holiest month on the Islamic calendar, Ramadan begins this year on the evening of Friday, April 1, 2022 and ends on Saturday, April 30, 2022 lasting for 30 days.</p>
    <div class="row g-0 mainRow">
      <div class="col-12 ml-2">

        <ngb-carousel>
          <ng-container >
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img
                  src="./assets/images/ramadan2022/images.jpg"
                  width="100%"
                  height="540"
                  style="border-radius: 12px"
                >
                <h4 class="sub-title"> The month lasts 29-30 days depending on the sightings of the crescent moon.</h4>
              </div>
              <div class="carousel-caption"></div>

            </ng-template>
          </ng-container>
        </ngb-carousel>
        <div class="portfolio-description  pt-4 readMore">

          <div class="row">
            <div class="col-4 tit-description">
              <h4 class="title"> MORE ON: <br> RAMADAN</h4>
              <div class="title-tit">
                <ng-container >
                  <div class="column">
                    <div class="card card-border" [routerLink]="[]">
                      <h5 class="tit">Ramadan 2022</h5>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="title-tit">
                <ng-container >
                  <div class="column">
                    <div class="card card-border" [routerLink]="[]">
                      <h5 class="tit">Ramadan 2021</h5>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="title-tit">
                <ng-container >
                  <div class="column">
                    <div class="card card-border" [routerLink]="[]">
                      <h5 class="tit">Ramadan 2020</h5>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="title-tit">
                <ng-container >
                  <div class="column">
                    <div class="card card-border" [routerLink]="[]">
                      <h5 class="tit">Ramadan 2019</h5>
                    </div>
                  </div>
                </ng-container>
              </div>



            </div>

            <div class="col-8">
              <p class="aboutText">It is one of the five pillars of Islam - the fundamental rules that all Muslims follow - along with the Shahadah (declaration of faith), Salat (prayer), Zakat (charity) and the Hajj pilgrimage.

                During Ramadan, there is an increased offering of the Salat, with Muslims giving thanks to Allah, while the fasting ritual allows them to understand the suffering of others.

                Those observing the fast are encouraged to read the Quran and the holy text is recited at the Tarawih, special nightly prayers that are held throughout the month.

                All Muslims who have reached puberty are expected to fast during Ramadan, although there are some exceptions, including women who are menstruating or pregnant, and those suffering from illness.

                The strict rules mean food and drink, including water, are not allowed during daylight hours for the entire month, while smoking, sex and masturbation are also prohibited.

                The fast begins just before dawn, when a light meal called ‘suhoor’ is permitted to be consumed. At sunset, a prayer known as ‘Adhan’ signifies the end of the fast, when worshippers traditionally eat dates with juice, milk or water.

                To break the fast fully at the end of the month, families usually come together to enjoy the meal of Iftar.

                Once fasting is over, it’s traditional to celebrate Eid al-Fitr.

                This celebration is marked with lights, decorations and gifts, with worshippers often dressing up and decorating their homes.</p>
            </div>
          </div>


          <div class="picsum-img-wrapper">
            <img
              src="./assets/images/ramadan2022/download.jpg"
              width="100%"
              height="540"
              style="border-radius: 12px"
            />
          </div>
        </div>

      </div>
      <div class="col-lg-12">

      </div>
    </div>

    <div class="row g-0 mb-5">
      <div class="col-lg-8 col-md-12 col-sm-12 col">
        <div class="row g-0 mainRow">
          <div class="col-12"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row justify-content-center dst-img">
      <ng-container>
        <div class="column">
          <div class="card bord" [routerLink]="[]">
            <img src="./assets/images/ramadan2022/download.jpg">
            <h5 class="tit">Ramadan 2022</h5>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="column">
          <div class="card bord" [routerLink]="[]">
            <img src="./assets/images/ramadan2022/images.jpg">
            <h5 class="tit">Ramadan 2021</h5>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="column">
          <div class="card bord" [routerLink]="[]">
            <img src="./assets/images/ramadan2022/download.jpg">
            <h5 class="tit">Ramadan 2020</h5>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="column">
          <div class="card bord" [routerLink]="[]">
            <img src="./assets/images/ramadan2022/images.jpg">
            <h5 class="tit">Ramadan 2019</h5>
          </div>
        </div>
      </ng-container>



    </div>
  </div>
</div>
  <h2 id="nearst" class="text-center">Read More In Ramadan </h2>
  <div class="container">
    <div class="row">
      <ng-container >
        <div class="col-3 ml-3 ">
          <div class="row justify-content-center">
            <div>
              <div class="card card-border" [routerLink]="[]">
                <img src="./assets/images/ramadan2022/download.jpg">
                <h5 class="tit">Ramadan 2022</h5>

              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container >
        <div class="col-3 ml-3 ">
          <div class="row justify-content-center">
            <div>
              <div class="card card-border" [routerLink]="[]">
                <img src="./assets/images/ramadan2022/images.jpg">
                <h5 class="tit">Ramadan 2021</h5>

              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container >
        <div class="col-3 ml-3 ">
          <div class="row justify-content-center">
            <div>
              <div class="card card-border" [routerLink]="[]">
                <img src="./assets/images/ramadan2022/download.jpg">
                <h5 class="tit">Ramadan 2020</h5>

              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container >
        <div class="col-3 ml-3 ">
          <div class="row justify-content-center">
            <div>
              <div class="card card-border" [routerLink]="[]">
                <img src="./assets/images/ramadan2022/images.jpg">
                <h5 class="tit">Ramadan 2019</h5>

              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>
<app-footer></app-footer>

