<div class="container print" >
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-4">
          <h1>New Egypt group</h1>
        </div>
        <div class="col-4">
          <mat-dialog-actions align="end">
            <button class="close"  mat-button mat-dialog-close>X</button>
          </mat-dialog-actions>
        </div>
        <div class="col-4">
          <div class="px-3 d-flex justify-content-center">
            <span class="brand-logo">
                <img src="assets/images/NEW%20EGYPT%20LOGO%20165.png" height="auto" width="150px" class="handel-logo">
              </span>
          </div>
<!--          <h1 class="logo">New Egypt group</h1>-->
        </div>

      </div>
      <div class="row">
        <div class="col-3">
<!--          <div class="px-3 d-flex justify-content-start">-->
            <p class="loction">99 Madison Ave
              suite 5002
              New York, NY 10016</p>
          <p  class="loction">800-484-0305</p>
<!--          </div>-->
        </div>
        <div class="col-6">
          <div class="">
            <h1>Donation Report Details</h1>
<!--            <h2 *ngIf="mydonations?.purpose?.name">{{mydonations.purpose.name}}</h2>-->
            <h3 *ngIf="mydonations?.date">{{mydonations.date | date}}</h3>
          </div>
        </div>
        <div class="col-3">
          <h2 class="logo">One Heart ... Many Hands</h2>
        </div>

      </div>
<table class="donate table table-hover table-bordered mt-3">
  <thead >
  <tr>
    <th scope="col">DONOR NAME</th>
    <th scope="col">GROSS</th>
    <th scope="col">FEE</th>
    <th scope="col">AMOUNT</th>
    <th scope="col">PURPOSE</th>
    <th scope="col">DATE</th>
<!--    <th scope="col" class="action">ACTIONS</th>-->
  </tr>
  </thead>
  <tbody>
  <tr  *ngIf="mydonations">
    <th scope="row">{{mydonations.donor_name.name}}</th>
    <td>{{mydonations.gross | currency}}</td>
    <td>{{mydonations.fee | currency}}</td>
    <td>{{mydonations.amount | currency}}</td>
    <td>{{mydonations.purpose.name}}</td>
    <td>{{mydonations.date | date}}</td>
  </tr>
  </tbody>
</table>

      <mat-tab-group >
        <mat-tab label="General Purpose" *ngIf="generalpurpose != 0">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col">GENERAL PURPOSE NAME</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">RESIDUAL</th>
              <th scope="col">DATE</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of generalpurpose">
              <td>{{item.general_purpose}}</td>
              <td>{{item.amount | currency}}</td>
              <td>{{item.Residual | currency}}</td>
              <td>{{item.created_at | date}}</td>
            </tr>
            </tbody>
          </table>

        </mat-tab>
        <mat-tab label="Activities Incomes" *ngIf="activitiesincomes != 0">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col">ACTIVITY NAME</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">PERCENTAGE</th>
              <th scope="col">DATE</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of activitiesincomes">
              <td>{{item.activity.name}}</td>
              <td>{{item.amount | currency}}</td>
              <td>{{item.percentage}} %</td>
              <td>{{item.date | date}}</td>
            </tr>
            </tbody>
          </table>
        </mat-tab>
        <mat-tab label="Activities From General Purpose" *ngIf="activitiesfromgeneral !=0">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col">ACTIVITY NAME</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">PERCENTAGE</th>
              <th scope="col">DATE</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of activitiesfromgeneral">
              <td>{{item.activity.name}}</td>
              <td>{{item.amount | currency}}</td>
              <td>{{item.percentage}} %</td>
              <td>{{item.date | date}}</td>
            </tr>
            </tbody>
          </table>
        </mat-tab>
      </mat-tab-group>
      <mat-tab-group >
        <mat-tab label="Expenses" *ngIf="expenses.length != 0">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col">ITEM</th>
              <th scope="col">ITEM PRICE</th>
              <th scope="col">QUANTITY</th>
              <th scope="col">RESIDUAL QUANTITY</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">RESIDUAL AMOUNT</th>
              <th scope="col">DETAILS</th>
<!--              <th scope="col">ACTIONS</th>-->
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of expenses">
              <td>{{item.item.item_name}}</td>
              <td>{{item.item_price}} {{item.currency.symbol}}</td>
              <td>{{item.quantity}}</td>
              <td>{{item.residual_quantity}}</td>
              <td>{{item.amount}} {{item.currency.symbol}}</td>
              <td>{{item.residual_amount}} {{item.currency.symbol}}</td>
              <td>{{item.details}} </td>
<!--              <td>-->
<!--                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="border: none;background:none;height: 35px;width: 35px;">-->
<!--                  <mat-icon>more_vert</mat-icon>-->
<!--                </button>-->
<!--              </td>-->
<!--              <mat-menu #menu="matMenu" >-->
<!--                <button mat-menu-item (click)="openeExpensesDetailsReports(item.id)">-->
<!--                  <mat-icon>-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit ml-3 mb-3">-->
<!--                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>-->
<!--                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>-->
<!--                    </svg>-->
<!--                  </mat-icon>-->
<!--                  <span>DETAILS</span>-->
<!--                </button>-->
<!--              </mat-menu>-->
            </tr>
            </tbody>
          </table>
        </mat-tab>
      </mat-tab-group>

      <div class="px-3 d-flex justify-content-center mt-2">
        <a class="btn btn-outline-secondary hide-print btn-block"
           href="javascript:window.print();"
           rippleEffect
        >
          Print
        </a>
      </div>
    </div>
  </div>
</div>
