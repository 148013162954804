import {Component, Input, OnInit} from '@angular/core';
import {ApisService} from "../../services/apis.service";

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  loadingDownload1: boolean = false;
  @Input() certificateId:number;
  // @Input() activityID:number;

  Certificates: any;
  constructor( private apis: ApisService,
  ) {

  }

  ngOnInit(): void {
    this.getCertificatesApi();
  }
  getCertificatesApi(): any {
    this.apis.getCertificates().subscribe((data: any) => {
        this.Certificates = data.data.filter((item)=> item.activity.id == this.certificateId);
        console.log(this.Certificates);
      },
      err => {
        console.log(err);
      });
  }

  CertificateDown1() {
    this.loadingDownload1 = true;
   
    this.apis.CertificateDown1(this.certificateId).subscribe(
        (data: any) => {
            this.loadingDownload1 = false;
            var link = document.createElement('a');
            link.href = data.data;
            link.target = '_blank';
            link.download = `Donation Report.pdf`;
            link.click();
        },
        error => {
            this.loadingDownload1 = false;
  
            console.log(error);
        }
    );
  }

}
