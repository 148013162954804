<div [dir]="lang == 'ar' ? 'rtl':'ltr'">
  <div class="container">
    <div class="mt-1">
      <div class="col-12 mb-5">
        <mat-dialog-actions align="end">
          <button class="close"  mat-button mat-dialog-close>X</button>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
<div class="mb-4 ">
    <div class="progress">
      <div id="prgBar" class="progress-bar" [style.width.%]="step"></div>
    </div>
  </div>

  <mat-horizontal-stepper class="justify-content-center" #stepper>
    <mat-step [stepControl]="0">
      <div class="row m-0 pt-4">
        <ng-container *ngIf="data.completed == 1">
          <ng-container *ngFor="let case of data.case">
            <div class="d-flex justify-content-between mb-1" style=" cursor: pointer" [matMenuTriggerFor]="menu">
              <span><i class="far fa-question-circle fa-1x" ></i></span>
            </div>
            <mat-menu #menu="matMenu">
              <!-- <button mat-menu-item (click)="openCases(case)">
              <div class="d-flex">
                <i class="bi bi-person-lines-fill position-absolute" style="left:10px;top:0"></i>
                <span style="font-size: .8rem;left: 34px;top:0" class="position-absolute">Details</span>
              </div>
            </button> -->
              <button mat-menu-item >
                <label  class="form-check-label cursor-pointer d-flex align-items-center justify-content-between"
                  for="flexCheckDefault">
                  <input (click)="caseSearch(case.id)" class="form-check-input check-box" style="left:30px;top:0;"
                    type="checkbox" value="" id="flexCheckDefault" [checked]="case.delivered == 1">
                  <span style="font-size: .8rem;left: 34px;" class="position-absolute">DELIVERED</span>
                </label>
              </button>
            </mat-menu>
            <!-- [disabled]="!displayPupose.includes(case.purpose.id)" -->
            <button mat-button matStepperNext (click)="getPurposeId(case.purpose.id , case.id)"
             class="card bg-card col-12">
              <div class="w-100 p-4 position-relative">
                <div class="plus" *ngIf="!casePurposeId.includes(case.purpose.id)">
                  <span>+</span>
                </div>
                <div class="label-top shadow-sm">
                  <!-- <ng-container *ngIf="case.purpose.id == case.id"> -->
                  <span class="mx-1 px-2 fa--6x d-inline-block"
                    [class]="case.completed != 0 ? 'bg-success':'bg-warning'">{{case.completed != 0 ? 'Completed':'Un
                    Completed'}}</span>
                  <span class="mx-1 px-2 fa--6x d-inline-block"
                    [class]="case.delivered != 0 ? 'bg-success':'bg-warning'">{{case.delivered != 0 ?
                    'Done':'shipped'}}</span>
                  <span class="mx-1 px-2 fa--6x d-inline-block" [class]="case.accepted == 0 ? 'bg-warning': case.accepted == 1 ? 'bg-success' :
                case.accepted == 2 ? 'bg-danger' : 'bg-success'">{{
                    case.accepted == 0 ? 'Under review':
                    case.accepted == 1 ? 'Accept open budget' :
                    case.accepted == 2 ? 'Rejected' :
                    'Accept'}}</span>
                  <!-- </ng-container> -->
                </div>

                 <div class="card-icon card-icon-large">
                  <!-- <i class="fas fa-shopping-cart"></i> -->
                </div>
                <div class="w-100">
                  <p class="card-title mb-0">{{case.purpose.name}}</p>
                </div>
              </div>
            </button>
          </ng-container>
        </ng-container>
      
        <ng-container *ngFor="let item of FilterPurpose">
          <!--  [disabled]="!displayPupose.includes(item.id)" -->

          <button mat-button matStepperNext (click)="getPurposeId(item.id , null)"
             (click)="getPurposId(item.id)" class="card bg-card col-12">
            <div class="w-100 p-4 position-relative">
              <div class="plus" *ngIf="!casePurposeId.includes(item.id)">
                <span>+</span>
              </div>
              <!-- <div class="card-icon card-icon-large"><i class="fas fa-shopping-cart"></i></div> -->
              <div class="w-100">
                <p class="card-title mb-0">{{item.name}}</p>
              </div>
            </div>
          </button>
        </ng-container>
      </div>
    </mat-step>
    <mat-step [stepControl]="1">
      <form [formGroup]="firstFormGroup">
        <div class="row g-3">

          <div class="col-sm-12 my-2">
            <label for="fullName" class="form-label" translate>home.full-name</label>
            <input type="text" class="form-control" formControlName="fullname"
              [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
              [ngClass]="{ 'is-invalid': ( firstFormGroup.get('fullname')?.dirty && firstFormGroup.get('fullname')?.invalid) || (firstFormGroup.get('fullname')?.dirty && firstFormGroup.get('fullname')?.errors?.required ) ,'is-valid': ( firstFormGroup.get('fullname')?.dirty && firstFormGroup.get('fullname')?.valid)}"
              placeholder="">
            <div class="invalid-feedback">
              Valid full name is required.
            </div>
          </div>
          <div class="col-sm-12  my-2">
            <label for="lastName" class="form-label" translate>home.Current-address</label>
            <input
              [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
              [ngClass]="{ 'is-invalid': ( firstFormGroup.get('address')?.dirty && firstFormGroup.get('address')?.invalid) || (firstFormGroup.get('address')?.dirty && firstFormGroup.get('address')?.errors?.required ) ,'is-valid': ( firstFormGroup.get('address')?.dirty && firstFormGroup.get('address')?.valid)}"
              type="text" class="form-control" formControlName="address" placeholder="">
            <div class="invalid-feedback">
              Valid address is required.
            </div>
          </div>
          <div class="col-sm-12 my-2">
            <label for="address" class="form-label" translate>home.age</label>
            <input
              [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
              [ngClass]="{ 'is-invalid': ( firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.invalid) || (firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.errors?.required ) ,'is-valid': ( firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.valid)}"
              type="text" class="form-control" placeholder="" formControlName="age">
            <div class="invalid-feedback"
              *ngIf="firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.errors?.pattern">
              Please enter only number.
            </div>
            <div class="invalid-feedback"
              *ngIf="firstFormGroup.get('age')?.dirty && firstFormGroup.get('age')?.errors?.required">
              Please enter your shipping address.
            </div>
          </div>
          <div class="col-sm-12 my-2">
            <label for="fullName" class="form-label" translate>home.birthdate</label>
            <div class="position-relative">
              <input formControlName="birthdate" type="text" class="form-control" [matDatepicker]="birthdate"
                [style.backgroundPosition]=" lang == 'ar'?'left calc(2.375em + 0.1875rem) center':'right calc(2.375em + 0.1875rem) center'"
                [ngClass]="{ 'is-invalid': ( firstFormGroup.get('birthdate')?.dirty && firstFormGroup.get('birthdate')?.invalid) || (firstFormGroup.get('birthdate')?.dirty && firstFormGroup.get('birthdate')?.errors?.required ) ,'is-valid': ( firstFormGroup.get('birthdate')?.dirty && firstFormGroup.get('birthdate')?.valid)}">
              <mat-datepicker-toggle [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}" matSuffix
                [for]="birthdate"></mat-datepicker-toggle>
              <mat-datepicker #birthdate></mat-datepicker>
            </div>
            <div class="invalid-feedback">
              birthdate is invalid.
            </div>
          </div>
        </div>
        <div class="submitBtn my-3">
          <button mat-button matStepperNext class="btn btn-success submitBtn" [disabled]="firstFormGroup.invalid"
            (click)="step = ( purpose_id[0] != 6)? (2/7)*100:(2/4)*100"><span translate>home.next</span></button>
        </div>
        <div class="submitBtn ">
          <button mat-button matStepperPrevious class="btn btn-primary submitBtn"><span
              translate>home.back</span></button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="2">
      <form [formGroup]="thirdFormGroup">
        <div class="col-12">
          <div class="row g-3">
            <!--

            <div class="col-sm-6 my-2 p-0 multiselectPurpose">
              <label for="purpose_id" class="form-label" translate>home.cas</label>
              <div class="position-relative">
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(1.375em + 0.1875rem) center':'right calc(1.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid':((thirdFormGroup.get('purpose_id')?.dirty || thirdFormGroup.get('purpose_id')?.touched) && thirdFormGroup.get('purpose_id')?.errors?.required ) ,'is-valid': ( (thirdFormGroup.get('purpose_id')?.dirty || thirdFormGroup.get('purpose_id')?.touched) && thirdFormGroup.get('purpose_id')?.valid)}"
                  type="text" style="cursor: pointer" class="form-control" formControlName="purpose_id"
                  [matAutocomplete]="auto">
                <div class="invalid-feedback"
                  *ngIf="secondFormGroup.get('purpose_id')?.dirty && secondFormGroup.get('purpose_id')?.errors?.required">
                  Please enter purpose.
                </div>
                <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
              </div>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let purpose of casePurpose " [value]="purpose.name" multiple>
                  <mat-checkbox [dir]="'rtl'"
                    [checked]="data.completed == 1? purpose.name == data.case.purpose.name : false"
                    (change)="toggleSelection($event , purpose.name , purpose.id)" (click)="$event.stopPropagation()">
                    {{ purpose.name }}
                  </mat-checkbox>
                </mat-option>
              </mat-autocomplete>
            </div> -->

            <div class="col-sm-12 my-2 p-0">
              <label for="goverment_id" class="form-label" translate>home.goverments</label>
              <div class="position-relative">
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(1.375em + 0.1875rem) center':'right calc(1.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': (thirdFormGroup.get('goverment_id')?.dirty && thirdFormGroup.get('goverment_id')?.errors?.required ) ,'is-valid': ( thirdFormGroup.get('goverment_id')?.dirty && thirdFormGroup.get('goverment_id')?.valid)}"
                  style="cursor: pointer" class="form-control" ng-model="myModel" [matAutocomplete]="goverments"
                  type="text" formControlName="goverment_id" />
                <div class="invalid-feedback"
                  *ngIf="thirdFormGroup.get('goverment_id')?.dirty && thirdFormGroup.get('goverment_id')?.errors?.required">
                  Please enter purpose.
                </div>
                <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
              </div>
              <mat-autocomplete #goverments="matAutocomplete">
                <mat-option *ngFor="let govermnet of filteredGovermnets | async" [value]="govermnet.name_en">
                  {{govermnet.name_en}}
                </mat-option>
              </mat-autocomplete>
            </div>

            <div class="col-sm-12 my-2 p-0">
              <label for="address" class="form-label" translate>home.clubs</label>
              <div class="position-relative">
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(1.375em + 0.1875rem) center':'right calc(1.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': (thirdFormGroup.get('club_id')?.dirty && thirdFormGroup.get('club_id')?.errors?.required ) ,'is-valid': ( thirdFormGroup.get('club_id')?.dirty && thirdFormGroup.get('club_id')?.valid)}"
                  style="cursor: pointer" class="form-control" ng-model="myModel" [matAutocomplete]="clubs" type="text"
                  formControlName="club_id" />
                <div class="invalid-feedback"
                  *ngIf="thirdFormGroup.get('club_id')?.dirty && thirdFormGroup.get('club_id')?.errors?.required">
                  Please enter club.
                </div>
                <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
              </div>
              <mat-autocomplete autoActiveFirstOption #clubs="matAutocomplete">
                <mat-option *ngFor="let club of filteredClubs | async" [value]="club.name">
                  {{club.name}}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="col-sm-12 my-2 p-0">
              <label for="fullName" class="form-label" translate>home.city</label>
              <input
                [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                [ngClass]="{'is-valid': ( thirdFormGroup.get('city')?.dirty && firstFormGroup.get('city')?.value != '')}"
                type="text" class="form-control" formControlName="city" placeholder="">
              <div class="invalid-feedback"
                *ngIf="thirdFormGroup.get('city')?.dirty && thirdFormGroup.get('city')?.errors?.required">
                city is required.
              </div>
            </div>
            <div class="col-sm-12 my-2 p-0">
              <label for="fullName" class="form-label" translate>home.village</label>
              <input
                [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                [ngClass]="{ 'is-valid': ( thirdFormGroup.get('village')?.dirty && thirdFormGroup.get('village')?.value != '')}"
                type="text" class="form-control" formControlName="village" placeholder="">
            </div>
          </div>
        </div>
        <div class="submitBtn my-3">
          <button mat-button matStepperNext class="btn btn-success submitBtn" [disabled]="thirdFormGroup.invalid"
            (click)="step = ( purpose_id[0] != 6)? (3/7)*100:(3/4)*100"><span translate>home.next</span></button>
        </div>
        <div class="submitBtn ">
          <button mat-button matStepperPrevious (click)="step = ( purpose_id[0] != 6)? 2:2"
            class="btn btn-primary submitBtn"><span translate>home.back</span></button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="3">
      <form [formGroup]="secondFormGroup">
        <div class="row g-3">

          <div class="col-sm-12 my-2">
            <label for="email" class="form-label" translate>home.email</label>
            <input
              [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
              [ngClass]="{ 'is-invalid': (secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.errors?.required ) || (secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.errors?.email ) ,'is-valid': ( secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.value != '')}"
              type="text" class="form-control" formControlName="email" placeholder="">
            <!-- <div class="invalid-feedback" *ngIf="secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.errors?.required">
              Email is required.
            </div> -->
            <div class="invalid-feedback"
              *ngIf="secondFormGroup.get('email')?.dirty && secondFormGroup.get('email')?.errors?.email">
              Email is invalid.
            </div>
          </div>
          <div class="col-sm-12 my-2">
            <label for="phone" class="form-label" translate>home.phone-number</label>
            <input
              [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
              [ngClass]="{ 'is-invalid': ( secondFormGroup.get('phone')?.dirty && secondFormGroup.get('phone')?.errors?.pattern) || (secondFormGroup.get('phone')?.dirty && secondFormGroup.get('phone')?.errors?.required ) ,'is-valid': ( secondFormGroup.get('phone')?.dirty && secondFormGroup.get('phone')?.valid)}"
              type="phone" class="form-control" formControlName="phone" placeholder="">
            <div class="invalid-feedback">
              Please enter a valid phone address for shipping updates.
            </div>
          </div>
          <div class="col-sm-12 my-2">
            <label for="address" class="form-label" translate>home.National-ID</label>
            <input
              [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
              [ngClass]="{ 'is-invalid':(secondFormGroup.get('national_id')?.dirty && (secondFormGroup.get('national_id')?.errors?.maxlength || secondFormGroup.get('national_id')?.errors?.minlength)) || (secondFormGroup.get('national_id')?.dirty && secondFormGroup.get('national_id')?.errors?.pattern) || (secondFormGroup.get('national_id')?.dirty && secondFormGroup.get('national_id')?.errors?.required ) ,'is-valid': ( secondFormGroup.get('national_id')?.dirty && secondFormGroup.get('national_id')?.valid)}"
              type="text" class="form-control" placeholder="" formControlName="national_id">
            <div class="invalid-feedback"
              *ngIf="secondFormGroup.get('national_id')?.dirty && secondFormGroup.get('national_id')?.errors?.required">
              The national ID is invalid.
            </div>
            <div class="invalid-feedback"
              *ngIf="secondFormGroup.get('national_id')?.dirty && (secondFormGroup.get('national_id')?.errors?.maxlength || secondFormGroup.get('national_id')?.errors?.minlength)">
              The national ID is invalid, it must consist of 14 digits.
            </div>
            <div class="invalid-feedback"
              *ngIf="secondFormGroup.get('national_id')?.dirty && secondFormGroup.get('national_id')?.errors?.pattern">
              Please enter only number.
            </div>
          </div>


        </div>
        <div class="submitBtn">
          <button mat-button matStepperNext class="btn btn-success submitBtn" [disabled]="secondFormGroup.invalid"
            (click)="step = ( purpose_id[0] != 6)? (4/7)*100:(4/4)*100"><span translate>home.next</span></button>
        </div>
        <div class="submitBtn my-3">
          <button mat-button matStepperPrevious (click)="step = ( purpose_id[0] != 6)? (2/7)*100:(2/4)*100"
            class="btn btn-primary submitBtn"><span translate>home.back</span></button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="4">
      <form [formGroup]="ForthFormGroup">
        <ng-container *ngIf="!this.purpose_id.includes(7)">
          <div class="uploader position-relative mb-3">

            <div class="lable w-100">
              <div class="position-relative ">
                <div class="mx-auto box-image" style="top: 30px;" *ngIf="image.length > 0">
                  <i class="fa fa-close" aria-hidden="true" (click)="deletimage()"></i>
                  <img id="file-image" [src]="image[0]" alt="Preview">
                </div>

                <div id="start" *ngIf="image.length == 0">
                  <i class="fa fa-download" aria-hidden="true"></i>
                  <div translate>home.profile-picture</div>
                  <span id="file-upload-btn" class="btn btn-primary overflow-hidden">
                    <input id="file-upload" type="file" name="fileUpload" (change)="onFileSelected($event)"
                      accept="image/*" />
                    Select a file</span>
                </div>
              </div>

            </div>
          </div>

          <div class="uploader position-relative">

            <div class="lable w-100">
              <div class="position-relative row justify-content-center">
                <ng-container *ngFor="let imgs of images1;let i = index">
                  <div class="m-1 box-image" style="max-width: 90px;max-height: 60px;" *ngIf="images1.length > 0">
                    <i class="fa fa-close" aria-hidden="true" (click)="deletimages1(i)"></i>
                    <img style="max-width:100%;max-height: 100%;" id="file-image" [src]="imgs" alt="Preview">
                  </div>
                </ng-container>

              </div>
              <div id="start" class="w-100">
                <ng-container *ngIf="images1.length == 0">
                  <i class="fa fa-download" aria-hidden="true"></i>
                  <div translate>home.pictures-support-your-case</div>
                </ng-container>
                <div class="col-12">
                  <span id="file-upload-btn" class="btn btn-primary overflow-hidden">
                    <input id="file-upload" type="file" name="fileUpload" multiple (change)="onFileSelected1($event)"
                      accept="image/*" />
                    Select a file</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="this.purpose_id.includes(7)">
          <div class="uploader position-relative mb-3">

            <div class="lable w-100">
              <div class="position-relative ">
                <div class="mx-auto box-image" style="top: 30px;" *ngIf="image.length > 0">
                  <i class="fa fa-close" aria-hidden="true" (click)="deletimage()"></i>
                  <img id="file-image" [src]="image[0]" alt="Preview">
                </div>

                <div id="start" *ngIf="image.length == 0">
                  <i class="fa fa-download" aria-hidden="true"></i>
                  <div translate>home.profile-image-child</div>
                  <span id="file-upload-btn" class="btn btn-primary overflow-hidden">
                    <input id="file-upload" type="file" name="fileUpload" (change)="onFileSelected($event)"
                      accept="image/*" />
                    Select a file</span>
                </div>
              </div>

            </div>
          </div>

          <div class="uploader position-relative">

            <div class="lable w-100">
              <div class="position-relative row justify-content-center">
                <ng-container *ngFor="let imgs of images1;let i = index">
                  <div class="m-1 box-image" style="max-width: 90px;max-height: 60px;" *ngIf="images1.length > 0">
                    <i class="fa fa-close" aria-hidden="true" (click)="deletimages1(i)"></i>
                    <img style="max-width:100%;max-height: 100%;" id="file-image" [src]="imgs" alt="Preview">
                  </div>
                </ng-container>

              </div>
              <div id="start" class="w-100">
                <ng-container *ngIf="images1.length == 0">
                  <i class="fa fa-download" aria-hidden="true"></i>
                  <div translate>home.birth-certificate</div>
                </ng-container>
                <div class="col-12">
                  <span id="file-upload-btn" class="btn btn-primary overflow-hidden">
                    <input id="file-upload" type="file" name="fileUpload" multiple (change)="onFileSelected1($event)"
                      accept="image/*" />
                    Select a file</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

     <!--  <div class="submitBtn mt-5 my-3" *ngIf=" purpose_id[0] == 6">
          <button mat-button class="btn btn-success" [disabled]="ForthFormGroup.invalid" (click)="add()">
            <span translate>home.submit</span>
            <div *ngIf="loading" class="spinner-border m-2 text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div> -->
        <div class="submitBtn mt-5 my-3" >
          <!-- *ngIf="( purpose_id[0] != 6)" -->
          <button mat-button matStepperNext [disabled]="ForthFormGroup.invalid" class="btn btn-success submitBtn"
            (click)="step = ( purpose_id[0] != 6)? (5/7)*100:(5/6)*100"><span translate>home.next</span></button>
        </div>
        <div class="submitBtn">
          <button mat-button matStepperPrevious (click)="step = ( purpose_id[0] != 6)? (3/7)*100:(2/6)*100"
            class="btn btn-primary "><span translate>home.back</span></button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="5">
      <form [formGroup]="FifthFormGroup">
        <div class="col-12">
          <div class="row g-3">
            <ng-container *ngIf="this.purpose_id.includes(7)">
              <div class="col-sm-12 my-2 p-0">
                <label for="father" class="form-label" translate>home.father</label>
                <div class="position-relative">
                  <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
                  <select formControlName="father" class="form-select form-control" aria-label="Default select example">
                    <!-- <option selected>Open this select menu</option> -->
                    <option *ngFor="let item of listOfFather" [value]="item.name_en">{{item.name_en}}</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-12 my-2 p-0">
                <label for="mother" class="form-label" translate>home.mother</label>
                <div class="position-relative">
                  <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
                  <select formControlName="mother" class="form-select form-control" aria-label="Default select example">
                    <!-- <option selected>Open this select menu</option> -->
                    <option *ngFor="let item of listOfMother" [value]="item.name_en">{{item.name_en}}</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-12 my-2 p-0">
                <label for="grandfather" class="form-label" translate>home.grandfather</label>
                <div class="position-relative">
                  <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
                  <select formControlName="grandfather" class="form-select form-control"
                    aria-label="Default select example">
                    <!-- <option selected>Open this select menu</option> -->
                    <option *ngFor="let item of listOfGrandfather" [value]="item.name_en">
                      {{item.name_en}}</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-12 my-2 p-0">
                <label for="grandmother" class="form-label" translate>home.grandmother</label>
                <div class="position-relative">
                  <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
                  <select formControlName="grandmother" class="form-select form-control"
                    aria-label="Default select example">
                    <!-- <option selected>Open this select menu</option> -->
                    <option *ngFor="let item of listOfGrandmother" [value]="item.name_en">
                      {{item.name_en}}</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.live_with_whom</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( FifthFormGroup.get('live_with_whom')?.dirty && FifthFormGroup.get('live_with_whom')?.value != '')}"
                  type="text" class="form-control" formControlName="live_with_whom" placeholder="">
                <div class="invalid-feedback"
                  *ngIf="FifthFormGroup.get('live_with_whom')?.dirty && FifthFormGroup.get('live_with_whom')?.errors?.required">
                  live with whom is required.
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="this.purpose_id.includes(6)">
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.job_place</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( FifthFormGroup.get('job_place')?.dirty && FifthFormGroup.get('job_place')?.value != '')}"
                  type="text" class="form-control" formControlName="job_place" placeholder="">
                <div class="invalid-feedback"
                  *ngIf="FifthFormGroup.get('job_place')?.dirty && FifthFormGroup.get('job_place')?.errors?.required">
                  job place is required.
                </div>
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.water_source</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( FifthFormGroup.get('water_source')?.dirty && FifthFormGroup.get('water_source')?.value != '')}"
                  type="text" class="form-control" formControlName="water_source" placeholder="">
                <div class="invalid-feedback"
                  *ngIf="FifthFormGroup.get('water_source')?.dirty && FifthFormGroup.get('water_source')?.errors?.required">
                  water source is required.
                </div>
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.current_job</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( FifthFormGroup.get('current_job')?.dirty && FifthFormGroup.get('current_job')?.value != '')}"
                  type="text" class="form-control" formControlName="current_job" placeholder="">
                <div class="invalid-feedback"
                  *ngIf="FifthFormGroup.get('current_job')?.dirty && FifthFormGroup.get('current_job')?.errors?.required">
                  current job is required.
                </div>
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.housing_type</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( FifthFormGroup.get('housing_type')?.dirty && FifthFormGroup.get('housing_type')?.value != '')}"
                  type="text" class="form-control" formControlName="housing_type" placeholder="">
                <div class="invalid-feedback"
                  *ngIf="FifthFormGroup.get('housing_type')?.dirty && FifthFormGroup.get('housing_type')?.errors?.required">
                  housing type is required.
                </div>
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.house_status</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( FifthFormGroup.get('house_status')?.dirty && FifthFormGroup.get('house_status')?.value != '')}"
                  type="text" class="form-control" formControlName="house_status" placeholder="">
                <div class="invalid-feedback"
                  *ngIf="FifthFormGroup.get('house_status')?.dirty && FifthFormGroup.get('house_status')?.errors?.required">
                  house status is required.
                </div>
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.house_independance</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( FifthFormGroup.get('house_independance')?.dirty && FifthFormGroup.get('house_independance')?.value != '')}"
                  type="text" class="form-control" formControlName="house_independance" placeholder="">
                <div class="invalid-feedback"
                  *ngIf="FifthFormGroup.get('house_independance')?.dirty && FifthFormGroup.get('house_independance')?.errors?.required">
                  house independance is required.
                </div>
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.possession</label>
                <input
                  [style.backgroundPosition]="lang == 'ar' ? 'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( FifthFormGroup.get('possession')?.dirty && FifthFormGroup.get('possession')?.value != '')}"
                  type="text" class="form-control" formControlName="possession" placeholder="">
                <div class="invalid-feedback"
                  *ngIf="FifthFormGroup.get('possession')?.dirty && FifthFormGroup.get('possession')?.errors?.required">
                  possession is required.
                </div>
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.wc</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( FifthFormGroup.get('wc')?.dirty && FifthFormGroup.get('wc')?.value != '')}"
                  type="text" class="form-control" formControlName="wc" placeholder="">
                <div class="invalid-feedback"
                  *ngIf="FifthFormGroup.get('wc')?.dirty && FifthFormGroup.get('wc')?.errors?.required">
                  wc is required.
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="submitBtn">
          <button mat-button matStepperNext class="btn btn-success submitBtn" [disabled]="FifthFormGroup.invalid"
            (click)="step = ( purpose_id[0] != 6)? (6/7)*100:(6/6)*100"><span translate>home.next</span></button>
        </div>
        <div class="submitBtn my-3">
          <button mat-button matStepperPrevious (click)="step = ( purpose_id[0] != 6)? (4/7)*100:(3/6)*100"
            class="btn btn-primary submitBtn"><span translate>home.back</span></button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="6">
      <form [formGroup]="SixhlyFormGroup">
        <div class="col-12">
          <div class="row g-3">
            <ng-container *ngIf="this.purpose_id.includes(7)">
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.educational_level</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('educational_level')?.dirty && SixhlyFormGroup.get('educational_level')?.value != '')}"
                  type="text" class="form-control" formControlName="educational_level" placeholder="">
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.academic_level</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('academic_level')?.dirty && SixhlyFormGroup.get('academic_level')?.value != '')}"
                  type="text" class="form-control" formControlName="academic_level" placeholder="">
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.hobbies</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('hobbies')?.dirty && SixhlyFormGroup.get('hobbies')?.value != '')}"
                  type="text" class="form-control" formControlName="hobbies" placeholder="">
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.favorite_colors</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('favorite_colors')?.dirty && SixhlyFormGroup.get('favorite_colors')?.value != '')}"
                  type="text" class="form-control" formControlName="favorite_colors" placeholder="">
              </div>
              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.favorite_sports</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('favorite_sports')?.dirty && SixhlyFormGroup.get('favorite_sports')?.value != '')}"
                  type="text" class="form-control" formControlName="favorite_sports" placeholder="">
              </div>
            </ng-container>
            <ng-container *ngIf="this.purpose_id.includes(6)">

              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.family_health_status</label>
                <textarea
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('family_health_status')?.dirty && SixhlyFormGroup.get('family_health_status')?.value != '')}"
                  type="text" class="form-control" formControlName="family_health_status" placeholder="">
                  </textarea>
                <div class="invalid-feedback"
                  *ngIf="SixhlyFormGroup.get('family_health_status')?.dirty && SixhlyFormGroup.get('family_health_status')?.errors?.required">
                  family health status is required.
                </div>
              </div>

              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.family_marital_status</label>
                <textarea
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('family_marital_status')?.dirty && SixhlyFormGroup.get('family_marital_status')?.value != '')}"
                  type="text" class="form-control" formControlName="family_marital_status" placeholder="">
                  </textarea>
                <div class="invalid-feedback"
                  *ngIf="SixhlyFormGroup.get('family_marital_status')?.dirty && SixhlyFormGroup.get('family_marital_status')?.errors?.required">
                  family marital status is required.
                </div>
              </div>

              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.family_economic_status</label>
                <textarea
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('family_economic_status')?.dirty && SixhlyFormGroup.get('family_economic_status')?.value != '')}"
                  type="text" class="form-control" formControlName="family_economic_status" placeholder="">
                  </textarea>
                <div class="invalid-feedback"
                  *ngIf="SixhlyFormGroup.get('family_economic_status')?.dirty && SixhlyFormGroup.get('family_economic_status')?.errors?.required">
                  family economic status is required.
                </div>
              </div>

              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.family_needs</label>
                <textarea
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('family_needs')?.dirty && SixhlyFormGroup.get('family_needs')?.value != '')}"
                  type="text" class="form-control" formControlName="family_needs" placeholder="">
                  </textarea>
                <div class="invalid-feedback"
                  *ngIf="SixhlyFormGroup.get('family_needs')?.dirty && SixhlyFormGroup.get('family_needs')?.errors?.required">
                  family needs is required.
                </div>
              </div>

              <!-- <div class="col-sm-12 my-2 p-0">
              <label for="fullName" class="form-label" translate>home.researcher_opinion</label>
              <input
                [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                [ngClass]="{'is-valid': ( SixhlyFormGroup.get('researcher_opinion')?.dirty && SixhlyFormGroup.get('researcher_opinion')?.value != '')}"
                type="text" class="form-control" formControlName="researcher_opinion" placeholder="">
              <div class="invalid-feedback"
                *ngIf="SixhlyFormGroup.get('researcher_opinion')?.dirty && SixhlyFormGroup.get('researcher_opinion')?.errors?.required">
                researcher opinion is required.
              </div>
            </div> -->

              <!-- <div class="col-sm-12 my-2 p-0">
              <label for="fullName" class="form-label" translate>home.who_creditor</label>
              <input
                [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                [ngClass]="{'is-valid': ( SixhlyFormGroup.get('who_creditor')?.dirty && SixhlyFormGroup.get('who_creditor')?.value != '')}"
                type="text" class="form-control" formControlName="who_creditor" placeholder="">
              <div class="invalid-feedback"
                *ngIf="SixhlyFormGroup.get('who_creditor')?.dirty && SixhlyFormGroup.get('who_creditor')?.errors?.required">
                who creditor is required.
              </div>
            </div> -->

              <div class="col-sm-12 my-2 p-0">
                <label for="fullName" class="form-label" translate>home.insolvency_reason</label>
                <textarea
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{'is-valid': ( SixhlyFormGroup.get('insolvency_reason')?.dirty && SixhlyFormGroup.get('insolvency_reason')?.value != '')}"
                  type="text" class="form-control" formControlName="insolvency_reason" placeholder="">
                  </textarea>
                <div class="invalid-feedback"
                  *ngIf="SixhlyFormGroup.get('insolvency_reason')?.dirty && SixhlyFormGroup.get('insolvency_reason')?.errors?.required">
                  insolvency reason is required.
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="submitBtn mt-5 my-3" *ngIf=" purpose_id[0] == 6">
          <button mat-button class="btn btn-success" [disabled]="ForthFormGroup.invalid" (click)="add()">
            <span translate>home.submit</span>
            <div *ngIf="loading" class="spinner-border m-2 text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
        <div class="submitBtn mt-5 my-3" *ngIf="( purpose_id[0] != 6)">
          <button mat-button matStepperNext [disabled]="ForthFormGroup.invalid" class="btn btn-success submitBtn"
            (click)="step = ( purpose_id[0] != 6)? (7/7)*100:(6/6)*100"><span translate>home.next</span></button>
        </div>
        <div class="submitBtn">
          <button mat-button matStepperPrevious (click)="step = ( purpose_id[0] != 6)? (5/7)*100:(4/6)*100"
            class="btn btn-primary "><span translate>home.back</span></button>
        </div>



        <!-- <div class="submitBtn">
          <button mat-button matStepperNext class="btn btn-success submitBtn" [disabled]="SixhlyFormGroup.invalid"
            (click)="step = ( purpose_id[0] != 6)? (7/7)*100:(5/5)*100"><span translate>home.next</span></button>
        </div>
        <div class="submitBtn my-3">
          <button mat-button matStepperPrevious (click)="step = ( purpose_id[0] != 6)? (5/7)*100:(3/5)*100"
            class="btn btn-primary submitBtn"><span translate>home.back</span></button>
        </div> -->


      </form>
    </mat-step>
    <mat-step [stepControl]="7">
      <form [formGroup]="VIIFormGroup">

        <div class="col-12">
          <div class="row g-3">
            <ng-container *ngIf="this.purpose_id.includes(7)">
              <div class="col-sm-12 my-2">
                <label for="gender" class="form-label" translate>home.gender</label>
                <div class="position-relative">
                  <i class="fa fa-chevron-down" [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}"></i>
                  <select formControlName="gender" class="form-select form-control" aria-label="Default select example">
                    <!-- <option selected>Open this select menu</option> -->
                    <option *ngFor="let item of listOfgender" [value]="item.name_en">{{item.name_en}}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 my-2">
                <label for="current_height" class="form-label" translate>home.current_height</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': ( VIIFormGroup.get('current_height')?.dirty && VIIFormGroup.get('current_height')?.errors?.pattern) || (VIIFormGroup.get('current_height')?.dirty && VIIFormGroup.get('current_height')?.errors?.required ) ,'is-valid': ( VIIFormGroup.get('current_height')?.dirty && VIIFormGroup.get('current_height')?.valid)}"
                  type="current_height" class="form-control" formControlName="current_height" placeholder="">
                <div class="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
              <div class="col-sm-12 my-2">
                <label for="current_weight" class="form-label" translate>home.current_weight</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': ( VIIFormGroup.get('current_weight')?.dirty && VIIFormGroup.get('current_weight')?.errors?.pattern) || (VIIFormGroup.get('current_weight')?.dirty && VIIFormGroup.get('current_weight')?.errors?.required ) ,'is-valid': ( VIIFormGroup.get('current_weight')?.dirty && VIIFormGroup.get('current_weight')?.valid)}"
                  type="current_weight" class="form-control" formControlName="current_weight" placeholder="">
                <div class="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
              <div class="col-sm-12 my-2">
                <label for="shoe_size" class="form-label" translate>home.shoe_size</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': ( VIIFormGroup.get('shoe_size')?.dirty && VIIFormGroup.get('shoe_size')?.errors?.pattern) || (VIIFormGroup.get('shoe_size')?.dirty && VIIFormGroup.get('shoe_size')?.errors?.required ) ,'is-valid': ( VIIFormGroup.get('shoe_size')?.dirty && VIIFormGroup.get('shoe_size')?.valid)}"
                  type="shoe_size" class="form-control" formControlName="shoe_size" placeholder="">
                <div class="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="this.purpose_id.includes(11)">
              <div class="col-sm-12 my-2">
                <label for="fullName" class="form-label" translate>home.insolvency_date</label>
                <div class="position-relative">
                  <input formControlName="insolvency_date" type="text" class="form-control"
                    [matDatepicker]="insolvency_date" (dateChange)="checkDate($event)"
                    [style.backgroundPosition]=" lang == 'ar'?'left calc(2.375em + 0.1875rem) center':'right calc(2.375em + 0.1875rem) center'"
                    [ngClass]="{ 'is-invalid': (VIIFormGroup.get('insolvency_date')?.dirty && (VIIFormGroup.get('insolvency_date')?.invalid) || VIIFormGroup.get('insolvency_date')?.dirty &&VIIFormGroup.get('insolvency_date')?.errors?.required ) ,'is-valid': (VIIFormGroup.get('insolvency_date')?.dirty &&VIIFormGroup.get('insolvency_date')?.valid)}"
                    placeholder="">
                  <mat-datepicker-toggle [ngStyle]="lang == 'ar' ? {'left':'8px'}:{'right':'8px'}" matSuffix
                    [for]="insolvency_date"></mat-datepicker-toggle>
                  <mat-datepicker #insolvency_date></mat-datepicker>
                </div>
                <div class="invalid-feedback">
                  insolvency date is invalid.
                </div>
              </div>
              <div class="col-sm-12 my-2">
                <label for="insurance_number" class="form-label" translate>home.insurance_number</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': ( VIIFormGroup.get('insurance_number')?.dirty && VIIFormGroup.get('insurance_number')?.errors?.pattern) || (VIIFormGroup.get('insurance_number')?.dirty && VIIFormGroup.get('insurance_number')?.errors?.required ) ,'is-valid': ( VIIFormGroup.get('insurance_number')?.dirty && VIIFormGroup.get('insurance_number')?.valid)}"
                  type="insurance_number" class="form-control" formControlName="insurance_number" placeholder="">
                <div class="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
              <div class="col-sm-12 my-2">
                <label for="housing_space" class="form-label" translate>home.housing_space</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': ( VIIFormGroup.get('housing_space')?.dirty && VIIFormGroup.get('housing_space')?.errors?.pattern) || (VIIFormGroup.get('housing_space')?.dirty && VIIFormGroup.get('housing_space')?.errors?.required ) ,'is-valid': ( VIIFormGroup.get('housing_space')?.dirty && VIIFormGroup.get('housing_space')?.valid)}"
                  type="housing_space" class="form-control" formControlName="housing_space" placeholder="">
                <div class="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
              <div class="col-sm-12 my-2">
                <label for="monthly_rent" class="form-label" translate>home.monthly_rent</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': ( VIIFormGroup.get('monthly_rent')?.dirty && VIIFormGroup.get('monthly_rent')?.errors?.pattern) || (VIIFormGroup.get('monthly_rent')?.dirty && VIIFormGroup.get('monthly_rent')?.errors?.required ) ,'is-valid': ( VIIFormGroup.get('monthly_rent')?.dirty && VIIFormGroup.get('monthly_rent')?.valid)}"
                  type="monthly_rent" class="form-control" formControlName="monthly_rent" placeholder="">
                <div class="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
              <div class="col-sm-12 my-2">
                <label for="room_numbers" class="form-label" translate>home.room_numbers</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': ( VIIFormGroup.get('room_numbers')?.dirty && VIIFormGroup.get('room_numbers')?.errors?.pattern) || (VIIFormGroup.get('room_numbers')?.dirty && VIIFormGroup.get('room_numbers')?.errors?.required ) ,'is-valid': ( VIIFormGroup.get('room_numbers')?.dirty && VIIFormGroup.get('room_numbers')?.valid)}"
                  type="room_numbers" class="form-control" formControlName="room_numbers" placeholder="">
                <div class="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
              <div class="col-sm-12 my-2">
                <label for="money_required" class="form-label" translate>home.money_required</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': ( VIIFormGroup.get('money_required')?.dirty && VIIFormGroup.get('money_required')?.errors?.pattern) || (VIIFormGroup.get('money_required')?.dirty && VIIFormGroup.get('money_required')?.errors?.required ) ,'is-valid': ( VIIFormGroup.get('money_required')?.dirty && VIIFormGroup.get('money_required')?.valid)}"
                  type="money_required" class="form-control" formControlName="money_required" placeholder="">
                <div class="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
              <div class="col-sm-12 my-2">
                <label for="money_paid" class="form-label" translate>home.money_paid</label>
                <input
                  [style.backgroundPosition]=" lang == 'ar'?'left calc(0.375em + 0.1875rem) center':'right calc(0.375em + 0.1875rem) center'"
                  [ngClass]="{ 'is-invalid': ( VIIFormGroup.get('money_paid')?.dirty && VIIFormGroup.get('money_paid')?.errors?.pattern) || (VIIFormGroup.get('money_paid')?.dirty && VIIFormGroup.get('money_paid')?.errors?.required ) ,'is-valid': ( VIIFormGroup.get('money_paid')?.dirty && VIIFormGroup.get('money_paid')?.valid)}"
                  type="money_paid" class="form-control" formControlName="money_paid" placeholder="">
                <div class="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="submitBtn mt-5 my-3">
          <button mat-button class="btn btn-success" [disabled]="ForthFormGroup.invalid" (click)="add()">
            <span translate>home.submit</span>
            <div *ngIf="loading" class="spinner-border m-2 text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
        <div class="submitBtn my-3">
          <button mat-button matStepperPrevious (click)="step = ( purpose_id[0] != 6)? (6/7)*100:(5/6)*100"
            class="btn btn-primary submitBtn"><span translate>home.back</span></button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
