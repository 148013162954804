import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PleaseLoginComponent} from '../../components/please-login/please-login.component';
import {ApisService} from '../../services/apis.service';

@Component({
  selector: 'app-orphans',
  templateUrl: './orphans.component.html',
  styleUrls: ['./orphans.component.scss']
})
export class OrphansComponent implements OnInit {
  numbers: any;
  cardLogin: boolean = true;
  orphans: any;
  constructor(private apis: ApisService, public dialog: MatDialog) {
    this.numbers = Array(20).fill(0).map((x, i) => i);
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.openDialog();
    // }, 1000);
    this.getOrphans();
  }

  getOrphans(): any {
    this.apis.getOrphans().subscribe((res: any) => {
      this.orphans = res.data;
      console.log(this.orphans);
    }, err => {
      console.log(err);
    });
  }

  openDialog(): any {
    let x = document.querySelectorAll('.col-md-3');
    x.forEach((ele: any) => {
      ele.style.opacity = '0.1';
      ele.style.filter = 'blur(8px)';
    });
    const dialogRef = this.dialog.open(PleaseLoginComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
