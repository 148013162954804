import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {ApisService} from "../services/apis.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DialogData} from "../pages/booking/booking.component";
import {ActivatedRoute} from "@angular/router";
import {id} from "date-fns/locale";
import {DialogCertificatesComponent} from "../dialog-certificates/dialog-certificates.component";
import {DialogexpensesComponent} from "../dialogexpenses/dialogexpenses.component";

@Component({
  selector: 'app-dialog-donation',
  templateUrl: './dialog-donation.component.html',
  styleUrls: ['./dialog-donation.component.scss']
})
export class DialogDonationComponent implements OnInit {
  myDate = new Date();
  mydonations: any;
  expenses: any;
  activitiesincomes: any;
  generalpurpose: any;
  activitiesfromgeneral: any;
  id: any;
  slug: any;
  donationId!:any;

  constructor(private apis: ApisService,
              private activatedRoute: ActivatedRoute,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              @Inject(PLATFORM_ID) private platformId: Object,
              public dialog: MatDialog

  ) {

  }

  ngOnInit(): void {
    this.donationId = this.data['donationId']
    this.getActivitiesIncomesApi(this.donationId);
    this.getGeneralPurposeApi(this.donationId);
    this.getActivitiesFromGeneralApi(this.donationId);

  }
  getActivitiesIncomesApi(id): any {
    console.log(id, "id")
    this.apis.getActivitiesIncomes(id).subscribe((data: any) => {
        this.activitiesincomes = data.data;
        console.log(this.activitiesincomes);
      },
      err => {
        console.log(err);
      });
  }
  getGeneralPurposeApi(id): any {
    console.log(id, "id")
    this.apis.getGeneralPurpose(id).subscribe((data: any) => {
        this.generalpurpose = data.data;
        console.log(this.generalpurpose);
      },
      err => {
        console.log(err);
      });
  }
  getActivitiesFromGeneralApi(id): any {
    console.log(id, "id")
    this.apis.getActivitiesFromGeneral(id).subscribe((data: any) => {
        this.activitiesfromgeneral = data.data;
        console.log(this.activitiesfromgeneral);
      },
      err => {
        console.log(err);
      });
  }

  openexpenses(event) {
    console.log(event)
    const dialogRef = this.dialog.open(DialogexpensesComponent,{
      data: {
        expensesId: event.activity.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
