  <nav class="navbar navbar-expand-lg navbar-light flex-column justify-content-between mobileFixPadding">
    <div class="container-fluid">
      <div class="row px-3 collapse navbar-collapse  d-flex justify-content-center" id="navbarText">
        <div class="nav-item logo col-1">
          <a class=" nav-link-image" routerLink="/">
            <img alt="" src="./assets/images/NEW%20EGYPT%20LOGO%20165.png">
          </a>
        </div>
        <div class="col-8 d-flex flex-column align-items-center" style="max-width: max-content">
          <!--        <div class="header-content d-flex justify-content-end flex-row">-->
          <!--          <div class="lang d-flex">-->
          <!--            <i class="fas fa-globe"></i>-->
          <!--            <span>EN</span>-->
          <!--          </div>-->
          <!--          <div class="line"></div>-->
          <!--          <span>تسجيل الدخول</span>-->
          <!--        </div>-->
          <ul class="navbar-nav">
                <!-- <li class="nav-item active mx-2">
                  <a class="nav-link " routerLinkActive="underline-decoration"
                     [routerLinkActiveOptions]="{exact:true}" routerLink="/" translate>home.home <span class="sr-only">(current)</span></a>
                </li> -->
<!--            *ngIf="isLogin"-->
            <li class="nav-item mx-2" (mouseover)="show=true; hidden=true; index=0" (mouseout)="show=false" >
              
              <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/news" translate>home.news</a>
            </li>
<!--            *ngIf="isLogin"-->
            <li class="nav-item mx-2" (mouseover)="show=true; hidden=true; index=1" (mouseout)="show=false">
               
              <a class="nav-link" routerLinkActive="underline-decoration" (click)="routeToBoards()"  translate>home.board</a>
            </li>
            <li class="nav-item mx-2" >
              <!-- (mouseover)="show=true; hidden=true; index=2" (mouseout)="show=false" -->
               
              <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/volunteers" translate>home.volunteer</a>
            </li>
            <li  class="nav-item mx-2" (mouseover)="show=true; hidden=true; index=3" (mouseout)="show=false">
              
              <a class="nav-link" routerLinkActive="underline-decoration" (click)="routeToAboutUs()" translate>home.about-us</a>
            </li>
<!--            *ngIf="isLogin"-->
            <li class="nav-item mx-2" (mouseover)="show=true; hidden=true; index=4" (mouseout)="show=false">
               
              <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/activities" translate>home.activities</a>
            </li>
            <li class="nav-item mx-2" (mouseover)="show=true; hidden=true; index=5"  (mouseout)="show=false">
              
              <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/join-us" translate>home.join-us</a>
            </li>
<!--            *ngIf="isLogin"-->
            <li class="nav-item mx-2" (mouseover)="show=true; hidden=true;  index=6" (mouseout)="show=false">
              
              <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/contact-us" translate>home.contact-us</a>
            </li>
<!--                      <li class="nav-item">-->
<!--                        <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/sign-in">Sign In</a>-->
<!--                      </li>-->
            <!--          <li class="nav-item search-button d-flex align-items-center">-->
            <!--            <i class="fa fa-search" aria-hidden="true"></i>-->
            <!--            <a class="nav-link" routerLink="/pcr-test-for-travel">بحث</a>-->
            <!--          </li>-->
          </ul>
        </div>
        <div class="col">
          <div class="d-flex justify-content-end">
            <div class="btn-donate-sec mt-1 mr-2" style="cursor: pointer" routerLink="/profile" *ngIf="user && isLogin">
              <img *ngIf="!user.image" height="50px" width="50px" class="circle" src="assets/images/avatar/user-anonymous.png">
              <img *ngIf="user.image"  style="border-radius: 50%; height:50px; width: 50px; border: gray solid 1px" src="{{user.image}}">
            </div>
            <div class="btn-donate-sec mt-2 mr-2" style="cursor: pointer" routerLink="/profile" *ngIf="user && isLogin">
              <h3 class="font-weight-bold text-left mb-0 ">{{user.fullname}}</h3>
              <h5 class="font-weight-bold text-left mb-0">{{user.email}}</h5>
            </div>
            <div class="btn-donate-sec" *ngIf="!isLogin">
              <button class="btn donation mx-2">
                <a class="" routerLinkActive="underline-decoration" routerLink="/sign-in" translate>home.sign-in</a>
              </button>
            </div>
            <div class="btn-donate-sec" *ngIf="isLogin">
              <button class=" btn donation mx-2">
                <a class="" routerLinkActive="underline-decoration" routerLink="/sign-in" (click)="logOut()" translate>home.logout</a>
              </button>
            </div>
<!--            <div class="btn-donate-sec mr-lg-4">-->
<!--              <button class="btn donation">-->

<!--                <a class="donateBtn" routerLinkActive="underline-decoration" routerLink="/donation"  translate>home.donate</a>-->
<!--&lt;!&ndash;                href="https://www.aplos.com/aws/give/newegyptgroup/Supportourmission"&ndash;&gt;-->
<!--              </button>-->
<!--            </div>-->
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="border: none;background:none;height: 70px;width: 61px;">
<!--              <mat-icon>more_vert</mat-icon>-->
<!--              <img src="assets/images/dots.png" alt="dots">-->
              <img src="assets/images/world.png" alt="world" class="">
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)= "getLang('en');refresh()">
                <mat-icon><img class="mb-3" src="./assets/images/USA%20flag.svg"></mat-icon>
                <span >English</span>
              </button>
              <button mat-menu-item (click)="getLang('ar');refresh()">
                <mat-icon>
                  <svg class="mt-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="14" viewBox="0 0 18 14">
                  <image id="Background" width="18" height="14" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAAIUlEQVQokWP8////fwYqACZqGMIwatCoQaMG0ccgBgYGABrkBBiNzHrjAAAAAElFTkSuQmCC"/>
                  <image id="Flag_of_the_United_States" x="-3" width="28" height="14" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAOCAYAAAA8E3wEAAABrklEQVQ4jb3Qz2oTURTH8e8kM2kcFKT2fybQxtYIaZqMpCpEXRTd+wDiolCfw6Ug7vUdSjfFpRu7cFKoQXAitWBsGxtNY9SImpl7Z0ZmfAIZmLM4l3MX93PPT3mw8TgYDIbMz89g221M8xKWZVOvl9nZeUP9xgoNq0VttYj+fJNpd0icUg8Pu1SrS3Q6p1y7XqL5+j1ra1ewGi1u31llt9Hi5q0VrFc2D58+oVIuxAKV9fVHwZlsBl3P0u9/Z2p6nO7JV3K5CbrdPnNzExwf95iZvUCutYuhyXgbhk1K79+gqghXoqQUfN8nCAJc4aGqaaTwqGzcp1TMxQM/dXqUSgtRpAuFWey3HyiXL9JsHlCrXWZvb59KdTGK+uDbR9yzqXigYUzhupJ8fpIvnwcsLxc4Ouphmku02yeY5mI0h/eTeR3DGI8Hhs3zwviIonMcgaqlo09oWpqRI8hkNISQOKcD/ihuPDCvCvAFqV8OeiDRRpIsDmO/AzLR+YMx3yErPM7r52JhYSn21nYQ+5X/KLXz4mWSHkrv3X6iGyqbd+8lC46GP5MFnxWvJgcCfwHeqa5gGhkcVgAAAABJRU5ErkJggg=="/>
                  <image id="Flag_of_Egypt.svg" x="-1" width="21" height="14" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAOCAYAAADABlfOAAAAy0lEQVQ4jbWSOwrCQBRFz0zG+AcLCwtbQexdgOtIbyO4Gvs02rsCd+AKRLC0EIKfSDSfkWQBzgjjaaa5nPeGd8V2MLnhlrUCuo6lfelYWPJQ9/PRmJL4gKYgtbKq8XzxNeDV2mSvGwiJ8jvkaWyUCq21NoUK4mpfSdNm0VCZEof9iuf1VElb3SGj6dJoNR8qf0ORIXRRvTZYfD9BRzuEkNCbAXWTNlRBEHxNCOmRJhHlbL+xQZcbGxBVV9wS/qP8Fof6nU5ZqbtTJVw+XYI7wCktz8MAAAAASUVORK5CYII="/>
                </svg>
                </mat-icon>
                <span >عربي</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="hidden">
    <div class="popup-header" [class]="show ? 'd-block' : 'd-none'" (mouseover)="show=true" (mouseout)="show=false">
      <div class="menu-dropdown visible-desktop" data-index="0">
        <div class="relative-wrapper">
          <div class="container">
            <div class="row">
              <!-- BEGIN first second wrapper -->
              <div class="col-md-6">
                <div class="row top-menu-row">
              <!-- BEGIN Intermediate Parent section -->
              <div class="col-md-6 offset-md-1">
                <div class="section">
                  <h3 class="font-nav-title text-secondary-d5-navy">{{dataCategories[index]?.result.title}}</h3>
                </div>
              </div>
              <!-- END Intermediate Parent section -->

              <!-- BEGIN Intermediate Parent section
<!--              <div class="col-md-5 offset-md-1">
<!--                <div class="section">
<!--                  <h3 class="font-nav-title text-secondary-d5-navy">Our Foundation</h3>
<!--                </div>
<!--              </div>
               END Intermediate Parent section -->
            </div>
              <div class="row">
               <div class="col-md-12 offset-md-1">
                <div class="section" *ngFor="let item of dataCategories[index]?.result.categories;let i = index">
                <ul>
                  <li class="header-menu-track" >
                    <a href="" data-uw-rm-brl="false" class="header-menu" [routerLink]="item.url" (click)="hidden=false" >{{item.list}}</a>
                  </li>
                </ul>       
              </div>
              <!-- <div class="row">
              <div class="col-12 offset-md-1">
              <img *ngIf="dataCategories[index]?.result"
                     src="{{dataCategories[index]?.result.image}}" class="img-fluid" alt="" style="height: 250px !important;">
                     </div>
                     </div> -->
            </div>
<!--              <div class="col-md-5 offset-md-1">-->
<!--               <div class="section">-->
<!--                <ul  style="color: black">-->
<!--                  <li class="header-menu-track">-->
<!--                    <a href="" data-uw-rm-brl="false"  style="color: black;font-size: 20px;line-height: 40px">LCIF Mission</a>-->
<!--                  </li>-->
<!--                  <li class="header-menu-track">-->
<!--                    <a href="" data-uw-rm-brl="false"  style="color: black;font-size: 20px;line-height: 40px">History</a>-->
<!--                  </li>-->
<!--                  <li class="header-menu-track">-->
<!--                    <a href="" data-uw-rm-brl="false"  style="color: black;font-size: 20px;line-height: 40px">Affiliations + Partnerships</a>-->
<!--                  </li>-->
<!--                  <li class="header-menu-track">-->
<!--                    <a href="" data-uw-rm-brl="false"  style="color: black;font-size: 20px;line-height: 40px">Meet the Leaders</a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
              <!-- END first second wrapper -->
              <!-- BEGIN Image URL -->
              <div class="col-md-6  promo-banner-wrapper">
                <a href="" data-uw-rm-brl="false">
                  <div class="promo">
                    <img [src]="'./assets/images/menu-photos/'+ dataCategories[index]?.result.image">
                    <!-- BEGIN Image title section -->
                    <div class="promo-text">
                      <h4 class="font-eyebrow text-white"></h4>
                      <span class="menu-title-text"></span>
                    </div>
                    <!-- END Image title section -->
                  </div>
                </a>
              </div>
              <!-- END Image URL -->
            </div>
          </div>
        </div>
      </div>
    </div>
    </ng-container>
  </nav>
