import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ApisService} from "../services/apis.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogData} from "../pages/booking/booking.component";

@Component({
  selector: 'app-cases-belong-volunteer',
  templateUrl: './cases-belong-volunteer.component.html',
  styleUrls: ['./cases-belong-volunteer.component.scss']
})
export class CasesBelongVolunteerComponent implements OnInit {
  casesBelongVolunteer: any;
  Cases!: any;

  constructor(
    private apis: ApisService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    this.Cases = this.data['Cases']
    // this.getCasesBelongVolunteer();

  }
  // getCasesBelongVolunteer(): any {
  //   this.apis.casesBelongVolunteer().subscribe((data: any) => {
  //       this.casesBelongVolunteer = data.data.data;
  //       console.log(this.casesBelongVolunteer);
  //     },
  //     err => {
  //       console.log(err);
  //     });
  // }

}
