import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApisService} from '../services/apis.service';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {
  activities: any;
  slug: any;
  counter: any[]=[];
  days;
  hours;
  myTime;
  dateNow;
  label=['Days','Hours','minutes','Seconds']
  Days=['Days']
  Hours=['Hours']
  minutes=['minutes']
  Seconds=['Seconds']

  constructor(public dialog: MatDialog,
              private apis: ApisService,
              @Inject(MAT_DIALOG_DATA) public data
  ) {
  }


  ngOnInit(): void {
    // this.getActivitiesApi();
    setInterval(() => {
      let date = (new Date()).toISOString().slice(0, 10);
      let time = (new Date()).toISOString().slice(11, 19);
      this.dateNow = (date+"T"+time+"Z");
      this.parseDates(this.data,this.dateNow);
    }, 1000);



  }

  getActivitiesApi(): void {
    this.apis.getActivities(this.slug).subscribe((data: any) => {
      console.log(data);
      this.activities = data.data;
    }, (err: any) => {
    });
  }


  parseDates(toValue, now): void {

    var start = Date.parse(toValue + "T" + "00:00:00Z");

    var dateNow = Date.parse(now);

    if (start < dateNow) {
      console.log("end")
    }

    if (isNaN(start) || isNaN(dateNow) || (start <  dateNow)) {
      return;
    }
    var deltaSeconds = ((start - dateNow) / 1000);
    this.counter = this.format(this.calculateDaysHoursMinutesSeconds(deltaSeconds))
    this.counter = this.counter.filter(
      (value, index) => {
        return (!index || !value.toString().startsWith("00"));
        console.log(this.counter);

      }
    );

  }
  private format(values: number[]): any {
    var parts: number[] = [];
    for (var value of values.slice().reverse()) {
      parts.unshift(value);
      console.log(parts[0]);
      // this.days=parts[0]
      // this.hours=parts[1]
      this.myTime=parts[1]
      // console.log(this.days);
      // console.log(this.hours);
    }

    console.log(parts,'parts')
    return (parts);
  }
  private calculateDaysHoursMinutesSeconds(delta: number): number[] {
    var days = Math.floor(delta / 60 / 60 / 24);
    var remainder = (delta - (days * 60 * 60 * 24));
    return ([days, ...this.calculateHoursMinutesSeconds(remainder)]);
  }

  private calculateHoursMinutesSeconds(delta: number): number[] {
    var hours = Math.floor(delta / 60 / 60);
    var remainder = (delta - (hours * 60 * 60));
    return ([hours, ...this.calculateMinutesSeconds(remainder)]);
  }

  private calculateMinutesSeconds(delta: number): number[] {
    var minutes = Math.floor(delta / 60);
    var remainder = (delta - (minutes * 60));
    return ([minutes, ...this.calculateSeconds(remainder)]);
  }

  private calculateSeconds(delta: number): number[] {
    return ([delta]);
  }

}
