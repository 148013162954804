<img style="width: 100%; margin-bottom: 4px" src="assets/images/gray-plane-wing-62623.png">
<mat-tab-group  >
<mat-tab *ngFor="let club of clubs" >
  <ng-template mat-tab-label>
    <span (click)="getBoard(club.club_id)">{{club.club_name}}</span>
</ng-template>
<section class="boards">
  <div class="container">
    <div class="row" [formGroup]="form">
      <div class="col-4">
          <section>
              <div class="mt-5">
                  <div class="sticky-content">
                      <div class="social-icons social-icons-colored social-icons-vertical">
                          <div class="icon-share">
                              <span class="social-label " translate>home.share</span>
                              <a class="social-icon image-item" (click)="cop()"
                                 target="_blank">
                                  <img *ngIf="!copied" title="copy" style="width: 22px"
                                       alt="copy"
                                       src="./assets/newIcon/copy-2.png"/>
                                  <img *ngIf="copied" title="copied" style="width: 28px"
                                       alt="copied"

                                       src="./assets/newIcon/copied.png"/>
                              </a>
                              <a (click)="fbc($event)" class="social-icon social-facebook"
                                 title="Facebook"><i
                                      class="bi bi-facebook"></i></a>
                              <a (click)="tw()" class="social-icon social-twitter"
                                 title="Twitter"><i
                                      class="bi bi-twitter"></i></a>
                              <a (click)="in()" class="social-icon social-linkedin"
                                 title="Linkedin"><i
                                      class="bi bi-linkedin"></i></a>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      <div class="col-4 text-left mt-4">

        <mat-form-field appearance="fill" *ngIf="boards!=null" class="w-100">
          <mat-label translate>home.date</mat-label>
          <mat-select formControlName="board_id" required  placeholder="{{ 'home.choose Date' | translate }}" ng-model="myModel">
            <mat-option *ngFor="let bd of boards" [value]="bd.id" (click)="getBoardMember(bd.id , form.get('club_id').value)">
              {{bd.board}}</mat-option>
          </mat-select>
<!--          <mat-error *ngIf="board_id.hasError('required')" translate>home.Please choose Date</mat-error>-->
        </mat-form-field>
      </div>
      <div class="col-4 text-left mt-4" *ngIf="getBoardClubsMember.length > 0" >
        <!--      *ngIf="getBoardClubsMember!=null"-->
        <mat-form-field appearance="fill" class="w-100">
          <mat-label translate>home.club</mat-label>
          <mat-select formControlName="club_id" required  placeholder="{{ 'home.board-members' | translate }}" ng-model="myModel">
            <mat-option *ngFor="let item of getBoardClubsMember"   [value]="item.id" (click)="getBoardMember(form.get('board_id').value,item.id );getBoard(item.id)">
              {{item.name}}</mat-option>
          </mat-select>
<!--          <mat-error *ngIf="club_id.hasError('required')" translate>home.board-members</mat-error>-->
        </mat-form-field>
      </div>
    </div>
  </div>
</section>
</mat-tab>
</mat-tab-group>
<div class="row">
      <div class="col-12"  *ngIf="!loading">
       <app-member-slider *ngIf="boardMember!=null" [members]="boardMember"></app-member-slider>
      </div>
      <div *ngIf="loading" class="loading">
        <mat-spinner></mat-spinner>
      </div>
</div>
