import {Component, Injectable, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ApisService} from '../../../services/apis.service';
import {Router} from '@angular/router';

@Injectable()
@Component({
  selector: 'app-home-videos-section',
  templateUrl: './home-videos-section.component.html',
  styleUrls: ['./home-videos-section.component.scss']
})
export class HomeVideosSectionComponent implements OnInit {
  videoUrl = 'https://player.vimeo.com/external/555380977.hd.mp4?s=e20422d7b72b89b39f9d245a8082be81cc3f5a2a&profile_id=175&download=1';
  readMore = true;
  carouselOptions: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };
  videos: any;

  constructor(
    private apis: ApisService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    // this.getVideos();
  }

  // getVideos(): void {
  //   this.apis.videosApi().subscribe((response: any) => {
  //     this.videos = response.data;
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  transform(n: string): string {
    var date = new Date(n);
    var months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    var days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }

  videDetails(id): void {
    this.router.navigate(['videos-description', id]);
  }

  prevent(e): void {
    e.preventDefault();
  }
}
